import React, { useEffect, useState, useMemo } from "react";
import { listApi } from "../../../../services/admin/request";
import useAuth from "../../../../hooks/useAuth";
import TableBase from "../../../../components/Admin/TableBase";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

export default function Product() {
    const { logout } = useAuth()
    const [currency, setCurrency] = useState(null)

    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id",
            },
            {
                Header: "NAME",
                accessor: "name",
            },
            {
                Header: "ACTIVE",
                accessor: "active",
            },
            {
                Header: "ACTIONS",
                accessor: "actions",
            },
        ],
        []
    )

    useEffect(() => {
        (async () => {
            const response = await listApi(logout, "currency");
            if (response.status) {
                const temp = response.data.map((doc) => {
                    let href = `/admin/currency/edit/${doc.id}`;

                    var active = "No";
                    if (doc.isActive) {
                        active = "Yes";
                    }

                    return {
                        id: doc.id,
                        active: active,
                        actions: (
                            <Link color="primary" href={href}>
                                Edit
                            </Link>
                        ),
                        ...doc,
                    };
                });
                setCurrency(temp)
            }
        })()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    if (!currency) return ""

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={6} md={6} lg={6}>
                    <h1>Maintain Currencies</h1>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ float: "right", margin: "20px 0px" }}
                        href="/admin/currency/create"
                    >
                        + add
                    </Button>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box border={1} className="box-default">
                        <TableBase 
                            columns={columns} 
                            data={currency}
                            selectedRows={currency}
                            onSelectedRowsChange={setCurrency}
                            search={true}/>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
