import React, {useEffect, useState} from "react";
import {Grid} from "@mui/material";
import Box from "@material-ui/core/Box";
import NewTable from "../../../../components/Admin/NewTable";
import {listApi} from "../../../../services/admin/request";
import useAuth from "../../../../hooks/useAuth";
export default function Products(props) {

    const {companyId, product, setProduct, stateField} = props
    const [rows, setRows] = useState([])
    const [state, setState] = useState(initialValues())
    const [loading, setLoading] = useState(true)
    const {logout} = useAuth()

    useEffect(async () => {
        if (companyId) {
            setLoading(true)
            setRows([])
            const response = await listApi(logout, "product", `?companyId=${companyId}`);
            if (response.status) {
                const temp = []
                await response.data.forEach((doc) => {
                    // buscamos en el producto el producto que seleccionamos
                    let tempPrice = product.find(item => item.id === parseInt(doc.id))

                    if (doc.isActive === "1") {
                        temp.push({
                            ...doc,
                            price: tempPrice ? tempPrice.price : null
                        })
                    }
                })
                setRows(temp)
                
                setLoading(false)
            } else {
                setLoading(false)
                setRows([])
            }
        } else {
            setLoading(false)
        }

    }, [companyId, stateField.products])

    const handleChangeCell = (e) => {

        let idProduct = parseInt(e.target.id)
        let price = e.target.value

        // Detectamos si el id existe en el array y lo añádimos o actualizamos array
        if (product.find(item => parseInt(item.id) === idProduct)) {
            // Actualizamos quantity en el array
            product.map(item => {
                if (item.id === idProduct) {
                    item.price = price
                }
            })

            // Eliminamos en el caso de que el quantity sea 0
            if (!price || price === '0' || price === 0) {
                product.splice(product.findIndex(item => item.id === idProduct), 1)
            }
            setProduct([...product])
        } else if (price > 0) {
            setProduct([...product, {
                id: idProduct,
                price: price
            }])
        }

    }

    return (
        <Grid item xs={12}>
            <Box border={1} className="p-20 box-default">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12} className="border-bottom">
                        <h5 className="p-0 mt-0">Product line</h5>
                    </Grid>

                    <Grid item xs={12} container spacing={1}>
                        <NewTable
                            columns={tableColumns()}
                            handleChangeCell={handleChangeCell}
                            rows={rows}
                            filter={state}
                            setFilter={setState}
                            loading={loading}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    )
}

function tableColumns() {

    return [
        {
            field: "name",
            headerName: "PRODUCT",
            flex: 2
        },
        {
            field: "description",
            headerName: "DESCRIPTION",
            flex: 2
        },
        {
            field: "price",
            headerName: "PRICE",
            editable: true,
            flex: 1
        }
    ]
}

function initialValues(props) {
    return {
        offset: 0,
        sort: {field: "id", sort: "desc"},
    }
}
