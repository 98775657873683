import React, {useEffect, useMemo, useState} from "react";
import useAuth from "../../../../../hooks/useAuth";
import TableBase from "../../../../../components/User/TableBase";
import {listApi} from "../../../../../services/user/request";
import Loading from "../../../../../components/User/Loading";
import moment from "moment/moment";

export default function Location(props) {

    const {logout} = useAuth()

    const {
        regionSelected,
        subregionSelected,
        countrySelected,
        locationSelected,
        dateFromSelected,
        dateToSelected,
        locationCategorySelected,
        loading
    } = props

    const [elements, setElements] = useState(null)
    const [headerCol, setHeaderCol] = useState([])
    const headerColOriginal = [
        {
            Header: "ORDER NUMBER",
            accessor: "id",
        },
        {
            Header: "DATE / TIME",
            accessor: "creationDate",
        },
        {
            Header: "NAME",
            accessor: "userName",
        },
        {
            Header: "PACKS",
            accessor: "pack",
        },
        {
            Header: "STATUS",
            accessor: "status",
        }
    ]

    const columns = useMemo(
        () => headerCol,
        [headerCol]
    )

    useEffect(() => {
        (async () => {
            const response = await listApi(logout, `report/location/location`, {
                idRegion: regionSelected?.id,
                idSubregion: subregionSelected?.id,
                idCountry: countrySelected?.id,
                idLocation: locationSelected?.id,
                dateFrom: dateFromSelected,
                dateTo: dateToSelected,
                idLocationCategory: locationCategorySelected?.id
            });
            if (response.status) {
                let col = response.data.infoPrice.map(doc => {
                    return {
                        Header: `PRICE (${doc.symbol})`,
                        accessor: doc.col
                    }
                })
                let colConcat = headerColOriginal.concat(col)
                setHeaderCol(colConcat)

                const temp = response.data.elements.map((doc) => {
                    return {
                        ...doc,
                        userName: `${doc.userName} ${doc.userSurnames}`,
                        creationDate: moment.unix(doc.creationDate).utc().format('DD-MMM-YYYY HH:mm A')
                    };
                });
                setElements(temp)
            }
        })()
    }, [regionSelected, subregionSelected, locationSelected, dateFromSelected, dateToSelected, countrySelected, locationCategorySelected]) // eslint-disable-line react-hooks/exhaustive-deps

    if (!elements) return ""

    return (
        <>
            <Loading show={loading} title="Fetching Store Reports..."/>

            {!loading && (
                <TableBase
                    columns={columns}
                    data={elements}
                    search={false}
                    urlDetail=""
                />
            )}

        </>
    );
}
