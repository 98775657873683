import Frontend from "../../../../containers/User/Layouts/Frontend";
import ReportStockPage from "../../../../containers/User/Reports/Stock";

export default function Report() {
    return (
        <Frontend title="Send Reports" menu="sendReportsStock">
            <ReportStockPage />
        </Frontend>
    );
}
