import Basic from "../../../../containers/Admin/Layouts/Basic"
import ContainersCreate from "../../../../containers/Admin/Region/Subregion/Fields"

export default function Create() {
  return (
    <Basic menu="region">
          <ContainersCreate />
    </Basic>
  )
}
