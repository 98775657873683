import React, {useEffect, useRef, useState} from "react";
import {LoadingButton} from "@mui/lab";
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";

/**
 * Muestra un botón para subir ficheros
 *
 * ## PROPS
 * - **label** *string*
 * - **name** *string*
 * - **onChange** *function*
 * - **required** *bool*
 * - **multiple** *bool*
 * - **loading** *bool*
 *
 * ## RETURN
 * - **multiple == true**
 *
 * {target: {name: name, value: [{binary: binary, base64: base64}]}}
 * - **multiple == false**
 *
 * {target: {name: name, value: {binary: binary, base64: base64}}}
 */
export default function InputButton(props) {

    const {color, text, onClick, disabled, variant, position, style} = props

    const colorIn = color ? color : "primary"
    const disabledIn = disabled ? disabled : false
    const variantIn = variant ? variant : 'contained'
    const positionIn = position ? position : 'right'

    return (
        <>
            <button
                color={colorIn}
                onClick={onClick}
                disabled={disabledIn}
                style={{
                    float: positionIn,
                    border: 'none',
                    borderRadius: '5px',
                    padding: '8px 20px',
                    backgroundColor: '#ef6f28',
                    color: 'white',
                    fontSize: '14px',
                    ...style
                }}
            >
                {text}
            </button>
        </>
    )
}
