import React, {useEffect, useState} from "react";
import useAuth from "../../../../../hooks/useAuth";
import {useParams} from "react-router-dom";
import {getTransaction} from "../../../../../services/user/transaction";
import ProductBox from "../ProductBox"
import Footer from "../Footer";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles({
    root: {
        position: "sticky",
        top: 0,
        zIndex: 100,
        backgroundColor: "white",
        marginBottom: 20,
        width: "100%",
        marginTop: 5,
        paddingTop: 7
    },

});

export default function UpdateCartOrder(props) {
    const classes = useStyles();

    const {logout} = useAuth();

    const {id} = useParams();

    const {
        setTotalCartProducts,
        totalCartProducts,
        isViewCart
    } = props

    const [products, setProducts] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [details, setDetails] = useState({})

    useEffect(() => {
        (async () => {
            await getTransactionDetails()
        })()

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const calculatePrice = () => {
        const cart = JSON.parse(localStorage.getItem("cart_products"));

        let tempPrice = [];
        cart?.forEach((storage) => {
            if (!tempPrice[storage.currencySymbol]) {
                tempPrice[storage.currencySymbol] = null;
            }
            tempPrice[storage.currencySymbol] =
                tempPrice[storage.currencySymbol] +
                (storage.priceDefault * storage.quantity);
        });

        setTotalPrice(tempPrice);
    };

    const getTransactionDetails = async () => {
        const responseTransaction = await getTransaction(logout, id);

        if (responseTransaction.status) {
            setDetails(responseTransaction.data.details)
        }
    }

    useEffect(() => {
        (async () => {
            await setProducts([]);
            updateUi();
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalCartProducts, isViewCart])

    const updateUi = () => {
        let cart = JSON.parse(localStorage.getItem("order_products"));
        setProducts(cart);
    }

    const number_format = (user_input) => {

        for (const property in user_input) {
            user_input[property] = parseFloat(user_input[property]).toFixed(2)
        }

        return user_input;

    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <div className="text-body pb-2">
                        <span className="small">Company:</span>
                        <span className="font-weight-bold small px-2">
                                        {details?.company_name}
                                    </span>
                    </div>
                    <div className="text-body pb-2">
                        <span className="small">User Name:</span>
                        <span className="font-weight-bold small px-2">
                                        {details?.user_name} {details?.user_surnames}
                                    </span>
                    </div>

                    <div className="text-body pb-2">
                        <span className="small">Store:</span>
                        <span className="font-weight-bold small px-2">
                                        {details?.location_name}
                                    </span>
                    </div>

                    <div className="text-body pb-2">
                        <span className="small">Address:</span>
                        <span className="font-weight-bold small px-2">
                                        <span>
                                            {details?.location_address1}
                                            {details?.location_address2 && (
                                                <span>, <br/></span>
                                            )}
                                        </span>
                                        <span>
                                            {details?.location_address2}
                                            {details?.location_address3 && (
                                                <span>, <br/></span>
                                            )}
                                        </span>
                                        <span>
                                            {details?.location_address3}
                                            {details?.location_address4 && (
                                                <span>, <br/></span>
                                            )}
                                        </span>
                                        <span>
                                            {details?.location_address4}
                                            {details?.location_address5 && (
                                                <span>, <br/></span>
                                            )}
                                        </span>
                                        <span>{details?.location_address5}</span>
                                    </span>
                    </div>

                    <div className="text-body pb-2">
                        <span className="small">Store Email:</span>
                        <span className="font-weight-bold small px-2">
                                        {details?.location_emails}
                                    </span>
                    </div>

                    <div className="text-body pb-2">
                        <span className="small">Store Tel:</span>
                        <span className="font-weight-bold small px-2">
                                        {details?.location_phone}
                                    </span>
                    </div>
                </Grid>

                <Grid item xs={12} md={6}>
                    <div className="text-body pb-2">
                        <span className="small">Order Date:</span>
                        <span className="font-weight-bold small px-2">
                                    {/*{dateOrder}*/}
                                </span>
                    </div>

                    <div className="text-body pb-2">
                        <span className="small">No of Packs:</span>
                        <span className="font-weight-bold small px-2">
                                    {details?.numberOfPacks}
                                </span>
                    </div>

                    <div className="text-body pb-2">
                        <span className="small">Order No:</span>
                        <span className="font-weight-bold small px-2">
                                    {details?.transaction_id}
                                </span>
                    </div>
                    <div className="text-body pb-2">
                        <span className="small">Status:</span>
                        <span className="font-weight-bold small px-2">
                                    {details?.status_name}
                                </span>
                    </div>

                    <div className="text-body pb-2">
                        <span className="small">Grand total:</span>
                        <span className="font-weight-bold small px-2">
                                    {details?.price}
                                </span>
                    </div>

                    <div className="text-body pb-2">
                        <span className="small">Num REF/PO#:</span>
                        <span className="font-weight-bold small px-2">
                                    {details?.transaction_ref}
                                </span>
                    </div>
                    <div className="text-body pb-2">
                        <span className="small">Num REF/PO# (TOV):</span>
                        <span className="font-weight-bold small px-2">
                                    {details?.transaction_refTov}
                                </span>
                    </div>
                    <div className="text-body pb-2">
                        <span className="small">Comment:</span>
                        <span className="font-weight-bold small px-2">
                                    {details?.transaction_comment}
                                </span>
                    </div>
                </Grid>
                <hr/>
            </Grid>
            <div className={classes.root}>
                <Grid container>
                    <Grid item xs={12}>
                        <Grid container style={{backgroundColor: "#f1f2f6", borderRadius: 10, padding: 10}}>
                            <Grid item xs={2} className={"text-center"}>
                                <span className={"h5"}>Image</span>
                            </Grid>
                            <Grid item xs={3} className={"text-center"}>
                                <span className={"h5"}>Item Description</span>
                            </Grid>
                            <Grid item xs={2} className={"text-center"}>
                                <span className={"h5"}>Quantity</span>
                            </Grid>
                            <Grid item xs={3} className={"text-center"}>
                                <span className={"h5"}>Pack price</span>
                            </Grid>
                            <Grid item xs={2} className={"text-center"}>
                                <span className={"h5"}>Total Price</span>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <Grid item xs={12}>
                <ul className="list-group list-group-flush list-group-no-gutters">
                    {products?.map((doc, key) => {

                        if (!doc.delete) {

                            return (
                                <li key={key} className="list-group-item">
                                    <ProductBox
                                        id={doc.id}
                                        name={doc.name}
                                        description={doc.description}
                                        price={doc.price}
                                        priceDefault={doc.priceDefault}
                                        category={doc.categoryName}
                                        image={doc.image}
                                        currencySymbol={doc.currencySymbol}
                                        unitsPerPack={doc.unitsPerPack}
                                        stockOnHand={doc.stockOnHand}
                                        quantityDefault={doc.quantity}
                                        unitDefault={doc.unit}
                                        quantityIsModify={true}
                                        setTotalCartProducts={setTotalCartProducts}
                                        calculatePrice={calculatePrice}
                                    />
                                </li>
                            )

                        }
                    })}
                </ul>
            </Grid>
            <Grid item xs={12}>
                <Footer
                    totalPrice={number_format(totalPrice)}
                    id={id}
                />
            </Grid>

        </>
    )
}
