import React, {useEffect, useRef, useState} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import InputText from "../../../../components/Admin/Inputs/InputText";
import MsgError from "../../../../components/Admin/MsgError";
import MsgSuccess from "../../../../components/Admin/MsgSuccess";
import {Grid} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import LoadingBar from "../../../../components/Admin/LoadingBar";
import Box from "@material-ui/core/Box";
import {
    apiCreate,
    apiUpdate,
    apiGetContainer,
    apiListSemester,
    apiListStatus
} from "../../../../services/admin/container";
import {getWarehouseApi} from "../../../../services/admin/warehouse";
import Products from "./Products";
import useAuth from "../../../../hooks/useAuth";
import InputSelect from "../../../../components/Inputs/InputSelect";
import {listApi} from "../../../../services/admin/request";
import DatePickerSimple from "../../../../components/Admin/Inputs/DatePickerSimple";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import moment from "moment";
import InputButtonLoading from "../../../../components/Inputs/InputButtonLoading";
import LoadingSpinner from "../../../../components/LoadingSpinner";

export default function Edit() {
    const {id, idWarehouse} = useParams()
    const history = useHistory()
    const {logout} = useAuth()

    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [state, setState] = useState(initialValues)
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState("")
    const [company, setCompany] = useState([])
    const [loadingGetInfo, setLoadingGetInfo] = useState(true)
    const [product, setProduct] = useState([])
    const [listStatus, setListStatus] = useState([])
    const [listSemester, setListSemester] = useState([])

    const myRef = useRef(null)

    useEffect(() => {
        getCompany()
        getSemester()
        getStatus()
        if (id) {
            void getContainer()
        } else {
            setLoadingGetInfo(false)
            getWarehouses(idWarehouse)
            state["warehouseId"] = idWarehouse
            setState({...state})
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        // Buscamos el code del stado
        const status = listStatus.find((doc) => doc.id === state.statusId)
        if (status?.code === "CONTAINER_CODE__CONFIRMED" && !state.dateAtaWhse) {
            const date = moment().format("YYYY-MM-DD")
            state.dateAtaWhse = moment(date).format("X")
            setState({...state})
        }

    }, [state.statusId])

    const getWarehouses = async (idWarehouse) => {
        const response = await getWarehouseApi(logout, {id: idWarehouse})
        if (response.status) {
            setName(response?.data?.warehouse?.name)
        }
    }

    const getCompany = async () => {
        const response = await listApi(logout, 'company')
        if (response.status) {
            const temp = response.data.map((doc) => {
                return {
                    id: doc.id,
                    name: doc.name,
                }
            })
            setCompany(temp)
        }
    }

    const getSemester = async () => {
        apiListSemester().then(resp => {
            setListSemester(resp.data)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }

    const getStatus = async () => {
        apiListStatus().then(resp => {
            setListStatus(resp.data)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }

    const getContainer = () => {
        setMsgError([])

        apiGetContainer(id).then(resp => {
            setProduct(resp.data?.data.products)
            setState(initialValues(resp.data?.data))
            setLoadingGetInfo(false)

            // Conaultar
            getWarehouses(resp.data?.data?.warehouseId)
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response.data?.msg)
        })
    }

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        setLoading(true)
        setMsgError([])
        setMsgSuccess([])

        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            setLoading(false)
            myRef.current.scrollIntoView()
            return
        }

        if (id) {
            void updateContainer()
        } else {
            void createContainer()
        }
    }

    const updateContainer = () => {

        setMsgError([])
        setMsgSuccess([])
        state['statusIdOriginal'] = state.statusId
        setState({...state})

        let stateTemp = {...state, warehouseId: idWarehouse, id: id}
        apiUpdate(stateTemp).then(resp => {
            setMsgSuccess(resp.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }

    const createContainer = () => {
        setMsgError([])
        setMsgSuccess([])
        let stateTemp = {...state, warehouseId: idWarehouse}
        apiCreate(stateTemp).then(resp => {
            history.push(`/admin/containers/edit/${resp.data?.id}`)
            setMsgSuccess(resp.data?.msg)
            setLoading(false)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }

    return (
        <>
            <div ref={myRef}></div>

            <HeaderPage
                title="Maintain Warehouses"
                subtitle={name}
                urlBack={`/admin/warehouse/edit/${state.warehouseId}/5`}
            />

            <LoadingSpinner loading={loadingGetInfo}/>

            {!loadingGetInfo && (
                <>
                    <MsgError msg={msgError}/>
                    <MsgSuccess msg={msgSuccess}/>

                    <Grid container spacing={3}>

                        <Grid item xs={12}></Grid>

                        <Grid item xs={12} container spacing={1}>
                            <Grid item xs={6} style={{paddingBottom: 10}}>
                                <InputSelect
                                    label="Company"
                                    name="idCompany"
                                    onChange={handleChange}
                                    value={state?.idCompany}
                                    options={company}
                                    disabled={id ? true : false}
                                    multiple={state?.idCompany?.length > 1 ? true : false}
                                />
                            </Grid>

                            <Grid item xs={6} style={{paddingBottom: 10}}>
                                <InputSelect
                                    label="Status"
                                    name="statusId"
                                    required
                                    onChange={handleChange}
                                    value={state?.statusId}
                                    options={listStatus}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <InputText
                                    label="Container reference"
                                    required
                                    name="containerReference"
                                    value={state?.containerReference}
                                    onChange={handleChange}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <InputText
                                    label="IDP Invoice"
                                    required
                                    name="idpInvoice"
                                    value={state?.idpInvoice}
                                    onChange={handleChange}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <InputSelect
                                    label="Semestre"
                                    name="semester"
                                    onChange={handleChange}
                                    value={state?.semester}
                                    options={listSemester}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <DatePickerSimple
                                    label="ETD"
                                    name="dateEtd"
                                    value={state?.dateEtd}
                                    onChange={handleChange}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <DatePickerSimple
                                    label="ETA Port"
                                    name="dateEtaPort"
                                    value={state?.dateEtaPort}
                                    onChange={handleChange}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <DatePickerSimple
                                    label="ETA Whse"
                                    name="dateEtaWhse"
                                    value={state?.dateEtaWhse}
                                    onChange={handleChange}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <DatePickerSimple
                                    label="ATA Whse"
                                    name="dateAtaWhse"
                                    value={state?.dateAtaWhse}
                                    onChange={handleChange}
                                />
                            </Grid>

                            <Grid item xs={12} style={{paddingBottom: 15}}>
                                <InputText
                                    label="Comments"
                                    name="comments"
                                    rows={4}
                                    multiline={true}
                                    value={state?.comments}
                                    onChange={handleChange}
                                />
                            </Grid>

                            <Grid item xs={8}></Grid>
                            <Grid item xs={4}>
                                <InputButtonLoading
                                    onClick={handleSubmit}
                                    loading={loading}
                                    label={id ? 'Save container' : 'Create container'}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <div style={{height: 15}}></div>
                            </Grid>

                        </Grid>
                    </Grid>

                    <Products
                        idContainer={id}
                        idCompany={state?.idCompany}
                        idWarehouse={state?.warehouseId}
                        setMsgError={setMsgError}
                        product={product}
                        setProduct={setProduct}
                        stateField={state}
                        listStatus={listStatus}
                        handleSubmit={handleSubmit}
                        loadingContainerSave={loading}
                        statusId={state?.statusId}
                        setMsgSuccess={setMsgSuccess}
                        myRef={myRef}
                    />
                </>
            )}

        </>
    )
        ;
}

function initialValues(props) {
    let dateEtd = props?.dateEtd ? props?.dateEtd.toString() : ""
    let dateEtaPort = props?.dateEtaPort ? props?.dateEtaPort.toString() : ""
    let dateEtaWhse = props?.dateEtaWhse ? props?.dateEtaWhse.toString() : ""
    let dateAtaWhse = props?.dateAtaWhse ? props?.dateAtaWhse.toString() : ""

    return {
        id: props?.id || "",
        idCompany: props?.company || null,
        containerReference: props?.containerReference || "",
        idpInvoice: props?.idpInvoice || "",
        comments: props?.comments || "",
        semester: props?.semester || "",
        dateEtd: dateEtd || "",
        dateEtaPort: dateEtaPort || "",
        dateEtaWhse: dateEtaWhse || "",
        dateAtaWhse: dateAtaWhse || "",
        warehouseId: props?.warehouseId || "",
        products: props?.products || "",
        statusId: props?.statusId || 3,
        statusIdOriginal: props?.statusId || 3,
    }
}

function validationSchema(schema) {

    let error = []
    if (!schema.idCompany) {
        error.push("Container company is required")
    }
    if (!schema.containerReference) {
        error.push("Container reference is required")
    }
    if (!schema.idpInvoice) {
        error.push("IDP Invoice is required")
    }
    if (!schema.semester) {
        error.push("Semester is required")
    }
    if (!schema.dateEtd) {
        error.push("Date ETD is required")
    }
    if (!schema.statusId) {
        error.push("Status is required")
    }
    if ((!schema.dateAtaWhse || schema.dateAtaWhse == "Invalid date") && schema?.statusId == 2) {
        error.push("Date ATA is required for confirming the reception")
    }
    return error
}
