import Frontend from "../../../../containers/User/Layouts/Frontend";
import ReportSupplyChainPage from "../../../../containers/User/Reports/SupplyChain"

export default function Report() {
    return (
        <Frontend title="Send Reports" menu="sendReportsSupplyChain">
            <ReportSupplyChainPage />
        </Frontend>
    );
}
