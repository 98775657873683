export default function Breadcrumb(props) {
    const { title } = props;

    return (
        <>
            <div
                className="bg-dark"
            >
                <div className="container space-1 space-top-lg-1 space-bottom-lg-2">
                    <div className="row align-items-center">
                        <div className="col">
                            <div>
                                {/*<div className={"d-flex"}>*/}
                                    <h2 className="text-danger text-center">{ window.location.hostname  !== 'orders.idpdirect.com'? "Test Environment":""}</h2>
                                    <h1 className="h2 text-white text-dark">{title}</h1>
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
