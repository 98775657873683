import Basic from "../../../containers/Admin/Layouts/Basic"
import Component from "../../../containers/Admin/Doc/DocInstall"

export default function Index() {
    return (
        <Basic menu="doc">
            <Component/>
        </Basic>
    )
}
