import Basic from "../../../containers/Admin/Layouts/Basic"
import ContainersCreate from "../../../containers/Admin/StoreCategory/Fields"

export default function Edit() {
    return (
        <Basic menu="storeCategory">
            <ContainersCreate />
        </Basic>
    )
}
