import React, { useState} from "react";
import Header from "../../../../components/User/Reports/Header";
import {listApi} from "../../../../services/user/request";
import useAuth from "../../../../hooks/useAuth";
import Region from "./Table/Region";
import Subregion from "./Table/Subregion";
import Locations from "./Table/Locations";
import Location from "./Table/Location";

export default function Reports() {

    const { logout } = useAuth()

    const [regionSelected, setRegionSelected] = useState(null)
    const [subregionSelected, setSubregionSelected] = useState(null)
    const [countrySelected, setCountrySelected] = useState(null)
    const [locationSelected, setLocationSelected] = useState(null)
    const [dateFromSelected, setDateFromSelected] = useState(null)
    const [dateToSelected, setDateToSelected] = useState(null)
    const [locationCategorySelected, setLocationCategorySelected] = useState(null)
    const [loading, setLoading] = useState(true)

    const clickExport = async () => {

        let typeTable = 'region'
        if (!regionSelected) {
            typeTable = 'region'
        } else if (regionSelected && !subregionSelected){
            typeTable = 'subregion'
        } else if (subregionSelected  && !locationSelected) {
            typeTable = 'locations'
        } else if (locationSelected) {
            typeTable = 'location'
        }

        const response = await listApi(logout, `report/location/${typeTable}`, {
            idRegion: regionSelected?.id,
            idSubregion: subregionSelected?.id,
            idCountry: countrySelected?.id,
            idLocation: locationSelected?.id,
            dateFrom: dateFromSelected,
            dateTo: dateToSelected,
            export: true,
            idLocationCategory: locationCategorySelected?.id
        });
        if (response.status){
            window.open(response.data, '_blank');
        }
    }

    return (
        <>
            <div className="card-body">

                <Header
                    regionSelected={regionSelected}
                    subregionSelected={subregionSelected}
                    countrySelected={countrySelected}
                    locationSelected={locationSelected}
                    dateFromSelected={dateFromSelected}
                    dateToSelected={dateToSelected}
                    locationCategorySelected={locationCategorySelected}

                    setRegionSelected={setRegionSelected}
                    setSubregionSelected={setSubregionSelected}
                    setCountrySelected={setCountrySelected}
                    setLocationSelected={setLocationSelected}
                    setDateFromSelected={setDateFromSelected}
                    setDateToSelected={setDateToSelected}
                    setLocationCategorySelected={setLocationCategorySelected}
                    loading={loading}
                    setLoading={setLoading}

                    clickExport={clickExport}
                />

                <br/>

                {!regionSelected && (
                    <Region
                        regionSelected={regionSelected}
                        subregionSelected={subregionSelected}
                        countrySelected={countrySelected}
                        locationSelected={locationSelected}
                        dateFromSelected={dateFromSelected}
                        dateToSelected={dateToSelected}
                        locationCategorySelected={locationCategorySelected}
                        loading={loading}
                    />
                )}

                {(regionSelected && !subregionSelected) && (
                    <Subregion
                        regionSelected={regionSelected}
                        subregionSelected={subregionSelected}
                        countrySelected={countrySelected}
                        locationSelected={locationSelected}
                        dateFromSelected={dateFromSelected}
                        dateToSelected={dateToSelected}
                        locationCategorySelected={locationCategorySelected}
                        loading={loading}
                    />
                )}

                {(subregionSelected && !locationSelected) && (
                    <Locations
                        regionSelected={regionSelected}
                        subregionSelected={subregionSelected}
                        countrySelected={countrySelected}
                        locationSelected={locationSelected}
                        dateFromSelected={dateFromSelected}
                        dateToSelected={dateToSelected}
                        locationCategorySelected={locationCategorySelected}
                        loading={loading}
                    />
                )}

                {(locationSelected) && (
                    <Location
                        regionSelected={regionSelected}
                        subregionSelected={subregionSelected}
                        countrySelected={countrySelected}
                        locationSelected={locationSelected}
                        dateFromSelected={dateFromSelected}
                        dateToSelected={dateToSelected}
                        locationCategorySelected={locationCategorySelected}
                        loading={loading}
                    />
                )}
            </div>
        </>
    )

}
