import useAuth from "../../../../hooks/useAuth";
import React, {useEffect, useState} from "react";
import {listApi} from "../../../../services/user/request";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import {makeStyles} from "@mui/styles";
import Button from "@material-ui/core/Button";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginBottom: 10,
    },
}));

export default function Header(props) {
    const {
        companySelected,
        regionSelected,
        warehouseSelected,
        productCategorySelected,
        setCompanySelected,
        setRegionSelected,
        setWarehouseSelected,
        setProductCategorySelected,
        setDateFromSelected,
        setDateToSelected,

        clickExport,

    } = props

    const classes = useStyles()

    const [company, setCompany] = useState([])
    const [region, setRegion] = useState([])
    const [warehouse, setWarehouse] = useState([])
    const [productCategory, setProductCategory] = useState([])
    const {logout, auth} = useAuth();

    const [disabledRegion, setDisabledRegion] = useState(true)
    const [disabledWarehouse, setDisabledWarehouse] = useState(true)
    const companyName = auth ? auth.companyName : null;
    const companyId = auth ? auth.companyId : null;
    const roleName = auth ? auth.role : null;

    useEffect(() => {


        (async () => {

            if (companyName === 'International Direct Packaging Limited') {

                const response = await listApi(logout, "company");
                if (response.status) {
                    setCompany(response.data)
                }

            }
            if (companyName !== 'International Direct Packaging Limited') {

                await handleChangeCompany(null, {id: companyId})

            }

            const response = await listApi(logout, "product-category");
            if (response.status) {
                setProductCategory(response.data)
            }

            if (roleName === "ROLE_REGION_MANAGER") {
                await handleChangeRegion(null, {id: auth.region?.id})
            }

        })()

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeCompany = async (event, value) => {
        setDisabledRegion(true)
        setDisabledWarehouse(true)
        setWarehouseSelected({})

        if (value) {
            setCompanySelected(value);
            // Buscar Loaction
            const response = await listApi(logout, `region`, {idCompany: value.id});
            if (response.status) {
                setRegion(response.data)
            } else {
                setRegion([])
            }

            // Activar location
            setDisabledRegion(false)
        }
    }

    const handleChangeRegion = async (event, value) => {
        setDisabledWarehouse(true)
        setWarehouseSelected({})

        if (value) {
            setRegionSelected(value)

            const response = await listApi(logout, `warehouse`, {idRegion: value.id});
            if (response.status) {
                setWarehouse(response.data)
            } else {
                setWarehouse([])
            }

            setDisabledWarehouse(false)
        } else {
            setRegionSelected(null)
        }

    }

    const handleChangeWarehouse = async (event, value) => {
        setWarehouseSelected(null)

        if (value) {
            setWarehouseSelected(value)
        }
    }

    const handleChangeDate = async (event, value) => {
        let date = moment(event.target.value)
        if (event.target.id === "dateFrom") {
            setDateFromSelected(moment(date).format("X"))
        }

        if (event.target.id === "dateTo") {
            setDateToSelected(moment(date).format("X"))
        }
    }

    const handleChangeProductCategory = async (event, value) => {
        setProductCategorySelected(null)

        if (value) {
            setProductCategorySelected(value)
        }
    }

    return (
        <div className="row">
            <div className="col-lg-4 col-6 d-none">
                <FormControl
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={classes.formControl}
                >
                    <OutlinedInput
                        inputvariant="outlined"
                        id="dateFrom"
                        type="date"
                        placeholder="From"
                        size="small"
                        onChange={handleChangeDate}
                        sx={{
                            "& label.Mui-focused": {
                                display: "none"
                            },
                            "& legend": {
                                display: "none"
                            }
                        }}
                    />
                </FormControl>
            </div>
            <div className="col-lg-4 col-6 d-none">
                <FormControl
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={classes.formControl}
                >
                    <OutlinedInput
                        inputvariant="outlined"
                        id="dateTo"
                        type="date"
                        placeholder="To"
                        onChange={handleChangeDate}
                        size="small"
                        sx={{
                            "& label.Mui-focused": {
                                display: "none"
                            },
                            "& legend": {
                                display: "none"
                            }
                        }}
                    />
                </FormControl>
            </div>

            {["ROLE_COMPANY_MANAGER", "ROLE_REGION_MANAGER"].includes(roleName) && (
                <>
                    {companyName === 'International Direct Packaging Limited' &&
                        <div className="col">
                            <Autocomplete
                                options={company}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) =>
                                    option.value === value.value
                                }
                                value={companySelected}
                                onChange={handleChangeCompany}
                                fullWidth
                                size="small"
                                InputLabelProps={{shrink: true}}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Company "
                                        variant="outlined"
                                        name="idCompany"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </div>
                    }
                    {["ROLE_COMPANY_MANAGER"].includes(roleName) && (
                        <div className="col">
                            <Autocomplete
                                options={region}
                                getOptionLabel={(option) => option.name}
                                getOptionSelected={(option, value) =>
                                    option.value === value.value
                                }
                                onChange={handleChangeRegion}
                                fullWidth
                                value={regionSelected?.id ? regionSelected : null}
                                disabled={disabledRegion}
                                size="small"
                                InputLabelProps={{shrink: true}}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Zone "
                                        variant="outlined"
                                        name="idRegion"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </div>
                    )}

                    <div className="col">
                        <Autocomplete
                            options={warehouse}
                            getOptionLabel={(option) => option.name}
                            getOptionSelected={(option, value) =>
                                option.value === value.value
                            }
                            fullWidth
                            value={warehouseSelected?.id ? warehouseSelected : null}
                            onChange={handleChangeWarehouse}
                            disabled={disabledWarehouse}
                            size="small"
                            InputLabelProps={{shrink: true}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Warehouse "
                                    variant="outlined"
                                    name="idWarehouse"
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}
                        />
                    </div>
                </>
            )}
            <div className="col">
                <Autocomplete
                    options={productCategory}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) =>
                        option.value === value.value
                    }
                    fullWidth
                    value={productCategorySelected}
                    onChange={handleChangeProductCategory}
                    size="small"
                    InputLabelProps={{shrink: true}}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Product Category "
                            variant="outlined"
                            name="idProductCategory"
                            InputLabelProps={{ shrink: true }}
                        />
                    )}
                />
            </div>
            <div className="col-lg-4 col-6">
                <Button
                    variant="outlined"
                    fullWidth
                    onClick={clickExport}
                >Export (.xlsx)</Button>
            </div>
        </div>
    )
}
