import React, {useEffect, useMemo, useState} from "react"
import {listApi} from "../../../../services/admin/request"
import useAuth from "../../../../hooks/useAuth"
import TableBase from "../../../../components/Admin/TableBase"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

export default function Management() {
    const [categories, setCategories] = useState(null)
    const {logout} = useAuth()

    const columns = useMemo(
        () => [
            {
                Header: "COMPANY",
                accessor: "companyName",
            },
            {
                Header: "NAME",
                accessor: "name",
            },
            {
                Header: "ACTIVE",
                accessor: "isActive",
            },
            {
                Header: "ACTIONS",
                accessor: "actions",
            },
        ],
        []
    )

    useEffect(() => {
        (async () => {
            const response = await listApi(logout, "location-category");
            if (response.status) {

                const data = response.data;

                const categories = data.map((category) => {

                    let href = `/admin/store-category/edit/${category.id}`

                    let companyName = ""

                    if(category.company){
                        companyName = category.company.name
                    }

                    return {
                        ...category,
                        isActive: category.isActive ? "Yes" : "No",
                        companyName: companyName,
                        actions: (<Link color="primary" href={href}>
                            Edit
                        </Link>)
                    }

                })

                setCategories(categories)

            }
        })()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (!categories) return ""

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={6} md={6} lg={6}>
                    <h1>Display Store Categories</h1>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ float: "right", margin: "20px 0px" }}
                        href="/admin/store-category/create"
                    >
                        + add
                    </Button>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box border={1} className="box-default">
                        <TableBase
                            columns={columns}
                            data={categories}
                            selectedRows={categories}
                            onSelectedRowsChange={setCategories}
                            search={true}/>
                    </Box>
                </Grid>
            </Grid>
        </>
    );

}