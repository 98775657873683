import Frontend from "../../../../containers/User/Layouts/Frontend";
import UpdateOrderPage from "../../../../containers/User/Transaction/Update/Prods"
import UpdateCartPage from "../../../../containers/User/Transaction/Update/Cart"
import React, {useState} from "react";
import NavBar from "../../../../components/NavBar";
import SubMenu from "../../../../containers/User/Order/SubMenu";
import Box from "@material-ui/core/Box";

export default function Update() {

    const [isViewCart, setViewCart] = useState(false);
    const [totalProducts, setTotalProducts] = useState(0);
    const [totalCartProducts, setTotalCartProducts] = useState(JSON.parse(localStorage.getItem("order_products"))?.length)

    return (
        <Frontend title="Update Order" menu="orderHistory">
            <div className={"card-body"}>
                <NavBar>

                    <SubMenu
                        name={"All products"}
                        totalProducts={totalProducts}
                        action={() => setViewCart(false)}
                        active={!isViewCart}
                    />

                    <SubMenu
                        name={"Selected products"}
                        totalProducts={totalCartProducts}
                        action={() => {
                            setViewCart(true)
                            setTotalCartProducts(JSON.parse(localStorage.getItem("order_products"))?.length)
                        }}
                        active={isViewCart}
                    />

                </NavBar>
                <Box component="div" sx={{display: !isViewCart ? "block" : "none"}}>
                    <UpdateOrderPage totalProducts={totalProducts} setTotalProducts={setTotalProducts}
                                     setTotalCartProducts={setTotalCartProducts} setViewCart={setViewCart}
                                     isViewCart={isViewCart}/>
                </Box>
                <Box component="div" sx={{display: isViewCart ? "block" : "none"}}>
                    <UpdateCartPage setTotalCartProducts={setTotalCartProducts} totalCartProducts={totalCartProducts} setViewCart={setViewCart}
                                    isViewCart={isViewCart}/>
                </Box>
            </div>
        </Frontend>
    );
}

