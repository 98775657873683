import Frontend from "../../../../containers/User/Layouts/Frontend";
import DetailsPage from "../../../../containers/User/Transaction/Details"

export default function Details() {
    return (
        <Frontend title="Order History" menu="orderHistory">
            <div className={"card-body"}>
                <DetailsPage />
            </div>
        </Frontend>
    );
}

