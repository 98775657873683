import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import NewTable from "../../../../components/Admin/NewTable";
import {apiList} from "../../../../services/admin/tariff";
import {DASH_MAX_ROW} from "../../../../utils/constants";
import moment from "moment";
import {Link} from "react-router-dom";
import {Button, IconButton} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import HeaderPage from "../../../../components/Admin/HeaderPage";
import useAuth from "../../../../hooks/useAuth";
import {listApi} from "../../../../services/admin/request";
import InputSelect from "../../../../components/Inputs/InputSelect";
import InputText from "../../../../components/Inputs/InputText";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import MsgError from "../../../../components/Admin/MsgError";

export default function User() {
    const [state, setState] = useState(initialValues())
    const [loading, setLoading] = useState(true)
    const [rows, setRows] = useState([])
    const [rowCount, setRowCount] = useState(null)
    const [msgError, setMsgError] = useState([])


    useEffect(() => {
        setLoading(true)
        const timeOutId = setTimeout(() => {
            getTariff()
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [state])

    const getTariff = () => {
        setMsgError([])

        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let field = state.sort.field

        let params = {
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort
        }

        if (state.companyId) { params.companyId = state.companyId }
        if (state.name) { params.name = state.name }
        if (state.description) { params.description = state.description }

        apiList(params).then(resp => {
            setRowCount(resp.data?.count)

            setRowCount(resp.data.total)
            let temp = resp.data.data?.map((doc, key) => {
                if(doc.updateDate)
                    var updateDate = doc.updateDate = moment.unix(doc?.updateDate).format('Y/M/D')
                else
                    var updateDate = doc.updateDate = '-'
                return {
                    ...doc,
                    creationDate: moment.unix(doc?.creationDate).format("Y/M/D"),
                    updateDate: updateDate,
                }
            })

            setRows(temp)
            setLoading(false)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setRows([])
        })
    }

    return (
        <>
            <HeaderPage title="Display Tariff">
                <Button
                    variant="contained"
                    color="primary"
                    style={{ float: "right", minWidth: 100 }}
                    href="/admin/tariff/create"
                >
                    + add
                </Button>
            </HeaderPage>

            <MsgError msg={msgError} />

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box border={1} className="box-default" style={{paddingBottom: 10}}>
                        <Filters
                            state={state}
                            setState={setState}
                        />
                        <NewTable
                            columns={tableColumns()}
                            rows={rows}
                            rowCount={rowCount}
                            filter={state}
                            setFilter={setState}
                            loading={loading}
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

function Filters(props) {

    const {state, setState} = props
    const [company, setCompany] = useState([])
    const {logout} = useAuth()

    useEffect(() => {
        getCompany()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        state["offset"] = 0
        setState({...state})
    }

    const getCompany = async () => {
        const response = await listApi(logout, 'company')
        if (response.status) {
            const temp = response.data.map((doc) => {
                return {
                    id: doc.id,
                    name: doc.name,
                }
            })
            setCompany(temp)
        }
    }

    return (
        <Grid container spacing={1} style={{padding:'20px'}}>
            <Grid item xs={3}>
                <InputSelect
                    label="Company"
                    name="companyId"
                    onChange={handleChange}
                    value={state?.companyId}
                    options={company}
                />
            </Grid>
            <Grid item xs={4}>
                <InputText
                    label="Name"
                    name="name"
                    onChange={handleChange}
                    value={state?.name}
                />
            </Grid>
            <Grid item xs={4}>
                <InputText
                    label="Description"
                    name="description"
                    onChange={handleChange}
                    value={state?.description}
                />
            </Grid>
            <Grid item xs={1}>
                <Button variant="text"  onClick={() => setState(initialValues())} style={{float: 'right', height: 35}}>
                    <HighlightOffIcon />
                </Button>
            </Grid>
        </Grid>
    )
}


function tableColumns(props) {
    return [
        {
            field: "companyName",
            headerName: "COMPANY",
            flex: 1,
            sortable: true,
        },
        {
            field: "name",
            headerName: "NAME",
            flex: 1,
            sortable: true,
        },
        {
            field: "description",
            headerName: "DESCRIPTION",
            flex: 1,
            sortable: true,
        },
        {
            field: "creationDate",
            headerName: "CREATION DATE",
            flex: 1,
            sortable: true,
        },
        {
            field: "updateDate",
            headerName: "UPDATE DATE",
            flex: 1,
            sortable: true,
        },
        {
            field: "actions",
            headerName: "ACTIONS",
            sortable: false,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={`/admin/tariff/edit/${params.id}`}>
                            <IconButton size="small">
                                <EditIcon fontSize="inherit" />
                            </IconButton>
                        </Link>
                    </>
                )
            },
        },
    ]
}

function initialValues(props) {
    return {
        offset: 0,
        sort: { field: "id", sort: "desc" },
        companyId: "",
        name: "",
        description: "",
    }
}
