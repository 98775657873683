import Frontend from "../../../../containers/User/Layouts/Frontend";
import ReportLocationPage from "../../../../containers/User/Reports/Location"

export default function Report() {
    return (
        <Frontend title="Send Reports" menu="sendReportsStore">
            <ReportLocationPage />
        </Frontend>
    );
}
