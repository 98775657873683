import Basic from "../../../containers/Admin/Layouts/Basic"
import ContainersEdit from "../../../containers/Admin/Company/Fields"

export default function Edit() {
  return (
    <Basic menu="company">
          <ContainersEdit />
    </Basic>
  )
}
