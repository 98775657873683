import Frontend from "../../../../containers/User/Layouts/Frontend";
import ReportMaxOrderPage from "../../../../containers/User/Reports/MaxOrder"

export default function Report() {
    return (
        <Frontend title="Send Reports" menu="sendReportsMaxOrder">
            <ReportMaxOrderPage />
        </Frontend>
    );
}
