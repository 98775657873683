import React from "react";
import Box from "@material-ui/core/Box";
import Checkbox from "@material-ui/core/Checkbox";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import { useTable, usePagination, useRowSelect, useMountedLayoutEffect, useGlobalFilter } from 'react-table'
import Grid from "@material-ui/core/Grid";
import NativeSelect from "@material-ui/core/NativeSelect";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@mui/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
    paper: {
        textAlign: "center",
    },
}));

const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <Checkbox ref={resolvedRef} {...rest} />
            </>
        )
    }
)

export default function TableBase({ columns, data, selectedRows, onSelectedRowsChange, search, selectable }) {
    const classes = useStyles();

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        setGlobalFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, selectedRowIds, globalFilter },
    } = useTable(
        {
            columns,
            data,
            initialState: {
                selectedRowIds: selectedRows
            }
        },
        useGlobalFilter,
        usePagination,
        useRowSelect,
        hooks => {

            if (selectable) {
                hooks.visibleColumns.push(columns => [
                    // Let's make a column for selection
                    {
                        id: 'selection',
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        Header: ({ getToggleAllPageRowsSelectedProps }) => (
                            <div>
                                <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
                            </div>
                        ),
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: ({ row }) => (
                            <div>
                                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                            </div>
                        )
                    },
                    ...columns,
                ])
            }

        }
    )
    React.useEffect(() => {
        // props.dispatch({ type: actions.resetPage })
    }, [globalFilter]);
    useMountedLayoutEffect(() => {
        onSelectedRowsChange && onSelectedRowsChange(selectedRowIds)

    }, [onSelectedRowsChange, selectedRowIds]);
    // Render the UI for your table
    return (
        <>
            {search && (
                <Box m={2}>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        placeholder="Search"
                        label="Search"
                        type="search"
                        fullWidth
                        value={globalFilter || ""}
                        onChange={(e) => setGlobalFilter(e.target.value)}
                        InputLabelProps={{shrink: true}}
                    />
                </Box>
            )}
            <Box m={0}>
                <Table {...getTableProps()}>
                    <TableHead style={{ background: "#f1f2f6" }}>
                        {headerGroups.map((headerGroup, key) => (
                            <TableRow
                                {...headerGroup.getHeaderGroupProps()}
                                key={key}
                            >
                                {headerGroup.headers.map((column, key) => (
                                    <TableCell
                                        {...column.getHeaderProps()}
                                        key={key}
                                    >
                                        {column.render("Header")}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody {...getTableBodyProps()}>
                        {page.map((row, keyParent) => {
                            prepareRow(row);
                            return (
                                <TableRow {...row.getRowProps()} key={keyParent}>
                                    {row.cells.map((cell, key) => {
                                        return (
                                            <TableCell
                                                {...cell.getCellProps()}
                                                key={key}
                                            >
                                                {cell.render("Cell", cell.row.original)}

                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Box>
            <Box m={2}>
                <Grid container spacing={3}>
                    <Grid item xs={2} md={2} lg={2}>
                        <NativeSelect
                            value={pageSize}
                            onChange={(e) => {
                                setPageSize(Number(e.target.value));
                            }}
                        >
                            {[10, 20, 30, 40, 50].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </NativeSelect>
                    </Grid>
                    <Grid item xs={8} md={8} lg={8}>
                        <Paper className={classes.paper} elevation={0}>
                            {canPreviousPage ? (
                                <Button onClick={() => previousPage()}>
                                    <span role="presentation">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            role="presentation"
                                        >
                                            <path
                                                fill="currentColor"
                                                fillRule="evenodd"
                                                d="M9.005 10.995l4.593-4.593a.99.99 0 111.4 1.4l-3.9 3.9 3.9 3.9a.99.99 0 01-1.4 1.4L9.005 12.41a1 1 0 010-1.414z"
                                            ></path>
                                        </svg>
                                    </span>
                                </Button>
                            ) : (
                                <Button disabled>
                                    <span role="presentation">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            role="presentation"
                                        >
                                            <path
                                                fill="currentColor"
                                                fillRule="evenodd"
                                                d="M9.005 10.995l4.593-4.593a.99.99 0 111.4 1.4l-3.9 3.9 3.9 3.9a.99.99 0 01-1.4 1.4L9.005 12.41a1 1 0 010-1.414z"
                                            ></path>
                                        </svg>
                                    </span>
                                </Button>
                            )}

                            {canNextPage ? (
                                <Button
                                    onClick={() => nextPage()}
                                    disabled={!canNextPage}
                                >
                                    <span role="presentation">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            role="presentation"
                                        >
                                            <path
                                                fill="currentColor"
                                                fillRule="evenodd"
                                                d="M14.995 10.995a1 1 0 010 1.414l-4.593 4.593a.99.99 0 01-1.4-1.4l3.9-3.9-3.9-3.9a.99.99 0 011.4-1.4l4.593 4.593z"
                                            ></path>
                                        </svg>
                                    </span>
                                </Button>
                            ) : (
                                <Button disabled>
                                    <span role="presentation">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            role="presentation"
                                        >
                                            <path
                                                fill="currentColor"
                                                fillRule="evenodd"
                                                d="M14.995 10.995a1 1 0 010 1.414l-4.593 4.593a.99.99 0 01-1.4-1.4l3.9-3.9-3.9-3.9a.99.99 0 011.4-1.4l4.593 4.593z"
                                            ></path>
                                        </svg>
                                    </span>
                                </Button>
                            )}
                        </Paper>
                    </Grid>
                    <Grid item xs={2} md={2} lg={2}>
                        Page <strong>{pageIndex + 1}</strong> of{" "}
                        <strong> {pageOptions.length} </strong>
                    </Grid>
                </Grid>
            </Box>

        </>
    )
}
