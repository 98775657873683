import Frontend from "../../../../containers/User/Layouts/Frontend";
import ReportAvailabilityPage from "../../../../containers/User/Reports/Availability/indexv2"

export default function Report() {
    return (
        <Frontend title="Send Reports" menu="sendReportsAvailabilityv2">
            <ReportAvailabilityPage />
        </Frontend>
    );
}
