import React, {useEffect, useState} from "react";
import useAuth from "../../../hooks/useAuth";
import {useMsal} from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";

export default function Logout() {
    const {logout} = useAuth()
    const { instance } = useMsal()
    const isAuthenticated = useIsAuthenticated()

    useEffect(() => {
        if (isAuthenticated) {
            handleLogout()
        } else {
            logout()
        }
    }, [])

    const handleLogout = () => {
        instance.logoutRedirect({
            postLogoutRedirectUri: "/logout",
        })
    }

    return (<></>)
}

