import Basic from "../../../containers/Admin/Layouts/Basic"
import ContainersCreate from "../../../containers/Admin/Company/Fields"

export default function Create() {
  return (
    <Basic menu="company">
          <ContainersCreate />
    </Basic>
  )
}
