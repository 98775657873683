import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createApi, getWarehouseApi, updateApi, getWarehouseCompanyRegionApi } from "../../../../services/admin/warehouse";
import { useHistory, useParams } from "react-router-dom";
import { listApi } from "../../../../services/admin/request";
import useAuth from "../../../../hooks/useAuth";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Loading from "../../../../components/User/Loading";
import InputAutocomplete from "../../../../components/Inputs/InputAutocomplete";

export default function Fields() {
    const { id } = useParams()
    const { logout } = useAuth()
    const history = useHistory()

    const [msgSuccess, setMsgSuccess] = useState(false)
    const [msgDanger, setMsgDanger] = useState(null)
    const [initialValues, setInitialValues] = useState(defaultValues())

    const [listRegion, setListRegion] = useState([])
    const [defaultRegion, setDefaultRegion] = useState({})

    const [imageBase64, setImageBase64] = useState(null)
    const [image, setImage] = useState(null)

    const [listCompany, setListCompany] = useState([])
    const [listCompanyRegion, setListCompanyRegion] = useState([])
    const [optionsCompany, setOptionsCompany]=useState([])
    const [stateOptionsCompany, setStateOptionsCompany]=useState([])

    // Init Rellenate optionsCompany
    const [loadingOptionCompany, setLoadingOptionCompany] = useState({
        listCompany: false,
        listCompanyRegion: false,
        optionsCompany: false,
        listRegion: false
    })

    const formik = useFormik({
        initialValues: initialValues,
        enableReinitialize: true,
        validationSchema: Yup.object(validationSchema()),
        onSubmit: async () => {
            setMsgDanger(null)

            let f = new FormData()
            for (const property in formik.values) {
                if (property !== "image"){
                    f.append(`${property}`, formik.values[property])
                }
            }
            if (image) {
                f.append("image", image[0])
            }

            if (id) {
                const response = await updateApi(logout, f)
                if (response.status) {
                    setMsgSuccess(true)
                } else {
                    setMsgDanger(response.errors)
                }
            } else {

                const response = await createApi(f);
                if (response.status) {
                    history.push(`/admin/warehouse/edit/${response.data.warehouse.id}/1`)
                } else {
                    setMsgDanger(response.errors)
                }

            }
        },
    });

    const getOptionsCompany = () => {
        let options = {}; // Usar un objeto en lugar de un array
    
        listCompany.forEach((company) => {
            // Inicializar el array de la compañía actual en el objeto
            options[company.id] = [];
    
            listRegion.forEach((region) => {
                if(company.id == region.idCompany.id) {
                    options[company.id].push(region); // Agregar la región al array de la compañía correspondiente
                }
            });
        });
    
        setOptionsCompany(options)
        setLoadingOptionCompany({...loadingOptionCompany, optionsCompany: true})

    }


    const onChangeOptionsSelected = (companyId, value) => {
        const newValue = value !== undefined ? value : null
        formik.values[`idCountryRegion${companyId}`] = newValue
        setStateOptionsCompany(prevState => ({...prevState, [companyId]: newValue}))
    }

    useEffect(() => {
        if(!listCompany && !listRegion) return
        getOptionsCompany()

    }, [listCompany, listRegion]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!loadingOptionCompany.listCompany
            || !loadingOptionCompany.listCompanyRegion
            || !loadingOptionCompany.optionsCompany
            || !loadingOptionCompany.listRegion
        ) return
        const newValues = {}

        listCompany.forEach((company) => {
            const compRegion = listCompanyRegion.find((compRegion) => parseInt(compRegion.idCompany) === parseInt(company.id))
            if (compRegion !== undefined) {
                const regionComp = compRegion.idRegion
                if (regionComp != null && optionsCompany[company.id] != null) {
                    const it = optionsCompany[company.id].find((reg) => parseInt(reg.id) === parseInt(regionComp))
                    if (it) {
                        newValues[company.id] = it.id
                        formik.values[`idCountryRegion${company.id}`] = it.id
                    }
                }
            }
        })
        setStateOptionsCompany(prevState => ({...prevState, ...newValues}))
    }, [loadingOptionCompany])


    useEffect(() => {
        // Función asíncrona para realizar todas las peticiones API
        const fetchData = async () => {
            try {
                // Solicitudes concurrentes para regiones y compañías
                const [regionsResponse, companiesResponse] = await Promise.all([
                    listApi(logout, "region"),
                    listApi(logout, "company"),
                ]);

                // Procesar respuesta de regiones
                if (regionsResponse.status) {
                    const regions = regionsResponse.data.map(doc => ({ id: doc.id, ...doc }));
                    setListRegion(regions);
                }

                // Procesar respuesta de compañías
                if (companiesResponse.status) {
                    const companies = companiesResponse.data.map(doc => ({ id: doc.id, ...doc }));
                    setListCompany(companies);
                }

                // Actualiza el estado de carga después de recibir las respuestas iniciales
                setLoadingOptionCompany(prev => ({
                    ...prev,
                    listCompany: companiesResponse.status,
                    listRegion: regionsResponse.status,
                }));

                // Solicitudes dependientes del ID, solo si id está presente
                let loadingListCompanyRegion = false
                if (id) {
                    const [warehouseResponse, companyRegionResponse] = await Promise.all([
                        getWarehouseApi(logout, { id }),
                        getWarehouseCompanyRegionApi(logout, { id }),
                    ]);

                    if (warehouseResponse.status) {
                        setImageBase64(warehouseResponse.data.warehouse.image);
                        setInitialValues(warehouseResponse.data.warehouse);
                        setDefaultRegion(warehouseResponse.data.idRegion);
                    }

                    if (companyRegionResponse.status) {
                        setListCompanyRegion(companyRegionResponse.data);
                    }

                    // Actualizar el estado de carga para las solicitudes dependientes del ID
                    loadingListCompanyRegion = true
                }else
                {
                     loadingListCompanyRegion = true
                }

                // Actualiza el estado de carga después de recibir las respuestas iniciales
                setLoadingOptionCompany(prev => ({
                    ...prev,
                    listCompany: companiesResponse.status,
                    listRegion: regionsResponse.status,
                    listCompanyRegion: loadingListCompanyRegion
                }));

            } catch (error) {
                console.error("An error occurred while fetching data:", error);
            }
        };

        fetchData();
    }, [id, logout]); // Correcto uso de dependencias


    if (!initialValues) return ""

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                {msgSuccess && (
                    <Grid item xs={12} md={12} lg={12}>
                        <Box className="alert-success">
                            Warehouse data has been saved correctly
                        </Box>
                    </Grid>
                )}

                {msgDanger && (
                    <Grid item xs={12} md={12} lg={12}>
                        <Box className="alert-danger">
                            {msgDanger.map((item) => {
                                return (
                                    <span>
                                        {" "}
                                        {item} <br />{" "}
                                    </span>
                                );
                            })}
                        </Box>
                    </Grid>
                )}
                {(!loadingOptionCompany.listCompany
                    || !loadingOptionCompany.listCompanyRegion
                    || !loadingOptionCompany.optionsCompany
                    || !listRegion ) ? (
                        <Loading {...{show: true}} />
                    ) : (
                        <>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={6}>
                                <Box border={1} className="p-20 box-default">
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}  md={12} lg={12} className="border-bottom">
                                            <h4 className="p-0 mt-0">Info</h4>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12}>
                                            { imageBase64 && (
                                                <div className="my-2 mb-2">
                                                    <img
                                                        alt="Warehouse"
                                                        src={imageBase64}
                                                        style={{
                                                            maxWidth: "200px",
                                                            width: "200px"
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            <div className="my-2">
                                                <TextField
                                                    variant="outlined"
                                                    label="* Image"
                                                    className="form-control"
                                                    name="file"
                                                    type="file"
                                                    onChange={(event) => {
                                                        let reader = new FileReader()
                                                        setImage(event.target.files)

                                                        let file = event.currentTarget.files[0]
                                                        reader.onloadend = () => {
                                                            setImageBase64(reader.result)
                                                        }
                                                        reader.readAsDataURL(file)
                                                    }}
                                                    fullWidth
                                                    size="small"
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </div>

                                            { id && (
                                                <div className="my-2">
                                                    <TextField
                                                        variant="outlined"
                                                        label="* ID Warehouse"
                                                        className="form-control"
                                                        name="id"
                                                        type="number"
                                                        value={formik.values.id}
                                                        fullWidth
                                                        size="small"
                                                        disabled
                                                        InputLabelProps={{ shrink: true }}
                                                    />
                                                </div>
                                            )}

                                            <div className="my-2">
                                                <TextField
                                                    variant="outlined"
                                                    label="* NetSuite ID"
                                                    className="form-control"
                                                    name="code"
                                                    type="text"
                                                    value={formik.values?.code}
                                                    onChange={formik.handleChange}
                                                    fullWidth
                                                    size="small"
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                                {formik.errors.code && (
                                                    <p className="text-100 text-danger">
                                                        {formik.errors.code}
                                                    </p>
                                                )}
                                            </div>

                                            <div className="my-2">
                                                <TextField
                                                    variant="outlined"
                                                    label="* Name"
                                                    className="form-control"
                                                    name="name"
                                                    type="text"
                                                    value={formik.values.name}
                                                    onChange={formik.handleChange}
                                                    fullWidth
                                                    size="small"
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                                {formik.errors.name && (
                                                    <p className="text-100 text-danger">
                                                        {formik.errors.name}
                                                    </p>
                                                )}
                                            </div>

                                            <div className="my-2">
                                                <TextField
                                                    variant="outlined"
                                                    label="* Telephone"
                                                    className="form-control"
                                                    name="phone"
                                                    type="text"
                                                    value={formik.values?.phone}
                                                    onChange={formik.handleChange}
                                                    fullWidth
                                                    InputLabelProps={{ shrink: true }}
                                                    size="small"
                                                />
                                                {formik.errors.phone && (
                                                    <p className="text-100 text-danger">
                                                        {formik.errors.phone}
                                                    </p>
                                                )}
                                            </div>

                                            <div className="my-2">
                                                <TextField
                                                    variant="outlined"
                                                    label="* Delivery lead time"
                                                    className="form-control"
                                                    name="deliveryLeadTime"
                                                    type="text"
                                                    value={
                                                        formik.values?.deliveryLeadTime
                                                    }
                                                    onChange={formik.handleChange}
                                                    fullWidth
                                                    size="small"
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                                {formik.errors.deliveryLeadTime && (
                                                    <p className="text-100 text-danger">
                                                        {formik.errors.deliveryLeadTime}
                                                    </p>
                                                )}
                                            </div>

                                            <div className="my-2">
                                                <TextField
                                                    variant="outlined"
                                                    label="* Emails"
                                                    name="email"
                                                    onChange={formik.handleChange}
                                                    value={formik.values?.email}
                                                    fullWidth
                                                    multiline
                                                    rows={4}
                                                    size="small"
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                                {formik.errors.email && (
                                                    <p className="text-100 text-danger">
                                                        {formik.errors.email}
                                                    </p>
                                                )}
                                            </div>

                                            <div className="my-2">
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            onChange={
                                                                formik.handleChange
                                                            }
                                                            checked={
                                                                formik.values?.isActive
                                                            }
                                                            name="isActive"
                                                            color="primary"
                                                        />
                                                    }
                                                    label="Visible"
                                                />
                                            </div>

                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <Box border={1} className="p-20 box-default">
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={12} lg={12} className="border-bottom">
                                            <h4 className="p-0 mt-0">Address</h4>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12}>
                                            <div className="my-2">
                                                <TextField
                                                    variant="outlined"
                                                    label="* Post Code"
                                                    name="postalCode"
                                                    className="form-control"
                                                    value={formik.values?.postalCode}
                                                    onChange={formik.handleChange}
                                                    fullWidth
                                                    size="small"
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                                {formik.errors.postalCode && (
                                                    <p className="text-100 text-danger">
                                                        {formik.errors.postalCode}
                                                    </p>
                                                )}
                                            </div>

                                            <div className="my-2 mb-2">
                                                 <Autocomplete
                                                    options={listRegion}
                                                    value={defaultRegion}
                                                    getOptionLabel={(option) => option.name || ""}
                                                    getOptionSelected={(option, value) => option.value === value.value }
                                                    onChange={(event, newValue) => {
                                                        if (newValue !== null) {
                                                            formik.values.idRegion = newValue.id
                                                            setDefaultRegion(newValue)
                                                        } else {
                                                            formik.values.idRegion = null
                                                            setDefaultRegion({})
                                                        }
                                                    }}
                                                    fullWidth
                                                    size="small"
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="* Region"
                                                            variant="outlined"
                                                            name="idRegion"
                                                        />
                                                    )}
                                                />
                                            </div>

                                            <div className="my-2">
                                                <TextField
                                                    variant="outlined"
                                                    label="* Address 1"
                                                    className="form-control"
                                                    name="address1"
                                                    value={formik.values?.address1}
                                                    onChange={formik.handleChange}
                                                    fullWidth
                                                    size="small"
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                                {formik.errors.address1 && (
                                                    <p className="text-100 text-danger">
                                                        {formik.errors.address1}
                                                    </p>
                                                )}
                                            </div>

                                            <div className="my-2">
                                                <TextField
                                                    variant="outlined"
                                                    label="Address 2"
                                                    className="form-control"
                                                    value={formik.values?.address2}
                                                    name="address2"
                                                    onChange={formik.handleChange}
                                                    fullWidth
                                                    size="small"
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </div>

                                            <div className="my-2">
                                                <TextField
                                                    variant="outlined"
                                                    className="form-control"
                                                    label="Address3"
                                                    value={formik.values?.address3}
                                                    name="address3"
                                                    onChange={formik.handleChange}
                                                    fullWidth
                                                    size="small"
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </div>

                                            <div className="my-2">
                                                <TextField
                                                    variant="outlined"
                                                    className="form-control"
                                                    label="Address4"
                                                    value={formik.values?.address4}
                                                    name="address4"
                                                    onChange={formik.handleChange}
                                                    fullWidth
                                                    size="small"
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </div>

                                            <div className="my-2">
                                                <TextField
                                                    variant="outlined"
                                                    className="form-control"
                                                    label="Address5"
                                                    name="address5"
                                                    value={formik.values?.address5}
                                                    onChange={formik.handleChange}
                                                    fullWidth
                                                    size="small"
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>

                        {(listCompany &&  listRegion && listCompanyRegion) && (
                            <Box border={1} className="p-20 box-default mt-2">
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12} lg={12} className="border-bottom mb-2">
                                        <h5 className="p-0 mt-0">Company Zones</h5>
                                    </Grid>
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} md={12} lg={12}>
                                                <div className="mb-2">
                                                    <p>INFO: Assign a zone for each company to calculate costs of products in the containers and stock warehouse.</p>
                                                </div>
                                            </Grid>
                                            {listCompany.map((company, key) => {
                                                return (
                                                    <Grid key={key} item xs={12} md={4} lg={4}>
                                                        <InputAutocomplete
                                                            label={`${company.name} Region`}
                                                            onChange={(e) => {
                                                                onChangeOptionsSelected(company.id, e.target.value)
                                                            }}
                                                            value={stateOptionsCompany[company.id] || null}
                                                            required
                                                            options={optionsCompany[company.id] || []}
                                                        />
                                                    </Grid>
                                                )})
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}

                        <Grid container spacing={3} className="p-20">
                            <Grid item xs={12} md={6} lg={6}>&nbsp;</Grid>
                            <Grid item xs={6} md={3} lg={3}>
                                <Button
                                    type="button"
                                    color="secondary"
                                    href="/admin/warehouse/management"
                                    fullWidth
                                >
                                    <ArrowBackIos />
                                    Back to list
                                </Button>
                            </Grid>
                            <Grid item xs={6} md={3} lg={3}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    fullWidth
                                >
                                    Modify
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                )}
            </form>
        </>
    )
}

function defaultValues() {
    return {
        id: "",
        code: "",
        name: "",
        phone: "",
        deliveryLeadTime: "",
        email: "",
        idRegion: null,
        postalCode: "",
        address1: "",
        address2: "",
        address3: "",
        address4: "",
        address5: "",
        isActive: true
    }
}

function validationSchema() {
    return {
        code: Yup.string().required("The code is required"),
        name: Yup.string().required("The name is required"),
        phone: Yup.string().required("The Telephone is required"),
        deliveryLeadTime: Yup.string().required("The Delivery lead time"),
        email: Yup.string().required("The emails is required"),
        postalCode: Yup.string().required("The Postal Code is required"),
        address1: Yup.string().required("The Address 1 is required")
    }
}
