import Basic from "../../../containers/Admin/Layouts/Basic"
import ContainersEdit from "../../../containers/Admin/StoreCategory/Fields"

export default function Edit() {
    return (
        <Basic menu="storeCategory">
            <ContainersEdit />
        </Basic>
    )
}
