import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { listApi } from "../../../../services/admin/request";
import {
    updateTransaction,
    getTransaction,
    resendEmailsApi,
    updateTransactionSimple
} from "../../../../services/admin/transaction";
import useAuth from "../../../../hooks/useAuth";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import InputText from "../../../../components/Admin/Inputs/InputText";
import TextField from "@material-ui/core/TextField";
import TableBase from "../../../../components/Admin/TableBase";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import moment from "moment";
import InputRadio from "../../../../components/Admin/Inputs/InputRadio";
import {Alert} from "@mui/lab";
import InputButtonLoading from "../../../../components/Inputs/InputButtonLoading";
import { display, maxWidth } from "@mui/system";
import DatePickerSimple from "../../../../components/Admin/Inputs/DatePickerSimple";

export default function Edit() {
    const { id } = useParams();
    const { logout } = useAuth();
    const [msgSuccess, setMsgSuccess] = useState(null);
    const [msgDanger, setMsgDanger] = useState(null);

    const [loading, setLoading] = useState(true);
    const [productos, setProducts] = useState([]);
    const [details, setDetails] = useState({});
    const [dataOrder, setDataOrder] = useState(null);
    const [numberPacks, setNumberPacks] = useState(0);
    const [listStatus, setListStatus] = useState([]);
    const [defaultStatus, setDefaultStatus] = useState({});
    const [defaultStatusOrigin, setDefaultStatusOrigin] = useState({});
    const [state, setState] = useState(initialValues)
    const [saveButtonLoading, setSaveButtonLoading] = useState(false);

    function initialValues() {
        return {
            idTransaction: id,
            comment: "",
            ref: "",
            refTov: "",
            sendEmail: false,
            dateStatusChange: ""
        }
    }
    const columns = useMemo(
        () => [
            {
                Header: "REFERENCE",
                accessor: "sort",
            },
            {
                Header: "NAME",
                accessor: "name",
            },
            {
                Header: "DESCRIPTION",
                accessor: "description",
            },
            {
                Header: "QUANTITY",
                accessor: "quantity",
            },
            {
                Header: "UNIT PRICE",
                accessor: "priceUnit",
            },
            {
                Header: "TOTAL",
                accessor: "totalPrice",
            },
            {
                Header: "CURRENCY",
                accessor: "currencyName",
            },
            {
                Header: "WAREHOUSE",
                accessor: "warehouseName",
            },
            {
                Header: "TARIFF",
                accessor: "tariffId",
            },
            {
                Header: "REF/PO",
                accessor: "refVisible",
            },
            {
                Header: "GROUPED",
                accessor: "isGrouped",
            }
        ],
        []
    );

    // Llamamos a la API para obtener los datos del usuario
    useEffect(() => {
        (async () => {
            const allStatus = await listApi(logout, "transaction/status");

            if (allStatus.status) {
                setListStatus(allStatus.data);
            }

            const response = await getTransaction(logout, id);
            if (response.status) {
                let redirectGroupingList = "";

                if (response.data.details.groupingList) {

                    var arrayGrouping = response.data.details.groupingList.split(",").map(function(item) {
                        return item.trim();
                    });
                    arrayGrouping.forEach((element, key) => {
                        redirectGroupingList += `<a href="/admin/transaction-grouping/edit/${element}/1" target="_blank" style="padding-right:10px">${element}</a>`
                        if (arrayGrouping.length > 0 ) {
                            redirectGroupingList += "<br />"
                        }
                    });
                    response.data.details.groupingList = redirectGroupingList
                }
                
                // get Details
                setDetails(response.data.details);
                setDataOrder(moment.unix(response.data.details.transaction_creationDate).utc().format('DD-MMM-YYYY HH:mm A'));
                setDefaultStatus(response.data.status);
                setDefaultStatusOrigin(response.data.status);
                setState({...state,
                    comment: response?.data?.details?.transaction_comment,
                    ref: response?.data?.details?.transaction_ref,
                    refTov: response?.data?.details?.transaction_refTov
                });

                if (response.data.details.transaction_shippingChargeTotalPrice) {
                    response.data.products.push({
                        sort:'',
                        name: response.data.details.transaction_shippingChargeName,
                        description:'Shipping charge for products',
                        quantity:0,
                        totalPrice: response.data.details.transaction_shippingChargeTotalPrice,
                        currencyName:'',
                        warehouseName:'',
                        tariffId:''
                    });
                }

                // get Products
                let numberPacks = 0;
                let currencyName = '';
                const products = response.data.products.map((doc) => {
                    numberPacks += parseInt(doc.quantity);
                    doc.refVisible = doc.refPo

                    if(doc.currencyName){
                        currencyName = doc.currencyName;
                    }
                    if(!doc.currencyName){
                        doc.currencyName = currencyName;
                    }
                    if(!doc.quantity){
                        doc.quantity = "";
                    }

                    return {
                        ...doc,
                        tariffId : doc.tariffId ? <a href={`/admin/tariff/edit/${doc.tariffId}`} target="_blank">{doc.tariffName}</a> : "",
                        warehouseName : doc.warehouseId ? <a href={`/admin/warehouse/edit/${doc.warehouseId}/4`} target="_blank">{doc.warehouseName}</a> : "",
                        isGrouped : doc.isGrouped ? "Yes" : "No",
                    };
                });
                setProducts(products);
                setNumberPacks(numberPacks);

                setLoading(false);
            }
        })();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const resendEmails = async () => {
        const response = await resendEmailsApi(logout, id)

        if (response.status) {
            setMsgSuccess(response.data)
        } else {
            setMsgDanger(response.errors)
        }

    }

    const updateDetails = async () => {
        setMsgSuccess(null);
        setMsgDanger(null);

        if(details?.idStatus === 9 && !details?.dateStatusChange){
            setMsgDanger(["Please select a date for the delivery"]);
            return false;
        }

        const response = await updateTransaction(logout, details);
        if (response.status) {
            setMsgSuccess("The status has been saved successfully");
            setDefaultStatusOrigin({id: details?.idStatus})
        } else {
            setMsgDanger(response.errors);
        }
    };

    const handleChangeStatus = (event) => {
        details["idStatus"] = parseInt(event.target.value)
        setDetails({ ...details })
    }

    const handleSubmit = async () => {
        setSaveButtonLoading(true)
        let params = state

        console.log(params)

        updateTransactionSimple(params).then(resp => {
            console.log(resp)

            setSaveButtonLoading(false)
        }).catch(err => {
            setSaveButtonLoading(false)
        })

    }

    const handleChange = (event) => {
        if (event.target.name === "dateStatusChange" && event.target.value) {
            event.target.value = moment.unix(event.target.value).utc().format("YYYY-MM-DD")
            details[event.target.name] = event.target.value
            setDetails({...details})
            return false
        }
        state[event.target.name] = event.target.value
        setState({...state})
    }


    if (loading) return "";

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                    <h2 className="p-0">Display Orders Settings</h2>
                </Grid>
            </Grid>

            {msgSuccess && (
                <Grid item xs={12} md={12} lg={12}>
                    <Box className="alert-success">
                        {msgSuccess}
                    </Box>
                </Grid>
            )}

            {msgDanger && (
                <Grid item xs={12} md={12} lg={12}>
                    <Box className="alert-danger">
                        {msgDanger.map((item) => {
                            return (
                                <span>
                                    {" "}
                                    {item} <br />{" "}
                                </span>
                            );
                        })}
                    </Box>
                </Grid>
            )}

            <Grid container spacing={3}>
                <Grid item xs={12} md={8} lg={8}>
                    <Box border={1} className="p-20 box-default">
                        <Grid container spacing={3}>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                lg={6}
                                className="border-bottom">
                                <h5 className="p-0 mt-0">Order Info</h5>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                lg={6}
                                style={{textAlign: "right", padding: 13}}
                                className="border-bottom"
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={resendEmails}
                                >
                                    Resend
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <ul className="MuiList-root css-1mk9mw3-MuiList-root" style={{padding: 0}}>
                                    <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                        <div className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                            <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                Customer Name
                                            </h6>
                                            <div className="MuiBox-root css-1clcuvi">
                                                <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                    {details.user_name}{" "}
                                                    {details.user_surnames}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                        <div className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                            <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                Email Address
                                            </h6>
                                            <div className="MuiBox-root css-1clcuvi">
                                                <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                    {details.user_email}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                        <div className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                            <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                Order Number
                                            </h6>
                                            <div className="MuiBox-root css-1clcuvi">
                                                <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                    {details.transaction_id}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <ul className="MuiList-root css-1mk9mw3-MuiList-root" style={{padding: 0}}>
                                    <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                        <div className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                            <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                Creation Date
                                            </h6>
                                            <div className="MuiBox-root css-1clcuvi">
                                                <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                    {dataOrder}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                        <div className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                            <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                QTY
                                            </h6>
                                            <div className="MuiBox-root css-1clcuvi">
                                                <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                    {numberPacks}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                        <div className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                            <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                TOTAL
                                            </h6>
                                            <div className="MuiBox-root css-1clcuvi">
                                                <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                    {details.price}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </Grid>

                            {details.transaction_isImport && (
                                <Grid item xs={12} md={12} lg={12}>
                                    <p>This order has been generated using the Import System.</p>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12} md={4} lg={4}>
                    <Box border={1} className="p-20 box-default">
                        <Grid container spacing={3}>
                            <Grid
                                item
                                xs={12}
                                md={12}
                                lg={12}
                                className="border-bottom"
                            >
                                <h5 className="p-0 mt-0">Order Status</h5>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} style={{paddingTop: 10}}>
                                <div className="my-2 mb-2">

                                     <InputRadio
                                         name="status"
                                         value={1}
                                         onChange={handleChangeStatus}
                                         checked={details?.idStatus === 1 ? true : false}
                                         label="Created"
                                         disabled={true}
                                     />

                                    <InputRadio
                                        name="status"
                                        value={2}
                                        onChange={handleChangeStatus}
                                        checked={details?.idStatus === 2 ? true : false}
                                        label="Awaiting approval"
                                        disabled={
                                            defaultStatusOrigin?.id === 7 ||
                                            defaultStatusOrigin?.id === 6 ||
                                            defaultStatusOrigin?.id === 5 ||
                                            defaultStatusOrigin?.id === 9
                                        }
                                    />

                                    <InputRadio
                                        name="status"
                                        value={3}
                                        onChange={handleChangeStatus}
                                        checked={details?.idStatus === 3 ? true : false}
                                        label="Approved"
                                        disabled={
                                            defaultStatusOrigin?.id === 7 ||
                                            defaultStatusOrigin?.id === 6 ||
                                            defaultStatusOrigin?.id === 5 ||
                                            defaultStatusOrigin?.id === 9
                                        }
                                    />

                                    <InputRadio
                                        name="status"
                                        value={8}
                                        onChange={handleChangeStatus}
                                        checked={details?.idStatus === 8 ? true : false}
                                        label="On hold"
                                        disabled={
                                            defaultStatusOrigin?.id === 1 ||
                                            defaultStatusOrigin?.id === 4 ||
                                            defaultStatusOrigin?.id === 5 ||
                                            defaultStatusOrigin?.id === 6 ||
                                            defaultStatusOrigin?.id === 7 ||
                                            defaultStatusOrigin?.id === 9
                                    }
                                    />

                                    <InputRadio
                                        name="status"
                                        value={4}
                                        onChange={handleChangeStatus}
                                        checked={details?.idStatus === 4 ? true : false}
                                        label="Processing"
                                        disabled={
                                            defaultStatusOrigin?.id === 7 ||
                                            defaultStatusOrigin?.id === 6 ||
                                            defaultStatusOrigin?.id === 5 ||
                                            defaultStatusOrigin?.id === 9
                                        }
                                    />

                                    <InputRadio
                                        name="status"
                                        value={5}
                                        onChange={handleChangeStatus}
                                        checked={details?.idStatus === 5 ? true : false}
                                        label="Shipped"
                                        disabled={true}
                                    />

                                    <InputRadio
                                        name="status"
                                        value={9}
                                        onChange={handleChangeStatus}
                                        checked={details?.idStatus === 9 ? true : false}
                                        label="Delivered"
                                        disabled={
                                            defaultStatusOrigin?.id != 5 ||
                                            defaultStatusOrigin?.id === 9 
                                        }
                                    />

                                    {defaultStatusOrigin?.id === 5 && details?.idStatus === 9 && (
                                        <Grid item xs={12}>
                                            <label style={{
                                                color: "rgba(0, 0, 0, 0.6)",
                                                fontFamily: "'Roboto','Helvetica','Arial',sans-serif",
                                                fontWeight: "400",
                                                fontSize: "0.75rem",
                                                lineHeight: "1.66",
                                                letterSpacing: "0.03333em",
                                                textAlign: "left",
                                                
                                            }}>
                                                Change to status Delivered detected. Please enter delivery date.
                                            </label>
                                            <DatePickerSimple
                                                label="Delivery date"
                                                name="dateStatusChange"
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    )}

                                    <hr/>

                                    <InputRadio
                                        name="status"
                                        value={6}
                                        onChange={handleChangeStatus}
                                        checked={details?.idStatus === 6 ? true : false}
                                        label="Canceled"
                                        disabled={
                                            defaultStatusOrigin?.id === 7 ||
                                            defaultStatusOrigin?.id === 6 ||
                                            defaultStatusOrigin?.id === 5 ||
                                            defaultStatusOrigin?.id === 9
                                        }
                                    />

                                    <InputRadio
                                        name="status"
                                        value={7}
                                        onChange={handleChangeStatus}
                                        checked={details?.idStatus === 7 ? true : false}
                                        label="Declined"
                                        disabled={true}
                                    />
                                </div>

                                {((defaultStatusOrigin?.id === 2 || defaultStatusOrigin?.id === 3 || defaultStatusOrigin?.id === 4 || defaultStatusOrigin?.id === 8) && (details?.idStatus === 6 || details?.idStatus === 7)) && (
                                    <>
                                        <Alert severity="warning">The stock will be returned to the warehouse.</Alert><br/>
                                    </>
                                )}

                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    size="small"
                                    onClick={updateDetails}
                                    fullWidth
                                    disabled={defaultStatusOrigin?.id === 7}
                                >
                                    Modify
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Box border={1} className="p-20 box-default">
                        <Grid container spacing={3}>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                lg={6}
                                className="border-bottom"
                            >
                                <h5 className="p-0 mt-0" style={{maxWidth:'200px'}}>Store</h5>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                md={6}
                                lg={6}
                                className="border-bottom"
                            >
                                <InputButtonLoading
                                    style={{marginBottom:2, float:'right', display:'inline-block'}}
                                    onClick={handleSubmit}
                                    loading={saveButtonLoading}
                                    label={'Save'}
                                />
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <ul className="MuiList-root css-1mk9mw3-MuiList-root" style={{padding: 0}}>
                                    <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                        <div className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                            <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                Company
                                            </h6>
                                            <div className="MuiBox-root css-1clcuvi">
                                                <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                    {details.company_name}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                        <div className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                            <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                Name
                                            </h6>
                                            <div className="MuiBox-root css-1clcuvi">
                                                <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                    {details.location_name}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                        <div className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                            <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                NetSuite Code
                                            </h6>
                                            <div className="MuiBox-root css-1clcuvi">
                                                <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                    {details.location_code}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                        <div className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                            <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                Comment
                                            </h6>
                                            <div className="MuiBox-root css-1clcuvi">
                                                <InputText
                                                    label=""
                                                    name="comment"
                                                    onChange={handleChange}
                                                    value={state?.comment}
                                                    style={{maxWidth: '300px'}}
                                                />
                                                <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                    {
                                                        /*details.transaction_comment*/
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </Grid>

                            <Grid item xs={12} md={6} lg={6}>
                                <ul className="MuiList-root css-1mk9mw3-MuiList-root" style={{padding: 0}}>
                                    <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                        <div className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                            <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                Region
                                            </h6>
                                            <div className="MuiBox-root css-1clcuvi">
                                                <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                    {details.region_name}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                        <div className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                            <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                Phone Number
                                            </h6>
                                            <div className="MuiBox-root css-1clcuvi">
                                                <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                    {details.location_phone}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                        <div className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                            <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                Address
                                            </h6>
                                            <div className="MuiBox-root css-1clcuvi">
                                                <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                    <span>
                                                        {
                                                            details.location_address1
                                                        }
                                                    </span>
                                                    {details.location_address2 && (
                                                        <br />
                                                    )}
                                                    {details.location_address2 && (
                                                        <span>
                                                            {
                                                                details.location_address2
                                                            }
                                                        </span>
                                                    )}
                                                    {details.location_address3 && (
                                                        <br />
                                                    )}
                                                    {details.location_address3 && (
                                                        <span>
                                                            {
                                                                details.location_address3
                                                            }
                                                        </span>
                                                    )}
                                                    {details.location_address4 && (
                                                        <br />
                                                    )}
                                                    {details.location_address4 && (
                                                        <span>
                                                            {
                                                                details.location_address4
                                                            }
                                                        </span>
                                                    )}
                                                    {details.location_postalCode && (
                                                        <br />
                                                    )}
                                                    {details.location_postalCode && (
                                                        <span>
                                                            {
                                                                details.location_postalCode
                                                            }
                                                        </span>
                                                    )}
                                                    {details.location_address5 && (
                                                        <br />
                                                    )}
                                                    {details.location_address5 && (
                                                        <span>
                                                            {
                                                                details.location_address5
                                                            }
                                                        </span>
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                        <div className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                            <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                Num REF/PO#
                                            </h6>
                                            <div className="MuiBox-root css-1clcuvi">
                                                <InputText
                                                    label=""
                                                    name="ref"
                                                    onChange={handleChange}
                                                    value={state?.ref}
                                                    style={{maxWidth: '300px'}}
                                                />
                                                <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                    {/*details.transaction_ref*/}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                            <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                Num REF/PO# (TOV)
                                            </h6>
                                            <div className="MuiBox-root css-1clcuvi">
                                                <InputText
                                                    label=""
                                                    name="refTov"
                                                    onChange={handleChange}
                                                    value={state?.refTov}
                                                    style={{maxWidth: '300px'}}
                                                />
                                                <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                    {/*details.transaction_refTov*/}
                                                </p>
                                            </div>
                                        </div>
                                        
                                        <div className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                            <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">
                                                Order Group Number(s)
                                            </h6>
                                            <div className="MuiBox-root css-1clcuvi">
                                                <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root" dangerouslySetInnerHTML={{__html: details.groupingList}}>
                                                </p>
                                            </div>
                                        </div>

                                    </li>
                                </ul>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <Grid item xs={12} md={12} lg={12}>
                    <Box border={1} className="box-default">
                        <TableBase
                            columns={columns}
                            data={productos}
                            selectedRows={productos}
                            onSelectedRowsChange={setProducts}
                            search={false}
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
