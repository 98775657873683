import { useParams } from "react-router-dom";
import React from "react";

import Basic from "../../../containers/Admin/Layouts/Basic"
import TransactionEdit from "../../../containers/Admin/TransactionGrouping/Fields"
import Grid from "@material-ui/core/Grid";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import Button from "@material-ui/core/Button";

export default function Edit() {

    const { tab } = useParams();
    const [value, setValue] = React.useState(tab);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Basic menu="transaction-grouping">

            <Grid container spacing={3} className="pl-20 pr-20">
                <Grid item xs={12} md={12} lg={12}>
                    <Button
                        type="button"
                        color="primary"
                        href="/admin/transaction/management"
                        style={{
                            float: "left"
                        }}
                    >
                        <ArrowBackIos />
                        BACK TO LIST
                    </Button>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                </Grid>
            </Grid>

            <Grid>
                <TransactionEdit />
            </Grid>

        </Basic>
    )
}
