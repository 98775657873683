import Basic from "../containers/User/Layouts/Basic"
import RegisterForm from "../containers/Auth/RegisterForm"

export default function Register() {
  return (
    <Basic>
        <RegisterForm/>
    </Basic>
  )
}
