import React from "react";
import {TextField} from "@mui/material";

/**
 * Muestra un input de texto
 *
 * ## PROPS
 * - **label** *string*
 * - **name** *string*
 * - **value** *string*
 * - **onChange** *function*
 * - **required** *bool*
 */
export default function InputText(props) {

    const {label, name, value, onChange, required, style, className, rows, disabled, placeholder, onClick} = props

    const rowsIn = rows ? rows : 1
    const disabledIn = disabled ? disabled : false
    const requiredIn = required ? required : false
    const placeholderIn = placeholder ? placeholder : ""

    const color = '#ef6f28'

    return (
        <TextField
            type="text"
            variant="outlined"
            placeholder={placeholderIn}
            className={`${className}`}
            size="small"
            fullWidth
            disabled={disabledIn}
            rows={rowsIn}
            multiline={rowsIn > 1}
            label={`${label} ${requiredIn ? "*" : ""}`}
            name={name}
            value={value}
            onChange={onChange}
            style={{...style}}
            InputLabelProps={{
                shrink: true,
            }}
            inputProps={{
                style: {
                    fontSize: '14px',
                }
            }}
            sx={{
                background: disabledIn ? '#f5f5f5' : 'white',
                borderRadius: '5px',
                '& label.Mui-focused': {
                    color: disabledIn ? 'none' : color,
                },
                '& .MuiInput-underline:after': {
                    borderBottomColor: disabledIn ? 'none' : color,
                },
                '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                        borderColor: disabledIn ? 'none' : color,
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: disabledIn ? 'none' : color,
                    },
                },
            }}
        />
    )
}
