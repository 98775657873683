import React from "react";
import {useFilters, useGlobalFilter, useMountedLayoutEffect, usePagination, useRowSelect, useTable,} from "react-table";
import {matchSorter} from "match-sorter";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableFooter from "@material-ui/core/TableFooter";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import NativeSelect from "@material-ui/core/NativeSelect";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@mui/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import moment from "moment";
// import {DatePicker} from "@mui/lab";

const useStyles = makeStyles((theme) => ({
    paper: {
        textAlign: "center",
        overflow: "auto",
    },
    formControl: {
        marginRight: 20,
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: 20,
    },
}));

const IndeterminateCheckbox = React.forwardRef(
    ({indeterminate, ...rest}, ref) => {
        const defaultRef = React.useRef();
        const resolvedRef = ref || defaultRef;

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate;
        }, [resolvedRef, indeterminate]);

        return (
            <>
                <Checkbox ref={resolvedRef} {...rest} />
            </>
        );
    }
);

export default function TableFilterBase({
                                            columns,
                                            data,
                                            selectedRows,
                                            onSelectedRowsChange,
                                            search,
                                            selectable,
                                            downInfo = true,
                                            upInfo = false,
                                            isButtonVisible = false,
                                            handleClick,
                                            onChangeContent = () => {}
                                        }) {

    // Define a default UI for filtering
    function DefaultColumnFilter({column: {filterValue, preFilteredRows, setFilter, id}}) {
        const count = preFilteredRows.length;

        return (
            <TextField
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                placeholder={`Search ${count} records...`}
                type="search"
                fullWidth
                id={id}
                name={id}
                size="small"
                value={filterValue || ""}
                onChange={(e) => {
                    setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
                }}
                sx={{
                    "& label.Mui-focused": {
                        display: "none"
                    },
                    "& legend": {
                        display: "none"
                    }
                }}
            />
        );
    }

    function fuzzyTextFilterFn(rows, id, filterValue) {
        return matchSorter(rows, filterValue, {
            keys: [(row) => row.values[id]],
        });
    }

    // Let the table remove the filter if the string is empty
    fuzzyTextFilterFn.autoRemove = (val) => !val;

    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    );

    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            fuzzyText: fuzzyTextFilterFn,
            dateBetween: dateBetweenFilterFn /*<- LIKE THIS*/,
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            },
        }),
        []
    );

    const props = useTable(
        {
            columns,
            data,
            initialState: {
                selectedRowIds: selectedRows,
            },
            defaultColumn, // Be sure to pass the defaultColumn option
            filterTypes,
        },
        useFilters, // useFilters!
        useGlobalFilter,
        usePagination,
        useRowSelect,
        (hooks) => {
            if (selectable) {
                hooks.visibleColumns.push((columns) => [
                    // Let's make a column for selection
                    {
                        id: "selection",
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        Header: ({getToggleAllPageRowsSelectedProps}) => (
                            <div>
                                <IndeterminateCheckbox
                                    {...getToggleAllPageRowsSelectedProps()}
                                />
                            </div>
                        ),
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: ({row}) => (
                            <div>
                                <IndeterminateCheckbox
                                    {...row.getToggleRowSelectedProps()}
                                />
                            </div>
                        ),
                    },
                    ...columns,
                ]);
            }
        }
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        visibleColumns,
        setGlobalFilter,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        nextPage,
        previousPage,
        setPageSize,
        filteredRows,
        state: {pageIndex, pageSize, globalFilter, selectedRowIds, filters},
    } = props;

    useMountedLayoutEffect(() => {
        onSelectedRowsChange && onSelectedRowsChange(selectedRowIds);
    }, [onSelectedRowsChange, selectedRowIds]);

    React.useEffect(() => {
    }, [globalFilter]);

    React.useEffect(() => {
        onChangeContent(filteredRows)
    }, [pageIndex, pageSize, filters])

    const classes = useStyles();

    function dateBetweenFilterFn(rows, id, filterValues) {
        let sd = new Date(filterValues[0])
        let ed = new Date(filterValues[1])
        sd.setHours(0, 0, 0, 0)
        ed.setHours(0, 0, 0, 0)
        if (ed || sd) {
            return rows.filter(r => {
                const time = moment(r.values[id], "D/MM/Y").toDate()

                time.setHours(0, 0, 0, 0)

                if (filterValues[0] && filterValues[1]) {
                    return time >= sd && time <= ed;
                } else if (filterValues[0]) {
                    return time >= sd;
                } else if (filterValues[1]) {
                    return time <= ed;
                }
                return r;
            });

        } else {
            return rows;
        }
    }

    return (
        <>
            {search && (
                <Box m={2}>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        placeholder="Buscador"
                        label="Buscador"
                        type="search"
                        fullWidth
                        value={globalFilter || ""}
                        onChange={(e) => setGlobalFilter(e.target.value)}
                    />
                </Box>
            )}
            {upInfo && (
                <Box m={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={2} md={2} lg={2}>
                            <NativeSelect
                                value={pageSize}
                                onChange={(e) => {
                                    setPageSize(Number(e.target.value));
                                }}
                            >
                                {[10, 20, 30, 40, 50].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </NativeSelect>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                            <Paper className={classes.paper} elevation={0}>
                                {canPreviousPage ? (
                                    <Button onClick={() => previousPage()}>
                                    <span role="presentation">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            role="presentation"
                                        >
                                            <path
                                                fill="currentColor"
                                                fillRule="evenodd"
                                                d="M9.005 10.995l4.593-4.593a.99.99 0 111.4 1.4l-3.9 3.9 3.9 3.9a.99.99 0 01-1.4 1.4L9.005 12.41a1 1 0 010-1.414z"
                                            ></path>
                                        </svg>
                                    </span>
                                    </Button>
                                ) : (
                                    <Button disabled>
                                    <span role="presentation">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            role="presentation"
                                        >
                                            <path
                                                fill="currentColor"
                                                fillRule="evenodd"
                                                d="M9.005 10.995l4.593-4.593a.99.99 0 111.4 1.4l-3.9 3.9 3.9 3.9a.99.99 0 01-1.4 1.4L9.005 12.41a1 1 0 010-1.414z"
                                            ></path>
                                        </svg>
                                    </span>
                                    </Button>
                                )}

                                {canNextPage ? (
                                    <Button
                                        onClick={() => nextPage()}
                                        disabled={!canNextPage}
                                    >
                                    <span role="presentation">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            role="presentation"
                                        >
                                            <path
                                                fill="currentColor"
                                                fillRule="evenodd"
                                                d="M14.995 10.995a1 1 0 010 1.414l-4.593 4.593a.99.99 0 01-1.4-1.4l3.9-3.9-3.9-3.9a.99.99 0 011.4-1.4l4.593 4.593z"
                                            ></path>
                                        </svg>
                                    </span>
                                    </Button>
                                ) : (
                                    <Button disabled>
                                    <span role="presentation">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            role="presentation"
                                        >
                                            <path
                                                fill="currentColor"
                                                fillRule="evenodd"
                                                d="M14.995 10.995a1 1 0 010 1.414l-4.593 4.593a.99.99 0 01-1.4-1.4l3.9-3.9-3.9-3.9a.99.99 0 011.4-1.4l4.593 4.593z"
                                            ></path>
                                        </svg>
                                    </span>
                                    </Button>
                                )}
                            </Paper>
                        </Grid>
                        <Grid item xs={2} md={2} lg={2}>
                            Page <strong>{pageIndex + 1}</strong> of{" "}
                            <strong> {pageOptions.length} </strong>
                        </Grid>
                        <Grid item xs={2} md={2} lg={2} style={{textAlign: "right"}}>
                            {isButtonVisible && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={handleClick}
                                    type={!handleClick ? "submit" : ""}
                                >
                                    Save
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            )}
            <Box m={0} style={{overflow: "auto"}}>
                <Table {...getTableProps()}>
                    <TableHead style={{background: "#f1f2f6"}}>
                        {headerGroups.map((headerGroup, key) => (
                            <TableRow
                                {...headerGroup.getHeaderGroupProps()}
                                key={key}
                            >
                                {headerGroup.headers.map((column, key) => {
                                    if (!column.hidden) {
                                        return (
                                            <TableCell
                                                {...column.getHeaderProps()}
                                                key={key}
                                                style={column.disableFilters ? {verticalAlign: "top", width: column.width} : {verticalAlign: "inherit", width: column.width}}
                                            >

                                                {column.render("Header")}
                                                <div>
                                                    {column.canFilter
                                                        ? column.render("Filter")
                                                        : null}
                                                </div>
                                            </TableCell>
                                        )
                                    } else {
                                        return (<></>)
                                    }
                                })}
                            </TableRow>
                        ))}
                        <tr>
                            <th
                                colSpan={visibleColumns.length}
                                style={{
                                    textAlign: "left",
                                }}
                            ></th>
                        </tr>
                    </TableHead>
                    <TableBody {...getTableBodyProps()}>
                        {page.map((row, key) => {
                            prepareRow(row);
                            return (
                                <TableRow {...row.getRowProps()} key={key}>
                                    {row.cells.map((cell, key) => {
                                        if (!cell.column.hidden) {
                                            return (
                                                <TableCell
                                                    {...cell.getCellProps()}
                                                    key={key}
                                                >
                                                    {cell.render("Cell")}
                                                </TableCell>
                                            );
                                        } else {
                                            return (<></>)
                                        }
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                    {headerGroups && (
                        <TableFooter style={{background: "#f1f2f6"}}>
                            {headerGroups.map((headerGroup, key) => (
                                <TableRow
                                    {...headerGroup.getHeaderGroupProps()}
                                    key={key}
                                >
                                    {headerGroup.headers.map((column, key) => {
                                        if (!column.hidden) {
                                            return (
                                                <TableCell
                                                    {...column.getHeaderProps()}
                                                    key={key}
                                                    style={column.disableFilters ? {
                                                        verticalAlign: "top", fontWeight: 500,
                                                        color: "rgba(0, 0, 0, 0.87)",
                                                    } : {
                                                        verticalAlign: "inherit", fontWeight: 500,
                                                        color: "rgba(0, 0, 0, 0.87)",
                                                    }}
                                                >

                                                    {column.render("Header")}
                                                    <div>
                                                        {column.canFilter
                                                            ? column.render("Filter")
                                                            : null}
                                                    </div>
                                                </TableCell>
                                            )
                                        } else {
                                            return (<></>)
                                        }
                                    })}
                                </TableRow>
                            ))}
                        </TableFooter>
                    )}
                </Table>
            </Box>
            {downInfo && (
                <Box m={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={2} md={2} lg={2}>
                            <NativeSelect
                                value={pageSize}
                                onChange={(e) => {
                                    setPageSize(Number(e.target.value));
                                }}
                            >
                                {[10, 20, 30, 40, 50].map((pageSize) => (
                                    <option key={pageSize} value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </NativeSelect>
                        </Grid>
                        <Grid item xs={6} md={6} lg={6}>
                            <Paper className={classes.paper} elevation={0}>
                                {canPreviousPage ? (
                                    <Button onClick={() => previousPage()}>
                                    <span role="presentation">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            role="presentation"
                                        >
                                            <path
                                                fill="currentColor"
                                                fillRule="evenodd"
                                                d="M9.005 10.995l4.593-4.593a.99.99 0 111.4 1.4l-3.9 3.9 3.9 3.9a.99.99 0 01-1.4 1.4L9.005 12.41a1 1 0 010-1.414z"
                                            ></path>
                                        </svg>
                                    </span>
                                    </Button>
                                ) : (
                                    <Button disabled>
                                    <span role="presentation">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            role="presentation"
                                        >
                                            <path
                                                fill="currentColor"
                                                fillRule="evenodd"
                                                d="M9.005 10.995l4.593-4.593a.99.99 0 111.4 1.4l-3.9 3.9 3.9 3.9a.99.99 0 01-1.4 1.4L9.005 12.41a1 1 0 010-1.414z"
                                            ></path>
                                        </svg>
                                    </span>
                                    </Button>
                                )}

                                {canNextPage ? (
                                    <Button
                                        onClick={() => nextPage()}
                                        disabled={!canNextPage}
                                    >
                                    <span role="presentation">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            role="presentation"
                                        >
                                            <path
                                                fill="currentColor"
                                                fillRule="evenodd"
                                                d="M14.995 10.995a1 1 0 010 1.414l-4.593 4.593a.99.99 0 01-1.4-1.4l3.9-3.9-3.9-3.9a.99.99 0 011.4-1.4l4.593 4.593z"
                                            ></path>
                                        </svg>
                                    </span>
                                    </Button>
                                ) : (
                                    <Button disabled>
                                    <span role="presentation">
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            role="presentation"
                                        >
                                            <path
                                                fill="currentColor"
                                                fillRule="evenodd"
                                                d="M14.995 10.995a1 1 0 010 1.414l-4.593 4.593a.99.99 0 01-1.4-1.4l3.9-3.9-3.9-3.9a.99.99 0 011.4-1.4l4.593 4.593z"
                                            ></path>
                                        </svg>
                                    </span>
                                    </Button>
                                )}
                            </Paper>
                        </Grid>
                        <Grid item xs={2} md={2} lg={2}>
                            Page <strong>{pageIndex + 1}</strong> of{" "}
                            <strong> {pageOptions.length} </strong>
                        </Grid>
                        <Grid item xs={2} md={2} lg={2} style={{textAlign: "right"}}>
                            {isButtonVisible && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={handleClick}
                                    type={!handleClick ? "submit" : ""}
                                >
                                    Save
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            )}

        </>
    );
}


export function SelectColumnFilter({column: {filterValue, setFilter, preFilteredRows, id}}) {
    const classes = useStyles();

    // Calculate the options for filtering
    // using the preFilteredRows
    const options = React.useMemo(() => {
        let options = new Set();

        preFilteredRows.sort().forEach((row) => {
            if (row.values[id]) {
                options.add(row.values[id]);
            }
        });

        options = Array.from(options).sort()

        return [...options.values()];

    }, [id, preFilteredRows]);

    // Render a multi-select box

    return (
        <FormControl
            variant="outlined"
            size="small"
            className={classes.formControl}
        >
            <Select
                value={filterValue}
                id={id}
                name={id}
                onChange={(e) => {
                    setFilter(e.target.value);
                }}
                sx={{
                    "& label.Mui-focused": {
                        display: "none"
                    },
                    "& legend": {
                        display: "none"
                    }
                }}
            >
                <MenuItem value="">
                    <em>All</em>
                </MenuItem>
                {options.map((option, i) => (
                    <MenuItem key={i} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export function DateColumnFilter({column: {filterValue = [], setFilter, preFilteredRows, id}}) {
    const classes = useStyles();

    // Calculate the options for filtering
    // using the preFilteredRows
    const [min, max] = React.useMemo(() => {
        let min = new Date(preFilteredRows[0]?.values[id])
        let max = new Date(preFilteredRows[0]?.values[id])
        preFilteredRows.forEach(row => {
            min = new Date(row.values[id]) <= min ? new Date(row.values[id]) : min
            max = new Date(row.values[id]) >= max ? new Date(row.values[id]) : max
        });
        return [min, max];
    }, [id, preFilteredRows]);

    // Render a multi-select box

    return (
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <FormControl
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={classes.formControl}>
                    <OutlinedInput
                        value={filterValue[0] || ""}
                        inputvariant="outlined"
                        id={`dateFrom_${id}`}
                        type="date"
                        placeholder="From"
                        name={`dateFrom_${id}`}
                        size="small"
                        onChange={
                            (e) => {
                                const val = e.target.value;
                                setFilter((old = []) => [val ? (val) : undefined, old[1]]);
                            }
                        }
                        sx={{
                            "& label.Mui-focused": {
                                display: "none"
                            },
                            "& legend": {
                                display: "none"
                            }
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <FormControl
                    variant="outlined"
                    size="small"
                    fullWidth
                    className={classes.formControl}>
                    <OutlinedInput
                        value={filterValue[1] || ""}
                        inputvariant="outlined"
                        id={`dateTo_${id}`}
                        type="date"
                        name={`dateTo_${id}`}
                        placeholder="To"
                        size="small"
                        onChange={(e) => {
                            const val = e.target.value;
                            setFilter((old = []) => [old[0], val ? (val) : undefined]);
                        }}
                        sx={{
                            "& label.Mui-focused": {
                                display: "none"
                            },
                            "& legend": {
                                display: "none"
                            }
                        }}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
}
