import Frontend from "../containers/User/Layouts/Frontend";
import { useHistory } from "react-router-dom";
import useAuth from "../hooks/useAuth";

export default function Homepage() {

    const history = useHistory();
    const { auth } = useAuth();

    if (!auth) {
        history.push("/login");
    } else {
        history.push("/dash/order")
    }

    return (
        <Frontend title="Homepage">
        </Frontend>
    );
}
