import Basic from "../../../containers/Admin/Layouts/Basic"
import ContainerManagement from "../../../containers/Admin/Container/Fields"

export default function Management() {
    return (
        <Basic menu="warehouse">
            <ContainerManagement />
        </Basic>
    )
}
