import Basic from "../../../containers/Admin/Layouts/Basic"
import ComponentManagement from "../../../containers/Admin/Product/Management"

export default function Management() {
  return (
    <Basic menu="products">
          <ComponentManagement />
    </Basic>
  )
}
