import Frontend from "../../../../containers/User/Layouts/Frontend";
import ReportAvailabilityPage from "../../../../containers/User/Reports/Availability"

export default function Report() {
    return (
        <Frontend title="Send Reports" menu="sendReportsAvailability">
            <ReportAvailabilityPage />
        </Frontend>
    );
}
