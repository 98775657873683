import { BASE_PATH, PATH_BASE_EXTENSION } from "../../utils/constants"
import { authFetch } from "../../utils/fetch"
import axios from "axios";

const baseUrl = `${BASE_PATH}${PATH_BASE_EXTENSION}/user/report`

export async function apiList(data) {

    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
    }

    return axios.post(`${baseUrl}/supply-chain/`, data, config)
}