import React, {useEffect, useMemo, useState} from "react";
import useAuth from "../../../../hooks/useAuth";
import TableFilterBase from "../../../../components/Admin/TableFilterBaser";
import {apiList} from "../../../../services/user/supplyChain";
import Loading from "../../../../components/User/Loading";
import clone from "../../../../utils/clone";
import MsgError from "../../../../components/User/MsgError";
import {textErrorGeneral} from "../../../../utils/constants"

export default function Table(props) {

    const {
        filter,
        setFilterDisabled
    } = props

    const { logout } = useAuth()
    const [loading, setLoading] = useState(false)
    const [elems, setElems] = useState(null)
    const [msgError, setMsgError] = useState([])

    const columns = useMemo(
        () => [
            {
                Header: "Product Code",
                accessor: "code",
                disableFilters: true,
            },
            {
                Header: "Product Category",
                accessor: "item_category",
                disableFilters: true,
            },
            {
                Header: "Item",
                accessor: "item",
                disableFilters: true
            },
            {
                Header: "Description",
                accessor: "item_description",
                disableFilters: true
            },
            {
                Header: "Pcs/Pack",
                accessor: "piecesPerPack",
                disableFilters: true
            },
            {
                Header: "Warehouse",
                accessor: "warehouse_name",
                disableFilters: true
            },
            {
                Header: "Zone",
                accessor: "region",
                disableFilters: true,
            },
            {
                Header: "PO For Current Campaign",
                accessor: "totalQtyCurrent",
                disableFilters: true
            },
            {
                Header: "PO Additional Quantity",
                accessor: "poAdditionalQuantity",
                disableFilters: true
            },
            {
                Header: "Total Po",
                accessor: "totalPo",
                disableFilters: true
            },
            {
                Header: "Price In Packs",
                accessor: "priceInPacks",
                disableFilters: true
            },
            {
                Header: "Total Received Idp Whse",
                accessor: "totalReceivedIdpWhse",
                disableFilters: true
            },
            {
                Header: "Total Pieces received",
                accessor: "totalReceivedIdpWhsePcs",
                disableFilters: true
            },
            {
                Header: "Stock Available",
                accessor: "stockOnHand",
                disableFilters: true
            },
            {
                Header: "Stock Available Pcs",
                accessor: "stockOnHandPcs",
                disableFilters: true
            },
            {
                Header: "In Transit",
                accessor: "stockInTransit",
                disableFilters: true
            },
            {
                Header: "In Transit Pcs",
                accessor: "stockInTransitPcs",
                disableFilters: true
            },
            {
                Header: "Eta Packs In Transit",
                accessor: "etaPacksInTransit",
                disableFilters: true
            },
            {
                Header: "Ata Packs In Transit",
                accessor: "ataPacksInTransit",
                disableFilters: true
            },
            {
                Header: "Current Stock Value",
                accessor: "currentStockValue",
                disableFilters: true
            },
            {
                Header: "In Transit Qty Value",
                accessor: "inTransitQtyValue",
                disableFilters: true
            },
            {
                Header: "Total Value",
                accessor: "totalValue",
                disableFilters: true
            },
            {
                Header: "Delivery Rate Versus Total Order",
                accessor: "deliveryRateVsTotalOrder",
                disableFilters: true
            },
            {
                Header: "Stock Availability Rate",
                accessor: "stockAvailabilityRate",
                disableFilters: true
            },
            {
                Header: "Stock Availability Rate Incl. Incoming Quantities",
                accessor: "stockAvailabilityRateIncldIncomingQty",
                disableFilters: true
            },
            {
                Header: "Stock Consumption Rate",
                accessor: "stockConsumptionRate",
                disableFilters: true
            },
            {
                Header: "Total Store Orders",
                accessor: "stockOnOrder",
                disableFilters: true
            },
            {
                Header: "Balance Packs Idp Factory",
                accessor: "balancePacksIdpFactory",
                disableFilters: true
            },
            {
                Header: "Comment",
                accessor: "comment",
                disableFilters: true
            },
            {
                Header: "Weekly Consumption",
                accessor: "weeklyConsumption",
                disableFilters: true
            },
            {
                Header: "Stock Coverage In Weeks",
                accessor: "stockCoverageInWeeks",
                disableFilters: true
            },
            {
                Header: "Stock Coverage In Weeks Incl. Incoming",
                accessor: "stockCoverageInWeeksIncludingIncoming",
                disableFilters: true
            },
            {Header: "W1",accessor: "ordersWeek1",disableFilters: true},
            {Header: "W2",accessor: "ordersWeek2",disableFilters: true},
            {Header: "W3",accessor: "ordersWeek3",disableFilters: true},
            {Header: "W4",accessor: "ordersWeek4",disableFilters: true},
            {Header: "W5",accessor: "ordersWeek5",disableFilters: true},
            {Header: "W6",accessor: "ordersWeek6",disableFilters: true},
            {Header: "W7",accessor: "ordersWeek7",disableFilters: true},
            {Header: "W8",accessor: "ordersWeek8",disableFilters: true},
            {Header: "W9",accessor: "ordersWeek9",disableFilters: true},
            {Header: "W10",accessor: "ordersWeek10",disableFilters: true},
            {Header: "W11",accessor: "ordersWeek11",disableFilters: true},
            {Header: "W12",accessor: "ordersWeek12",disableFilters: true},
            {Header: "W13",accessor: "ordersWeek13",disableFilters: true},
            {Header: "W14",accessor: "ordersWeek14",disableFilters: true},
            {Header: "W15",accessor: "ordersWeek15",disableFilters: true},
            {Header: "W16",accessor: "ordersWeek16",disableFilters: true},
            {Header: "W17",accessor: "ordersWeek17",disableFilters: true},
            {Header: "W18",accessor: "ordersWeek18",disableFilters: true},
            {Header: "W19",accessor: "ordersWeek19",disableFilters: true},
            {Header: "W20",accessor: "ordersWeek20",disableFilters: true},
            {Header: "W21",accessor: "ordersWeek21",disableFilters: true},
            {Header: "W22",accessor: "ordersWeek22",disableFilters: true},
            {Header: "W23",accessor: "ordersWeek23",disableFilters: true},
            {Header: "W24",accessor: "ordersWeek24",disableFilters: true},
            {Header: "W25",accessor: "ordersWeek25",disableFilters: true},
            {Header: "W26",accessor: "ordersWeek26",disableFilters: true},
            {Header: "W27",accessor: "ordersWeek27",disableFilters: true},
            {Header: "W28",accessor: "ordersWeek28",disableFilters: true},
            {Header: "W29",accessor: "ordersWeek29",disableFilters: true},
            {Header: "W30",accessor: "ordersWeek30",disableFilters: true},
            {Header: "W31",accessor: "ordersWeek31",disableFilters: true},
            {Header: "W32",accessor: "ordersWeek32",disableFilters: true},
            {Header: "W33",accessor: "ordersWeek33",disableFilters: true},
            {Header: "W34",accessor: "ordersWeek34",disableFilters: true},
            {Header: "W35",accessor: "ordersWeek35",disableFilters: true},
            {Header: "W36",accessor: "ordersWeek36",disableFilters: true},
            {Header: "W37",accessor: "ordersWeek37",disableFilters: true},
            {Header: "W38",accessor: "ordersWeek38",disableFilters: true},
            {Header: "W39",accessor: "ordersWeek39",disableFilters: true},
            {Header: "W40",accessor: "ordersWeek40",disableFilters: true},
            {Header: "W41",accessor: "ordersWeek41",disableFilters: true},
            {Header: "W42",accessor: "ordersWeek42",disableFilters: true},
            {Header: "W43",accessor: "ordersWeek43",disableFilters: true},
            {Header: "W44",accessor: "ordersWeek44",disableFilters: true},
            {Header: "W45",accessor: "ordersWeek45",disableFilters: true},
            {Header: "W46",accessor: "ordersWeek46",disableFilters: true},
            {Header: "W47",accessor: "ordersWeek47",disableFilters: true},
            {Header: "W48",accessor: "ordersWeek48",disableFilters: true},
            {Header: "W49",accessor: "ordersWeek49",disableFilters: true},
            {Header: "W50",accessor: "ordersWeek50",disableFilters: true},
            {Header: "W51",accessor: "ordersWeek51",disableFilters: true},
            {Header: "W52",accessor: "ordersWeek52",disableFilters: true},
            {
                Header: "Last Updated",
                accessor: "lastUpdate",
                disableFilters: true
            }
        ],
        []
    )


    const getItemsData = async () => {
        setMsgError([])
        setElems([])
        setLoading(true)
        setFilterDisabled(true)

        apiList( {
            idCompany: filter?.idCompany?.id,
            idRegion: filter?.idRegion?.id,
            idRegionName: filter?.idRegion?.name,
            idWarehouseName: filter?.idWarehouse?.name,
            idWarehouse: filter?.idWarehouse?.id,
            idProductCategory: filter?.idProductCategory?.id,
            idSemester: filter?.idSemester?.id,
        }).then(resp => {
            let temp = []
            temp = resp.data.data.map((doc) => {
                return {
                    ...doc,
                    name: `${doc.name} ${doc.description}`,
                }

            })
            setElems(temp)
            setLoading(false)
            setFilterDisabled(false)
        }).catch(err => {
            setElems([])
            setLoading(false)
            setFilterDisabled(false)
            setMsgError([textErrorGeneral])
        })
    }

    useEffect(() => {
        setMsgError([])
        setElems([])
        if (filter?.idSemester) {
            setLoading(true)
            const timeOutId = setTimeout(() => {
                getItemsData()
            }, 4000)
            return () => clearTimeout(timeOutId)
        }
    }, [filter])

    return (
        <>
            {!filter?.idSemester && (
                <h1 style={{textAlign: 'center', padding: 30}}>Select a semester for displaying data</h1>
            )}

            <MsgError msg={msgError} />

            {filter?.idSemester && (
                <>
                    <Loading show={loading} title="Fetching Supply Chain Reports..."/>

                    {(!loading) && (
                        <TableFilterBase
                            columns={columns}
                            data={elems || []}
                            selectedRows={elems}
                            onSelectedRowsChange={setElems}
                            search={false}
                            selectable={false}
                        />
                    )}
                </>
            )}
        </>
    )
}
