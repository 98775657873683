import Basic from "../../../containers/Admin/Layouts/Basic"
import ContainersCreate from "../../../containers/Admin/Warehouse/Fields"
import Grid from "@material-ui/core/Grid";
import React from "react";
import Button from "@material-ui/core/Button";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";

export default function Create() {
  return (
    <Basic menu="warehouse">
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Button
                        type="button"
                        color="primary"
                        href="/admin/warehouse/management"
                        style={{
                            float: "left"
                        }}
                    >
                        <ArrowBackIos />
                        BACK TO LIST
                    </Button>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <h2 className="p-0">Warehouse Settings</h2>
                </Grid>
                <Grid item xs={6} md={6} lg={6}></Grid>
            </Grid>
          <ContainersCreate />
    </Basic>
  )
}
