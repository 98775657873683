import React, {useEffect, useMemo, useState} from "react"
import {apiShippingChargeList} from "../../../../services/admin/shippingCharge"
import useAuth from "../../../../hooks/useAuth"
import NewTable from "../../../../components/Admin/NewTable"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import {Grid, IconButton} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import InputText from "../../../../components/Admin/Inputs/InputText";
import {DASH_MAX_ROW} from "../../../../utils/constants";

export default function Management() {
    const [rows, setRows] = useState([])
    const [msgError, setMsgError] = useState([])
    const [loading, setLoading] = useState(false)
    const [rowCount, setRowCount] = useState(0)
    const [state, setState] = useState(initialValues)

    useEffect(() => {
        setLoading(true)
        const timeOutId = setTimeout(() => {
            getItemsData()
        }, 1000)
        return () => clearTimeout(timeOutId)
    }, [state])

    const getItemsData = () => {

        let params = {
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: state.sort.field,
            sort: state.sort.sort
        }
        if (state.name) { params.name = state.name }
        if (state.company) { params.company = state.company }

        apiShippingChargeList(params).then(resp => {
            setRowCount(resp.data.total)

            let temp = resp.data.item.map((doc) => {

                let href = `/admin/shipping-charge/edit/${doc.id}`

                let companyName = ""
                let currencyName = ""

                return {
                    ...doc,
                    isActive: doc.isActive ? "Yes" : "No",
                    editLink: href
                }

            })

            setRows(temp)
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        })
    }


    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={6} md={6} lg={6}>
                    <h1>Display Shipping Charges</h1>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ float: "right", margin: "20px 0px" }}
                        href="/admin/shipping-charge/create"
                    >
                        + add
                    </Button>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box border={1} className="box-default">

                        <Filters
                            state={state}
                            setState={setState}
                        />
                        
                        <NewTable
                            columns={tableColumns()}
                            rows={rows}
                            rowCount={rowCount}
                            filter={state}
                            setFilter={setState}
                            loading={loading}
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    );

}


function Filters(props) {

    const {state, setState} = props

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        state["offset"] = 0
        setState({...state})
    }

    return (
        <Grid container spacing={3} style={{padding: 20}}>
            <Grid item xs={6}>
                <InputText
                    label="Company"
                    name="company"
                    value={state.company}
                    onChange={handleChange}
                />
            </Grid>

            <Grid item xs={6}>
                <InputText
                    label="Name"
                    name="name"
                    value={state.name}
                    onChange={handleChange}
                />
            </Grid>
        </Grid>
    )
}


function initialValues(props) {
    return {
        sort: { field: "company", sort: "desc" },
        limit: DASH_MAX_ROW,
        offset: 0,
        company: "",
        name: ""
    }
}

function tableColumns(handleChangeSelection, selection) {

    return [
        {
            field: "companyName",
            headerName: "COMPANY",
            sortable: true,
            flex: 1
        },
        {
            field: "name",
            headerName: "NAME",
            sortable: false,
            flex: 1
        },
        {
            field: "costPerPack",
            headerName: "PRICE PER PACK",
            sortable: false,
            flex: 1
        },
        {
            field: "currencyName",
            headerName: "CURRENCY",
            sortable: false,
            flex: 1
        },
        {
            field: "isActive",
            headerName: "ACTIVE",
            sortable: false,
            flex: 1
        },
        {
            field: "actions",
            headerName: "ACTIONS",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={params.editLink}>
                            <IconButton size="small">
                                <EditIcon fontSize="inherit" />
                            </IconButton>
                        </Link>
                    </>
                )
            },
        },
    ]
}