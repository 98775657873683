import { BASE_PATH, PATH_BASE_EXTENSION } from "../../utils/constants"
import { authFetch } from "../../utils/fetch"

export async function addApi(logout, data) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/user/transaction/add`
        const token = localStorage.getItem("token")
        const params = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        return error;
    }
}

export async function getTransaction(logout, id) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/user/transaction/${id}`;
        const token = localStorage.getItem("token");
        const params = {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}


export async function updateOrder(logout, data) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/user/transaction/update`
        const token = localStorage.getItem("token")
        const params = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function approveTransaction(logout, data) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/user/transaction/approve`
        const token = localStorage.getItem("token")
        const params = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function declineTransaction(logout, data) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/user/transaction/decline`
        const token = localStorage.getItem("token")
        const params = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function reorderTransaction(logout, data) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/user/transaction/duplicate`
        const token = localStorage.getItem("token")
        const params = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function getShipments(logout, id) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/user/transaction/shipments/${id}`;
        const token = localStorage.getItem("token");
        const params = {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function markAsDeliveredApi(logout, id) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/user/transaction/delivered/${id}`;
        const token = localStorage.getItem("token");
        const params = {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}
