import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import NewTable from "../../../../components/Admin/NewTable";
import InputText from "../../../../components/Inputs/InputText";
import {apiList, apiListProducts, apiListSemester, apiListStatus} from "../../../../services/admin/po";
import {DASH_MAX_ROW} from "../../../../utils/constants";
import moment from "moment";
import {Link} from "react-router-dom";
import {Button, Chip, IconButton} from "@mui/material";
import Stack from "@mui/material/Stack";
import InputSelect from "../../../../components/Inputs/InputSelect";
import {listApi} from "../../../../services/admin/request";
import useAuth from "../../../../hooks/useAuth";
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Checkbox from "@material-ui/core/Checkbox";

export default function User() {
    const [state, setState] = useState(initialValues())
    const [loading, setLoading] = useState(true)
    const [rows, setRows] = useState([])
    const [rowCount, setRowCount] = useState(null)
    const [msgError, setMsgError] = useState([])


    useEffect(() => {
        setLoading(true)
        const timeOutId = setTimeout(() => {
            getPo()
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [state])

    const getPo = () => {
        setMsgError([])

        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let field = state.sort.field
        if (state.sort.field === "roleName") { field = "role" }
        if (state.sort.field === "active") { field = "isActive" }
        if (state.sort.field === "access") { field = "lastActivity" }

        let params = {
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort,
            idCompany: state.idCompany,
            idWarehouse: state.idWarehouse,
            statusId: state.statusId,
            name: state.name,
            semester: state.semesterId,
            description: state.description,

        }

        if (state.idCompany) { params.idCompany = state.idCompany }
        if (state.idWarehouse) { params.idWarehouse = state.idWarehouse }
        if (state.statusId) { params.statusId = state.statusId }
        if (state.semesterId) { params.semesterId = state.semesterId }
        if (state.name) { params.name = state.name }
        if (state.description) { params.description = state.description }


        apiList(params).then(resp => {
            setRowCount(resp.data?.count)

            setRowCount(resp.data.total)
            let temp = resp.data.data?.map((doc, key) => {
                return {
                    ...doc,
                    idPo: doc.id,
                    creationDate: moment.unix(doc?.creationDate).format("Y/M/D"),
                    validationDate: doc?.validationDate ? moment.unix(doc?.validationDate).format("Y/M/D") : "-",
                }
            })

            setRows(temp)
            setLoading(false)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setRows([])
        })
    }


    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <h1>Display PO</h1>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box border={1} className="box-default" style={{paddingBottom: 10}}>
                        <Filters
                            state={state}
                            setState={setState}
                            setMsgError={setMsgError}
                        />
                        <NewTable
                            columns={tableColumns()}
                            rows={rows}
                            rowCount={rowCount}
                            filter={state}
                            setFilter={setState}
                            loading={loading}
                        />

                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

function Filters(props) {

    const {state, setState, setMsgError} = props
    const [company, setCompany] = useState([])
    const [listStatus, setListStatus] = useState([])
    const [warehouse, setWarehouse] = useState([])
    const [listSemester, setListSemester] = useState([])
    const {logout} = useAuth()

    useEffect(() => {
        getCompany()
        getSemester()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        state["offset"] = 0
        setState({...state})
    }

    const getCompany = async () => {
        const response = await listApi(logout, 'company')
        if (response.status) {
            const temp = response.data.map((doc) => {
                return {
                    id: doc.id,
                    name: doc.name,
                }
            })
            setCompany(temp)
        }
    }
    
    const getSemester = async () => {
        apiListSemester().then(resp => {
            setListSemester(resp.data)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
        })
    }

    
    return (
        <Grid container spacing={1} style={{padding:'20px'}}>
            <Grid item xs={6}>
                <InputSelect
                    label="Company"
                    name="idCompany"
                    onChange={handleChange}
                    value={state?.idCompany}
                    options={company}
                />
            </Grid>
            <Grid item xs={6}>
                <InputSelect
                    label="Semester"
                    name="semesterId"
                    onChange={handleChange}
                    value={state?.semesterId}
                    options={listSemester}
                />
            </Grid>
            <Grid item xs={6}>
                <InputText
                    label="Name"
                    name="name"
                    onChange={handleChange}
                    value={state?.name}
                />
            </Grid>
            <Grid item xs={5}>
                <InputText
                    label="Description"
                    name="description"
                    onChange={handleChange}
                    value={state?.description}
                />
            </Grid>
            <Grid item xs={1}>
                <Button variant="text"  onClick={() => setState(initialValues())} style={{float: 'right', height: 35}}>
                    <HighlightOffIcon />
                </Button>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{float: "right"}}
                    href={`/admin/po/create`}
                >
                    + add PO
                </Button>
            </Grid>

        </Grid>
    )
}
function tableColumns(props) {
    return [
        {
            field: "companyName",
            headerName: "COMPANY",
            flex: 2,
            sortable: true,
        },
        {
            field: "warehouseName",
            headerName: "WAREHOUSE",
            flex: 2,
            sortable: false,
        },
        {
            field: "name",
            headerName: "NAME",
            flex: 1,
            sortable: false
        },
        {
            field: "description",
            headerName: "DESCRIPTION",
            flex: 1,
            sortable: false
        },
        {
            field: "semesterName",
            headerName: "SEMESTER",
            flex: 1,
            sortable: false
        },
        {
            field: "creationDate",
            headerName: "CREATION DATE",
            flex: 1,
            sortable: false
        },
        {
            field: "validationDate",
            headerName: "VALIDATION DATE",
            flex: 1,
            sortable: false
        },
        {
            field: "statusName",
            headerName: "STATUS",
            flex: 1,
            sortable: false
        },
        {
            field: "actions",
            headerName: "ACTIONS",
            sortable: false,
            renderCell: (params) => {
                return (
                    <>
                        <Link to={`/admin/po/edit/${params.idPo}`}>
                            <IconButton size="small">
                                <EditIcon fontSize="inherit" />
                            </IconButton>
                        </Link>
                    </>
                )
            },
        },
    ]
}

function initialValues(props) {
    return {
        offset: 0,
        sort: { field: 'creationDate', sort: 'desc' },
        idCompany: "",
        idWarehouse: "",
        statusId: "",
        name: "",
        description: "",
        semesterId: "",
    }
}
