import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { listApi } from "../../../../services/admin/request";
import useAuth from "../../../../hooks/useAuth";
import TableBase from "../../../../components/Admin/TableFilterBaser";
import { createDeleteRelationProduct } from "../../../../services/admin/warehouse";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";

export default function Product() {
    const { id } = useParams()
    const [products, setProducts] = useState(null)

    const [selectedRows, setSelectedRows] = useState({})
    const [msgSuccess, setMsgSuccess] = useState(false)
    const [msgDanger, setDsgDanger] = useState(null)

    const { logout } = useAuth();

    const columns = useMemo(
        () => [
            {
                Header: "COMPANY",
                accessor: "companyName",
            },
            {
                Header: "NAME",
                accessor: "name",
            },
            {
                Header: "DESCRIPTION",
                accessor: "description",
                disableFilters: true
            },
            {
                Header: "REFERENCE",
                accessor: "item_reference"
            },
        ],
        []
    )

    useEffect(() => {
        (async () => {
            const response = await listApi( logout, `warehouse/product-relations/${id}`)
            if (response.status) {
                let position = {};
                let product = [];

                response.data.forEach( (doc, key) => {
                    product.push({...doc})

                    if (doc.isCheckout) {
                        position[`${key}`] = true
                    }

                })

                setSelectedRows(position)
                setProducts(product)
            }
        })()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const selectProduct = async () => {
        setMsgSuccess(false)
        setDsgDanger(null)

        let idProducts = [];
        for (const position in selectedRows) {
            idProducts.push(products[position].idProduct)
        }

        let response = await createDeleteRelationProduct({
            idWarehouse: id,
            idProducts: idProducts
        })

        if (response.status) {
            setMsgSuccess(true)
        } else {
            setDsgDanger(response.error)
        }
    }

    if (!products) return ""

    return (
        <>
            {msgSuccess && (
                <Grid item xs={12} md={12} lg={12}>
                    <Box className="alert-success">

                        Products have been successfully added to warehouse
                    </Box>
                </Grid>
            )}

            {msgDanger && (
                <Grid item xs={12} md={12} lg={12}>
                    <Box className="alert-danger">
                        {msgDanger.map((item) => {
                            return (
                                <span>{item} <br />{" "}</span>
                            );
                        })}
                    </Box>
                </Grid>
            )}

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box border={1} className="box-default">
                        <TableBase
                            columns={columns}
                            data={products}
                            selectedRows={selectedRows}
                            onSelectedRowsChange={setSelectedRows}
                            selectable={true}
                            upInfo={true}
                            downInfo={false}
                            isButtonVisible={true}
                            handleClick={selectProduct}
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}