import React from "react";
import {Button, Grid} from "@mui/material";
import {ArrowBackIos} from "@mui/icons-material";

export default function HeaderPage(props) {
    const {title, subtitle, children, urlBack} = props

    const urlBackIn = urlBack ? urlBack : null

    return (
        <Grid container justifyContent="flex-start" spacing={1}>
            <Grid item xs={12} md={6} lg={6}>
                <div>
                    {urlBackIn && (
                        <Button
                            type="button"
                            href={urlBack}
                            style={{float: 'left', color: 'black'}}
                        >
                            <ArrowBackIos/>
                        </Button>
                    )}
                    <h2 className="p-0" style={{float: 'left'}}>{title} <span className="font-w-100"> {subtitle}</span></h2>
                </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
                {children}
            </Grid>
        </Grid>
    )
}
