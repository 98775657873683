import Frontend from "../../../../containers/User/Layouts/Frontend";
import OrderPage from "../../../../containers/User/Reorder/Store"
import SubMenu from "../../../../containers/User/Order/SubMenu";
import React, {useState} from "react";
import Box from "@material-ui/core/Box";
import NavBar from "../../../../components/NavBar";

export default function Order() {

    const [totalProducts, setTotalProducts] = useState(0);

    return (
        <Frontend title="Order Form" menu="orderFrom">
            <div className="card-body">
                <NavBar>

                    <SubMenu
                        name={"Selected products"}
                        totalProducts={totalProducts}
                        active={true}
                    />

                </NavBar>

                <Box component="div">
                    <OrderPage totalProducts={totalProducts} setTotalProducts={setTotalProducts}/>
                </Box>
            </div>
        </Frontend>
    );
}

