import React, {useEffect, useMemo, useState} from "react";
import useAuth from "../../../../hooks/useAuth";
import TableFilterBase from "../../../../components/Admin/TableFilterBaser";
import {listApi} from "../../../../services/user/request";
import Loading from "../../../../components/User/Loading";

export default function Table(props) {

    const { logout, auth } = useAuth()
    const roleName = auth ? auth.role : null;
    const [loading, setLoading] = useState(true)

    const {
        companySelected,
        regionSelected,
        warehouseSelected,
        productCategorySelected,
        dateFromSelected,
        dateToSelected
    } = props

    const [elements, setElements] = useState(null)

    const columns = useMemo(
        () => [
            {
                Header: "LAST UPDATED",
                accessor: "lastUpdate",
                disableFilters: true
            },
            {
                Header: "ZONE",
                accessor: "region",
                disableFilters: true,
            },
            {
                Header: "WAREHOUSE",
                accessor: "warehouse_name",
                disableFilters: true
            },
            {
                Header: "ITEM",
                accessor: "item",
                disableFilters: true
            },
            {
                Header: "DESCRIPTION",
                accessor: "item_description",
                disableFilters: true
            },
            {
                Header: "ITEM REF.",
                accessor: "item_reference",
                disableFilters: true
            },
            {
                Header: "STOCK AVAILABLE",
                accessor: "stockOnHand",
                disableFilters: true
            },
            {
                Header: "PACKS IN TRANSIT",
                accessor: "stockInTransit",
                disableFilters: true
            },
            {
                Header: "COMMENT",
                accessor: "comment",
                disableFilters: true
            },
        ],
        []
    )

    useEffect(() => {
        (async () => {
            setLoading(true)
            const response = await listApi(logout, `report/stock`, {
                idCompany: companySelected?.id,
                idRegion: regionSelected?.id,
                idRegionName: regionSelected?.name,
                idWarehouseName: warehouseSelected?.name,
                idProductCategory: productCategorySelected?.id,
                dateFrom: dateFromSelected,
                dateTo: dateToSelected
            });
            if (response.status) {
                const temp = response.data.map((doc) => {

                    return {
                        ...doc,
                        name: `${doc.name} ${doc.description}`
                    }

                })
                setElements(temp)
                setLoading(false)
            }
        })()
    }, [companySelected, regionSelected, warehouseSelected, dateFromSelected, dateToSelected, productCategorySelected]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>

            <Loading show={loading} title="Fetching Stock Reports..."/>

            {!loading && (
                <TableFilterBase
                    columns={columns}
                    data={elements}
                    selectedRows={elements}
                    onSelectedRowsChange={setElements}
                    search={false}
                    selectable={false}
                />
            )}
        </>
    )
}
