import React, {useEffect, useState} from "react";
import useAuth from "../../../../hooks/useAuth";
import {CircularProgress, Grid, TextField} from "@material-ui/core";
import imgNoData from "../../../../assets/userTheme/assets/svg/components/empty-state-no-data.svg";
import ProductBox from "../ProductBox";
import {getTransaction} from "../../../../services/user/transaction";
import HeaderOrder from "../HeaderOrder";
import {makeStyles} from "@mui/styles";
import Footer from "../Footer";
import {useParams} from "react-router-dom";

const useStyles = makeStyles({
    root: {
        position: "sticky",
        top: 0,
        zIndex: 100,
        backgroundColor: "white",
        marginBottom: 20,
    },

});

export default function Order(props) {

    const classes = useStyles();
    const {id} = useParams()

    const [submitForm, setSubmitForm] = useState({
        comment: "",
        ref: ""
    });

    const {
        setTotalProducts
    } = props

    const {logout} = useAuth();

    const [products, setProducts] = useState([])
    const [noData, setNoData] = useState(false)
    const [productCategory, setProductCategory] = useState([]);
    const [productCategorySelected, setProductCategorySelected] = useState(null);
    const [loading, setLoading] = useState(true)
    const [selectedRegion, setSelectedRegion] = useState("");
    const [selectedSubregion, setSelectedSubregion] = useState("");
    const [selectedCountry, setSelectedCountry] = useState("");
    const [selectedLocation, setSelectedLocation] = useState("");
    const [totalPrice, setTotalPrice] = useState(0);

    useEffect(() => {
        (async () => {

            await listOrderDetails()

        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (event) => {
        submitForm[event.target.name] = event.target.value;
        setSubmitForm({...submitForm});
    }

    const listOrderDetails = async () => {
        const response = await getTransaction(logout, id)

        if (response.status) {
            setProducts(response.data.products)
            setTotalPrice(response.data.details.price)
            setSelectedRegion(response.data.details.region_code)
            setSelectedSubregion(response.data.details.subregion_code)
            setSelectedLocation(response.data.details.location_name)
            setSelectedCountry(response.data.details.listCountry_name)
            setLoading(false)
            setTotalProducts(response.data.products?.length)
        }

    }

    return (
        <>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <HeaderOrder
                                productCategorySelected={productCategorySelected}
                                setProductCategorySelected={setProductCategorySelected}
                                productCategory={productCategory}
                                setProductCategory={setProductCategory}
                                noData={noData}
                                setNoData={setNoData}
                                setProducts={setProducts}
                                selectedRegion={selectedRegion}
                                selectedSubregion={selectedSubregion}
                                selectedLocation={selectedLocation}
                                selectedCountry={selectedCountry}/>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    name="ref"
                                    onChange={handleChange}
                                    label="Num REF/PO#"
                                    multiline
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    value={submitForm?.ref}
                                    InputLabelProps={{shrink: true}}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    name="comment"
                                    onChange={handleChange}
                                    label="Comment"
                                    multiline
                                    rows={2}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    value={submitForm?.comment}
                                    InputLabelProps={{shrink: true}}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        {!noData && (
                            <Grid container style={{backgroundColor: "#f1f2f6", borderRadius: 10, padding: 10}}>
                                <Grid item xs={2} className={"text-center"}>
                                    <span className={"h5"}>Image</span>
                                </Grid>
                                <Grid item xs={3} className={"text-center"}>
                                    <span className={"h5"}>Item Description</span>
                                </Grid>
                                <Grid item xs={2} className={"text-center"}>
                                    <span className={"h5"}>Quantity</span>
                                </Grid>
                                <Grid item xs={3} className={"text-center"}>
                                    <span className={"h5"}>Pack price</span>
                                </Grid>
                                <Grid item xs={2} className={"text-center"}>
                                    <span className={"h5"}>Total Price</span>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    {(noData && !loading) && (
                        <Grid container spacing={1} className="text-center">
                            <Grid item xs={12}>
                                <img src={imgNoData} className="avatar avatar-xl mb-3" alt="Product"/>
                            </Grid>
                            <Grid item xs={12}>
                                <p className="card-text">There's no data available</p>
                            </Grid>
                        </Grid>
                    )}
                    {loading && (
                        <Grid container spacing={3} className="text-center">
                            <Grid item xs={12}>
                                <CircularProgress color="primary"/>
                            </Grid>
                            <Grid item xs={12}>
                                <p className="card-text">Fetching products...</p>
                            </Grid>
                        </Grid>
                    )}
                    {products?.map((doc, key) => {
                        return (
                            <ProductBox
                                id={doc.id}
                                name={doc.name}
                                description={doc.description}
                                price={doc.totalPrice}
                                priceDefault={doc.priceDefault}
                                category={doc.categoryName}
                                image={doc.image}
                                currencySymbol={doc.currencySymbol}
                                unitsPerPack={doc.unitsPerPack}
                                quantityDefault={doc.quantity}
                                quantityIsModify={false}
                                stockOnHand={0}
                            />
                        )
                    })}
                </Grid>
                <Grid item xs={12}>
                    <Footer
                        totalPrice={totalPrice}
                        submitForm={submitForm}
                        idTransaction={id}
                    />
                </Grid>
            </Grid>
        </>
    )
}

