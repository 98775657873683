import React from "react";
import Grid from "@material-ui/core/Grid";
import {TextField} from "@material-ui/core";
import useAuth from "../../../../hooks/useAuth";

export default function ProductBox(props) {
    const {
        id,
        name,
        description,
        price,
        priceDefault,
        category,
        sort,
        currencySymbol,
        unitsPerPack,
        quantityDefault,
        image,
        quantityIsModify,
        stockOnHand,
    } = props

    const quantity = quantityDefault
    const color = '#ef6f28'
    const {auth} = useAuth();

    return (
        <Grid container alignItems={"center"} style={{borderBottom: "1px solid #eef0f5", marginBottom: 20, paddingBottom: 20}}>
            <Grid item xs={2} className={"text-center"}>
                <img
                    alt="Product"
                    src={image ?? "https://ows-idp-packaging.s3.eu-west-3.amazonaws.com/product/no_available.png"}
                    className="avatar avatar-xl"
                    loading="lazy"
                />
            </Grid>
            <Grid item xs={3}>
                <span className={"h5"}>{name}</span>
                <br/>
                <span className={"h6"}>{description}</span>
                <div className="row">
                    <div className="col-md-12">
                        {sort && (
                            <div className="text-body">
                                <span className="small">Ref:</span>
                                <span className="font-weight-bold small px-2">{sort}</span>
                            </div>
                        )}
                        <div className="text-body">
                            <span className="small">Category:</span>
                            <span className="font-weight-bold small px-2">{category}</span>
                        </div>
                    </div>
                </div>
            </Grid>
            <Grid item xs={2}>
                <TextField
                    id={`input-${id}`}
                    variant={"outlined"}
                    size={"small"}
                    className="form-control form-control-sm"
                    type="number"
                    placeholder="Pack quantity"
                    value={quantity}
                    onWheel={() => document.activeElement.blur()}
                    disabled={!quantityIsModify}
                    sx={{
                        "& label.Mui-focused": {
                            display: "none"
                        },
                        "& legend": {
                            display: "none"
                        },
                        background: 'white',
                        borderRadius: '5px',
                        '& .MuiInput-underline:after': {
                            borderBottomColor: color,
                        },
                        '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                                borderColor: color,
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: color,
                            },
                        },
                    }}
                    inputProps={{style: {fontSize: 15, padding: "2px 5px 5px 5px"}}}
                    InputLabelProps={{shrink: true}}
                />
                {quantity && unitsPerPack ? (
                    <div className="text-body">
                        <span className="font-weight-bold small pr-1">{unitsPerPack * quantity}</span>
                        <span className="small">pcs in <b>{quantity}</b> packs</span>
                        <br/>
                        {(stockOnHand && !auth.isHideAvailability) && (
                            <>
                                <span className="small">Packs available: </span>
                                <span className="font-weight-bold small">{stockOnHand}</span>
                            </>
                        )}
                    </div>
                ) : (
                    <div className="text-body">
                        <span className="small">Pcs per pack:</span>
                        <span className="font-weight-bold small px-2">{unitsPerPack}</span>
                    {!auth.isHideAvailability ? (
                        <>
                        <br/>
                        <span className="small">Packs available:</span>
                        <span className="font-weight-bold small px-2">{stockOnHand}</span>
                        </>
                    ): null
                    }
                    </div>
                )}
            </Grid>
            <Grid item xs={3} className={"text-center"}>
                <span className="h5"> {priceDefault} {currencySymbol}</span>
            </Grid>
            <Grid item xs={2} className={"text-center"}>
                <span className="h5"> {(quantity * price).toFixed(2)} {currencySymbol}</span>
            </Grid>
        </Grid>
    )
}
