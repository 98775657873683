import React, {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {listApi} from "../../../../services/admin/request";
import useAuth from "../../../../hooks/useAuth";
import {createDeleteRelationLocation} from "../../../../services/admin/warehouse";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import TableBase, {SelectColumnFilter} from "../../../../components/Admin/TableFilterBaser";

export default function Fields() {
    const {id} = useParams()
    const {logout} = useAuth()

    const [locations, setLocations] = useState(null)
    const [selectedRows, setSelectedRows] = useState({})
    const [msgSuccess, setMsgSuccess] = useState(false)
    const [msgDanger, setMsgDanger] = useState(null)

    const columns = useMemo(
        () => [
            {
                Header: "COMPANY",
                accessor: "companyName",
                Filter: SelectColumnFilter
            },
            {
                Header: "ZONE",
                accessor: "regionName",
                Filter: SelectColumnFilter
            },
            {
                Header: "COUNTRY",
                accessor: "country",
                Filter: SelectColumnFilter
            },
            {
                Header: "STORE",
                accessor: "name",
                Filter: SelectColumnFilter
            },
            {
                Header: "STORE CATEGORY",
                accessor: "locationCategoryName",
                Filter: SelectColumnFilter
            },
            {
                Header: "VISIBLE",
                accessor: "access",
                Filter: SelectColumnFilter
            },
            {
                Header: "ACTIONS",
                accessor: "actions",
                disableFilters: true
            },
        ],
        []
    );

    useEffect(() => {
        (async () => {
            const response = await listApi(logout, `warehouse/product-locations/${id}`)
            if (response.status) {
                let position = {}
                let temp = []

                response.data.forEach((doc, key) => {
                    let href = `/admin/warehouse/store-product/${id}/${doc.idLocation}`

                    let access = "No"
                    if (doc.isActive) {
                        access = "Yes"
                    }

                    temp.push({
                        ...doc,
                        access: access,
                        actions: (
                            <Link color="primary" href={href}>
                                Products
                            </Link>
                        )
                    })

                    if (doc.isCheckout) {
                        position[`${key}`] = true
                    }

                })

                setSelectedRows(position)
                setLocations(temp)
            }
        })()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const selectLocations = async () => {
        setMsgSuccess(false)
        setMsgDanger(null)

        let idLocations = []
        for (const position in selectedRows) {
            idLocations.push(locations[position].idLocation)
        }

        let response = await createDeleteRelationLocation({
            idWarehouse: id,
            idLocations: idLocations
        })

        if (response.status) {
            setMsgSuccess(true)
        } else {
            setMsgDanger(response.error)
        }
    }

    if (!locations) return ""

    return (
        <>
            {msgSuccess && (
                <Grid item xs={12} md={12} lg={12}>
                    <Box className="alert-success">

                        Products have been successfully added to warehouse
                    </Box>
                </Grid>
            )}

            {msgDanger && (
                <Grid item xs={12} md={12} lg={12}>
                    <Box className="alert-danger">
                        {msgDanger.map((item) => {
                            return (
                                <span>{item} <br/>{" "}</span>
                            );
                        })}
                    </Box>
                </Grid>
            )}


            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box border={1} className="box-default">
                        <TableBase
                            columns={columns}
                            data={locations}
                            selectedRows={selectedRows}
                            onSelectedRowsChange={setSelectedRows}
                            selectable={true}
                            downInfo={false}
                            upInfo={true}
                            isButtonVisible={true}
                            handleClick={selectLocations}
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
