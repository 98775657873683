import { BASE_PATH } from "../../utils/constants";
import axios from "axios";

const baseUrl = `${BASE_PATH}/v1/api/auth`;

export function checkAuthKey(props) {
    return axios.get(`${baseUrl}/auth-key/check/${props.authKeyUser}/${props.email}`, {})
}

export function sendAuthKey(props) {
    return axios.get(`${baseUrl}/auth-key/send/${props.email}`, {})
}

export function checkAuthDate(props){
    return axios.get(`${baseUrl}/auth-key/check-date/${props.email}`, {})
}

