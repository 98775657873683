import Basic from "../../../containers/Admin/Layouts/Basic"
import LogCeline from "../../../containers/Admin/Log/LogCeline"

export default function Management() {
    return (
        <Basic menu="logCeline">
            <LogCeline />
        </Basic>
    )
}
