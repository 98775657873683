 import React, { useState, useEffect } from "react"
 import { useFormik } from "formik"
 import * as Yup from "yup"
 import { useHistory, useParams } from "react-router-dom"
 import useAuth from "../../../../hooks/useAuth"
 import {createApi, getProductTypeApi, updateApi} from "../../../../services/admin/productType"
 import Grid from "@material-ui/core/Grid"
 import Box from "@material-ui/core/Box"
 import Button from "@material-ui/core/Button"
 import TextField from "@material-ui/core/TextField"
 import ArrowBackIos from "@material-ui/icons/ArrowBackIos"
 import Switch from "@material-ui/core/Switch"
 import FormControlLabel from "@material-ui/core/FormControlLabel"
 
 export default function Fields() {
     const { id } = useParams()
     const { logout } = useAuth()
     const history = useHistory()

     const [msgSuccess, setMsgSuccess] = useState(false)
     const [msgDanger, setMsgDanger] = useState(null)
     const [loading, setLoading] = useState(true)

     const [initialValues, setInitialValues] = useState({
         isActive: true,
         priority: null
     })

     const formik = useFormik({
         initialValues: initialValues,
         validationSchema: Yup.object(validationSchema()),
         enableReinitialize: true,
         onSubmit: async (formData) => {
             setMsgSuccess(false)
             setMsgDanger(null)

             if (id){
                 const response = await updateApi(logout, formData)
                 if (response.status) {
                     setMsgSuccess(true)
                 } else {
                     setMsgDanger(response.errors)
                 }
             } else {
                 const response = await createApi(formData)
                 if (response.status) {
                     history.push(`/admin/product-type/edit/${response.data.id}`)
                 } else {
                     setMsgDanger(response.errors)
                 }
             }

         },
     });
 
     // Llamamos a la API para obtener los datos del usuario
     useEffect(() => {
         (async () => {
             if (id) {
                 const response = await getProductTypeApi(logout, {id: id})
                 if (response.status) {
                     setInitialValues({
                         id: response.data.id,
                         name: response.data.name,
                         isActive: response.data.isActive,
                         priority: response.data.priority
                     })
                 }
             }
             setLoading(false)
         })()
     }, []) // eslint-disable-line react-hooks/exhaustive-deps
 
     if (loading) return ""
 
     return (
         <>
             <form onSubmit={formik.handleSubmit}>
                 <Grid container spacing={3}>
                     <Grid item xs={12} md={12} lg={12}>
                         <Button
                             type="button"
                             color="primary"
                             href="/admin/product-type/management"
                             style={{
                                 float: "left"
                             }}
                         >
                             <ArrowBackIos />
                             BACK TO LIST
                         </Button>
                     </Grid>
                     <Grid item xs={12} md={12} lg={12}>
                         <h2 className="p-0">Product Type Settings</h2>
                     </Grid>
                 </Grid>
 
                 {msgSuccess && (
                     <Grid item xs={12} md={12} lg={12}>
                         <Box className="alert-success">
                             Product type data has been saved correctly
                         </Box>
                     </Grid>
                 )}
 
                 {msgDanger && (
                     <Grid item xs={12} md={12} lg={12}>
                         <Box className="alert-danger">
                             {msgDanger.map((item) => {
                                 return (
                                     <span>
                                         {" "}
                                         {item} <br />{" "}
                                     </span>
                                 );
                             })}
                         </Box>
                     </Grid>
                 )}
 
                 <Box border={1} className="p-20 box-default">
                     <Grid container spacing={3}>
                         <Grid item xs={12} md={12} lg={12} className="border-bottom">
                             <h5 className="p-0 mt-0">Info</h5>
                         </Grid>
                         <Grid item xs={12} md={12} lg={12}>
                             {id && (
                                 <div className="my-2">
                                     <TextField
                                         variant="outlined"
                                         label="* Product Type ID"
                                         className="form-control"
                                         name="id"
                                         type="text"
                                         value={formik.values.id}
                                         onChange={formik.handleChange}
                                         disabled
                                         fullWidth
                                         size="small"
                                         InputLabelProps={{ shrink: true }}
                                     />
                                 </div>
                             )}
 
                             <div className="my-2">
                                 <TextField
                                     variant="outlined"
                                     label="* Product Type Name"
                                     className="form-control"
                                     name="name"
                                     type="text"
                                     value={formik.values.name}
                                     onChange={formik.handleChange}
                                     fullWidth
                                     size="small"
                                     InputLabelProps={{ shrink: true }}
                                 />
                                 {formik.errors.name && (
                                     <p className="text-100 text-danger">
                                         {formik.errors.name}
                                     </p>
                                 )}
                             </div>

                             <div className="my-2">
                                 <TextField
                                     variant="outlined"
                                     label="* Priority"
                                     className="form-control"
                                     name="priority"
                                     type="number"
                                     value={formik.values.priority}
                                     onChange={formik.handleChange}
                                     fullWidth
                                     size="small"
                                     InputLabelProps={{ shrink: true }}
                                 />
                                 {formik.errors.priority && (
                                     <p className="text-100 text-danger">
                                         {formik.errors.priority}
                                     </p>
                                 )}
                             </div>
 
                             <div className="my-2">
                                 <FormControlLabel
                                     control={
                                         <Switch
                                             checked={formik.values.isActive}
                                             onChange={formik.handleChange}
                                             name="isActive"
                                             color="primary"
                                         />
                                     }
                                     label="Visible"
                                 />
                             </div>
                         </Grid>
                     </Grid>
                 </Box>
 
 
                 <Grid container spacing={3} className="p-20">
                     <Grid item xs={12} md={6} lg={6}>&nbsp;</Grid>
                     <Grid item xs={6} md={3} lg={3}>
                         <Button
                             type="button"
                             color="secondary"
                             href="/admin/product-type/management"
                             fullWidth
                         >
                             { id ? ( <><ArrowBackIos /> Back to list </>) : "Cancel" }
                         </Button>
                     </Grid>
                     <Grid item xs={6} md={3} lg={3}>
                         <Button
                             variant="contained"
                             color="primary"
                             type="submit"
                             fullWidth
                         >
                             { id ? "Modify" : "Create" }
                         </Button>
                     </Grid>
                 </Grid>
             </form>
         </>
     )
 }
 
 function validationSchema() {
     return {
         id: Yup.number(),
         name: Yup.string().required("El nombre es obligatorio"),
     };
 }
