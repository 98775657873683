import React, {useEffect, useState} from "react";
import {Grid} from "@mui/material";
import HeaderPage from "../../../components/Admin/HeaderPage";
import {apiInstall} from "../../../services/admin/doc";
import MsgError from "../../../components/Admin/MsgError";
import Table from "../../../components/Admin/NewTable";

export default function DocInstall() {
    const [rows, setRows] = useState([])
    const [msgError, setMsgError] = useState([])
    const [loading, setLoading] = useState(true)
    const [rowCount, setRowCount] = useState(0)
    const [state, setState] = useState(initialValues)

    const pjson = require("../../../../package.json")

    useEffect(() => {
        getItemsData()
    }, [state])

    const getItemsData = () => {
        setLoading(true)
        setMsgError([])

        apiInstall().then(resp => {

            const api = Object.keys(resp.data).map((key) => {
                return {type: "symfony", name: key, version: resp.data[key]}
            })

            // convert json to array
            const dependencies = Object.keys(pjson.dependencies).map((key) => {
                return {type: "react", name: key, version: pjson.dependencies[key]}
            })

            // Unir dos arrays
            const allDependencies = [...dependencies, ...api]

            setRows(allDependencies)
            setLoading(false)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setRows([])
        })
    }

    return (
        <>
            <HeaderPage title="Bookstores" />

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <MsgError msg={msgError}/>
                </Grid>

                <Grid item xs={12}>
                    <Table
                        columns={tableColumns()}
                        rows={rows}
                        rowCount={null}
                        filter={state}
                        setFilter={setState}
                        loading={loading}
                    />
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
    return {
        offset: 0,
        sort: { field: "id", sort: "desc" },
        email: "",
        role: ""
    }
}

function tableColumns(handleChangeSelection, selection) {
    return [
        {
            field: "type",
            headerName: "TYPE",
            sortable: false,
            flex: 1
        },
        {
            field: "name",
            headerName: "NAME",
            sortable: false,
            flex: 1
        },
        {
            field: "version",
            headerName: "VERSION",
            sortable: false,
            flex: 1
        },
    ]
}
