import { BASE_PATH, PATH_BASE_EXTENSION } from "../../utils/constants";
import { authFetch } from "../../utils/fetch";


export async function getCatalogByCompanyApi(logout, data) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/user/product-catalog/by-company/${data}`;
        const token = localStorage.getItem("token");
        const params = {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}