import React, {useEffect, useState} from "react";
import {Chip, Grid} from "@mui/material";
import Box from "@material-ui/core/Box";
import {apiListProductsRelation} from "../../../../services/admin/warehouse";
import NewTable from "../../../../components/Admin/NewTable";

export default function Products(props) {

    const {idCompany, idWarehouse, setMsgError, product, setProduct, stateField, listStatus} = props
    const [rows, setRows] = useState([])
    const [state, setState] = useState(initialValues())
    const [rowCount, setRowCount] = useState(null)
    const [loading, setLoading] = useState(true)
    const [nameStatusEdit, setNameStatusEdit] = useState(null)

    useEffect(() => {
        setNameStatusEdit(null)
        if (listStatus.length > 0) {
            setNameStatusEdit(listStatus[0]?.name)
        }
    }, [listStatus])

    useEffect(() => {
        if (idWarehouse) {
            setLoading(true)
            apiListProductsRelation({
                idCompany: idCompany || null,
                isCheckout: true,
                idWarehouse: idWarehouse
            }).then(resp => {
                setRowCount(resp.data?.total)

                let temp = resp.data.data?.map((doc, key) => {

                    // buscamos en el producto el producto que seleccionamos
                    let temp = product.find(item => item.id === doc.idProduct)
                    return {
                        ...doc,
                        id: doc.idProduct,
                        packs: temp ? temp.quantity : null
                    }
                })

                setRows(temp)
                setLoading(false)
            }).catch(err => {
                setMsgError(err.response?.data?.msg)
                setLoading(false)
                setRows([])
            })
        }
    }, [idWarehouse, idCompany, stateField.products])

    const handleChangeCell = (e) => {
        let idProduct = e.target.id
        let quantity = e.target.value

        // Detectamos si el id existe en el array y lo añádimos o actualizamos array
        if (product.find(item => item.id === idProduct)) {
            // Actualizamos quantity en el array
            product.map(item => {
                if (item.id === idProduct) {
                    item.quantity = quantity
                }
            })

            // Eliminamos en el caso de que el quantity sea 0
            if (!quantity || quantity === '0' || quantity === 0) {
                product.splice(product.findIndex(item => item.id === idProduct), 1)
            }
            setProduct([...product])
        } else if(quantity > 0 || quantity < 0) {
            setProduct([...product, {
                id: idProduct,
                quantity: quantity
            }])
        }

    }

    return (
        <Grid item xs={12}>
            <Box border={1} className="p-20 box-default">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12} className="border-bottom">
                        <h5 className="p-0 mt-0">PO line
                            {stateField.statusIdOriginal !== 1 && (
                                <>
                                    <span style={{fontWeight: 100, marginLeft: 5, float: 'right'}}>
                                        <Chip color="info" label={`Locked: To update quantities, you need to be in ${nameStatusEdit}`} style={{marginTop: '-13px', fontWeight: 'bold'}}/>
                                    </span>
                                </>
                            )}
                        </h5>
                    </Grid>

                    <Grid item xs={12} container spacing={1}>
                        <NewTable
                            columns={tableColumns(stateField)}
                            handleChangeCell={handleChangeCell}
                            rows={rows}
                            rowCount={rowCount}
                            filter={state}
                            setFilter={setState}
                            loading={loading}
                        />
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    )
}

function tableColumns(props) {

    const editable = props.statusIdOriginal === 1 ? true : false

    return [
        {
            field: "companyName",
            headerName: "COMPANY",
            flex: 2
        },
        {
            field: "name",
            headerName: "PRODUCT",
            flex: 2
        },
        {
            field: "description",
            headerName: "DESCRIPTION",
            flex: 2
        },
        {
            field: "unitsPerPack",
            headerName: "PCS PER PACK",
            flex: 1
        },
        {
            field: "packs",
            headerName: "PACKS",
            editable: editable,
            flex: 1
        }
    ]
}

function initialValues(props) {
    return {
        offset: 0,
        sort: {field: "id", sort: "desc"},
    }
}
