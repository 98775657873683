import Frontend from "../../../../containers/User/Layouts/Frontend";
import ReportProductionPlanPage from "../../../../containers/User/Reports/ProductionPlan"

export default function Report() {
    return (
        <Frontend title="Send Reports" menu="sendReportsProductionPlan">
            <ReportProductionPlanPage />
        </Frontend>
    );
}
