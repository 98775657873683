import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useHistory, useParams } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import {createApi, getRegionApi, updateApi} from "../../../../services/admin/region";
import { listApi } from "../../../../services/admin/request";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function Fields() {
    const { id } = useParams()
    const { logout } = useAuth()
    const history = useHistory()

    const [msgSuccess, setMsgSuccess] = useState(false)
    const [msgDanger, setMsgDanger] = useState(null)
    const [loading, setLoading] = useState(true)
    const [listCompany, setListCompany] = useState([])
    const [defaultCompany, setDefaultCompany] = useState(null)

    const [initialValues, setInitialValues] = useState({
        code: "",
        idCompany: null,
        name: "",
        isActive: true
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object(validationSchema()),
        enableReinitialize: true,
        onSubmit: async (formData) => {
            setMsgSuccess(false)
            setMsgDanger(null)

            if (id) {
                const response = await updateApi(logout, formData)
                if (response.status) {
                    setMsgSuccess(true)
                } else {
                    setMsgDanger(response.errors)
                }
            } else {
                const response = await createApi(formData)
                if (response.status) {
                    history.push(`/admin/region/edit/${response.data.id}/1`)
                } else {
                    setMsgDanger(response.errors)
                }
            }

        },
    })

    useEffect(() => {
        (async () => {
            const response = await listApi(logout, "company")
            if (response.status) {
                let temp = response.data.map((doc, key) => {
                    return {
                        id: doc.id,
                        ...doc,
                    }
                })
                setListCompany(temp)
            }
        })()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
         (async () => {
             if (id) {
                 const response = await getRegionApi(logout, {id: id});
                 if (response.status) {
                     setInitialValues({
                         id: response.data.id,
                         name: response.data.name,
                         code: response.data.code,
                         idCompany: response.data.company.id,
                         isActive: response.data.isActive,
                     })
                     setDefaultCompany(response.data.company)
                 }
             }
             setLoading(false)
         })()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (loading) return ""

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                {msgSuccess && (
                    <Grid item xs={12} md={12} lg={12}>
                        <Box className="alert-success">
                            Company data has been saved correctly
                        </Box>
                    </Grid>
                )}

                {msgDanger && (
                    <Grid item xs={12} md={12} lg={12}>
                        <Box className="alert-danger">
                            {msgDanger.map((item) => {
                                return (
                                    <span>
                                        {" "}
                                        {item} <br />{" "}
                                    </span>
                                );
                            })}
                        </Box>
                    </Grid>
                )}

                <Box border={1} className="p-20 box-default">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12} className="border-bottom">
                            <h5 className="p-0 mt-0">Info</h5>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            { id && (
                                <div className="my-2">
                                    <TextField
                                        variant="outlined"
                                        label="* Region ID"
                                        className="form-control"
                                        name="id"
                                        type="text"
                                        value={formik.values.id}
                                        onChange={formik.handleChange}
                                        disabled
                                        fullWidth
                                        size="small"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </div>
                            )}

                            <div className="my-2 mb-2">
                                <Autocomplete
                                    options={listCompany}
                                    value={defaultCompany}
                                    getOptionLabel={(option) => option.name}
                                    getOptionSelected={(option, value) =>
                                        option.value === value.value
                                    }
                                    onChange={(event, newValue) => {
                                        if (newValue !== null) {
                                            formik.values.idCompany = newValue.id;
                                            setDefaultCompany({
                                                id: newValue.id,
                                                name: newValue.name,
                                            });
                                        } else {
                                            formik.values.idCompany = null
                                            setDefaultCompany(null);
                                        }
                                    }}
                                    fullWidth
                                    size="small"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="* Company "
                                            variant="outlined"
                                            name="idCompany"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    )}
                                />
                            </div>

                            <div className="my-2">
                                <TextField
                                    variant="outlined"
                                    label="* Code"
                                    className="form-control"
                                    name="code"
                                    type="text"
                                    value={formik.values.code}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    size="small"
                                    InputLabelProps={{ shrink: true }}
                                />
                                {formik.errors.code && (
                                    <p className="text-100 text-danger">
                                        {formik.errors.code}
                                    </p>
                                )}
                            </div>

                            <div className="my-2">
                                <TextField
                                    variant="outlined"
                                    label="* Zone Name"
                                    className="form-control"
                                    name="name"
                                    type="text"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    size="small"
                                    InputLabelProps={{ shrink: true }}
                                />
                                {formik.errors.name && (
                                    <p className="text-100 text-danger">
                                        {formik.errors.name}
                                    </p>
                                )}
                            </div>

                            <div className="my-2">
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={formik.values.isActive}
                                            onChange={formik.handleChange}
                                            name="isActive"
                                            color="primary"
                                        />
                                    }
                                    label="Visible"
                                />
                            </div>

                            <div className="my-2"></div>
                        </Grid>
                    </Grid>
                </Box>

                <Grid container spacing={3} className="p-20">
                    <Grid item xs={12} md={6} lg={6}></Grid>
                    <Grid item xs={6} md={3} lg={3}>
                        <Button
                            type="button"
                            color="secondary"
                            href="/admin/region/management"
                            fullWidth
                        >
                            { id ? ( <><ArrowBackIos /> Back to list </>) : "Cancel" }
                        </Button>
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            fullWidth
                        >
                            { id ? "Modify" : "Create" }
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
}

function validationSchema() {
    return {
        name: Yup.string().required("The name is required"),
        code: Yup.string().required("The code is required")
    };
}
