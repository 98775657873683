import React from "react";
import {updateOrder} from "../../../../services/user/transaction";
import {useHistory} from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import {toast} from "react-toastify";

export default function Footer(props) {
    const {
        totalPrice,
        id
    } = props
    const {logout} = useAuth()
    const history = useHistory()

    const makeOrder = async () => {
        const order = JSON.parse(localStorage.getItem('order_products'))

        if (order.length > 0) {
            const responseTransaction = await updateOrder(logout, {
                order: order,
                idOrder: id
            });

            if (responseTransaction.status) {
                localStorage.removeItem("order");
                history.push(`/dash/order-history/details/${id}`);
            } else {
                responseTransaction.errors.forEach((error) => {
                    toast.error(error, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined
                    });
                })
            }
        }
    }

    return (
        <div className="d-flex">
            <div className="flex-grow-1">
                <div className="row justify-content-end">
                    <div className="col-md-4">
                        <button type="button" className="btn btn-sm btn-primary transition-3d-hover w-100 mt-1"
                                onClick={makeOrder}>Update order
                        </button>
                    </div>
                    <div className="col-4 col-sm-2 d-none d-sm-inline-block text-right">
                        {Object.keys(totalPrice).map((currency, i) => {
                            let price = totalPrice[currency]
                            return (
                                <span key={i} className="h6 d-block mb-1">{price} {currency}</span>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}