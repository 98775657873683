import React, { useEffect, useMemo, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { listApi } from "../../../../services/admin/request";
import useAuth from "../../../../hooks/useAuth";
import { useParams } from "react-router-dom";
import NewTable from "../../../../components/Admin/NewTable";
import Checkbox from "@material-ui/core/Checkbox";
import InputButtonLoading from "../../../../components/Inputs/InputButtonLoading";
import { updateLocationLimitApi, importLocationProductLimitApi, exportLocationProductLimitApi } from "../../../../services/admin/location";
import Loading from "../../../../components/User/Loading";
import moment from "moment/moment";
import DatePickerSimple from "../../../../components/Admin/Inputs/DatePickerSimple";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from "@material-ui/core/Button";
import { TextField } from "@material-ui/core";
import { width } from "@mui/system";

export default function ProductLimit() {
    const { id } = useParams();
    const { logout } = useAuth();

    const [products, setProducts] = useState(null);
    const [selectedRows, setSelectedRows] = useState({});
    const [msgSuccess, setMsgSuccess] = useState(false);
    const [msgDanger, setMsgDanger] = useState(null);
    const [loading, setLoading] = useState(false);
    const [filter, setFilter] = useState(initialValuesFilter());
    const [newParams, setNewParams] = useState([]);
    const [selection, setSelection] = useState([]);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [state, setState] = useState(initialValues());
    const [file, setFile] = useState(null)
    const [loadingExport, setLoadingExport] = useState(false);
    const [loadingImport, setLoadingImport] = useState(false);

    const handleChangeSelection = (e) => {
        let idProduct = parseInt(e.target.value)

        // Obtenemos max_ordered_quantity de products teniendo en cuanta el IdProduct
        let maxOrderedQuantity = products.find(item => item.id === idProduct).max_ordered_quantity

        // Obtenemos date_from de products teniendo en cuanta el IdProduct
        let dateFrom = products.find(item => item.id === idProduct).date_from

        // Obtenemos date_to de products teniendo en cuanta el IdProduct
        let dateTo = products.find(item => item.id === idProduct).date_to

        let isActive = false
        if (selection.includes(idProduct)) {
            setSelection(selection.filter(item => item !== idProduct))
        } else {
            isActive = true
            setSelection([...selection, idProduct])
        }
        if (newParams.find(item => parseInt(item.id) === idProduct)) {
            // Actualizamos quantity en el array
            newParams.map(item => {
                if (item.id === idProduct) {
                    item.is_active = isActive
                    item.max_ordered_quantity = maxOrderedQuantity
                    item.date_from = dateFrom
                    item.date_to = dateTo
                }
            })

            setNewParams([...newParams])
        } else {
            setNewParams([...newParams, {
                id: idProduct,
                is_active: isActive,
                max_ordered_quantity: maxOrderedQuantity,
                date_from: dateFrom,
                date_to: dateTo
            }])
        }
    }

    const handleChangeCell = (e, field, row) => {

        let idProduct = parseInt(row.id)
        let value = e.target.value

        // Obtenemos el is_active de selection
        let isActive = selection.includes(idProduct)

        // Detectamos si el id existe en el array y lo añádimos o actualizamos array
        if (newParams.find(item => parseInt(item.id) === idProduct)) {
            // Actualizamos quantity en el array
            newParams.map(item => {
                if (item.id === idProduct) {
                    item.is_active = isActive
                    item.date_from = row.date_from
                    item.date_to = row.date_to
                    item.max_ordered_quantity = row.max_ordered_quantity
                    if (!['id', 'is_active', 'date_from', 'date_to', 'max_ordered_quantity'].includes(field)) {
                        item[field] = e.target.value;
                    }
                }
            })

            setNewParams([...newParams])
        } else if (value > 0) {

            let newObject = {
                id: idProduct,
                is_active: isActive,
                date_from: row.date_from,
                date_to: row.date_to,
                max_ordered_quantity: row.max_ordered_quantity
            }

            if (!['id', 'is_active', 'date_from', 'date_to', 'max_ordered_quantity'].includes(field)) {
                newObject[field] = e.target.value;
            }

            setNewParams([...newParams, newObject])
        }
    }

    useEffect(() => {
        getData()

    }, []);

    const getData = async () => {
        setLoading(true)
        const response = await listApi(
            logout,
            `location/product-location-limit/${id}`
        );

        let extra = "?field=${filter.sort.field}&sort=${filter.sort.sort}"
        if (response.status) {
            let tempSelection = []
            const prods = response.data.map(doc => {
                if (doc.is_active) {
                    tempSelection.push(doc.id)
                }
                return {
                    ...doc
                }
            })
            setSelection(tempSelection)
            setProducts(prods);
            setLoading(false)
        } else {
            setLoading(false)
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setMsgSuccess();
        setButtonLoading(true)

        updateLocationLimitApi({ productLimits: newParams }).then(resp => {
            setMsgSuccess(['Data has been successfully saved'])
            setButtonLoading(false)
        }).catch(err => {
            setButtonLoading(false)
        })
    };

    useEffect(() => {

        if (products && !loading) {
            setLoading(true)

            let tempSelection = []
            let tempNewParams = []

            products.forEach(doc => {

                if (state.isActive === true) {
                    tempSelection.push(doc.id)
                }

                let idProduct = doc.id

                // Obtenemos max_ordered_quantity de products teniendo en cuanta el IdProduct
                let maxOrderedQuantity = products.find(item => item.id === idProduct).max_ordered_quantity

                // Obtenemos date_from de products teniendo en cuanta el IdProduct o si es del input general
                let dateFrom
                if (state.dateFrom) { dateFrom = state.dateFrom }
                else { dateFrom = products.find(item => item.id === idProduct).date_from }

                // Obtenemos date_to de products teniendo en cuanta el IdProduct
                let dateTo
                if (state.dateTo) { dateTo = state.dateTo }
                else { dateTo = products.find(item => item.id === idProduct).date_to }

                if (newParams.find(item => parseInt(item.id) === idProduct)) {

                    newParams.map(item => {
                        if (item.id === idProduct) {
                            item.is_active = state.isActive
                            item.max_ordered_quantity = maxOrderedQuantity
                            item.date_from = dateFrom
                            item.date_to = dateTo
                            tempNewParams.push({ ...item, isActive: state.isActive, date_from: state.dateFrom, date_to: state.dateTo })
                        }
                    })

                } else {
                    tempNewParams.push({
                        id: idProduct,
                        is_active: state.isActive,
                        max_ordered_quantity: maxOrderedQuantity,
                        date_from: dateFrom,
                        date_to: dateTo
                    })
                }


            })

            let tempProducts=[]
            products.forEach(doc => {
                let itemTemp=tempNewParams.find(item => item.id === doc.id)
                tempProducts.push({ ...doc, ...itemTemp })    
            })

            setNewParams(tempNewParams)
            setSelection(tempSelection)
            setProducts([])
            setProducts(tempProducts)
            setLoading(false)
        }

    }, [state]);

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.value
        })
    }


    const handleChangeFile = (event) => {
        if (event.target.files.length > 0) {
            setFile(event.target.files)
        } else {
            setFile(null)
        }
    }

    const exportLocationProductLimit = async () => {
        setMsgSuccess(null)
        setMsgDanger(null)
        setLoadingExport(true)
        const response = await exportLocationProductLimitApi(logout, {"idLocation": id});

        if (response.status) {
            window.open(response.data, '_blank');
            setMsgSuccess(['Export finished'])
        } else {
            setMsgDanger(response.errors)
        }
        setLoadingExport(false)
    }

    const importLocationProductLimit = async () => {
        setMsgSuccess(null)
        setMsgDanger(null)
        setLoadingImport(true)
        let formData = new FormData()

        formData.append('excelFile', file[0])

        const response = await importLocationProductLimitApi(logout, formData)

        if (response.status) {
            setMsgSuccess([response.data])
            getData()
        } else {
            setMsgDanger(response.errors)
        }
        setLoadingImport(false)
    }


    return (
        <>

            <Grid container style={{ marginBottom: "20px" }}>
                <Grid item xs={3}>
                    <div style={{ marginRight: "20px" }}>
                        <TextField
                            variant="outlined"
                            label="Import Product Orders Limits"
                            name="excelFile"
                            size="small"
                            type="file"
                            onChange={handleChangeFile}
                            InputLabelProps={{ shrink: true }}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} md={3}>
                    <div style={{ marginRight: "20px" }}>
                        <InputButtonLoading
                            style={{width:"100%"}}
                            onClick={importLocationProductLimit}
                            label={'IMPORT'}
                            loading={loadingImport}
                            disabled={file === null}
                        />
                    </div>
                </Grid>
                <Grid item xs={12} md={3}>
                    <div style={{ marginRight: "20px" }}>
                        <InputButtonLoading
                            style={{width:"100%"}}
                            onClick={exportLocationProductLimit}
                            label={'EXPORT'}
                            loading={loadingExport}
                        />
                    </div>
                </Grid>
            </Grid>

            {msgSuccess && (
                <Grid item xs={12} md={12} lg={12}>
                    <Box className="alert-success">
                        {msgSuccess.map((item) => {
                            return (
                                <span>
                                    {item} <br />{" "}
                                </span>
                            );
                        })}
                    </Box>
                </Grid>
            )}

            {msgDanger && (
                <Grid item xs={12} md={12} lg={12}>
                    <Box className="alert-danger">
                        {msgDanger.map((item) => {
                            return (
                                <span>
                                    {item} <br />{" "}
                                </span>
                            );
                        })}
                    </Box>
                </Grid>
            )}

            <Loading show={loading} />

            {(products && !loading) && (
                <form>
                    <Grid container spacing={3}>

                        <Grid item xs={4} md={4} lg={4}>
                            <FormGroup  style={{ marginTop: "5px" }}>
                                <FormControlLabel control={<Checkbox style={{ marginTop: "-10px" }}  onChange={(e) => {
                                    setState({ ...state, isActive: !state.isActive })
                                }}
                                    value={null}
                                    checked={state.isActive}

                                />} label="Enable / Disable All" />
                            </FormGroup>

                        </Grid>
                        <Grid item xs={4} md={4} lg={4}>
                            <DatePickerSimple
                                label="Date From"
                                name="dateFrom"
                                onChange={handleChange}
                                value={state?.dateFrom}
                            />
                        </Grid>
                        <Grid item xs={4} md={4} lg={4}>
                            <DatePickerSimple
                                dateTo={true}
                                label="Date To"
                                name="dateTo"
                                onChange={handleChange}
                                value={state?.dateTo}
                            />
                        </Grid>

                        <Grid item xs={12} md={12} lg={12}>
                            <InputButtonLoading
                                onClick={handleSubmit}
                                loading={buttonLoading}
                                label={id ? 'Save' : 'Save'}
                            />
                            <Box border={1} className="box-default">
                                <Box border={1} className="box-default">
                                    <NewTable
                                        columns={tableColumns(handleChangeSelection, selection)}
                                        handleChangeCell={handleChangeCell}
                                        rows={products}
                                        filter={filter}
                                        setFilter={setFilter}
                                        loading={loading}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </form>
            )}
        </>
    );
}

function tableColumns(handleChangeSelection, selection) {
    return [
        {
            headerName: "COMPANY",
            field: "company_name",
            sortable: false,
            flex: 1
        },
        {
            headerName: "NAME",
            field: "product_name",
            sortable: false,
            flex: 1
        },
        {
            headerName: "DESCRIPTION",
            field: "product_description",
            sortable: false,
            flex: 1
        },
        {
            headerName: "CATALOG",
            field: "catalog_name",
            sortable: false,
            flex: 1
        },
        {
            headerName: "PROD. CATEGORY",
            field: "category_name",
            sortable: false,
            flex: 1
        },
        {
            headerName: "DATE FROM",
            field: "date_from",
            sortable: false,
            datepicker: true,
            width: "200px",
            flex: 1,
        },
        {
            headerName: "DATE TO",
            field: "date_to",
            sortable: false,
            datepicker_to: true,
            width: "200px",
            flex: 1
        },
        {
            headerName: "MAX. ORDERED QTY.",
            field: "max_ordered_quantity",
            sortable: false,
            editable: true,
            flex: 1
        },
        {
            headerName: "ORDERED",
            field: "ordered_products",
            sortable: false,
            flex: 1
        },
        {
            headerName: "BALANCE",
            field: "balance_products",
            sortable: false,
            flex: 1
        },
        {
            headerName: "ENABLED",
            field: "is_active",
            flex: 1,
            sortable: false,
            renderCell: (params) => {
                return (
                    <Checkbox onChange={handleChangeSelection}
                        value={params.id}
                        checked={selection.includes(params.id)}
                    />
                )
            },
        }
    ]
}

function initialValues(props) {
    return {
        isActive: false,
        dateFrom: null,
        dateTo: null,
    }
}


function initialValuesFilter(props) {
    return {
        offset: 0,
        sort: { field: "id", sort: "desc" },
    }
}
