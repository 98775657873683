import Basic from "../../../containers/Admin/Layouts/Basic"
import ContainersEdit from "../../../containers/Admin/CurrencyRegion/Fields"

export default function Edit() {
  return (
    <Basic menu="currencyRegion">
          <ContainersEdit />
    </Basic>
  )
}
