import Basic from "../../../containers/Admin/Layouts/Basic"
import ContainerManagement from "../../../containers/Admin/CurrencyRegion/Management"
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import React from "react";

export default function Management() {
  return (
    <Basic menu="currencyRegion">
        <Grid container spacing={3}>
            <Grid item xs={6} md={6} lg={6}>
                <h1>Maintain Currencies by Zone</h1>
            </Grid>
            <Grid item xs={6} md={6} lg={6}>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ float: "right", margin: "20px 0px" }}
                    href="/admin/currency-region/create/0"
                >
                    + add
                </Button>
            </Grid>
        </Grid>
        <ContainerManagement
            id="0"
        />
    </Basic>
  )
}
