import Basic from "../../../containers/Admin/Layouts/Basic"
import ContainerManagement from "../../../containers/Admin/Tariff/Fields"

export default function Management() {
    return (
        <Basic menu="tariff">
            <ContainerManagement />
        </Basic>
    )
}
