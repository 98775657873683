import { BASE_PATH, PATH_BASE_EXTENSION } from "../../utils/constants";
import { authFetch } from "../../utils/fetch";

export async function createApi(formData) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/catalog/create`;
        const token = localStorage.getItem("token");
        const params = {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(formData),
        };
        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function updateApi(logout, data) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/catalog/update`;
        const token = localStorage.getItem("token");
        const params = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function getCatalogApi(logout, data) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/catalog/${data.id}`;
        const token = localStorage.getItem("token");
        const params = {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function getAllCatalogApi(logout, data) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/catalog/`;
        const token = localStorage.getItem("token");
        const params = {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function getCatalogByCompanyApi(logout, data) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/catalog/by-company/${data}`;
        const token = localStorage.getItem("token");
        const params = {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}