import Frontend from "../../../../containers/User/Layouts/Frontend";
import ReportProductPage from "../../../../containers/User/Reports/Product"

export default function Report() {
    return (
        <Frontend title="Send Reports" menu="sendReportsProduct">
            <ReportProductPage />
        </Frontend>
    );
}
