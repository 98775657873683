import Basic from "../../../containers/Admin/Layouts/Basic"
import ContainersEdit from "../../../containers/Admin/Currency/Fields"

export default function Edit() {
  return (
    <Basic menu="currency">
          <ContainersEdit  />
    </Basic>
  )
}
