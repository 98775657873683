import {reorderTransaction} from "../../../services/user/transaction";
import {useHistory} from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import "react-toastify/dist/ReactToastify.css";
import Grid from "@material-ui/core/Grid";

export default function Footer(props) {
    const {
        totalPrice,
        submitForm,
        idTransaction
    } = props;
    const {logout} = useAuth();
    const history = useHistory();

    const makeOrder = async () => {
        const responseTransaction = await reorderTransaction(logout, {
            idTransaction: idTransaction,
            comment: submitForm?.comment,
            ref: submitForm?.ref,
        })

        if (responseTransaction.status) {
            history.push(`/dash/order-history/details/${responseTransaction.data.idTransaction}`)
        }

    }

    return (
        <Grid container spacing={3} justifyContent={"flex-end"}>
            <Grid item xs={12} md={4}>
                <button
                    type="button"
                    className="btn btn-sm btn-primary transition-3d-hover w-100 mt-1"
                    onClick={makeOrder}
                >
                    Submit
                </button>
            </Grid>
            <Grid item xs={12} className={"text-right"}>
                <span className="h6 d-block mb-1">
                    {totalPrice}
                </span>
            </Grid>
        </Grid>
    );
}
