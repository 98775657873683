import React from "react";
import {TextField} from "@mui/material";

/**
 * Muestra un input de texto
 *
 * ## PROPS
 * - **label** *string*
 * - **name** *string*
 * - **rows** *int*
 * - **multiline** *bool*
 * - **value** *string*
 * - **onChange** *function*
 * - **required** *bool*
 */
export default function InputText(props) {

    const {label, name, value, onChange, required, rows, multiline, shrink, disabled} = props

    const multilineIn = multiline || false
    const disabledIn = disabled || false
    const rowsIn = rows || 1

    const color = '#ef6f28'

    return (
        <TextField
            type="text"
            variant="outlined"
            size="small"
            fullWidth
            required={required}
            disabled={disabledIn}
            label={label}
            name={name}
            value={value}
            onChange={onChange}
            multiline={multilineIn}
            rows={rowsIn}
            InputLabelProps={{
                shrink: true,
            }}
            sx={{
                background: disabledIn ? '#f5f5f5' : 'white',
                borderRadius: '5px',
                '& label.Mui-focused': {
                    color: disabledIn ? 'none' : color,
                },
                '& .MuiInput-underline:after': {
                    borderBottomColor: disabledIn ? 'none' : color,
                },
                '& .MuiOutlinedInput-root': {
                    '&:hover fieldset': {
                        borderColor: disabledIn ? 'none' : color,
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: disabledIn ? 'none' : color,
                    },
                },
            }}
        />
    )
}
