import React, { useEffect, useState, useMemo } from "react";
import { listApi } from "../../../../services/admin/request";
import useAuth from "../../../../hooks/useAuth";
import TableBase from "../../../../components/Admin/TableBase";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

export default function Management() {
    const [categories, setCategories] = useState(null)
    const { logout } = useAuth()

    const columns = useMemo(
        () => [
            {
                Header: "COMPANY",
                accessor: "companyName",
            },
            {
                Header: "CATEGORY",
                accessor: "name",
            },
            {
                Header: "VISIBLE",
                accessor: "access",
            },
            {
                Header: "ACTIONS",
                accessor: "actions",
            },
        ],
        []
    )

    useEffect(() => {
        (async () => {
            const response = await listApi(logout, 'category');
            if (response.status) {
                const temp = response.data.map((doc) => {
                    let href = `/admin/category/edit/${doc.id}`

                    let access = "No"
                    if (doc.isActive) {
                        access = "Yes"
                    }

                    let companyName = ""
                    if(doc.company){
                        companyName = doc.company.name
                    }

                    return {
                        ...doc,
                        id: doc.id,
                        access: access,
                        companyName: companyName,
                        actions: (
                            <Link color="primary" href={href}>
                                Edit
                            </Link>
                        ),
                    };
                });
                setCategories(temp)
            }
        })()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (!categories) return "";

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={6} md={6} lg={6}>
                    <h1>Product Category</h1>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ float: "right", margin: "20px 0px" }}
                        href="/admin/category/create"
                    >
                        + add
                    </Button>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box border={1} className="box-default">
                        <TableBase
                            columns={columns} 
                            data={categories} 
                            search={true}
                            selectedRows={categories}
                            onSelectedRowsChange={setCategories}
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}


