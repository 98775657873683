import React, {useEffect, useMemo, useState} from "react";
import useAuth from "../../../../hooks/useAuth";
import TableFilterBase from "../../../../components/Admin/TableFilterBaser";
import {apiList} from "../../../../services/user/maxOrder";
import Loading from "../../../../components/User/Loading";
import clone from "../../../../utils/clone";
import MsgError from "../../../../components/User/MsgError";
import {textErrorGeneral} from "../../../../utils/constants";
import moment from "moment";

export default function Table(props) {

    const {
        filter,
        setFilterDisabled
    } = props

    const { logout } = useAuth()
    const [loading, setLoading] = useState(false)
    const [elems, setElems] = useState(null)
    const [msgError, setMsgError] = useState([])

    const columns = useMemo(
        () => [
            {
                Header: "Prod. Name",
                accessor: "product_name",
                disableFilters: true,
            },
            {
                Header: "Description",
                accessor: "product_description",
                disableFilters: true
            },
            { Header: "Company Name", accessor: "company_name", disableFilters: true,},
            { Header: "Catalog Name", accessor: "catalog_name", disableFilters: true,},
            { Header: "Category Name", accessor: "category_name", disableFilters: true,},
            { Header: "From", accessor: "date_from", disableFilters: true,},
            { Header: "To", accessor: "date_to", disableFilters: true,},
            { Header: "Total Order", accessor: "max_ordered_quantity", disableFilters: true,},
            { Header: "Shipped", accessor: "ordered_products", disableFilters: true,},
            { Header: "Balance", accessor: "balance_products", disableFilters: true,},
        ],
        []
    )


    const getItemsData = async () => {
        setMsgError([])
        setElems([])
        setLoading(true)
        setFilterDisabled(true)

        apiList( {
            idCompany: filter?.idCompany?.id,
            idRegion: filter?.idRegion?.id,
            idRegionName: filter?.idRegion?.name,
            idWarehouseName: filter?.idWarehouse?.name,
            idWarehouse: filter?.idWarehouse?.id,
            idProductCategory: filter?.idProductCategory?.id,
            idSemester: filter?.idSemester?.id,
            idLocation: filter?.idLocation?.id,
        }).then(resp => {
            let temp = []
            temp = resp.data.data.map((doc) => {
                return {
                    ...doc,
                    name: `${doc.name} ${doc.description}`,
                    date_from: doc.date_from ? moment.unix(doc.date_from).utc().format("YYYY-MM-DD") : null,
                    date_to: doc.date_to ? moment.unix(doc.date_to).utc().format("YYYY-MM-DD") : "",
                    is_active: doc.is_active ? "Yes" : "No"
                }

            })
            setElems(temp)
            setLoading(false)
            setFilterDisabled(false)
        }).catch(err => {
            setElems([])
            setLoading(false)
            setFilterDisabled(false)
            setMsgError([textErrorGeneral])
        })
    }

    useEffect(() => {
        setMsgError([])
        setElems([])
        if (filter?.idLocation) {
            setLoading(true)
            const timeOutId = setTimeout(() => {
                getItemsData()
            }, 4000)
            return () => clearTimeout(timeOutId)
        }
    }, [filter])

    return (
        <>
            {!filter?.idLocation && (
                <h1 style={{textAlign: 'center', padding: 30}}>Select a Store for displaying data</h1>
            )}

            <MsgError msg={msgError} />

            {filter?.idLocation && (
                <>
                    <Loading show={loading} title="Fetching Reports..."/>

                    {(!loading) && (
                        <TableFilterBase
                            columns={columns}
                            data={elems || []}
                            selectedRows={elems}
                            onSelectedRowsChange={setElems}
                            search={false}
                            selectable={false}
                        />
                    )}
                </>
            )}
        </>
    )
}
