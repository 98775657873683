import {BASE_PATH, PATH_BASE_EXTENSION, PATH_BASE_EXTENSION_ADMIN} from "../../utils/constants"
import {authFetch} from "../../utils/fetch"
import {getToken} from "../auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH}${PATH_BASE_EXTENSION_ADMIN}/transaction-grouping`

export function apiList(props) {
    const config = {headers: {'Authorization': `Bearer ${localStorage.getItem("token")}`}};
    let query = "";

    if (props.dateFrom) {
        query += `&dateFrom=${props.dateFrom}`;
    }
    if (props.dateTo) {
        query += `&dateTo=${props.dateTo}`;
    }
    if (props.status) {
        query += `&status=${props.status}`;
    }
    if (props.company) {
        query += `&company=${props.company}`;
    }
    if (props.region) {
        query += `&region=${props.region}`;
    }
    if (props.subregion) {
        query += `&subregion=${props.subregion}`;
    }
    if (props.location) {
        query += `&location=${props.location}`;
    }
    if (props.id) {
        query += `&id=${props.id}`;
    }
    if (props.exportExcel) {
        query += `&exportExcel=${props.exportExcel}`;
    }

    return axios.get(`${BASE_PATH}${PATH_BASE_EXTENSION}/admin/transaction/list?limit=${props.limit}&offset=${props.offset}${query}`, config)
}

export function apiGetTransactionGrouping(id) {
    const config = {headers: {'Authorization': `Bearer ${getToken()}`}};

    return axios.get(`${baseUrl}/${id}`, config)
}

export async function groupTransactionOrder(data) {

    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
    }

    return axios.post(`${baseUrl}/group-transaction`, data, config)
}

export async function transferApi(data) {
    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
    }
    return axios.post(`${baseUrl}/transfer-netsuite`, data, config)
}

export async function deleteApi(id) {
    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
    }
    return axios.get(`${baseUrl}/delete/${id}`, config)
}

export async function deleteTransactionApi(id, idTransaction) {
    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
    }
    return axios.get(`${baseUrl}/delete-transaction/${id}/${idTransaction}`, config)
}
