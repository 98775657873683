import React, {useEffect, useMemo, useState} from "react";
import useAuth from "../../../../hooks/useAuth";
import TableFilterBase from "../../../../components/Admin/TableFilterBaser";
import {apiList} from "../../../../services/user/availability";
import Loading from "../../../../components/User/Loading";
import clone from "../../../../utils/clone";
import MsgError from "../../../../components/User/MsgError";
import {textErrorGeneral} from "../../../../utils/constants"
import NewTable from "../../../../components/Admin/NewTable";

export default function Table(props) {

    const {
        filter,
        setFilterDisabled,
        products
    } = props

    const { logout } = useAuth()
    const [loading, setLoading] = useState(false)
    const [elems, setElems] = useState(null)
    const [msgError, setMsgError] = useState([])
    const [state, setState] = useState(initialValues())


    const getItemsData = async () => {
        setMsgError([])
        setElems([])
        setLoading(true)
        setFilterDisabled(true)

        apiList( {
            idCompany: filter?.idCompany?.id,
            idRegion: filter?.idRegion?.id,
            idRegionName: filter?.idRegion?.name,
            idWarehouseName: filter?.idWarehouse?.name,
            idWarehouse: filter?.idWarehouse?.id,
            idProductCategory: filter?.idProductCategory?.id,
            idSemester: filter?.idSemester?.id,
            weeksRange: filter?.weeksRange?.id,
            version: 1
        }).then(resp => {
            let temp = []
            temp = resp.data.data.map((doc) => {
                return {
                    ...doc,
                    product_units_per_pack: doc.product_units_per_pack_difference ? "!" : doc.product_units_per_pack,
                    product_po_order_total: doc.product_units_per_pack_difference ? "!" : doc.product_po_order_total,
                    product_po_order_recieved: doc.product_units_per_pack_difference ? "!" : doc.product_po_order_recieved,
                    total_recieved_pieces: doc.product_units_per_pack_difference ? "!" : doc.total_recieved_pieces,
                }

            })
            setElems(temp)
            setLoading(false)
            setFilterDisabled(false)
        }).catch(err => {
            setElems([])
            setLoading(false)
            setFilterDisabled(false)
            setMsgError([textErrorGeneral])
        })
    }

    const handleChangeCell = (e) => {
        
        let idProduct = parseInt(e.target.id)
        let mslRequired = e.target.value

        // Detectamos si el id existe en el array y lo añádimos o actualizamos array
        if (elems.find(item => parseInt(item.id) === idProduct)) {
            // Actualizamos quantity en el array
            elems.map(item => {
                if (item.id === idProduct) {
                    if (mslRequired < 1) {
                        item.msl_required = mslRequired
                        item.percent_stock_vs_msl = ""
                        item.percent_stock_with_in_transit_vs_msl = ""
                        setElems([...elems])
                        return false
                    }
                    item.msl_required = mslRequired
                    item.percent_stock_vs_msl = (item.stock_on_hand / mslRequired).toFixed(0) ?? "0"
                    item.percent_stock_with_in_transit_vs_msl = (item.total_stock / mslRequired).toFixed(0) ?? "0"

                }
            })
            setElems([...elems])
        } else if (mslRequired > 0) {
            setElems([...elems, {
                id: idProduct,
                msl_required: mslRequired,
            }])
        }

        filter['products'] = elems
    }

    useEffect(() => {
        setMsgError([])
        setElems([])
        if (filter?.idRegion) {
            setLoading(true)
            const timeOutId = setTimeout(() => {
                getItemsData()
            }, 4000)
            return () => clearTimeout(timeOutId)
        }
    }, [filter])

    return (
        <>
            {(!filter?.idRegion) && (
                <h1 style={{textAlign: 'center', padding: 30}}>Select a zone for displaying data</h1>
            )}

            <MsgError msg={msgError} />

            {filter?.idRegion && (
                <>
                    <Loading show={loading} title="Fetching Availability Reports..."/>

                    {(!loading) && (
                        <>
                            <div style={{overflow:'auto'}}>
                                <NewTable
                                    columns={tableColumns()}
                                    handleChangeCell={handleChangeCell}
                                    rows={elems}
                                    filter={state}
                                    setFilter={setState}
                                    loading={loading}
                                    style={{overflow: 'scroll'}}
                                />
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    )
}
function tableColumns() {

    return [
        {
            headerName: "Prod. Name",
            field: "product_name",
            
        },
        {
            headerName: "Prod. Description",
            field: "product_description",
            
        },
        {
            headerName: "Item Ref.",
            field: "product_reference",
            
        },
        {
            headerName: "Product Unit/Pack",
            field: "product_units_per_pack",
            
        },
        {
            headerName: "Stock on Hand",
            field: "stock_on_hand",
            
        },
        {
            headerName: "Stock in Transit",
            field: "stock_in_transit",
            
        },
        {
            headerName: "Comment",
            field: "comment",
            
        },
        {
            headerName: "Total Stock",
            field: "total_stock",
            
        },
        {
            headerName: "MSL Required",
            field: "msl_required",
            
        },
        {
            headerName: "% Stock VS. MSL",
            field: "percent_stock_vs_msl",
            
        },
        {
            headerName: "% Stock + In Transit VS. MSL",
            field: "percent_stock_with_in_transit_vs_msl",
            
        },
        
    ]
}
function initialValues(props) {
    return {
        offset: 0,
        sort: {field: "id", sort: "desc"},
    }
}
