import React from "react";
import {FormControlLabel, Switch} from "@mui/material";

/**
 * Muestra un switch
 *
 * ## PROPS
 * - **label** *string*
 * - **name** *string*
 * - **checked** *bool*
 * - **onChange** *function*
 */
export default function InputSwitch(props) {

    const {label, name, checked, onChange} = props

    return (
        <FormControlLabel
            control={
                <Switch
                    checked={checked}
                    onChange={onChange}
                    name={name}
                    color="primary"
                />
            }
            label={label}
        />
    )
}