import Basic from "../../../containers/Admin/Layouts/Basic";
import ContainerManagement from "../../../containers/Admin/Region/Management";

export default function Management() {
    return (
        <Basic menu="region">
            <ContainerManagement />
        </Basic>
    );
}
