import React, {useEffect, useMemo, useState} from "react";
import {listApi} from "../../../../services/admin/request";
import useAuth from "../../../../hooks/useAuth";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import InputButtonLoading from "../../../../components/Inputs/InputButtonLoading";
import {useParams} from "react-router-dom";
import {apiList, apiUpdateMsl} from "../../../../services/admin/availability";
import TableBase, {SelectColumnFilter} from "../../../../components/Admin/TableFilterBaser";
import NewTable from "../../../../components/Admin/NewTable";
import Loading from "../../../../components/User/Loading";
import moment from "moment/moment";

export default function Management() {
    const {id} = useParams();
    const {logout} = useAuth();

    const [products, setProducts] = useState(null);
    const [selectedRows, setSelectedRows] = useState({});
    const [msgSuccess, setMsgSuccess] = useState(false);
    const [msgDanger, setMsgDanger] = useState(null);
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState(initialValues());
    const [newParams, setNewParams] = useState([]);
    const [selection, setSelection] = useState([]);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [warehouse, setWarehouse] = useState([])

    const handleChangeSelection = (e) => {
        let idProduct = parseInt(e.target.value)
    
        // Obtenemos max_ordered_quantity de products teniendo en cuanta el IdProduct
        let mslRequired = products.find(item => item.id === idProduct).msl_required
    
        let isActive = false
        if (selection.includes(idProduct)) {
            setSelection(selection.filter(item => item !== idProduct))
        } else {
            isActive = true
            setSelection([...selection, idProduct])
        }
        if (newParams.find(item => parseInt(item.id) === idProduct)) {
            // Actualizamos quantity en el array
            newParams.map(item => {
                if (item.id === idProduct) {
                    item.is_active = isActive
                    item.msl_required = mslRequired
                }
            })
    
            setNewParams([...newParams])
        } else {
            setNewParams([...newParams, {
                id: idProduct,
                is_active: isActive,
                msl_required: mslRequired,
            }])
        }
    }
    
    const handleChangeCell = (e, field, row) => {
    
        let idProduct = parseInt(row.id)
        let value = e.target.value
    
        // Obtenemos el is_active de selection
        let isActive = selection.includes(idProduct)
    
        // Detectamos si el id existe en el array y lo añádimos o actualizamos array
        if (newParams.find(item => parseInt(item.id) === idProduct)) {
            // Actualizamos quantity en el array
            newParams.map(item => {
                if (item.id === idProduct) {
                    item.is_active = isActive
                    item.msl_required = row.msl_required
                    if (!['id', 'msl_required'].includes(field)) {
                        item[field] = e.target.value;
                    }
                }
            })
    
            setNewParams([...newParams])
        } else if (value > 0) {
    
            let newObject = {
                id: idProduct,
                msl_required: row.msl_required
            }
    
            if (!['id', 'msl_required'].includes(field)) {
                newObject[field] = e.target.value;
            }
    
            setNewParams([...newParams, newObject])
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        setMsgSuccess(false);
        setButtonLoading(true)
    
        
        apiUpdateMsl({
            products:newParams,
            idWarehouse: id
        }).then(resp => {
            
            setButtonLoading(false)
        }).catch(err => {
            setButtonLoading(false)
        })
        
    };

    function tableColumns(handleChangeSelection, selection) {
    
        return [

            { headerName: "ID", field: "id", sortable: false, flex: 1 }, 
            { headerName: "Prod. Name", field: "product_name", sortable: false, flex: 1 }, 
            { headerName: "Prod. Description", field: "product_description", sortable: false, flex: 1 }, 
            { headerName: "Item Ref.", field: "product_reference", sortable: false, flex: 1 }, 
            { headerName: "Stock on Hand", field: "stock_on_hand", sortable: false, flex: 1 }, 
            { headerName: "Stock in Transit", field: "stock_in_transit", sortable: false, flex: 1 }, 
            { headerName: "Total Stock", field: "total_stock", sortable: false, flex: 1 }, 
            { headerName: "MSL Required", field: "msl_required", sortable: false, flex: 1, editable: true }, 
            { headerName: "% Stock VS. MSL", field: "percent_stock_vs_msl", sortable: false, flex: 1 }, 
            { headerName: "% Stock + In Transit VS. MSL", field: "percent_stock_with_in_transit_vs_msl", sortable: false, flex: 1 }, 
        ]
    }

    useEffect(() => {
        getData()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        console.log(newParams)
        console.log(selection)
    }, [newParams]);

    const getData = async () => {
        setLoading(true)

        apiList(state).then(response => {
            
            let prods = response.data.data.map(doc => {
                return {
                    ...doc,
                    lastUpdate: moment.unix(doc.lastUpdate).utc().format('DD-MMM-YYYY HH:mm A')
                }
            })
            setProducts(prods);
            setLoading(false)
        }).catch(err => {
            setLoading(false)
        })
        
        /*
        const response = await listApi(
            logout,
            `report/availability`
        );
        if (response.status) {
            const prods = response.data.map(doc => {
                return {
                    ...doc,
                    lastUpdate: moment.unix(doc.lastUpdate).utc().format('DD-MMM-YYYY HH:mm A')
                }
            })
            setProducts(prods);
            setLoading(false)
        } else {
            setLoading(false)
        }
        */
    }

    return (
        <>
            {msgSuccess && (
                <Grid item xs={12} md={12} lg={12}>
                    <Box className="alert-success">
                        Data has been successfully saved
                    </Box>
                </Grid>
            )}

            {msgDanger && (
                <Grid item xs={12} md={12} lg={12}>
                    <Box className="alert-danger">
                        {msgDanger.map((item) => {
                            return (
                                <span>
                                    {item} <br/>{" "}
                                </span>
                            );
                        })}
                    </Box>
                </Grid>
            )}

            <Loading show={loading} />

            { (products && !loading) && (
            <form>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <InputButtonLoading
                            onClick={handleSubmit}
                            loading={buttonLoading}
                            label={id ? 'Save' : 'Save'}
                            disabled={newParams.length > 0 ? true : false }
                        />
                        <Box border={1} className="box-default">
                            <Box border={1} className="box-default">
                                <NewTable
                                    columns={tableColumns(handleChangeSelection, selection)}
                                    handleChangeCell={handleChangeCell}
                                    rows={products}
                                    filter={state}
                                    setFilter={setState}
                                    loading={loading}
                                />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </form>
            )}
        </>
    );

    function initialValues(props) {
        return {
            offset: 0,
            sort: {field: "id", sort: "desc"},
            idCompany: null,
            idRegion: null,
            idRegionName: null,
            idWarehouseName: null,
            idWarehouse: id ?? null,
            idProductCategory: null,
            idSemester: null,
            products: null,
            weeksRange: null,
            version: 1
        }
    }
}
