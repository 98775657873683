import React, {useEffect, useMemo, useState} from "react"
import {useHistory, useParams} from "react-router-dom"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import {listApi} from "../../../../../services/admin/request"
import useAuth from "../../../../../hooks/useAuth"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import TextField from "@material-ui/core/TextField"
import TableBase from "../../../../../components/Admin/TableBase"
import ArrowBackIos from "@material-ui/icons/ArrowBackIos"
import Button from "@material-ui/core/Button"
import Switch from "@material-ui/core/Switch"
import {createEditShipment} from "../../../../../services/admin/transaction"
import moment from "moment/moment";

const EditableCell = ({
                          value: initialValue,
                          row: {original},
                          column: {Header, id: idColumn, inputType, disableInput},
                      }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue)
    const {id} = useParams()

    const onChange = e => {

        if (e.target.value <= parseInt(original.maxQuantity)) {

            setValue(e.target.value)

        }

    }

    // If the initialValue is changed external, sync it up with our state
    useEffect(() => {
        setValue(id ? initialValue ?? 0 : 0)

        if (!id) {
            let toShipCalculated = original.qtyOrdered - original.qtyShipped
            setValue(toShipCalculated > 0 ? toShipCalculated : 0)
        }
    }, [initialValue]) // eslint-disable-line react-hooks/exhaustive-deps

    return <TextField
        variant="outlined"
        value={value ?? ""}
        className="form-control"
        placeholder={Header}
        type={inputType ?? "text"}
        name={`${idColumn}_${original.idProduct}`}
        style={{width: "50%"}}
        disabled={!original.maxQuantity || disableInput}
        onChange={onChange}
        size="small"
        sx={{
            "& label.Mui-focused": {
                display: "none"
            },
            "& legend": {
                display: "none"
            }
        }}
        inputProps={{style: {fontSize: 15}}}
        InputLabelProps={{shrink: true}}
    />

}

export default function Edit(props) {

    const {idTransaction, id} = useParams()
    const {logout} = useAuth()

    const [loading, setLoading] = useState(true);
    const [transaction, setTransaction] = useState({});
    const [products, setProducts] = useState([]);
    const [shipment, setShipment] = useState({});
    const [msgDanger, setMsgDanger] = useState(null);
    const history = useHistory();

    useEffect(() => getData(), []) // eslint-disable-line react-hooks/exhaustive-deps

    const getData = async () => {
        const response = await listApi(logout, `transaction/shipment/${idTransaction}/${id ?? 0}`)

        if (response.status) {

            setTransaction(response.data.details)

            setProducts(response.data.products);

            if (response.data.shipment) {
                setShipment(response.data.shipment);
            }

            setLoading(false)
        }

    }

    const handleCreateEditShipment = async (event) => {

        event.preventDefault();

        let url = "shipment/create"

        const data = new FormData(event.target)

        data.append("idTransaction", idTransaction)

        let object = {};

        data.forEach((value, key) => {
            object[key] = value
        });

        const response = await createEditShipment(logout, url, object);

        if (response.status) {
            let href = `/admin/transaction/edit/${idTransaction}/2`
            history.push(href);
        } else {

            setMsgDanger(response.message);

        }

    }

    const columns = useMemo(
        () => [
            {
                Header: "PRODUCT",
                accessor: "name",
            },
            {
                Header: "QTY ORDERED",
                accessor: "qtyOrdered",
            },
            {
                Header: "TO SHIP",
                accessor: "toShip",
                editable: true,
                inputType: "number",
                Cell: EditableCell,
                disableInput: id !== undefined && id !== null
            },
            {
                Header: "QTY SHIPPED",
                accessor: "qtyShipped",
            }
        ],
        []) // eslint-disable-line react-hooks/exhaustive-deps

    if (loading) return "";

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                    <h2 className="p-0">
                        {id && (
                            <span>Edit Shipment</span>
                        )}
                        {!id && (
                            <span>Create shipment</span>
                        )}
                    </h2>
                </Grid>
            </Grid>
            {msgDanger && (
                <Grid item xs={12} md={12} lg={12}>
                    <Box className="alert-danger">
                        {msgDanger.map((item) => {
                            return (
                                <span>
                                    {" "}
                                    {item} <br/>{" "}
                                </span>
                            );
                        })}
                    </Box>
                </Grid>
            )}
            <form onSubmit={handleCreateEditShipment}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box border={1} className="p-20 box-default">
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12} lg={12} className="border-bottom">
                                    <h5 className="p-0 mt-0">Order Info</h5>
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                    <ul className="MuiList-root css-1mk9mw3-MuiList-root" style={{padding:0}}>
                                        <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                            <div
                                                className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                                <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">Customer
                                                    Name</h6>
                                                <div className="MuiBox-root css-1clcuvi">
                                                    <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">{transaction.user_name} {transaction.user_surnames}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                            <div
                                                className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                                <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">Email
                                                    Address</h6>
                                                <div className="MuiBox-root css-1clcuvi">
                                                    <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">{transaction.user_email}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                            <div
                                                className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                                <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">Phone
                                                    Number</h6>
                                                <div className="MuiBox-root css-1clcuvi">
                                                    <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">{transaction.location_phone}</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                    <ul className="MuiList-root css-1mk9mw3-MuiList-root" style={{padding:0}}>
                                        <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                            <div
                                                className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                                <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">Creation
                                                    Date</h6>
                                                <div className="MuiBox-root css-1clcuvi">
                                                    <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">{moment.unix(transaction.transaction_creationDate).utc().format('DD-MMM-YYYY HH:mm A')}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                            <div
                                                className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                                <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">QTY</h6>
                                                <div className="MuiBox-root css-1clcuvi">
                                                    <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                        {transaction.numberOfPacks}
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                            <div
                                                className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                                <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">TOTAL</h6>
                                                <div className="MuiBox-root css-1clcuvi">
                                                    <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">{transaction.price}</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                    <ul className="MuiList-root css-1mk9mw3-MuiList-root" style={{padding:0}}>
                                        <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                            <div
                                                className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                                <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">Num
                                                    REF/PO#</h6>
                                                <div className="MuiBox-root css-1clcuvi">
                                                    <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">{transaction.transaction_ref}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                            <div
                                                className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                                <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">Comment</h6>
                                                <div className="MuiBox-root css-1clcuvi">
                                                    <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">{transaction.transaction_comment}</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={3} style={{marginTop: 10}}>
                    <Grid item xs={12}>
                        <Box border={1} className="p-20 box-default">
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12} lg={12} className="border-bottom">
                                    <h5 className="p-0 mt-0">Store</h5>
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                    <ul className="MuiList-root css-1mk9mw3-MuiList-root" style={{padding:0}}>
                                        <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                            <div
                                                className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                                <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">Company</h6>
                                                <div className="MuiBox-root css-1clcuvi">
                                                    <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">{transaction.company_name}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                            <div
                                                className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                                <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">Name</h6>
                                                <div className="MuiBox-root css-1clcuvi">
                                                    <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">{transaction.location_name}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                            <div
                                                className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                                <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">Code</h6>
                                                <div className="MuiBox-root css-1clcuvi">
                                                    <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">{transaction.location_code}</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </Grid>
                                <Grid item xs={12} md={4} lg={4}>
                                    <ul className="MuiList-root css-1mk9mw3-MuiList-root" style={{padding:0}}>
                                        <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                            <div
                                                className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                                <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">Region</h6>
                                                <div className="MuiBox-root css-1clcuvi">
                                                    <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">{transaction.region_name}</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                            <div
                                                className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                                <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">Phone
                                                    Number</h6>
                                                <div className="MuiBox-root css-1clcuvi">
                                                    <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                        {transaction.location_phone}
                                                    </p>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="MuiListItem-root MuiListItem-padding css-1jo6fxu-MuiListItem-root" style={{listStyleType: 'none'}}>
                                            <div
                                                className="MuiListItemText-root MuiListItemText-multiline css-f1tjrh-MuiListItemText-root">
                                                <h6 className="MuiTypography-root MuiTypography-subtitle2 css-rhgnhp-MuiTypography-root">Address</h6>
                                                <div className="MuiBox-root css-1clcuvi">
                                                    <p className="MuiTypography-root MuiTypography-body2 css-19s0m9k-MuiTypography-root">
                                                    <span>
                                                        {transaction.location_address1}
                                                    </span>
                                                        {transaction.location_address2 && <br/>}
                                                        {transaction.location_address2 && (
                                                            <span>{transaction.location_address2}</span>
                                                        )}
                                                        {transaction.location_address3 && <br/>}
                                                        {transaction.location_address3 && (
                                                            <span>{transaction.location_address3}</span>
                                                        )}
                                                        {transaction.location_address4 && <br/>}
                                                        {transaction.location_address4 && (
                                                            <span>{transaction.location_address4}</span>
                                                        )}
                                                        {transaction.location_address5 && <br/>}
                                                        {transaction.location_address5 && (
                                                            <span>{transaction.location_address5}</span>
                                                        )}</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={3} style={{marginTop: 10}}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Box border={1} className="p-20 box-default">
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <div className="">
                                        <TextField
                                            variant="outlined"
                                            label="Tracking Number"
                                            className="form-control"
                                            name="trackingNumber"
                                            type="text"
                                            value={shipment.trackingNumber}
                                            onChange={(e) => setShipment({...shipment, trackingNumber: e.target.value})}
                                            fullWidth
                                            disabled={id !== undefined && id !== null}
                                            size="small"
                                        />
                                    </div>
                                </Grid>
    
                                <Grid item xs={6}>
                                    <div className="">
                                        <TextField
                                            name="eta"
                                            label="ETA"
                                            variant="outlined"
                                            fullWidth
                                            disabled={id !== undefined && id !== null}
                                            value={shipment.eta}
                                            size="small"
                                            onChange={(e) => setShipment({...shipment, eta: e.target.value})}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={4}>
                                    <div className="">
                                        <TextField
                                            name="shippingMethod"
                                            label="Ship Method"
                                            variant="outlined"
                                            fullWidth
                                            disabled={id !== undefined && id !== null}
                                            value={shipment.shippingMethod}
                                            size="small"
                                            onChange={(e) => setShipment({...shipment, shippingMethod: e.target.value})}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={4}>
                                    <div className="">
                                        <TextField
                                            name="palletNumber"
                                            label="Nº of Pallets"
                                            variant="outlined"
                                            fullWidth
                                            disabled={id !== undefined && id !== null}
                                            value={shipment.palletNumber}
                                            size="small"
                                            onChange={(e) => 
                                                setShipment({...shipment, palletNumber: e.target.value})
                                            }
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={4}>
                                    <div className="">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={shipment.whiteGloveService}
                                                    value={shipment.whiteGloveService}
                                                    onChange={(e) => setShipment({...shipment, whiteGloveService: e.target.checked})}
                                                    disabled={id !== undefined && id !== null}
                                                    name="whiteGloveService"
                                                    color="primary"
                                                />
                                            }
                                            label="White Glove Services"
                                        />
                                    </div>
                                </Grid>
                                
                                <Grid item xs={12}>
                                    <div className="mb-1">
                                        <TextField
                                            name="comment"
                                            label="Comment"
                                            multiline
                                            rows={2}
                                            variant="outlined"
                                            fullWidth
                                            disabled={id !== undefined && id !== null}
                                            value={shipment.comment}
                                            onChange={(e) => setShipment({...shipment, comment: e.target.value})}
                                            inputProps={{maxLength: 100}}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={3} style={{marginTop: 10}}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Box border={1} className="box-default">
                            <Grid container>
                                <Grid item xs={12} style={{padding: 0}}>
                                    <TableBase
                                        columns={columns}
                                        data={products}
                                        selectedRows={products}
                                        onSelectedRowsChange={setProducts}/>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={3} justifyContent="flex-end">
                    <Grid item xs={12} md={4} lg={4}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6} lg={6}>
                                <Button
                                    type="button"
                                    color="secondary"
                                    href={`/admin/transaction/edit/${idTransaction}/2`}
                                    fullWidth
                                >
                                    <ArrowBackIos/> Back to list
                                </Button>
                            </Grid>
                            {!id && (
                                <Grid item xs={12} md={6} lg={6}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        type="submit"
                                    >
                                        {id ? "Edit shipment" : "Create shipment"}
                                    </Button>

                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}
