import Basic from "../../../containers/Admin/Layouts/Basic"
import LogMail from "../../../containers/Admin/Log/LogMail"

export default function Management() {
    return (
        <Basic menu="logMail">
            <LogMail />
        </Basic>
    )
}
