import Basic from "../../../../containers/Admin/Layouts/Basic"
import ContainersCreate from "../../../../containers/Admin/Transaction/Shipment/Fields"

export default function Edit() {
  return (
    <Basic menu="shippings">
          <ContainersCreate />
    </Basic>
  )
}
