import {Box, Container, Toolbar} from "@mui/material";
import * as React from "react";
import {useEffect} from "react";
import {apiMaintenance} from "../../../../services/auth/maintenence";
import {Alert} from "@mui/lab";
import Snackbar from '@mui/material/Snackbar';

export default function Maintenance(props) {

    const vertical = 'bottom'
    const horizontal = 'center'

    const [maintenance, setMaintenance] = React.useState(false)
    const [comeBackTime, setComeBackTime] = React.useState("")

    useEffect(() => {
        apiMaintenance({}).then(resp => {
            setMaintenance(resp.data?.isActive)
            setComeBackTime(resp.data?.comeBackTime)
        }).catch(err => {
            setMaintenance(false)
            setComeBackTime("")
        })
    }, [])

    return (
        <Snackbar
            anchorOrigin={{vertical, horizontal}}
            open={maintenance}
            variant="filled"
            key={vertical + horizontal}
        >
            <Alert severity="error" style={{fontSize: 20}}>
                Maintenance mode is activated. <br/>Please come back later once this mode is disabled.<br/>
                {comeBackTime !== "" ? (<>Estimated time to resume service {comeBackTime}</>) : ''}
            </Alert>
        </Snackbar>
    )
}
