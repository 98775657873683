import Basic from "../../../containers/Admin/Layouts/Basic"
import ContainerManagement from "../../../containers/Admin/Currency/Management"

export default function Management() {
  return (
    <Basic menu="currency">
          <ContainerManagement />
    </Basic>
  )
}
