import useAuth from "../../../../hooks/useAuth";
import React, {useEffect, useState} from "react";
import {listApi} from "../../../../services/user/request";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@mui/styles";
import moment from "moment";
import {LoadingButton} from "@mui/lab";
import InputAutocomplete from "../../../../components/User/Inputs/InputAutocomplete";
import InputButtonLoading from "../../../../components/Inputs/InputButtonLoading";

export default function Header(props) {
    const {
        filter,
        setFilter,
        loadingExport,
        clickExport,
        filterDisabled,
    } = props

    const [company, setCompany] = useState([])
    const [region, setRegion] = useState([])
    const [warehouse, setWarehouse] = useState([])
    const [semester, setSemester] = useState([])
    const [productCategory, setProductCategory] = useState([])
    const {logout, auth} = useAuth();

    const [disabledRegion, setDisabledRegion] = useState(true)
    const [disabledWarehouse, setDisabledWarehouse] = useState(true)
    const companyName = auth ? auth.companyName : null;
    const companyId = auth ? auth.companyId : null;
    const roleName = auth ? auth.role : null;

    const weeksRange = [
        {id: 4, name: '4 Weeks'},
        {id: 5, name: '5 Weeks'},
        {id: 6, name: '6 Weeks'},
        {id: 7, name: '7 Weeks'},
        {id: 8, name: '8 Weeks'},
        {id: 9, name: '9 Weeks'},
        {id: 10, name: '10 Weeks'},
        {id: 11, name: '11 Weeks'},
        {id: 12, name: '12 Weeks'},
        {id: 13, name: '13 Weeks'},
        {id: 14, name: '14 Weeks'},
        {id: 15, name: '15 Weeks'},
        {id: 16, name: '16 Weeks'},
        {id: 17, name: '17 Weeks'},
        {id: 18, name: '18 Weeks'},
        {id: 19, name: '19 Weeks'},
        {id: 20, name: '20 Weeks'},
    ]

    useEffect(() => {
        (async () => {
            if (companyName === 'International Direct Packaging Limited') {
                const response = await listApi(logout, "company");
                if (response.status) {
                    setCompany(response.data)
                }
            }
            if (companyName !== 'International Direct Packaging Limited') {
                await handleChangeCompany(null, {id: companyId})
            }

            const response = await listApi(logout, "product-category");
            if (response.status) {
                setProductCategory(response.data)
            }

            if (roleName === "ROLE_REGION_MANAGER") {
                await handleChangeRegion(null, {id: auth.region?.id})
            }

            const responseSemester = await listApi(logout, "list-semester");
            if (responseSemester.status) {
                setSemester(responseSemester.data)
            }

        })()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeCompany = async (event, value) => {
        setDisabledRegion(true)
        setDisabledWarehouse(true)
        filter["idWarehouse"] = null

        if (value) {
            filter["idCompany"] = value
            // Buscar Loaction
            const response = await listApi(logout, `region`, {idCompany: value.id})
            if (response.status) {
                setRegion(response.data)
            } else {
                setRegion([])
            }

            // Activar location
            setDisabledRegion(false)
        }
        setFilter({...filter})

    }

    const handleChangeRegion = async (event, value) => {
        setDisabledWarehouse(true)
        filter["idWarehouse"] = null

        if (value) {
            filter["idRegion"] = value

            const response = await listApi(logout, `warehouse`, {idRegion: value.id});
            if (response.status) {
                setWarehouse(response.data)
            } else {
                setWarehouse([])
            }

            setDisabledWarehouse(false)
        } else {
            filter["idRegion"] = null
        }

        setFilter({...filter})

    }

    const handleChangeProductCategory = (event, value) => {
        setFilter({...filter, idProductCategory: value})
    }
    const handleChangeWarehouse = (event, value) => {
        setFilter({...filter, idWarehouse: value})
    }
    const handleChangeSemester = (event, value) => {
        setFilter({...filter, idSemester: value})
    }
    const handleChangeWeeksRange = (event, value) => {
        setFilter({...filter, weeksRange: value})
    }

    const handleChange = (event) => {
        filter[event.target.name] = event.target.value
        setFilter({...filter})
    }

    return (
        <div className="row">
            
                
                    {companyName === 'International Direct Packaging Limited' &&
                        <div className="col">
                            <InputAutocomplete
                                options={company}
                                value={filter?.idCompany}
                                onChange={handleChangeCompany}
                                disabled={filterDisabled}
                                label={"Company"}
                                name={"idCompany"}
                            />
                        </div>
                    }
                    
                    <div className="col">
                        <InputAutocomplete
                            options={region}
                            value={filter?.idRegion}
                            onChange={handleChangeRegion}
                            disabled={disabledRegion || filterDisabled}
                            label={"Zone"}
                            name={"idRegion"}
                        />
                    </div>
                    
                    {/* 
                    <div className="col">
                        <InputAutocomplete
                            options={semester}
                            value={filter?.idSemester}
                            onChange={handleChangeSemester}
                            disabled={filterDisabled || filterDisabled}
                            label={"Semester"}
                            name={"idSemester"}
                        />
                    </div>
                    */}

                    <div className="col">
                        <InputAutocomplete
                            options={warehouse}
                            value={filter?.idWarehouse}
                            onChange={handleChangeWarehouse}
                            disabled={disabledWarehouse || filterDisabled}
                            label={"Warehouse"}
                            name={"idWarehouse"}
                        />
                    </div>
                
            
            <div className="col">
                <InputAutocomplete
                    options={productCategory}
                    value={filter?.idProductCategory}
                    onChange={handleChangeProductCategory}
                    label={"Product Category"}
                    disabled={filterDisabled}
                    name={"idProductCategory"}
                />
            </div>
            <div className="col-lg-4 col-6">
                <InputButtonLoading
                    type="button"
                    loading={loadingExport}
                    loadingPosition="start"
                    variant="contained"
                    fullWidth
                    onClick={clickExport}
                    disabled={filter?.idRegion ? false : true}
                    label="EXPORT (.XLSX)"
                    style={{width: "100%"}}
                />
            </div>
        </div>
    )
}
