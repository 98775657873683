import React, {useEffect, useState} from "react";
import {apiLogCeline} from "../../../services/admin/log";
import moment from "moment";
import {Chip, Grid} from "@mui/material";
import HeaderPage from "../../../components/Admin/HeaderPage";
import Table from "../../../components/Admin/NewTable";
import {DASH_MAX_ROW} from "../../../utils/constants";
import InputText from "../../../components/Inputs/InputText";

export default function LogCeline() {
    const [rows, setRows] = useState([])
    const [msgError, setMsgError] = useState([])
    const [loading, setLoading] = useState(true)
    const [rowCount, setRowCount] = useState(0)
    const [state, setState] = useState(initialValues)
   

    useEffect(() => {
        setLoading(true)
        const timeOutId = setTimeout(() => {
            getItemsData()
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [state])

    const getItemsData = () => {
        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let field = state.sort.field

        let params = {
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort
        }

        if (state.email) { params.email = state.email }
      
        apiLogCeline(params).then(resp => {
            setRowCount(resp.data?.count)

            let temp = resp.data.data?.map(doc => {
                let links=doc.fileUpload?.split(";")
                return {
                    ...doc,
                    date: moment.unix(doc.creationDate).utc().format('DD-MMM-YYYY HH:mm A'),
                    textResponse: doc.textResponse,
                    fileUpload: links?.map((urlFile) => { 
                        console.log(urlFile)
                        return (
                            <>
                            <a className="pr-2" href={urlFile} target="_blank" rel="noreferrer">{urlFile.split("/").pop()}</a><br/>
                            </>
                        )
                    }),
                    isFinish: doc.isFinish ? (
                        <Chip label="Finish" color="primary" size="small" />
                    ) : (
                        <></>
                    ),
                    //isResponseError: doc.isResponseError ? (
                    isResponseError: doc.textResponse ? (
                        <Chip label="Error" color="error" size="small" />
                    ) : (
                        <Chip label="Success" color="success" size="small" />
                    ),
                }
            })
            setRows(temp)
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            setRows([])
        })
    }

    return (
        <>
            <HeaderPage title="Celine Power BI" />

            <Grid container spacing={1}>
                <Grid item xs={12}>
                </Grid>

                <Grid item xs={12}>


                    <Filters
                        state={state}
                        setState={setState}
                    />


                    <Table
                        columns={tableColumns()}
                        rows={rows}
                        rowCount={rowCount}
                        filter={state}
                        setFilter={setState}
                        loading={loading}
                    />
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
    return {
        offset: 0,
        sort: { field: "id", sort: "desc" },
        email: "",
    }
}

function tableColumns() {
    return [
        {
            field: "id",
            headerName: "ID",
            sortable: false,
            flex: 1
        },
        {
            field: "email",
            headerName: "USER",
            sortable: false,
            flex: 1
        },
        {
            field: "date",
            headerName: "DATE",
            sortable: false,
            flex: 1
        },
        {
            field: "isFinish",
            headerName: "IS FINISH",
            sortable: false,
            flex: 1
        },
        {
            field: "fileUpload",
            headerName: "UPLOAD",
            sortable: false,
            flex: 1
        },
        {
            field: "textResponse",
            headerName: "RESPONSE",
            sortable: false,
            flex: 1
        },
        {
            field: "isResponseError",
            headerName: "ERROR UPLOAD",
            sortable: false,
            flex: 1
        },
    ]
}

function Filters(props) {

    const {state, setState} = props

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        state["offset"] = 0
        setState({...state})
    }

    return (
        <Grid container spacing={1} style={{marginBottom: '20px'}}>
            <Grid item xs={3}>
                <InputText
                    label="Email"
                    name="email"
                    onChange={handleChange}
                    value={state?.email}
                />
            </Grid>
        </Grid>
    )
}
