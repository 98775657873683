import { useParams } from "react-router-dom";
import React from "react";
import ContainerManagementShipment from "../../../containers/Admin/Transaction/Shipment/Management";

import Basic from "../../../containers/Admin/Layouts/Basic"
import TransactionEdit from "../../../containers/Admin/Transaction/Fields"
import TransactionLog from "../../../containers/Admin/Transaction/Log/Management"
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Grid from "@material-ui/core/Grid";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import Button from "@material-ui/core/Button";

export default function Edit() {

    const { tab } = useParams();
    const [value, setValue] = React.useState(tab);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Basic menu="transaction">

            <Grid container spacing={3} className="pl-20 pr-20">
                <Grid item xs={12} md={12} lg={12}>
                    <Button
                        type="button"
                        color="primary"
                        href="/admin/transaction/management"
                        style={{
                            float: "left"
                        }}
                    >
                        <ArrowBackIos />
                        BACK TO LIST
                    </Button>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <h2 className="p-0">Order Settings</h2>
                </Grid>
            </Grid>

            <TabContext value={value}>

                <AppBar position="static" style={{ background: "#fff", color: "#000", boxShadow: "none" }}>
                    <TabList onChange={handleChange}>
                        <Tab label="Order" value="1" />
                        <Tab label="Shipping" value="2" />
                        <Tab label="Log" value="3" />
                    </TabList>
                </AppBar>

                <TabPanel value="1">
                    <TransactionEdit />
                </TabPanel>
                <TabPanel value="2">
                    <ContainerManagementShipment />
                </TabPanel>
                <TabPanel value="3">
                    <TransactionLog />
                </TabPanel>

            </TabContext>

        </Basic>
    )
}
