import React, {useEffect, useMemo, useState} from "react";
import {listApi} from "../../../../../services/admin/request";
import useAuth from "../../../../../hooks/useAuth";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {useParams} from "react-router-dom";
import TableBase from "../../../../../components/Admin/TableFilterBaser";
import Link from "@material-ui/core/Link";
import {saveStockDataReq} from "../../../../../services/admin/product";
import NewTable from "../../../../../components/Admin/NewTable";
import {IconButton} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

export default function Management() {
    const {id} = useParams()
    const {logout} = useAuth()

    const [row, setRow] = useState([])
    const [state, setState] = useState(initialValues)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        (async () => {
            setLoading(true)
            const response = await listApi(
                logout,
                `product/warehouse-stock/${id}`
            );
            if (response.status) {
                const temp = response.data.map((doc) => {

                    return {
                        ...doc,
                        comment: doc.comment ? doc.comment : '-',
                        stockBalanceIdpPo: doc.stockBalanceIdpPo ? doc.stockBalanceIdpPo : '-',
                    };
                });

                setRow(temp)
                setLoading(false)
            }
        })();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box border={1} className="box-default">
                        <NewTable
                            columns={tableColumns()}
                            rows={row}
                            rowCount={row.length}
                            filter={state}
                            setFilter={setState}
                            loading={loading}
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
    return {
        offset: 0,
        sort: {field: "id", sort: "desc"},
    }
}

function tableColumns() {
    return [
        {
            headerName: "CODE",
            field: "code",
            sortable: false,
            flex: 1
        },
        {
            headerName: "NAME",
            field: "name",
            sortable: false,
            flex: 1
        },
        {
            headerName: "REGION",
            field: "regionName",
            sortable: false,
            flex: 1
        },
        {
            headerName: "STOCK ON HAND",
            field: "stockOnHand",
            sortable: false,
            flex: 1
        },
        {
            headerName: "STOCK ON ORDER",
            field: "stockOnOrder",
            sortable: false,
            flex: 1
        },
        {
            headerName: "STOCK IN TRANSIT",
            field: "stockInTransit",
            sortable: false,
            flex: 1
        },
        {
            headerName: "COMMENT",
            field: "comment",
            sortable: false,
            flex: 1
        },
        {
            headerName: "BALANCE IDP PO",
            field: "stockBalanceIdpPo",
            sortable: false,
            flex: 1
        },
        {
            headerName: "ACTIONS",
            field: "actions",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <a
                            href={`/admin/warehouse/edit/${params.idWarehouse}/2`}
                            target="_blank"
                        >
                            <IconButton size="small">
                                <EditIcon fontSize="inherit"/>
                            </IconButton>
                        </a>
                    </>
                )
            },
        },
    ]
}
