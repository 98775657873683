import React, {useEffect, useState} from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PeopleIcon from "@material-ui/icons/People";
import BusinessIcon from "@material-ui/icons/Business";
import MapIcon from "@material-ui/icons/Map";
import RoomIcon from "@material-ui/icons/Room";
import CategoryIcon from "@material-ui/icons/Category";
import PublicIcon from "@material-ui/icons/Public";
import StorefrontIcon from "@material-ui/icons/Storefront";
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Divider from "@material-ui/core/Divider";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import ArticleIcon from '@mui/icons-material/Article';

export default function menuOne(props) {
    const {menu} = props

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [classMenu, setClassMenu] = useState({
        users: false,
        category: false,
        company: false,
        country: false,
        currency: false,
        currencyRegion: false,
        invoiceEntities: false,
        location: false,
        products: false,
        productType: false,
        region: false,
        storeCategory: false,
        shippingCharge:false,
    })

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        switch (menu) {
            case "users":
                setClassMenu({
                    ...classMenu,
                    users: true
                })
                break
            case "category":
                setClassMenu({
                    ...classMenu,
                    category: true
                })
                break
            case "catalog":
                setClassMenu({
                    ...classMenu,
                    catalog: true
                })
                break
            case "company":
                setClassMenu({
                    ...classMenu,
                    company: true
                })
                break
            case "country":
                setClassMenu({
                    ...classMenu,
                    country: true
                })
                break
            case "currency":
                setClassMenu({
                    ...classMenu,
                    currency: true
                })
                break
            case "currencyRegion":
                setClassMenu({
                    ...classMenu,
                    currencyRegion: true
                })
                break
            case "invoiceEntities":
                setClassMenu({
                    ...classMenu,
                    invoiceEntities: true
                })
                break
            case "location":
                setClassMenu({
                    ...classMenu,
                    location: true
                })
                break
            case "products":
                setClassMenu({
                    ...classMenu,
                    products: true
                })
                break
            case "productType":
                setClassMenu({
                    ...classMenu,
                    productType: true
                })
                break
            case "region":
                setClassMenu({
                    ...classMenu,
                    region: true
                })
                break
            case "storeCategory":
                setClassMenu({
                    ...classMenu,
                    storeCategory: true
                })
                break
            case "tariff":
                setClassMenu({
                    ...classMenu,
                    tariff: true
                })
                break
            case "shippingCharge":
                setClassMenu({
                    ...classMenu,
                    shippingCharge: true
                })
                break
            default:
                break;
        }
    }, [menu]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            <ListItem
                button
                component="a"
                href="/admin/users/management"
                selected={classMenu.users}
            >
                <ListItemIcon>
                    <PeopleIcon/>
                </ListItemIcon>
                <ListItemText primary="Users"/>
            </ListItem>
            <ListItem
                button
                component="a"
                href="/admin/company/management"
                selected={classMenu.company}
            >
                <ListItemIcon>
                    <BusinessIcon/>
                </ListItemIcon>
                <ListItemText primary="Company"/>
            </ListItem>
            <ListItem
                button
                component="a"
                href="/admin/region/management"
                selected={classMenu.region}
            >
                <ListItemIcon>
                    <MapIcon/>
                </ListItemIcon>
                <ListItemText primary="Zone"/>
            </ListItem>
            <ListItem
                button
                component="a"
                href="/admin/store/management"
                selected={classMenu.location}
            >
                <ListItemIcon>
                    <RoomIcon/>
                </ListItemIcon>
                <ListItemText primary="Store"/>
            </ListItem>
            <ListItem
                button
                component="a"
                href="/admin/store-category/management"
                selected={classMenu.storeCategory}
            >
                <ListItemIcon>
                    <CategoryIcon/>
                </ListItemIcon>
                <ListItemText primary="Store Category"/>
            </ListItem>
            <ListItem
                button
                component="a"
                href="/admin/invoice-entities/management"
                selected={classMenu.invoiceEntities}
            >
                <ListItemIcon>
                    <AccountBalanceWalletIcon/>
                </ListItemIcon>
                <ListItemText primary="Invoice Entities"/>
            </ListItem>

            <Divider/>

            <ListItem
                button
                component="a"
                href="/admin/product/management"
                selected={classMenu.products}
            >
                <ListItemIcon>
                    <StorefrontIcon/>
                </ListItemIcon>
                <ListItemText primary="Products"/>
            </ListItem>

            <ListItem
                button
                component="a"
                href="/admin/catalog/management"
                selected={classMenu.catalog}
            >
                <ListItemIcon>
                    <CategoryIcon/>
                </ListItemIcon>
                <ListItemText primary="Catalog Products"/>
            </ListItem>

            <ListItem
                button
                component="a"
                href="/admin/tariff/management"
                selected={classMenu.tariff}
            >
                <ListItemIcon>
                    <RequestQuoteIcon/>
                </ListItemIcon>
                <ListItemText primary="Tariffs"/>
            </ListItem>

            <ListItem
                button
                component="a"
                href="/admin/currency-region/management"
                selected={classMenu.currencyRegion}
            >
                <ListItemIcon>
                    <MonetizationOnIcon/>
                </ListItemIcon>
                <ListItemText primary="Currencies By Zone"/>
            </ListItem>

            <ListItem
                button
                component="a"
                href="/admin/category/management"
                selected={classMenu.category}
            >
                <ListItemIcon>
                    <CategoryIcon/>
                </ListItemIcon>
                <ListItemText primary="Product Category"/>
            </ListItem>

            <ListItem
                button
                component="a"
                href="/admin/country/management"
                selected={classMenu.country}
            >
                <ListItemIcon>
                    <PublicIcon/>
                </ListItemIcon>
                <ListItemText primary="Countries"/>
            </ListItem>

            <ListItem
                button
                component="a"
                href="/admin/product-type/management"
                selected={classMenu.productType}
            >
                <ListItemIcon>
                    <CategoryIcon/>
                </ListItemIcon>
                <ListItemText primary="Product Types"/>
            </ListItem>

            <ListItem
                button
                component="a"
                href="/admin/shipping-charge/management"
                selected={classMenu.shippingCharge}
            >
                <ListItemIcon>
                    <CategoryIcon/>
                </ListItemIcon>
                <ListItemText primary="Shipping Charges"/>
            </ListItem>

            <ListItem
                button
                component="a"
                href="/admin/currency/management"
                selected={classMenu.currency}
            >
                <ListItemIcon>
                    <AttachMoneyIcon/>
                </ListItemIcon>
                <ListItemText primary="Currencies"/>
            </ListItem>

            <ListItem
                button
                component="a"
                href="/admin/po/management"
                selected={classMenu.currency}
            >
                <ListItemIcon>
                    <ArticleIcon/>
                </ListItemIcon>
                <ListItemText primary="POs"/>
            </ListItem>
        </div>
    );
}
