import React from "react";
import {CircularProgress, Grid} from "@material-ui/core";

export default function Loading(props) {
    const {show, title} = props

    return (
        <>
            {show && (
                <>
                <Grid container spacing={3} className="text-center pt-2 pb-2">
                    <Grid item xs={12}>
                        <br/>
                        <CircularProgress sx={{ color: '#bababa' }} size={25} />
                    </Grid>
                    {/*{title && (*/}
                    {/*    <Grid item xs={12}>*/}
                    {/*        <p className="card-text">{title}</p>*/}
                    {/*    </Grid>*/}
                    {/*)}*/}
                </Grid>
                <br/>
                <br/>
                </>
            )}
        </>
    )
}
