import React, {useEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import InputText from "../../../../components/Inputs/InputText";
import MsgError from "../../../../components/Admin/MsgError";
import MsgSuccess from "../../../../components/Admin/MsgSuccess";
import {Grid} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import LoadingBar from "../../../../components/Admin/LoadingBar";
import Box from "@material-ui/core/Box";
import {
    apiCreate,
    apiUpdate,
    apiGetTariff
} from "../../../../services/admin/tariff";
import Products from "./Products";
import useAuth from "../../../../hooks/useAuth";
import InputSelect from "../../../../components/Inputs/InputSelect";
import {listApi} from "../../../../services/admin/request";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import moment from "moment/moment";

export default function Edit() {
    const {id} = useParams()
    const history = useHistory()
    const {logout} = useAuth()

    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])

    const [state, setState] = useState(initialValues)
    const [loading, setLoading] = useState(false)
    const [loadingGetInfo, setLoadingGetInfo] = useState(true)

    const [product, setProduct] = useState([])
    const [listCurrency, setListCurrency] = useState([])
    const [listCompany, setListCompany] = useState([])

    const myRef = useRef(null)

    useEffect(() => {
        getCompany()
        getCurrency()
        if (id) {
            void getTariffa()
        } else {
            setLoadingGetInfo(false)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    const getCompany = async () => {
        const response = await listApi(logout, 'company')
        if (response.status) {
            const temp = response.data.map((doc) => {
                return {
                    id: doc.id,
                    name: doc.name,
                }
            })
            setListCompany(temp)
        }
    }

    const getCurrency = async () => {
        const response = await listApi(logout, 'currency')
        if (response.status) {
            const temp = response.data.map((doc) => {
                return {
                    id: doc.id,
                    name: doc.name,
                }
            })
            setListCurrency(temp)
        }
    }

    const getTariffa = () => {
        setMsgError([])

        apiGetTariff(id).then(resp => {
            setProduct(resp.data?.data.products)
            setState(initialValues(resp.data?.data))
            setLoadingGetInfo(false)
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response.data?.msg)
        })
    }

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        setLoading(true)
        setMsgError([])
        setMsgSuccess([])

        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            setLoading(false)
            myRef.current.scrollIntoView()
            return
        }

        if (id) {
            void updateContainer()
        } else {
            void createContainer()
        }
    }

    const updateContainer = () => {

        let stateTemp = {...state, id: id, products: product}
        apiUpdate(stateTemp).then(resp => {
            setMsgSuccess(resp.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }

    const createContainer = () => {
        let stateTemp = {...state, id: id, products: product}
        apiCreate(stateTemp).then(resp => {
            history.push(`/admin/tariff/management`)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }

    return (
        <>
            <div ref={myRef}></div>

            <HeaderPage title="Tariff Settings" urlBack={`/admin/tariff/management`}/>

            <MsgError msg={msgError}/>
            <MsgSuccess msg={msgSuccess}/>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box border={1} className="p-20 box-default">
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12} className="border-bottom">
                                <h5 className="p-0 mt-0">Info</h5>
                            </Grid>
                            <LoadingBar loading={loadingGetInfo}/>

                            <Grid item xs={12} container spacing={1}>
                                <Grid item xs={4} style={{paddingBottom: 10}}>
                                    <InputSelect
                                        label="Company"
                                        name="companyId"
                                        required
                                        onChange={handleChange}
                                        value={state?.companyId}
                                        options={listCompany}
                                        disabled={id ? true : false}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <InputSelect
                                        label="Currency"
                                        name="currencyId"
                                        required
                                        onChange={handleChange}
                                        value={state?.currencyId}
                                        options={listCurrency}
                                    />
                                </Grid>

                                <Grid item xs={2}>
                                    <InputText
                                        label="Creation Date"
                                        name={'creationDate'}
                                        value={state?.creationDate ? moment.unix(state?.creationDate).format("Y/M/D") : null}
                                        disabled={true}
                                    />
                                </Grid>

                                <Grid item xs={2}>
                                    <InputText
                                        label="Last Update Date"
                                        name={'updateDate'}
                                        value={state?.updateDate ? moment.unix(state?.updateDate).format("Y/M/D") : null}
                                        disabled={true}
                                    />
                                </Grid>

                                <Grid item xs={4}>
                                    <InputText
                                        label="Name"
                                        required
                                        name="name"
                                        value={state?.name}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={8}>
                                    <InputText
                                        label="Description"
                                        required
                                        name="description"
                                        value={state?.description}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={8}></Grid>
                                <Grid item xs={4}>
                                    <LoadingButton
                                        onClick={handleSubmit}
                                        loading={loading}
                                        loadingPosition="start"
                                        variant="contained"
                                        fullWidth
                                    >
                                        {id ? 'Save' : 'Create'}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <Products
                    companyId={state?.companyId}
                    setMsgError={setMsgError}
                    product={product}
                    setProduct={setProduct}
                    stateField={state}
                />
            </Grid>

        </>
    )
        ;
}

function initialValues(props) {
    return {
        id: props?.id || "",
        companyId: props?.companyId || "",
        name: props?.name || "",
        description: props?.description || "",
        currencyId: props?.currencyId || "",
        creationDate: props?.creationDate || "",
        updateDate: props?.updateDate || "",
    }
}

function validationSchema(schema) {

    let error = []

    if (!schema.companyId) {
        error.push("Company is required")
    }
    if (!schema.name) {
        error.push("Name is required")
    }
    if (!schema.description) {
        error.push("Description is required")
    }
    if (!schema.currencyId) {
        error.push("Currency is required")
    }
    return error
}
