// Peticiones protegidas al servidor

import { getToken, hasExpiredToken } from "../services/auth/token"

export async function authFetch(url, params, logout) {
    const token = getToken()

    if (!token) {
        // Usuario no logueado
        logout()
    } else {
        if (hasExpiredToken(token)) {
            // Token caducado
            logout()
        } else {
            try {
                const response = await fetch(url, params)
                const result = await response.json()

                return result
            } catch (error) {
                return error
            }
        }
    }
}
