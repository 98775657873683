import Basic from "../../../containers/Admin/Layouts/Basic"
import ComponentManagement from "../../../containers/Admin/ProductType/Management"

export default function Management() {
  return (
    <Basic menu="productType">
          <ComponentManagement />
    </Basic>
  )
}
