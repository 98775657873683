import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {TextField} from "@material-ui/core";
import useAuth from "../../../hooks/useAuth";

export default function ProductBox(props) {
    const {
        id,
        name,
        description,
        price,
        priceDefault,
        category,
        sort,
        currencySymbol,
        unitsPerPack,
        quantityDefault,
        unitDefault,
        image,
        quantityIsModify,
        stockOnHand,
        setTotalCartProducts,
        calculatePrice,
        catalogId,
        catalogName,
        idWarehouse
    } = props

    const {auth} = useAuth();
    const [unit, setUnit] = useState(unitDefault)
    const [quantity, setQuantity] = useState(quantityDefault)
    const [isSeparateOrdersBasedOnCatalogs, setisSeparateOrdersBasedOnCatalogs] = useState(auth?.isSeparateOrdersBasedOnCatal ?? null)
    const color = '#ef6f28'

    /**
     * Añadimos elementos al carrito
     */
    const addCart = () => {

        setUnit(unitsPerPack * quantity)

        const cart = JSON.parse(localStorage.getItem('cart_products'))

        if (quantity < 1 || !quantity) {

            removeCard(cart, id)
            setTotalCartProducts(cart?.length || 0)
            calculatePrice()
            return
        }

        let existId = false
        if (cart) {
            existId = updateCard(cart, existId, quantity)
        }

        if (!existId) {
            addCreateCart(cart, id, quantity)
        }
        setTotalCartProducts(cart?.length || 0)
        calculatePrice()
    }

    useEffect(() => {
        addCart()
        if (auth?.company_isSeparateOrdersBasedOnCatalogs) {
            checkCartSeparateOrdersBasedOnCatalogs(id, catalogId)
        }
    }, [quantity])

    const removeCard = (cart, id) => {
        cart.forEach((item, key) => {
            if (item.id === id) {
                cart.splice(key, 1)
            }
        })
        localStorage.setItem('cart_products', JSON.stringify(cart))
    }

    const updateCard = (cart, existId, quantity) => {

        cart.forEach(storage => {
            if (storage.id === id) {
                existId = true
                storage.quantity = quantity
            }
        })
        localStorage.setItem('cart_products', JSON.stringify(cart))
        return existId;
    }

    const addCreateCart = (cart, id, quantity) => {
        if (!cart) {
            cart = []
        }
        cart.push({
            id: id,
            quantity: quantity,
            packsPrice: `${priceDefault} ${currencySymbol}`,
            image: image ?? "https://ows-idp-packaging.s3.eu-west-3.amazonaws.com/product/no_available.png",
            name: name,
            description: description,
            category: category,
            catalog: catalogId,
            catalogName: catalogName,
            sort: sort,
            price: price,
            priceDefault: priceDefault,
            unit: unit,
            unitsPerPack: unitsPerPack,
            stockOnHand: stockOnHand,
            currencySymbol: currencySymbol,
            idWarehouse: idWarehouse
        })
        localStorage.setItem('cart_products', JSON.stringify(cart))
    }

    const handleImageError = (event) => {
        event.target.src = "https://ows-idp-packaging.s3.eu-west-3.amazonaws.com/product/no_available.png";
    };

    const checkCartSeparateOrdersBasedOnCatalogs = (id, catalog) => {
        const cart = JSON.parse(localStorage.getItem('cart_products'))        
        cart.forEach(function(item, index, storage) {
            if (item?.catalog !== catalog && catalog !== undefined) {
                storage.splice(index, 1)
            }
        })
        localStorage.setItem('cart_products', JSON.stringify(cart))
    }

    return (
        <Grid container alignItems={"center"} style={{borderBottom: "1px solid #eef0f5", marginBottom: 20, paddingBottom: 20}}>
            <Grid item xs={2} className={"text-center"}>
                <img
                    alt="Product"
                    src={image ?? "https://ows-idp-packaging.s3.eu-west-3.amazonaws.com/product/no_available.png"}
                    className="avatar avatar-xl"
                    loading="lazy"
                    onError={handleImageError}
                />
            </Grid>
            <Grid item xs={3}>
                <span className={"h5"}>{name}</span>
                <br/>
                <span className={"h6"}>{description}</span>
                <div className="row">
                    <div className="col-md-12">
                        {sort && (
                            <div className="text-body">
                                <span className="small">Ref:</span>
                                <span className="font-weight-bold small px-2">{sort}</span>
                            </div>
                        )}
                        <div className="text-body">
                            <span className="small">Category:</span>
                            <span className="font-weight-bold small px-2">{category}</span>
                        </div>
                    </div>
                </div>
            </Grid>
            <Grid item xs={2}>
                <TextField
                    id={`input-${id}`}
                    variant={"outlined"}
                    size={"small"}
                    type="number"
                    placeholder="Pack quantity"
                    label={" "}
                    value={quantity}
                    onWheel={() => document.activeElement.blur()}
                    disabled={!quantityIsModify}
                    onChange={(event) => {
                        setQuantity(event.target.value)
                    }}
                    sx={{
                        "& label.Mui-focused": {
                            display: "none"
                        },
                        "& legend": {
                            display: "none"
                        },
                        background: 'white',
                        borderRadius: '5px',
                        '& .MuiInput-underline:after': {
                            borderBottomColor: color,
                        },
                        '& .MuiOutlinedInput-root': {
                            '&:hover fieldset': {
                                borderColor: color,
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: color,
                            },
                        },
                    }}
                    inputProps={{style: {fontSize: 15, padding: "2px 5px 5px 5px"}}}
                    InputLabelProps={{shrink: true}}
                />
                {quantity && unitsPerPack ? (
                    <div className="text-body">
                        <span className="font-weight-bold small pr-1">{unit || unitsPerPack * quantity}</span>
                        <span className="small">pcs in <b>{quantity}</b> packs</span>
                        <br/>
                        {(stockOnHand && !auth.isHideAvailability) && (
                            <>
                                <span className="small">Packs available: </span>
                                <span className="font-weight-bold small">{stockOnHand}</span>
                            </>
                        )}
                    </div>
                ) : (
                    <div className="text-body">
                        <span className="small">Pcs per pack:</span>
                        <span className="font-weight-bold small px-2">{unitsPerPack}</span>
                    {!auth.isHideAvailability ? (
                        <>
                        <br/>
                        <span className="small">Packs available:</span>
                        <span className="font-weight-bold small px-2">{stockOnHand}</span>
                        </>
                    ): null
                    }
                    </div>
                )}
            </Grid>
            <Grid item xs={3} className={"text-center"}>
                <span
                    className="h5"
                    style={{color: quantity ? "black" : "#aaaaaa"}}
                >
                    {priceDefault} {currencySymbol}
                </span>
            </Grid>
            <Grid item xs={2} className={"text-center"}>
                <span
                    className="h5"
                    style={{color: quantity ? "black" : "#aaaaaa"}}
                >
                    {(quantity && (price * quantity).toFixed(2)) || priceDefault} {currencySymbol}
                </span>
            </Grid>
        </Grid>
    )
}
