import {BASE_PATH, PATH_BASE_EXTENSION_ADMIN} from "../../utils/constants";
import {getToken} from "../auth/token";

import axios from "axios";

const baseUrl = `${BASE_PATH}${PATH_BASE_EXTENSION_ADMIN}`;

export function apiInstall() {
    const config = {headers: { 'Authorization': `Bearer ${getToken()}`}};
    return axios.get(`${baseUrl}/doc/`, config)
}
