import Basic from "../../../containers/Admin/Layouts/Basic"
import ContainerManagement from "../../../containers/Admin/Warehouse/Management"

export default function Management() {
  return (
    <Basic menu="warehouse">
          <ContainerManagement />
    </Basic>
  )
}
