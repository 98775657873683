import "../../../../assets/userTheme/assets/css/theme.css";
import "../../../../assets/userTheme/assets/vendor/fontawesome/css/all.min.css";
import "../../../../assets/userTheme/assets/vendor/slick-carousel/slick/slick.css";
import Header from "../../../../components/User/Layout/header"
import Breadcrumb from "../../../../components/User/Layout/breadcrumb";
import MenuLeft from "../../../../components/User/Layout/menuLeft";
import "./style.css"
import {apiMaintenance} from "../../../../services/auth/maintenence";
import {useEffect} from "react";
import {useHistory} from "react-router-dom";
import {getToken} from "../../../../services/auth/token";
import {checkAuthDate} from "../../../../services/auth/authKey";
import useAuth from "../../../../hooks/useAuth";

export default function Frontend(props) {
    const { children, title, menu } = props
    const history = useHistory()
    const {auth} = useAuth();
    const {logout} = useAuth()

    useEffect(() => {
        apiMaintenance({}).then(resp => {
            if (resp.data?.isActive) {
                history.push("/maintenance")
            }
        })
    }, [])

    useEffect(() => {
        checkAuthDate({
            email: auth.email
        }).catch(err => {
            logout()
        })
    }, [])

    return (
        <div>
            <Header />

            <Breadcrumb title={title}/>

            <div className="container space-1 space-top-lg-0 space-bottom-lg-1 mt-lg-n10">
                <div className="row">
                    <div className="col-lg-3">
                        <div className="navbar-expand-lg navbar-expand-lg-collapse-block navbar-light">
                            <div id="sidebarNav" className="collapse navbar-collapse navbar-vertical">
                                <div className="card mb-5">
                                    <div className="card-body">
                                        <MenuLeft menu={menu}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-9">
                        <div className="card mb-3 mb-lg-5">
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
