import React, {useEffect, useMemo, useState} from "react"
import {getTransactionLog} from "../../../../../services/admin/transaction";
import { useParams } from "react-router-dom"
import moment from "moment";
import NewTable from "../../../../../components/Admin/NewTable"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import {DASH_MAX_ROW} from "../../../../../utils/constants";
import useAuth from "../../../../../hooks/useAuth"

export default function Management() {
    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(true)
    const [rowCount, setRowCount] = useState(0)
    const [state, setState] = useState(initialValues)
    const [msgError, setMsgError] = useState([])
    const { logout } = useAuth()
    const { id } = useParams()

    useEffect(() => {
        setLoading(true)
        const timeOutId = setTimeout(() => {
            getItemsData()
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [state])

    function tableColumns() {
        return [
            {
                field: "changeDate",
                headerName: "DATE & TIME",
                width: 200,
                flex: 1,
                sortable: true
            },
            {
                field: "idTransactionStatus",
                headerName: "STATUS",
                width: 150,
                flex: 1,
                sortable: true

            },
            {
                field: "description",
                headerName: "DESCRIPTION",
                width: 200,
                flex: 1,
                sortable: false
            },
            {
                field: "idUser",
                headerName: "USER",
                width: 150,
                flex: 1,
                sortable: true
            },

        ]
    }
    
    const getItemsData = () => {
        setMsgError([])
        let field = state.sort.field
        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let params = {
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort,
        }
        getTransactionLog(logout, params, id).then(resp => {
            setRowCount(resp.count)
            let temp = resp.data?.map(doc => {
                return {
                    id: doc.id,
                    idUser: doc.idUser.name + " " + doc.idUser.surnames,
                    idTransactionStatus: doc.idTransactionStatus.name,
                    changeDate: moment.unix(doc.changeDate).format("M/D/Y h:mm a"),
                    description: doc.description,
                }
            })
            setRows(temp)
            setLoading(false)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setRows([])
        })
    }



    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box border={1} className="box-default">
                        <NewTable
                            columns={tableColumns()}
                            rows={rows}
                            rowCount={rowCount}
                            filter={state}
                            setFilter={setState}
                            loading={loading}                            
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
    return {
        offset: 0,
        sort: { field: "changeDate", sort: "asc" },
    }
}
