import React, { useEffect, useState, useMemo } from "react";
import { listApi } from "../../../../services/admin/request";
import {importProductsApi, exportProductsApi} from "../../../../services/admin/product";
import useAuth from "../../../../hooks/useAuth";
import TableFilterBase, {SelectColumnFilter} from "../../../../components/Admin/TableFilterBaser";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import { TextField } from "@material-ui/core";
import MsgSuccess from "../../../../components/Admin/MsgSuccess";
import MsgError from "../../../../components/Admin/MsgError";
import Loading from "../../../../components/User/Loading";
import InputButtonLoading from "../../../../components/Inputs/InputButtonLoading";

export default function Fields() {

    const [company, setCompany] = useState(null)
    const { logout } = useAuth()
    const [file, setFile] = useState(null)
    const [msgError, setMsgError] = useState(null)
    const [msgSuccess, setMsgSuccess] = useState(null)
    const [loading, setLoading] = useState(true)
    const [loadingImport, setLoadingImport] = useState(false)
    const [loadingExport, setLoadingExport] = useState(false)
    const [ids, setIds] = useState([])

    const columns = useMemo(
        () => [
            {
                Header: "COMPANY",
                accessor: "companyName",
                Filter: SelectColumnFilter,
                filter: "includes",
            },
            {
                Header: "NAME",
                accessor: "name",
            },
            {
                Header: "DESCRIPTION",
                accessor: "description",
            },
            {
                Header: "CATALOG",
                accessor: "catalogName",
            },
            {
                Header: "PRODUCT CATEGORY",
                accessor: "categoryName",
            },
            {
                Header: "REFERENCE",
                accessor: "sort",
            },
            {
                Header: "ACTIVE",
                accessor: "active",
            },
            {
                Header: "ACTIONS",
                accessor: "actions",
                disableFilters: true
            },
        ],
        []
    )

    useEffect(() => {
        getProducts()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getProducts = async () => {
        setLoading(true)
        const response = await listApi(logout, "product");
        if (response.status) {
            const temp = response.data.map((doc) => {
            
                let href = `/admin/product/edit/${doc.id}/1`;

                return {
                    id: doc.id,
                    active: doc.isActive == '1' ? "Yes" : "No",
                    actions: (
                        <Link color="primary" href={href}>
                            Edit
                        </Link>
                    ),
                    ...doc,
                };
            });
            setCompany(temp)
            setLoading(false)
        }else
        {
            setLoading(false)
        }
        
    }
    
    const handleChangeFile = (event) => {
        if (event.target.files.length > 0) {
            setFile(event.target.files)
        } else {
            setFile(null)
        }
    }

    const downloadTemplate = () => {
        window.open("/import_products.xlsx", '_blank');
    }

    const importProducts = async () => {
        setMsgSuccess([])
        setMsgError([])
        setLoadingImport(true)

        let formData = new FormData()

        formData.append('excelFile', file[0])

       const response = await importProductsApi(logout, formData)

        if (response.status) {
            setLoadingImport(false)
            setMsgSuccess([response.data])
            getProducts()
        } else {
            setLoadingImport(false)
            setMsgError(response.errors)
        }

    }

    const onChangeProducts = (data) => {
        let temporalIds = []
        setIds([]);

        for (let i = 0; i < data.length; i++) {
            temporalIds.push(data[i].original.id)
        }

        setIds(temporalIds)
    }


    const exportProducts = async () => {
        setLoadingExport(true)
        const response = await exportProductsApi(logout, {"products": ids});

        if (response.status) {
            setLoadingExport(false)
            window.open(response.data, '_blank');
        }
        setLoadingExport(false)
    }


    if (!company) return ""

    return (
        <>
             <Grid container spacing={3} justifyContent={"space-between"}>
                <Grid item xs={6} md={6} lg={6}>
                    <h1>Maintain Products</h1>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                    <Grid container justifyContent="flex-end">
                        <Grid item xs={12} md={2} lg={2} style={{textAlign: "right"}}>
                            <InputButtonLoading
                                    onClick={exportProducts}
                                    disabled={loading}
                                    label={'EXPORT'}
                                    loading={loadingExport}
                                    style={{width:"100%", margin: "20px 0px"}}
                                />
                        </Grid>
                        <Grid item xs={12} md={2} lg={2} style={{textAlign: "right"}}>
                            <Button
                                variant="contained"
                                color="primary"
                                style={{ float: "right", margin: "20px 0px" }}
                                href="/admin/product/create"
                            >
                                + add
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={3} style={{marginTop:'0', marginBottom:'0px'}}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={3}>
                            <div style={{ marginRight: "20px" }}>
                                <TextField
                                    variant="outlined"
                                    label="Import products"
                                    name="excelFile"
                                    size="small"
                                    type="file"
                                    onChange={handleChangeFile}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <div style={{ marginRight: "20px" }}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                    disabled={file === null}
                                    onClick={importProducts}
                                    fullWidth
                                >
                                    Import
                                </Button>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="button"
                                onClick={downloadTemplate}
                                fullWidth
                            >
                                Download template
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <MsgSuccess msg={msgSuccess}/>
                    <MsgError msg={msgError}/>
                    <Loading show={loadingImport} title=""/>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                { (company && !loading) && (
                    <Box border={1} className="box-default">
                        <TableFilterBase
                            columns={columns}
                            data={company}
                            selectedRows={company}
                            onSelectedRowsChange={setCompany}
                            onChangeContent={onChangeProducts}
                            search={false}
                            selectable={false}
                        />
                    </Box>
                 )}
                </Grid>
            </Grid>
        </>
    );
}
