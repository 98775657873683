import Basic from "../../../containers/Admin/Layouts/Basic"
import ComponentEdit from "../../../containers/Admin/ShippingCharge/Fields"

export default function Edit() {
  return (
    <Basic menu="shipping-charge">
          <ComponentEdit />
    </Basic>
  )
}
