import React, {useEffect, useState} from "react";
import useAuth from "../../../../../hooks/useAuth";
import {useHistory, useParams} from "react-router-dom";
import {getTransaction} from "../../../../../services/user/transaction";
import {listApi} from "../../../../../services/user/request"
import {listApi as listProductsApi} from "../../../../../services/user/product"
import {TextField} from "@material-ui/core";
import ProductBox from "../ProductBox"
import Footer from "../Footer";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@mui/styles";
import Loading from "../../../../../components/User/Loading";
import {getCatalogByCompanyApi} from "../../../../../services/user/catalog";

const useStyles = makeStyles({
    root: {
        position: "sticky",
        top: 0,
        zIndex: 100,
        backgroundColor: "white",
        marginBottom: 20,
        width: "100%",
        marginTop: 5,
        paddingTop: 7
    },

});

export default function UpdateOrder(props) {
    const classes = useStyles();

    const {logout, auth} = useAuth();
    const history = useHistory();

    const {id} = useParams();

    const {
        setTotalProducts,
        setTotalCartProducts,
        totalProducts,
        isViewCart
    } = props

    const [loading, setLoading] = useState(true)
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [productCategories, setProductCategories] = useState([]);
    const [productCatalogs, setProductCatalogs] = useState([]);
    const [productCategorySelected, setProductCategorySelected] = useState(null)
    const [productCatalogSelected, setProductCatalogSelected] = useState(null)
    const [details, setDetails] = useState(null);

    useEffect(() => {
        (async () => {
            await getTransactionDetails()
            await searchCategories()
            await searchProductCatalog()
        })()

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        (async () => {
            if (details !== null) {
                await searchProduct()
            }
        })()
    }, [details, productCategorySelected, productCatalogSelected]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        (async () => {
            const cart = JSON.parse(localStorage.getItem("order_products"));

            const temp = products.map((prod) => {

                const quantity = cart?.find(prodCart => prod.id === prodCart.id)?.quantity || ""

                return {
                    ...prod,
                    quantity: quantity
                }
            })

            await setProducts([])
            setProducts(temp)
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalProducts, isViewCart])


    const getTransactionDetails = async () => {
        if (!["ROLE_COMPANY_MANAGER", "ROLE_REGION_MANAGER"].includes(auth.role)) {
            history.push(`/dash/order-history/details/${id}`)
        }

        const responseTransaction = await getTransaction(logout, id);

        if (responseTransaction.status) {
            setDetails(responseTransaction.data.details)
            setSelectedProducts(responseTransaction.data.products)
            createOrder(responseTransaction.data.products);
        }
    }

    const searchCategories = async () => {
        const categories = await listApi(logout, 'product-category');

        if (categories.status) {
            setProductCategories(categories.data)
        }
    }

    const searchProductCatalog = async () => {
        const response = await getCatalogByCompanyApi(logout, auth.companyId);
        if (response.status) {
            const temp = response.data.map((doc) => {
                return {
                    id: doc.id,
                    ...doc,
                }
            })
            setProductCatalogs(temp)
        }
    }

    const searchProduct = async () => {
        setLoading(true)

        setProducts([])
        let idRegion = details.region_id
        let idLocation = details.location_id

        const response = await listProductsApi(logout, {
            idRegion: idRegion,
            idLocation: idLocation,
            productCategory: productCategorySelected?.id,
            productCatalog: productCatalogSelected?.id
        })

        setProducts(response.data)
        setTotalProducts(response.data.length)
        calculatePrice()
    }

    const calculatePrice = () => {

        const order = JSON.parse(localStorage.getItem('order_products'))

        let tempPrice = []
        products?.forEach(item => {
            order?.forEach(storage => {
                if (storage.id === item.id && !storage.delete) {
                    if (!tempPrice[item.currency_symbol] && item.priceDefault) {
                        tempPrice[item.currency_symbol] = null
                    }
                    tempPrice[item.currency_symbol] = tempPrice[item.currency_symbol] + (item.priceDefault * storage.quantity)
                }
            })
        })

        setTotalPrice(tempPrice)
        setLoading(false)

    }

    const handleChangeProductCategory = (event, value) => {
        setProductCategorySelected(null)

        if (value) {
            setProductCategorySelected(value)
        }
    }

    const handleChangeProductCatalog = (event, value) => {
        setProductCatalogSelected(null)

        if (value) {
            setProductCatalogSelected(value)
        }
    }

    const number_format = (user_input) => {

        for (const property in user_input) {
            user_input[property] = parseFloat(user_input[property]).toFixed(2)
        }

        return user_input;

    }

    const createOrder = (transactionProducts) => {

        let order = []

        transactionProducts.forEach(product => {
            order.push({
                id: product.idProduct,
                quantity: product.quantity,
                packsPrice: `${product.priceDefault} ${product.currencySymbol}`,
                image: product.image ?? "https://ows-idp-packaging.s3.eu-west-3.amazonaws.com/product/no_available.png",
                name: product.name,
                description: product.description,
                category: product.category,
                sort: product.sort,
                price: product.totalPrice,
                priceDefault: product.priceDefault,
                unit: product.unit,
                unitsPerPack: product.unitsPerPack,
                stockOnHand: product.stockOnHand,
                currencySymbol: product.currencySymbol,
                delete: false
            })
        })

        localStorage.setItem("order_products", JSON.stringify(order))

    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <div className="text-body pb-2">
                        <span className="small">Company:</span>
                        <span className="font-weight-bold small px-2">
                            {details?.company_name}
                        </span>
                    </div>
                    <div className="text-body pb-2">
                        <span className="small">User Name:</span>
                        <span className="font-weight-bold small px-2">
                            {details?.user_name} {details?.user_surnames}
                        </span>
                    </div>

                    <div className="text-body pb-2">
                        <span className="small">Store:</span>
                        <span className="font-weight-bold small px-2">
                            {details?.location_name}
                        </span>
                    </div>

                    <div className="text-body pb-2">
                        <span className="small">Address:</span>
                        <span className="font-weight-bold small px-2">
                            <span>
                                {details?.location_address1}
                                {details?.location_address2 && (
                                    <span>, <br/></span>
                                )}
                            </span>
                            <span>
                                {details?.location_address2}
                                {details?.location_address3 && (
                                    <span>, <br/></span>
                                )}
                            </span>
                            <span>
                                {details?.location_address3}
                                {details?.location_address4 && (
                                    <span>, <br/></span>
                                )}
                            </span>
                            <span>
                                {details?.location_address4}
                                {details?.location_address5 && (
                                    <span>, <br/></span>
                                )}
                            </span>
                            <span>{details?.location_address5}</span>
                        </span>
                    </div>

                    <div className="text-body pb-2">
                        <span className="small">Store Email:</span>
                        <span className="font-weight-bold small px-2">
                            {details?.location_emails}
                        </span>
                    </div>

                    <div className="text-body pb-2">
                        <span className="small">Store Tel:</span>
                        <span className="font-weight-bold small px-2">
                            {details?.location_phone}
                        </span>
                    </div>
                </Grid>

                <Grid item xs={12} md={6}>
                    <div className="text-body pb-2">
                        <span className="small">Order Date:</span>
                        <span className="font-weight-bold small px-2">
                            {/*{dateOrder}*/}
                        </span>
                    </div>

                    <div className="text-body pb-2">
                        <span className="small">No of Packs:</span>
                        <span className="font-weight-bold small px-2">
                            {details?.numberOfPacks}
                        </span>
                    </div>

                    <div className="text-body pb-2">
                        <span className="small">Order No:</span>
                        <span className="font-weight-bold small px-2">
                            {details?.transaction_id}
                        </span>
                    </div>
                    <div className="text-body pb-2">
                        <span className="small">Status:</span>
                        <span className="font-weight-bold small px-2">
                            {details?.status_name}
                        </span>
                    </div>

                    <div className="text-body pb-2">
                        <span className="small">Grand total:</span>
                        <span className="font-weight-bold small px-2">
                            {details?.price}
                        </span>
                    </div>

                    <div className="text-body pb-2">
                        <span className="small">Num REF/PO#:</span>
                        <span className="font-weight-bold small px-2">
                            {details?.transaction_ref}
                        </span>
                    </div>
                    <div className="text-body pb-2">
                        <span className="small">Num REF/PO# (TOV):</span>
                        <span className="font-weight-bold small px-2">
                            {details?.transaction_refTov}
                        </span>
                    </div>
                    <div className="text-body pb-2">
                        <span className="small">Comment:</span>
                        <span className="font-weight-bold small px-2">
                            {details?.transaction_comment}
                        </span>
                    </div>
                </Grid>
                <hr/>
            </Grid>
            <div className={classes.root}>
                <Grid container>
                    <Grid item xs={12} md={12} style={{marginBottom: 25}}>
                        <Autocomplete
                            options={productCategories}
                            getOptionLabel={(option) =>
                                option.name
                            }
                            getOptionSelected={(
                                option,
                                value
                            ) => option.value === value.value}
                            value={productCategorySelected}
                            onChange={handleChangeProductCategory}
                            fullWidth
                            size="small"
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Product Category "
                                    variant="outlined"
                                    name="idProductCategory"
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}
                        />
                        <Grid item xs={12} md={12} style={{marginBottom: 25, marginTop: 25}}>
                            <Autocomplete
                                options={productCatalogs}
                                getOptionLabel={(option) =>
                                    option.name
                                }
                                getOptionSelected={(
                                    option,
                                    value
                                ) => option.value === value.value}
                                value={productCatalogSelected}
                                onChange={handleChangeProductCatalog}
                                fullWidth
                                size="small"
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Product Catalog "
                                        variant="outlined"
                                        name="idProductCatalog"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container style={{backgroundColor: "#f1f2f6", borderRadius: 10, padding: 10}}>
                            <Grid item xs={2} className={"text-center"}>
                                <span className={"h5"}>Image</span>
                            </Grid>
                            <Grid item xs={3} className={"text-center"}>
                                <span className={"h5"}>Item Description</span>
                            </Grid>
                            <Grid item xs={2} className={"text-center"}>
                                <span className={"h5"}>Quantity</span>
                            </Grid>
                            <Grid item xs={3} className={"text-center"}>
                                <span className={"h5"}>Pack price</span>
                            </Grid>
                            <Grid item xs={2} className={"text-center"}>
                                <span className={"h5"}>Total Price</span>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            <Loading show={loading} title="Fetching products..."/>
            <Grid item xs={12}>
                <ul className="list-group list-group-flush list-group-no-gutters">
                    {products?.map((doc, key) => {

                        let quantity = ""

                        if (selectedProducts.find((product) => product.idProduct === doc.id) !== undefined) {
                            quantity = selectedProducts.find((product) => product.idProduct === doc.id)?.quantity
                        }

                        return (
                            <li key={key} className="list-group-item">
                                <ProductBox
                                    id={doc.id}
                                    name={doc.name}
                                    description={doc.description}
                                    price={doc.price}
                                    priceDefault={doc.priceDefault}
                                    category={doc.category_name}
                                    image={doc.image}
                                    currencySymbol={doc.currency_symbol}
                                    unitsPerPack={doc.unitsPerPack}
                                    stockOnHand={doc.stockOnHand}
                                    quantityDefault={quantity}
                                    unitDefault={doc.unit}
                                    quantityIsModify={true}
                                    setTotalCartProducts={setTotalCartProducts}
                                    calculatePrice={calculatePrice}
                                />
                            </li>
                        )
                    })}
                </ul>
            </Grid>
            <Grid item xs={12}>
                <Footer
                    totalPrice={number_format(totalPrice)}
                    id={id}
                />
            </Grid>

        </>
    )
}
