import Basic from "../../../containers/Admin/Layouts/Basic"
import LogNetSuite from "../../../containers/Admin/Log/LogNetSuite"

export default function Management() {
    return (
        <Basic menu="logNetSuite">
            <LogNetSuite />
        </Basic>
    )
}
