import React from "react";
import {TextField} from "@mui/material";
import Autocomplete from "@material-ui/lab/Autocomplete";

/**
 * Muestra un input de texto
 *
 * ## PROPS
 * - **label** *string*
 * - **name** *string*
 * - **value** *string*
 * - **options** *array*
 * - **onChange** *function*
 * - **disabled** *bool*
 */
export default function InputAutocomplete(props) {

    const {
        label,
        name,
        value,
        options,
        onChange,
        disabled
    } = props

    const disabledIn = disabled ? disabled : false

    return (
        <Autocomplete
            options={options}
            getOptionLabel={(option) => option.name ? option.name : ""}
            getOptionSelected={(option, value) =>
                option.value === value.value
            }
            fullWidth
            value={value}
            onChange={onChange}
            disabled={disabledIn}
            size="small"
            InputLabelProps={{shrink: true}}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    name={name}
                    InputLabelProps={{shrink: true}}
                />
            )}
        />
    )
}
