import Basic from "../../../containers/Admin/Layouts/Basic";
import ContainerManagement from "../../../containers/Admin/StoreCategory/Management";

export default function Management() {
    return (
        <Basic menu="storeCategory">
            <ContainerManagement />
        </Basic>
    );
}
