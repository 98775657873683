import useAuth from "../../../hooks/useAuth";
import React, {useEffect, useState} from "react";
import {listApi} from "../../../services/user/request";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import {makeStyles} from "@mui/styles";
import Button from "@material-ui/core/Button";
import moment from "moment";
import LoadingBar from "../../Admin/LoadingBar";
import Loading from "../Loading";
import {LoadingButton} from "@mui/lab";

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginBottom: 20,
    },
}));

export default function Header(props) {


    const {
        regionSelected,
        subregionSelected,
        countrySelected,
        locationSelected,
        locationCategorySelected,

        setRegionSelected,
        setCountrySelected,
        setSubregionSelected,
        setLocationSelected,
        setDateFromSelected,
        setDateToSelected,
        setLocationCategorySelected,

        clickExport,
        loading,
        setLoading,
        loadingExport

    } = props

    const classes = useStyles()

    const [region, setRegion] = useState([])
    const [subregion, setSubregion] = useState([])
    const [country, setCountry] = useState([])
    const [location, setLocation] = useState([])
    const [locationCategory, setLocationCategory] = useState([])

    const [filtersDisable, setFiltersDisable] = useState(filterDisable)

    const {logout, auth} = useAuth()

    const roleName = auth ? auth.role : null;

    useEffect(() => {
        (async () => {
            let tempDisable = filtersDisable
            setLoading(true)
            switch (roleName) {
                case "ROLE_MULTI_STORE":
                    await handleChangeRegion(null, {id: auth.region.id})
                    await handleChangeSubregion(null, {}, auth.region.id)
                    await handleChangeCountry(null, {id: auth.country?.id ?? null}, auth.region?.id, auth.subregion?.id)
                    break;
                case "ROLE_STOREG":
                    await handleChangeRegion(null, {id: auth.region.id})
                    await handleChangeSubregion(null, {id: null}, auth.region.id)
                    await handleChangeCountry(null, {id: auth.country?.id}, {}, auth.country.id)
                    break;
                case "ROLE_REGION":
                    await handleChangeRegion(null, {id: auth.region?.id})
                    await handleChangeSubregion(null, {id: null}, auth.region?.id)
                    await handleChangeCountry(null, {id: auth.country?.id}, {}, auth.country?.id)
                    break;
                case "ROLE_REGION_MANAGER":
                    await handleChangeRegion(null, {id: auth.region.id})
                    if (auth.subregion?.id) {
                        await handleChangeSubregion(null, {name: auth.subregion.name, id: auth.subregion.id}, auth.region?.id)
                        tempDisable.subregion = true
                    }

                    if (auth.country?.id) {
                        await handleChangeCountry(null, {name: auth.country.name, id: auth.country.id})
                        tempDisable.country = true
                    }

                    if (auth.location?.id) {
                        await handleChangeLocation(null, {name: auth.location.name, id: auth.location.id})
                        tempDisable.location = true
                    }
                    break;
                default:
                    const response = await listApi(logout, "region");
                    if (response.status) {
                        setRegion(response.data)
                    }
                    break;
            }
            setFiltersDisable(tempDisable)
            setLoading(false)
            const response = await listApi(logout, "location-category");

            if (response.status) {

                const data = response.data;

                setLocationCategory(data)

            }
        })()

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleChangeRegion = async (event, value) => {
        setSubregionSelected(null)
        setCountrySelected(null)
        setLocationSelected(null)
        setCountry([])

        if (value) {
            setRegionSelected(value)

            // Buscar Subregion
            const response = await listApi(logout, `subregion`, {idRegion: value.id});
            if (response.status) {
                setSubregion(response.data)
            } else {
                setSubregion([])
            }
            // Activar Subregion
        } else {
            setRegionSelected(null)
        }

    }

    const handleChangeSubregion = async (event, value, regionId) => {
        setSubregionSelected(null)
        setCountrySelected(null)
        setLocationSelected(null)

        if (value) {
            setSubregionSelected(value)

            // Buscar Loaction
            const response = await listApi(logout, `location/countries`, {
                idRegion: regionSelected?.id ?? regionId,
                idSubregion: value?.id
            });
            if (response.status) {
                setCountry(response.data)
            } else {
                setCountry([])
            }

        } else {
            setSubregionSelected(null)
        }
    }

    const handleChangeCountry = async (event, value, regionId, subregionId) => {
        setCountrySelected(null)
        setLocationSelected(null)

        if (value) {
            setCountrySelected(value)

            // Buscar Loaction
            const response = await listApi(logout, `location`, {
                idRegion: regionSelected?.id ?? regionId,
                idSubregion: subregionSelected?.id ?? subregionId,
                idCountry: value?.id
            });
            if (response.status) {
                setLocation(response.data)
            } else {
                setLocation([])
            }

        } else {
            setCountrySelected(null)
        }
    }

    const handleChangeLocation = async (event, value) => {
        setLocationSelected(null)

        if (value) {
            setLocationSelected(value)
        }
    }

    const handleChangeDate = async (event, value) => {
        let date = moment(event.target.value)
        if (event.target.id === "dateFrom") {
            setDateFromSelected(moment(date).format("X"))
        }

        if (event.target.id === "dateTo") {
            setDateToSelected(moment(date).format("X"))
        }
    }

    const handleChangeLocationCategory = (event, value) => {
        setLocationCategorySelected(value)
    }

    return (
        <div className="row">

            {["ROLE_STOREG", "ROLE_MULTI_STORE", "ROLE_COMPANY_MANAGER", "ROLE_REGION_MANAGER"].includes(roleName) && (
                <>
                    <div className="col-lg-4 col-6">
                        <FormControl
                            variant="outlined"
                            size="small"
                            fullWidth
                            className={classes.formControl}
                        >
                            <OutlinedInput
                                inputvariant="outlined"
                                id="dateFrom"
                                type="date"
                                placeholder="From"
                                size="small"
                                onChange={handleChangeDate}
                                sx={{
                                    "& label.Mui-focused": {
                                        display: "none"
                                    },
                                    "& legend": {
                                        display: "none"
                                    }
                                }}
                            />
                        </FormControl>
                    </div>
                    <div className="col-lg-4 col-6">
                        <FormControl
                            variant="outlined"
                            size="small"
                            fullWidth
                            className={classes.formControl}
                        >
                            <OutlinedInput
                                inputvariant="outlined"
                                id="dateTo"
                                type="date"
                                placeholder="To"
                                onChange={handleChangeDate}
                                size="small"
                                sx={{
                                    "& label.Mui-focused": {
                                        display: "none"
                                    },
                                    "& legend": {
                                        display: "none"
                                    }
                                }}
                            />
                        </FormControl>
                    </div>
                </>
            )}

            <div className="col-lg-4 col-6">
                <LoadingButton
                    type="button"
                    loading={loadingExport}
                    loadingPosition="start"
                    variant="contained"
                    fullWidth
                    onClick={clickExport}
                >
                    Export (.xlsx)
                </LoadingButton>
            </div>

            {["ROLE_COMPANY_MANAGER", "ROLE_REGION_MANAGER"].includes(roleName) && (
                <>
                    {["ROLE_COMPANY_MANAGER"].includes(roleName) && (
                        <div className="col">
                            <Autocomplete
                                options={region}
                                getOptionLabel={(option) => option.name || ""}
                                getOptionSelected={(option, value) =>
                                    option.value === value.value
                                }
                                value={regionSelected}
                                onChange={handleChangeRegion}
                                disabled={filtersDisable.region}
                                fullWidth
                                size="small"
                                InputLabelProps={{shrink: true}}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Zone "
                                        variant="outlined"
                                        name="idRegion"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                )}
                            />
                        </div>
                    )}

                    <div className="col">
                        <Autocomplete
                            options={subregion}
                            getOptionLabel={(option) => option.name || ""}
                            getOptionSelected={(option, value) =>
                                option.value === value.value
                            }
                            onChange={handleChangeSubregion}
                            fullWidth
                            disabled={filtersDisable.subregion}
                            value={subregionSelected}
                            size="small"
                            InputLabelProps={{shrink: true}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Region "
                                    variant="outlined"
                                    name="idSubregion"
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}
                        />
                    </div>

                    <div className="col">
                        <Autocomplete
                            options={country}
                            getOptionLabel={(option) => option.name || ""}
                            getOptionSelected={(option, value) =>
                                option.value === value.value
                            }
                            onChange={handleChangeCountry}
                            fullWidth
                            disabled={filtersDisable.country}
                            value={countrySelected}
                            size="small"
                            InputLabelProps={{shrink: true}}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Country "
                                    variant="outlined"
                                    name="idCountry"
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}
                        />
                    </div>
                </>
            )}

            {["ROLE_MULTI_STORE", "ROLE_COMPANY_MANAGER", "ROLE_REGION_MANAGER"].includes(roleName) && (
                <div className="col">
                    <Autocomplete
                        options={location}
                        getOptionLabel={(option) => option.name || ""}
                        getOptionSelected={(option, value) =>
                            option.value === value.value
                        }
                        fullWidth
                        value={locationSelected}
                        disabled={filtersDisable.location}
                        onChange={handleChangeLocation}
                        size="small"
                        InputLabelProps={{shrink: true}}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Store "
                                variant="outlined"
                                name="idLocation"
                                InputLabelProps={{ shrink: true }}
                            />
                        )}
                    />
                </div>
            )}

            {["ROLE_COMPANY_MANAGER", "ROLE_REGION_MANAGER"].includes(roleName) && (
                <div className="col">
                    <Autocomplete
                        options={locationCategory}
                        getOptionLabel={(option) => option.name || ""}
                        getOptionSelected={(option, value) =>
                            option.value === value.value
                        }
                        value={locationCategorySelected}
                        onChange={handleChangeLocationCategory}
                        fullWidth
                        size="small"
                        InputLabelProps={{shrink: true}}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Store Category "
                                variant="outlined"
                                name="idLocationCategory"
                                InputLabelProps={{ shrink: true }}
                            />
                        )}
                    />
                </div>
            )}
        </div>
    )
}

function filterDisable() {

    return {
        region: false,
        subregion: false,
        country: false,
        location: false
    }

}
