import React, { useState} from "react";
import Header from "../../../../components/User/Reports/Header";
import Table from "./Table";
import {listApi} from "../../../../services/user/request";
import useAuth from "../../../../hooks/useAuth";
import Loading from "../../../../components/User/Loading";
export default function Reports() {

    const { logout } = useAuth()

    const [regionSelected, setRegionSelected] = useState(null)
    const [subregionSelected, setSubregionSelected] = useState(null)
    const [countrySelected, setCountrySelected] = useState(null)
    const [locationSelected, setLocationSelected] = useState(null)
    const [dateFromSelected, setDateFromSelected] = useState(null)
    const [dateToSelected, setDateToSelected] = useState(null)
    const [locationCategorySelected, setLocationCategorySelected] = useState(null)
    const [loading, setLoading] = useState(true)
    const [loadingExport, setLoadingExport] = useState(false)

    const clickExport = async () => {
        setLoadingExport(true)
        const response = await listApi(logout, `report/product`, {
            idRegion: regionSelected?.id,
            idSubregion: subregionSelected?.id,
            idCountry: countrySelected?.id,
            idLocation: locationSelected?.id,
            dateFrom: dateFromSelected,
            dateTo: dateToSelected,
            idLocationCategory: locationCategorySelected?.id,
            export: true
        });
        if (response.status){
            setLoadingExport(false)
            window.open(response.data, '_blank');
        } else {
            setLoadingExport(false)
        }
    }

    return (
        <>
            <div className="card-body">

                <Header
                    regionSelected={regionSelected}
                    subregionSelected={subregionSelected}
                    countrySelected={countrySelected}
                    locationSelected={locationSelected}
                    dateFromSelected={dateFromSelected}
                    dateToSelected={dateToSelected}
                    locationCategorySelected={locationCategorySelected}

                    setRegionSelected={setRegionSelected}
                    setSubregionSelected={setSubregionSelected}
                    setCountrySelected={setCountrySelected}
                    setLocationSelected={setLocationSelected}
                    setDateFromSelected={setDateFromSelected}
                    setDateToSelected={setDateToSelected}
                    setLocationCategorySelected={setLocationCategorySelected}
                    loading={loading}
                    setLoading={setLoading}
                    loadingExport={loadingExport}
                    setLoadingExport={setLoadingExport}

                    clickExport={clickExport}
                />

                <br/>

                <Table
                    regionSelected={regionSelected}
                    subregionSelected={subregionSelected}
                    countrySelected={countrySelected}
                    locationSelected={locationSelected}
                    dateFromSelected={dateFromSelected}
                    dateToSelected={dateToSelected}
                    locationCategorySelected={locationCategorySelected}
                    loading={loading}
                    setLoading={setLoading}
                />

            </div>
        </>
    )

}
