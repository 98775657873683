import React from "react";
import {TextField} from "@mui/material";

/**
 * Muestra un input de texto
 *
 * ## PROPS
 * - **label** *string*
 * - **name** *string*
 * - **value** *string*
 * - **onChange** *function*
 * - **required** *bool*
 */
export default function InputRadio(props) {

    const {onChange, name, value, checked, label, disabled} = props

    const disabledIn = disabled ? disabled : false

    const color = '#ef6f28'

    return (
        <>
            <label className={`input-radio-label ${disabledIn ? 'input-radio-label-disabled' : ''}`}>
                <input
                    type="radio"
                    name={name}
                    value={value}
                    onChange={onChange}
                    checked={checked}
                    disabled={disabledIn}
                />
                {label}
            </label>

            <style>
                {`
                    .input-radio-label {
                          line-height: 1.1;
                          display: grid;
                          grid-template-columns: 1em auto;
                          gap: 0.5em;
                          margin-top: 20px;
                          margin-bottom: 20px;
                    }
                    
                    .input-radio-label-disabled {
                        opacity: 0.5;
                    }

                    input[type="radio"] {
                      /* Add if not using autoprefixer */
                      -webkit-appearance: none;
                      /* Remove most all native input styles */
                      appearance: none;
                      /* For iOS < 15 */
                      background-color: var(--form-background);
                      /* Not removed via appearance */
                      margin: 0;
                    
                      font: inherit;
                      color: #000;
                      width: 1.15em;
                      height: 1.15em;
                      border: 0.15em solid currentColor;
                      border-radius: 50%;
                      transform: translateY(-0.075em);
                    
                      display: grid;
                      place-content: center;
                    }
                    
                    input[type="radio"][disabled] {
                        opacity: 0.9 !important;
                    }
                   
                    input[type="radio"]::before {
                      content: "";
                      width: 0.65em;
                      height: 0.65em;
                      border-radius: 50%;
                      transform: scale(0);
                      transition: 120ms transform ease-in-out;
                      box-shadow: inset 1em 1em var(--form-control-color);
                      /* Windows High Contrast Mode */
                      background-color: ${color};
                    }
                    
                    input[type="radio"]:checked::before {
                      transform: scale(1);
                    }
                    
                `}
            </style>
        </>
    )
}
