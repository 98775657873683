import Basic from "../containers/User/Layouts/Basic"
import Container from "../containers/User/Maintenance"

export default function Maintenance() {
    return (
        <Basic>
            <Container/>
        </Basic>
    )
}
