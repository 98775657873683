import Basic from "../../../containers/Admin/Layouts/Basic"
import PoManagement from "../../../containers/Admin/Po/Fields"

export default function Management() {
    return (
        <Basic menu="product">
            <PoManagement />
        </Basic>
    )
}
