import * as React from 'react';
import {ThemeProvider} from '@mui/material/styles';
import {Box} from '@mui/material';
import logo from "../../../assets/images/logo.png";
import {useState} from "react";
import {checkAuthKey, sendAuthKey} from "../../../services/auth/authKey";
import MsgError from "../../../components/User/MsgError";
import MsgSuccess from "../../../components/User/MsgSuccess";

export default function Verification(props) {

    const {email, setVerificationCodeStatus} = props
    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [authKeyUser, setAuthKeyUser] = useState("")

    const handleSubmit = () => {
        checkAuthKey({
            authKeyUser,
            email
        }).then(resp => {
            setVerificationCodeStatus(true)
        }).catch(err => {
            setVerificationCodeStatus(false)
            setMsgError([err.response.data.msg])
        })
    }

    const handleSendAuthKey = () => {
        sendAuthKey({
            email
        }).then(resp => {
            setMsgSuccess([resp.data.msg]);
        }).catch(err => {
            setMsgError([err.response.data.msg])
        })
    }

    return (
        <ThemeProvider >
            <Box sx={{display: 'flex'}}>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: '#ffffff',
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <div style={{"height": "20vh"}}></div>

                    <MsgError msg={msgError}/>
                    <MsgSuccess msg={msgSuccess}/>

                    <div className="mb-3 mb-md-7">
                        <h1 className="h2">Verification Code</h1>
                        <p>In order to continue using IDP WOT, it is necessary to verify your e-mail. Please check your email inbox and enter the received code.</p>
                    </div>

                    <div className="js-form-message form-group">
                        <label
                            className="input-label"
                        >
                            <span className="d-flex justify-content-between align-items-center">
                                Enter the verification code
                                <a
                                    className="link-underline font-weight-normal"
                                    href="#"
                                    onClick={handleSendAuthKey}
                                >
                                    Resend verification code to {email}
                                </a>
                            </span>
                        </label>
                        <input
                            type="text"
                            value={authKeyUser}
                            onChange={(e) => setAuthKeyUser(e.target.value)}
                            className="form-control"
                        />
                    </div>

                    <div className="row align-items-center mb-5">
                        <div className="col-sm-6 mb-3 mb-sm-0"></div>

                        <div className="col-sm-6 text-sm-right">
                            <button
                                className="btn btn-primary transition-3d-hover"
                                style={{minWidth: 150}}
                                onClick={handleSubmit}
                            >
                                Verify
                            </button>
                        </div>
                    </div>
                </Box>
            </Box>
        </ThemeProvider>
    )
}
