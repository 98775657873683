import React, {useEffect, useState} from "react";
import {apiLogMail, apiLogNetSuite} from "../../../services/admin/log";
import moment from "moment";
import {Box, Chip, Grid} from "@mui/material";
import HeaderPage from "../../../components/Admin/HeaderPage";
import Table from "../../../components/Admin/NewTable";
import {DASH_MAX_ROW} from "../../../utils/constants";
import InputText from "../../../components/Inputs/InputText";
import {listApi} from "../../../services/admin/request";
import InputSelect from "../../../components/Admin/Inputs/InputSelect";
import useAuth from "../../../hooks/useAuth";

export default function LogMail() {
    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(true)
    const [rowCount, setRowCount] = useState(0)
    const [state, setState] = useState(initialValues)

    useEffect(() => {
        setLoading(true)
        const timeOutId = setTimeout(() => {
            getItemsData()
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [state])

    const getItemsData = () => {
        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let field = state.sort.field

        let params = {
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort
        }

        if (state.email) { params.email = state.email }
        if (state.companyName) { params.companyName = state.companyName }
        if (state.transactionGroupNumber) { params.transactionGroupNumber = state.transactionGroupNumber }
        if (state.customerRefPo) { params.customerRefPo = state.customerRefPo }

        apiLogNetSuite(params).then(resp => {
            setRowCount(resp.data?.count)

            let temp = resp.data.data?.map(doc => {
                return {
                    ...doc,
                    creationDate: moment.unix(doc.creationDate).utc().format('DD-MMM-YYYY HH:mm A'),
                    fileResponse: (
                        <>
                            <a href={doc.fileResponse} target="_blank" rel="noreferrer">Response</a>
                        </>
                    ),
                    fileUpload: (
                        <>
                            <a href={doc.fileUpload} target="_blank" rel="noreferrer">Upload</a>
                        </>
                    ),
                    isFinish: doc.isFinish ? (
                        <Chip label="Finish" color="primary" size="small" />
                    ) : (
                        <></>
                    ),
                    isResponseError: doc.isResponseError ? (
                        <Chip label="Error" color="error" size="small" />
                    ) : (
                        <Chip label="Success" color="success" size="small" />
                    ),
                }
            })
            setRows(temp)
            setLoading(false)
        }).catch(err => {
            setLoading(false)
            setRows([])
        })
    }

    return (
        <>
            <HeaderPage title="NetSuite" />

            <Grid container spacing={1}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box border={1} className="box-default">
                        <Filters {...{state, setState}}/>

                        <Table
                            columns={tableColumns()}
                            rows={rows}
                            rowCount={rowCount}
                            filter={state}
                            setFilter={setState}
                            loading={loading}
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

function Filters({state, setState}) {
    const {logout} = useAuth()

    const [company, setCompany] = useState([])
    const [loadingCompany, setLoadingCompany] = useState(true)

    const handleChange = (e) => {
        state[e.target.name] = e.target.value
        state["offset"] = 0

        if (e.target.name === "company") {
            // Al obtener el id vamos a buscar el nombre
            const { name } = company.find(({ id }) => id === e.target.value) || {}
            state["companyName"] = name
        }

        setState({...state})
    }

    const getCompany = async () => {
        const { status, data } = await listApi(logout, 'company/')
        setCompany(status ? data.map(({ id, name }) => ({ id, name })) : [])
        setLoadingCompany(false)
    }

    useEffect(() => {
        getCompany()
    }, [])

    return (
        <Grid container spacing={1} style={{padding: 10}}>
            <Grid item xs={3}>
                <InputText
                    label="Email"
                    name="email"
                    onChange={handleChange}
                    value={state?.email}
                />
            </Grid>
            <Grid item md={3}>
                <InputSelect
                    label="Company"
                    name="company"
                    onChange={handleChange}
                    value={state?.company}
                    options={company}
                    loading={loadingCompany}
                />
            </Grid>
            <Grid item xs={3}>
                <InputText
                    label="Order group number"
                    name="transactionGroupNumber"
                    onChange={handleChange}
                    value={state?.transactionGroupNumber}
                />
            </Grid>
            <Grid item xs={3}>
                <InputText
                    label="Customer Po/Ref."
                    name="customerRefPo"
                    onChange={handleChange}
                    value={state?.customerRefPo}
                />
            </Grid>
        </Grid>
    )
}

function initialValues(props) {
    return {
        offset: 0,
        sort: { field: "id", sort: "desc" },
        email: "",
        companyName: "",
        transactionGroupNumber: "",
        customerRefPo: "",
    }
}

function tableColumns() {
    return [
        {
            field: "email",
            headerName: "USER",
            sortable: false,
            flex: 1
        },
        {
            field: "companyName",
            headerName: "COMPANY",
            sortable: false,
            flex: 1
        },
        {
            field: "transactionGroupNumber",
            headerName: "ORDER GROUP NUMBER",
            sortable: false,
            flex: 1
        },
        {
            field: "customerRefPo",
            headerName: "CUSTOMER PO/Ref.",
            sortable: false,
            flex: 1
        },
        {
            field: "creationDate",
            headerName: "DATE",
            sortable: false,
            flex: 1
        },
        {
            field: "isFinish",
            headerName: "IS FINISH",
            sortable: false,
            flex: 1
        },
        {
            field: "fileUpload",
            headerName: "UPLOAD",
            sortable: false,
            flex: 1
        },
        {
            field: "fileResponse",
            headerName: "RESPONSE",
            sortable: false,
            flex: 1
        },
        {
            field: "isResponseError",
            headerName: "ERROR IN NETSUITE",
            sortable: false,
            flex: 1
        },
    ]
}
