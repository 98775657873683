import {BASE_PATH, PATH_BASE_EXTENSION, PATH_BASE_EXTENSION_ADMIN} from "../../utils/constants";
import { authFetch } from "../../utils/fetch";
import {getToken} from "../auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH}${PATH_BASE_EXTENSION_ADMIN}/warehouse`;

export function apiListProductsRelation(props) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()}`}};

    let query = "";
    if (props.idCompany) {
        if (query){ query += `&`; } else { query += `?`; }
        // si props.idCompany no es un array, lo pasamos directamente a la query
        if (!Array.isArray(props.idCompany)) {
            query += `idCompany=${props.idCompany}`;
        }else{
            props.idCompany.forEach(item => {
                query += `&idCompany[]=${item}`;
            })
        }

    }

    if (props.isCheckout) {
        if (query){ query += `&`; } else { query += `?`; }
        query += `isCheckout=${props.isCheckout}`
    }
    return axios.get(`${baseUrl}/product-relations/${props.idWarehouse}${query}`, config)
}

export async function listApi(logout) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/warehouse/`;
        const token = localStorage.getItem("token");
        const params = {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function createDeleteRelationProduct(formData) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/warehouse/select-product`;
        const token = localStorage.getItem("token");
        const params = {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(formData),
        };
        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function createDeleteRelationLocation(formData) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/warehouse/select-location`;
        const token = localStorage.getItem("token");
        const params = {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(formData),
        };
        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function createApi(formData) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/warehouse/create`;
        const token = localStorage.getItem("token");
        const params = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        };
        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function updateApi(logout, data) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/warehouse/update`;
        const token = localStorage.getItem("token");
        const params = {
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: data,
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function getWarehouseApi(logout, data) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/warehouse/${data.id}`;
        const token = localStorage.getItem("token");
        const params = {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}

/**
 * Lista comparativa de los productos del almacén y los productos de la tienda
 * @param logout
 * @param data
 * @returns {Promise<*|null>}
 */
export async function getProductWarehouseLocation(logout, props) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/warehouse/get-products-warehouse-location/${props.idWarehouse}/${props.idLocation}`;
        const token = localStorage.getItem("token");
        const params = {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}

/**
 * Realizamos la relación  o la eliminamos entre almacén, tienda y producto.
 * @param logout
 * @param data
 * @returns {Promise<*|null>}
 */
export async function setProductWarehouseLocation(logout, props) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/warehouse/set-products-warehouse-location`;
        const token = localStorage.getItem("token");
        const params = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(props),
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function exportWarehouseProductStockExportApi(logout, data) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/warehouse/product-stock/export`
        const token = localStorage.getItem("token")
        const params = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {

        return null;
    }
}

export async function getWarehouseCompanyRegionApi(logout, data) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/warehouse/company-region/${data.id}`;
        const token = localStorage.getItem("token");
        const params = {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}
