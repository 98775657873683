import axios from "axios";
import { BASE_PATH, PATH_BASE_EXTENSION_ADMIN } from "../../utils/constants";
import {getToken} from "../auth/token";

const baseUrl = `${BASE_PATH}${PATH_BASE_EXTENSION_ADMIN}/po`;

export function apiList(props) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()}`}};

    let query = "";
    if (props.idCompany) { query += `&idCompany=${props.idCompany}`; }
    if (props.idWarehouse) { query += `&idWarehouse=${props.idWarehouse}`; }
    if (props.semester) { query += `&semester=${props.semester}`; }
    if (props.name) { query += `&name=${props.name}`; }
    if (props.description) { query += `&description=${props.description}`; }


    return axios.get(`${baseUrl}/list?limit=${props.limit}&offset=${props.offset}&field=${props.field}&sort=${props.sort}${query}`, config)
}

export function apiListProducts(props) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()}`}};
    let query = "";

    if (props.idCompany) { query += `&idCompany=${props.idCompany}`; }
    if (props.idWarehouse) { query += `&idWarehouse=${props.idWarehouse}`; }
    if (props.statusId) { query += `&statusId=${props.statusId}`; }
    if (props.idpInvoice) { query += `&idpInvoice=${props.idpInvoice}`; }
    if (props.containerReference) { query += `&containerReference=${props.containerReference}`; }
    if (props.productName) { query += `&productName=${props.productName}`; }

    return axios.get(`${baseUrl}/list-products?limit=${props.limit}&offset=${props.offset}&field=${props.field}&sort=${props.sort}${query}`, config)
}

export function apiCreate(props) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()}`}};

    return axios.post(`${baseUrl}/create`, props , config)
}

export function apiUpdate(props) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()}`}};

    return axios.put(`${baseUrl}/update/${props.id}`, props , config)
}

export function apiListSemester(id) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()}`}};

    return axios.get(`${baseUrl}/list-semester`, config)
}

export function apiListStatus(id) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()}`}};

    return axios.get(`${baseUrl}/list-status`, config)
}

export function apiGetPo(id) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()}`}};

    return axios.get(`${baseUrl}/${id}`, config)
}
