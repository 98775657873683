import React, {useEffect, useMemo, useState} from "react";
import useAuth from "../../../../hooks/useAuth";
import TableBase from "../../../../components/User/TableBase";
import Header from "./Header";
import {listApi} from "../../../../services/user/request";
import moment from "moment";
import MessageIcon from '@mui/icons-material/Message';

export default function OrderHistory() {
    const {logout, auth} = useAuth();

    const [transactions, setTransactions] = useState([])
    const [loadingExcel, setLoadingExcel] = useState(false)
    const [loading, setLoading] = useState(false)
    const [filterStatus, setFilterStatus] = useState(false)

    const [filter, setFilter] = useState({
        dateFrom: (moment().subtract(6, 'months') / 1000) ?? null,
        dateTo: null,
        idRegion: null,
        idSubregion: null,
        idCountry: null,
        idStore: null,
        idStatus: null
    })

    const roleName = auth ? auth.role : null;

    const columns = useMemo(
        () => [
            {
                Header: "ZONE",
                accessor: "region_name",
                disableFilters: true
            },
            {
                Header: "REGION",
                accessor: "subregion_name",
                disableFilters: true
            },
            {
                Header: "COUNTRY",
                accessor: "listCountry_name",
                disableFilters: true
            },
            {
                Header: "STORE",
                accessor: "location_name",
                disableFilters: true
            },
            {
                Header: "DATE",
                accessor: "creationDate",
                disableFilters: true
            },
            {
                Header: "ORDER NO",
                accessor: "id",
                disableFilters: true
            },
            {
                Header: "ORDERED BY",
                accessor: "shipTo",
                disableFilters: true
            },
            {
                Header: "TOTAL",
                accessor: "price",
                disableFilters: true
            },
            {
                Header: "STATUS",
                accessor: "status_name",
                disableFilters: true
            },
            {
                Header: "STATUS DATE",
                accessor: "deliveredDate",
                disableFilters: true
            },
        ],
        []
    )

    const clickExport = async () => {
        setLoadingExcel(true)
        const response = await listApi(logout, "transaction/export", {
            idRegion: filter?.idRegion,
            idSubregion: filter?.idSubregion,
            idCountry: filter?.idCountry,
            idStore: filter?.idStore,
            idStatus: filter?.idStatus,
            dateFrom: filter?.dateFrom ? moment.unix(filter?.dateFrom).format("YYYY-MM-DD") : null,
            dateTo: filter?.dateTo ? moment.unix(filter?.dateTo).format("YYYY-MM-DD") : null
        })
        if (response.status) {
            window.open(response.data, '_blank');
        }
        setLoadingExcel(false)
    }

    useEffect(() => {
        setLoading(true)
        if (filterStatus) {
            searchTransaction()
        }
    },[filter, filterStatus]) // eslint-disable-line react-hooks/exhaustive-deps

    const searchTransaction = async () => {
        setLoading(true)

        const response = await listApi(logout, "transaction", {
            idRegion: filter?.idRegion,
            idSubregion: filter?.idSubregion,
            idCountry: filter?.idCountry,
            idStore: filter?.idStore,
            idStatus: filter?.idStatus,
            dateFrom: filter?.dateFrom ? moment.unix(filter?.dateFrom).format("YYYY-MM-DD") : null,
            dateTo: filter?.dateTo ? moment.unix(filter?.dateTo).format("YYYY-MM-DD") : null
        })
        if (response.status) {
            const temp = response.data.map((doc) => {
                let date= moment.unix(doc.transaction_creationDate).utc().format('DD-MMM-YYYY HH:mm A')

                return {
                    ...doc,
                    id: doc.transaction_id,
                    shipTo: `${doc.user_name} ${doc.user_surnames}`,
                    deliveredDate: (doc.transaction_deliveredDate !== null) ? (moment.unix(doc.transaction_deliveredDate).format("D/MM/Y")) : ((doc.statusDate !== null) ? ((moment.unix(doc.statusDate).format("D/MM/Y"))) : (null)),
                    creationDate: date,
                };
            });

            setTransactions(temp)
            setLoading(false)
        }
    }

    return (
        <>
            <div className="card-body">
                <div>
                    <Header
                        filter={filter}
                        setFilter={setFilter}

                        roleName={roleName}
                        loadingExcel={loadingExcel}
                        filterStatus={filterStatus}
                        setFilterStatus={setFilterStatus}
                        clickExport={clickExport}
                    />

                    <br/>

                    <div style={{overflow: 'scroll'}}>
                    <TableBase
                        columns={columns}
                        data={transactions}
                        search={false}
                        urlDetail="/dash/order-history/details/"
                        loading={loading}
                    />

                    </div>
                </div>
            </div>
        </>
    );
}
