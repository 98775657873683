import React, { useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { registerApi } from "../../../services/admin/user"
import { useHistory } from "react-router-dom";

export default function RegisterForm() {

    const [msg, setMsg] = useState(false);
    const history = useHistory();

    const formik = useFormik({
        initialValues: initialValues(),
        validationSchema: Yup.object(validationSchema()),
        onSubmit: async (formData) => {
            const response = await registerApi(formData)
            if (response.status) {
                formik.resetForm({ values: initialValues() })
                setMsg(true)
                history.push("/login")
            }
        }
    })

    return (
        <div className="register-form">
            {msg && <p className="text-100">Te has registrado correctamente</p>}

            <h1>Estoy en register</h1>
            <form onSubmit={formik.handleSubmit} >
                <div>
                    <label>Nombre: </label>
                    <input
                        name="name"
                        type="text"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                    />
                    {formik.errors.name && <p className="text-100">{formik.errors.name}</p>}
                </div>

                <br />

                <div>
                    <label>Apellidos: </label>
                    <input
                        name="surnames"
                        type="text"
                        value={formik.values.surnames}
                        onChange={formik.handleChange}
                    />
                    {formik.errors.surnames && <p className="text-100">{formik.errors.surnames}</p>}
                </div>

                <br />
                
                <div>
                    <label>Email: </label>
                    <input
                        name="email"
                        type="text"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                    />
                    {formik.errors.email && <p className="text-100">{formik.errors.email}</p>}
                </div>

                <br />
                
                <div>
                    <label>Contraseña: </label>
                    <input
                        name="password"
                        type="password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                    />
                    {formik.errors.password && <p className="text-100">{formik.errors.password}</p>}
                </div>

                <br/>

                <div>
                    <button type="submit">Registrar</button>
                </div>

            </form>
        </div>
    );
}

function initialValues() {
    return {
        name: "",
        surnames: "",
        email: "",
        password: ""
    }
}

function validationSchema() {
    return {
        name: Yup.string().required("El nombre es obligatorio"),
        surnames: Yup.string(),
        email: Yup.string().email("El email no es correcto").required("El email es obligatorio"),
        password: Yup.string().required("La contrseña es obligatoria")
    }
}