import Basic from "../../../../containers/Admin/Layouts/Basic"
import ComponentManagement from "../../../../containers/Admin/Warehouse/LocationProduct"
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import useAuth from "../../../../hooks/useAuth";
import {getWarehouseApi} from "../../../../services/admin/warehouse";
import {getLocationApi} from "../../../../services/admin/location";

export default function Management() {
    const { idWarehouse, idLocation } = useParams()
    const { logout } = useAuth()
    const [nameW, setNameW] = useState(null)
    const [nameL, setNameL] = useState(null)

    useEffect(() => {
        (async () => {
            if (idWarehouse){
                const response = await getWarehouseApi(logout, {id: idWarehouse})
                if (response.data) {
                    setNameW(response.data.warehouse.name)
                }
            }
            if (idLocation){
                const response = await getLocationApi(logout, {id: idLocation})
                if (response.status) {
                    setNameL(response.data.name)
                }
            }
        })()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Basic menu="warehouse">
            <Grid container spacing={3} className="pl-20 pr-20">
                <Grid item xs={12} md={12} lg={12}>
                    <Button
                        type="button"
                        color="primary"
                        href={`/admin/warehouse/edit/${idWarehouse}/3`}
                        style={{
                            float: "left"
                        }}
                    >
                        <ArrowBackIos />
                        BACK TO LIST
                    </Button>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <h2 className="p-0">Products supplied by the warehouse
                        {idWarehouse && (
                            <span className="font-w-100"> {nameW} /</span>
                        )}

                        {idWarehouse && (
                            <span className="font-w-100"> {nameL}</span>
                        )}
                    </h2>
                </Grid>
            </Grid>

            <ComponentManagement />
        </Basic>
    )
}