 import React, { useState, useEffect } from "react"
 import { useFormik } from "formik"
 import * as Yup from "yup"
 import { useHistory, useParams } from "react-router-dom"
 import useAuth from "../../../../hooks/useAuth"
 import {createApi, getCategoryApi, updateApi} from "../../../../services/admin/category"
 import Grid from "@material-ui/core/Grid"
 import Box from "@material-ui/core/Box"
 import Button from "@material-ui/core/Button"
 import TextField from "@material-ui/core/TextField"
 import ArrowBackIos from "@material-ui/icons/ArrowBackIos"
 import Switch from "@material-ui/core/Switch"
 import FormControlLabel from "@material-ui/core/FormControlLabel"
 import Autocomplete from "@material-ui/lab/Autocomplete";
 import {listApi} from "../../../../services/admin/request";
 
 export default function Fields() {
     const { id } = useParams()
     const { logout } = useAuth()
     const history = useHistory()

     const [msgSuccess, setMsgSuccess] = useState(false)
     const [msgDanger, setMsgDanger] = useState(null)
     const [loading, setLoading] = useState(true)
     const [listCompany, setListCompany] = useState([])
     const [defaultCompany, setDefaultCompany] = useState(null)

     const [initialValues, setInitialValues] = useState({
         isActive: true
     })

     const changeCompany = (event, value) => {
        if (value !== null) {
            formik.values.idCompany = value.id
            setDefaultCompany({
                id: value.id,
                name: value.name
            })
        } else {
            setDefaultCompany(null)
            formik.values.idCompany = null
        }
    }

     const formik = useFormik({
         initialValues: initialValues,
         validationSchema: Yup.object(validationSchema()),
         enableReinitialize: true,
         onSubmit: async (formData) => {
             setMsgSuccess(false)
             setMsgDanger(null)

             if (id){
                 const response = await updateApi(logout, formData)
                 if (response.status) {
                     setMsgSuccess(true)
                 } else {
                     setMsgDanger(response.errors)
                 }
             } else {
                 const response = await createApi(formData)
                 if (response.status) {
                     history.push(`/admin/category/edit/${response.data.id}`)
                 } else {
                     setMsgDanger(response.errors)
                 }
             }

         },
     });
 
     // Llamamos a la API para obtener los datos del usuario
     useEffect(() => {
         (async () => {
             if (id) {
                 const response = await getCategoryApi(logout, {id: id})
                 if (response.status) {
                     setInitialValues({
                         id: response.data.id,
                         name: response.data.name,
                         isActive: response.data.isActive,
                     })
                     setDefaultCompany(response.data.company)
                 }
             }
             setLoading(false)
         })();
         (async () => {
            const response = await listApi(logout, "company");
            if (response.status) {
                const temp = response.data.map((doc) => {
                    return {
                        id: doc.id,
                        ...doc,
                    }
                })
                setListCompany(temp)
            }
        })()
     }, []) // eslint-disable-line react-hooks/exhaustive-deps
 
     if (loading) return ""
 
     return (
         <>
             <form onSubmit={formik.handleSubmit}>
                 <Grid container spacing={3}>
                     <Grid item xs={12} md={12} lg={12}>
                         <Button
                             type="button"
                             color="primary"
                             href="/admin/category/management"
                             style={{
                                 float: "left"
                             }}
                         >
                             <ArrowBackIos />
                             BACK TO LIST
                         </Button>
                     </Grid>
                     <Grid item xs={12} md={12} lg={12}>
                         <h2 className="p-0">Category Settings</h2>
                     </Grid>
                 </Grid>
 
                 {msgSuccess && (
                     <Grid item xs={12} md={12} lg={12}>
                         <Box className="alert-success">
                             Category data has been saved correctly
                         </Box>
                     </Grid>
                 )}
 
                 {msgDanger && (
                     <Grid item xs={12} md={12} lg={12}>
                         <Box className="alert-danger">
                             {msgDanger.map((item) => {
                                 return (
                                     <span>
                                         {" "}
                                         {item} <br />{" "}
                                     </span>
                                 );
                             })}
                         </Box>
                     </Grid>
                 )}
 
                 <Box border={1} className="p-20 box-default">
                     <Grid container spacing={3}>
                         <Grid item xs={12} md={12} lg={12} className="border-bottom">
                             <h5 className="p-0 mt-0">Info</h5>
                         </Grid>
                         <Grid item xs={12} md={12} lg={12}>
                             {id && (
                                 <div className="my-2">
                                     <TextField
                                         variant="outlined"
                                         label="* Category ID"
                                         className="form-control"
                                         name="id"
                                         type="text"
                                         value={formik.values.id}
                                         onChange={formik.handleChange}
                                         disabled
                                         fullWidth
                                         size="small"
                                         InputLabelProps={{ shrink: true }}
                                     />
                                 </div>
                             )}
 
                             <div className="my-2">
                                 <TextField
                                     variant="outlined"
                                     label="* Category Name"
                                     className="form-control"
                                     name="name"
                                     type="text"
                                     value={formik.values.name}
                                     onChange={formik.handleChange}
                                     fullWidth
                                     size="small"
                                     InputLabelProps={{ shrink: true }}
                                 />
                                 {formik.errors.name && (
                                     <p className="text-100 text-danger">
                                         {formik.errors.name}
                                     </p>
                                 )}
                             </div>

                             <div className="my-2 mb-2">
                                 <Autocomplete
                                     options={listCompany}
                                     value={defaultCompany}
                                     getOptionLabel={(option) => option.name || ""}
                                     getOptionSelected={(option, value) => option.value === value.value }
                                     onChange={changeCompany}
                                     fullWidth
                                     disabled={loading}
                                     size="small"
                                     renderInput={(params) => (
                                         <TextField
                                             {...params}
                                             label="* Company "
                                             variant="outlined"
                                             name="idCompany"
                                             InputLabelProps={{ shrink: true }}
                                         />
                                     )}
                                 />
                             </div>

                             <div className="my-2">
                                 <FormControlLabel
                                     control={
                                         <Switch
                                             checked={formik.values.isActive}
                                             onChange={formik.handleChange}
                                             name="isActive"
                                             color="primary"
                                         />
                                     }
                                     label="Visible"
                                 />
                             </div>
                         </Grid>
                     </Grid>
                 </Box>
 
 
                 <Grid container spacing={3} className="p-20">
                     <Grid item xs={12} md={6} lg={6}>&nbsp;</Grid>
                     <Grid item xs={6} md={3} lg={3}>
                         <Button
                             type="button"
                             color="secondary"
                             href="/admin/category/management"
                             fullWidth
                         >
                             { id ? ( <><ArrowBackIos /> Back to list </>) : "Cancel" }
                         </Button>
                     </Grid>
                     <Grid item xs={6} md={3} lg={3}>
                         <Button
                             variant="contained"
                             color="primary"
                             type="submit"
                             fullWidth
                         >
                             { id ? "Modify" : "Create" }
                         </Button>
                     </Grid>
                 </Grid>
             </form>
         </>
     )
 }
 
 function validationSchema() {
     return {
         id: Yup.number(),
         name: Yup.string().required("El nombre es obligatorio"),
         idCompany: Yup.number(),
     };
 }
