import React, {useEffect, useState} from "react";
import {TextField} from "@mui/material";
import Autocomplete from "@material-ui/lab/Autocomplete";

export default function InputAutocomplete({options, onChange, loading, disabled, label, name, value}) {

    const disabledIn = disabled ? disabled : false
    const color = '#ef6f28'
    const [valueIn, setValueIn] = useState(null)
    const handleChnage = (event, newValue) => {
        onChange({target: {name: name, value: newValue?.id}})
    }

    useEffect(() => {
        if (options.length > 0 && value !== null) {
            setValueIn(options.find((item) => item.id === value))
        } else {
            setValueIn(null)
        }
    }, [value, options])

    return (
        <>
            <Autocomplete
                options={options}
                getOptionLabel={(option) => option.name ? option.name : ""}
                getOptionSelected={(option, value) =>
                    option.value === value.value
                }
                onChange={handleChnage}
                loading={loading}
                disabled={disabledIn}
                fullWidth
                size="small"
                value={valueIn || null}
                MenuProps={MenuProps}
                sx={{
                    background: disabledIn ? '#f5f5f5' : 'white',
                    borderRadius: '5px',

                    '& label.Mui-focused': {
                        color: disabledIn ? 'none' : color,
                    },
                    '& .MuiInput-underline:after': {
                        borderBottomColor: disabledIn ? 'none' : color,
                    },
                    '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                            borderColor: disabledIn ? 'none' : color,
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: disabledIn ? 'none' : color,
                        },
                    },
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={label}
                        variant="outlined"
                        name={name}
                        InputLabelProps={{ shrink: true }}
                    />
                )}
            />
        </>
    )
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
}
