import Basic from "../../../containers/Admin/Layouts/Basic"
import TransactionManagement from "../../../containers/Admin/Transaction/Management"

export default function Management() {
    return (
        <Basic menu="transaction">
            <TransactionManagement />
        </Basic>
    )
}
