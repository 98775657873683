import { BASE_PATH, PATH_BASE_EXTENSION } from "../utils/constants";

export async function impersonateApi(data) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/user/impersonate`;
        const token = localStorage.getItem("token")
        const params = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(data),
        };
        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        console.error(error);
        return null;
    }
}