import {BASE_PATH, PATH_BASE_EXTENSION_ADMIN} from "../../utils/constants";
import {getToken} from "../auth/token";

import axios from "axios";

const baseUrl = `${BASE_PATH}${PATH_BASE_EXTENSION_ADMIN}/import`;

export function apiImport(typeId, props) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()}`}};
    return axios.post(`${baseUrl}/${typeId}`, props,config)
}

export function apiList(props, lang="en") {
    const config = {headers: { 'Authorization': `Bearer ${getToken()}`}};
    let query = "";

    return axios.get(`${baseUrl}/list?limit=${props.limit}&offset=${props.offset}&field=${props.field}&sort=${props.sort}${query}`, config)
}

