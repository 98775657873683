import React, {useEffect, useMemo, useState} from "react"
import {getAllTransactionLog} from "../../../../services/admin/transaction";

import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import { useParams } from "react-router-dom";
import moment from "moment";
import NewTable from "../../../../components/Admin/NewTable"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import {DASH_MAX_ROW} from "../../../../utils/constants";
import useAuth from "../../../../hooks/useAuth"
import InputText from "../../../../components/Admin/Inputs/InputText";
import Button from "@material-ui/core/Button";
import InputSelect from "../../../../components/Admin/Inputs/InputSelect";
import DatePickerSimple from "../../../../components/Admin/Inputs/DatePickerSimple";
import DatePickerTime from "../../../../components/Admin/Inputs/DatePickerTime";
import {listApi} from "../../../../services/admin/request";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LoadingSpinner from "../../../../components/LoadingSpinner";
import InputButtonLoading from "../../../../components/Inputs/InputButtonLoading";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import KpiTable from "../kpi";

export default function Management() {
    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingExcel, setLoadingExcel] = useState(false)
    const [rowCount, setRowCount] = useState(0)
    const [state, setState] = useState(initialValues)
    const [msgError, setMsgError] = useState([])
    const [iniStart, setIniStart] = useState(true)
    const { id, tab } = useParams();
    const [value, setValue] = React.useState('2')
    const {logout} = useAuth()

    const handleChangeTab = (event, newValue) => {
        setValue(newValue)
    }

    useEffect(() => {
        setLoading(true)
        const timeOutId = setTimeout(() => {
            getItemsData(false)
        }, 1500)
        return () => clearTimeout(timeOutId)
    }, [state])

    const exportDataOld = async () => {
        setLoadingExcel(true)
        getItemsData(true)
    }

    const getItemsData = (exportRow = false) => {
        setMsgError([])
        let field = state.sort.field
        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let params = {
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort,
            transactionId: "",
            user: "",
            exportRow: exportRow
        }


        if (state.user) {
            params.user = state.user
        }
        if (state.userId) {
            params.userId = state.userId
        }
        if (state.transactionId) {
            params.transactionId = state.transactionId
        }
        if (state.dateToTime) {
            params.dateToTime = state.dateToTime
        }
        if (state.dateToDate) {
            params.dateToDate = state.dateToDate
        }
        if (state.dateFromTime) {
            params.dateFromTime = state.dateFromTime
        }
        if (state.dateFromDate) {
            params.dateFromDate = state.dateFromDate
        }
        if (state.country) {
            params.country = state.country
        }
        if (state.region) {
            params.region = state.region
        }
        if (state.zone) {
            params.zone = state.zone
        }
        if (state.company) {
            params.company = state.company
        }
        if (state.location) {
            params.location = state.location
        }

        if (params.dateFromDate && params.dateFromTime) {
            // Unimos fecha y hora
            params.dateFromDate = moment(`${moment.unix(params.dateFromDate).format("YYYY-MM-DD")} ${params.dateFromTime}`).format("X")
            // Eliminamos params.dateFromTime
            delete params.dateFromTime
        }

        if (params.dateToDate && params.dateToTime) {
            // Unimos fecha y hora
            params.dateToDate = moment(`${moment.unix(params.dateToDate).format("YYYY-MM-DD")} ${params.dateToTime}`).format("X")

            // Eliminamos params.dateToTime
            delete params.dateToTime
        }

        if (state.locationCategory) {
            params.locationCategory = state.locationCategory
        }
        if (state.orderComment) {
            params.orderComment = state.orderComment
        }
        if (state.shipmentComment) {
            params.shipmentComment = state.shipmentComment
        }

        getAllTransactionLog(logout, params).then(resp => {
            if (exportRow) {
                window.open(resp.data, '_blank');
                setLoadingExcel(false)
            } else {
                setRowCount(0)
                setRows([])
                let temp = resp.data?.map(doc => {
                    return {
                        ...doc,
                        id: doc.id,
                        idTransaction: doc.idTransaction,
                        idUser: doc.userName + " " + doc.userSurname,
                        idTransactionStatus: doc.transactionStatusName,
                        changeDate: moment.unix(doc.changeDate).format("D/M/Y h:mm a"),
                    }
                })
                setRowCount(resp.count)
                setRows(temp)
                setLoading(false)
            }
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setLoadingExcel(false)
            setRowCount(0)
            setRows([])
        })
    }

    function tableColumns() {
        return [
            {field: "changeDate", headerName: "DATE & TIME", width: 100, flex: 1, sortable: true},
            {field: "companyName", headerName: "COMPANY", width: 150, flex: 1},
            {field: "countryName", headerName: "COUNTRY", width: 150, flex: 1},
            {field: "regionName", headerName: "ZONE", width: 150, flex: 1},
            {field: "subregionName", headerName: "REGION", width: 150, flex: 1},
            {field: "storeName", headerName: "STORE", width: 150, flex: 1},
            {field: "storeCategory", headerName: "STORE CATEGORY", width: 150, flex: 1},
            {field: "idTransaction", headerName: "ORDER", width: 150, flex: 1},
            {field: "comment", headerName: "COMMENT", width: 150, flex: 1},
            {field: "idTransactionStatus", headerName: "STATUS", width: 150, flex: 1},
            {field: "quantity", headerName: "ORDER QTY", width: 150, flex: 1},
            {field: "tn_shipments", headerName: "TRACKING Nº", width: 150, flex: 1},
            {field: "eta_shipments", headerName: "ETA", width: 150, flex: 1},
            {field: "idUser", headerName: "USER", width: 150, flex: 1},
            {field: "shipment_comments", headerName: "SHP. COMMENT", width: 150, flex: 1},
            {field: "method_shipments", headerName: "SHIPPING METHOD", width: 150, flex: 1},
            {field: "white_glove_shipments", headerName: "WHITE GLOVE", width: 150, flex: 1},
        ]
    }

    return (<>

            <HeaderPage title="Display Orders Log/KPI">
                
            </HeaderPage>

            <TabContext value={value}>
                <AppBar
                    position="static"
                    style={{
                        background: "#fff",
                        color: "#000",
                        boxShadow: "none",
                    }}
                >
                    <TabList onChange={handleChangeTab}>
                        <Tab label="KPI Log" value="2" />
                        <Tab label="Orders Log" value="1" />
                    </TabList>
                </AppBar>

                <TabPanel value="1">
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12} lg={12}>
                            <Box border={1} className="box-default">

                                <LoadingSpinner loading={iniStart}/>

                                <Filters
                                    state={state}
                                    setState={setState}
                                    iniStart={iniStart}
                                    setIniStart={setIniStart}
                                />

                                {!iniStart && (
                                    <>
                                        <div
                                            style={{overflow: 'scroll'}}
                                        >
                                            <NewTable
                                                columns={tableColumns()}
                                                rows={rows}
                                                rowCount={rowCount}
                                                filter={state}
                                                setFilter={setState}
                                                loading={loading}
                                            />
                                        </div>
                                    </>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </TabPanel>
                <TabPanel value="2">
                    <KpiTable />
                </TabPanel>
            </TabContext>

            
        </>
    )
}

function Filters(props) {

    const {state, setState, setIniStart, iniStart} = props
    const {logout} = useAuth()

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        state["offset"] = 0
        setState({...state})
    }

    const [company, setCompany] = useState([])
    const [zone, setZone] = useState([])
    const [region, setRegion] = useState([])
    const [country, setCountry] = useState([])
    const [location, setLocation] = useState([])
    const [rows, setRows] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingExcel, setLoadingExcel] = useState(false)
    const [rowCount, setRowCount] = useState(0)
    const [msgError, setMsgError] = useState([])

    useEffect(() => {
        getCompany()
        getRegion()
        getCountry()
        getZone()
        getLocation()
    }, [])

    useEffect(() => {
        if (company.length > 0 && zone.length > 0 && country.length > 0 && region.length > 0) {
            setIniStart(false)
        }
    }, [company, zone, country, region])

    useEffect(() => {
        getZone()
        getLocation()

        setState({...state, region: "", zone: ""})

    }, [state.company])

    useEffect(() => {
        getRegion()

        setState({...state, region: ""})

    }, [state.zone])

    const getLocation = async () => {
        const response = await listApi(logout, 'location?idCompany=' + state.company)
        if (response.status) {
            const temp = response.data.map((doc) => {
                return {
                    id: doc.id,
                    name: doc.name,
                }
            })
            setLocation(temp)
        }
    }

    const getCompany = async () => {
        const response = await listApi(logout, 'company')
        if (response.status) {
            const temp = response.data.map((doc) => {
                return {
                    id: doc.id,
                    name: doc.name,
                }
            })
            setCompany(temp)
        }
    }

    const getZone = async () => {
        const response = await listApi(logout, 'region?idCompany=' + state.company)
        if (response.status) {
            const temp = response.data.map((doc) => {
                return {
                    id: doc.id,
                    name: doc.name,
                }
            })
            setZone(temp)
        }
    }

    const getRegion = async () => {
        const response = await listApi(logout, "region/subregion/list?idRegion=" + state.zone)
        if (response.status) {
            const temp = response.data.map((doc) => {
                return {
                    id: doc.id,
                    name: doc.name,
                }
            })
            setRegion(temp)
        }
    }

    const getCountry = async () => {
        const response = await listApi(logout, 'country')
        if (response.status) {
            const temp = response.data.map((doc) => {
                return {
                    id: doc.id,
                    name: doc.name,
                }
            })
            setCountry(temp)
        }
    }

    const exportData = async () => {
        setLoadingExcel(true)
        getItemsData(true)
    }

    const getItemsData = (exportRow = false) => {
        setMsgError([])
        let field = state.sort.field
        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let params = {
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort,
            transactionId: "",
            user: "",
            exportRow: exportRow
        }


        if (state.user) {
            params.user = state.user
        }
        if (state.userId) {
            params.userId = state.userId
        }
        if (state.transactionId) {
            params.transactionId = state.transactionId
        }
        if (state.dateToTime) {
            params.dateToTime = state.dateToTime
        }
        if (state.dateToDate) {
            params.dateToDate = state.dateToDate
        }
        if (state.dateFromTime) {
            params.dateFromTime = state.dateFromTime
        }
        if (state.dateFromDate) {
            params.dateFromDate = state.dateFromDate
        }
        if (state.country) {
            params.country = state.country
        }
        if (state.region) {
            params.region = state.region
        }
        if (state.zone) {
            params.zone = state.zone
        }
        if (state.company) {
            params.company = state.company
        }
        if (state.location) {
            params.location = state.location
        }

        if (params.dateFromDate && params.dateFromTime) {
            // Unimos fecha y hora
            params.dateFromDate = moment(`${moment.unix(params.dateFromDate).format("YYYY-MM-DD")} ${params.dateFromTime}`).format("X")
            // Eliminamos params.dateFromTime
            delete params.dateFromTime
        }

        if (params.dateToDate && params.dateToTime) {
            // Unimos fecha y hora
            params.dateToDate = moment(`${moment.unix(params.dateToDate).format("YYYY-MM-DD")} ${params.dateToTime}`).format("X")

            // Eliminamos params.dateToTime
            delete params.dateToTime
        }


        getAllTransactionLog(logout, params).then(resp => {
            if (exportRow) {
                window.open(resp.data, '_blank');
                setLoadingExcel(false)
            } else {
                
            }
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setLoadingExcel(false)
            setRowCount(0)
            setRows([])
        })
    }

    return (
        <>
            {!iniStart && (
                <Grid container spacing={1} style={{padding: 10}}>

                    <Grid item md={3}>
                        <InputText
                            label="User"
                            name="user"
                            value={state.user}
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item md={3}>
                        <InputSelect
                            label="Company"
                            name="company"
                            onChange={handleChange}
                            value={state?.company}
                            options={company}
                        />
                    </Grid>

                    <Grid item md={3}>
                        <InputSelect
                            label="Store"
                            name="location"
                            onChange={handleChange}
                            value={state?.location}
                            options={location}
                            disabled={state.company ? false : true}
                        />
                    </Grid>

                    <Grid item md={3}>
                        <InputButtonLoading
                            onClick={exportData}
                            loading={loadingExcel}
                            label="Export (.xlsx)"
                            style={{width: 200}}
                        />
                    </Grid>

                    <Grid item md={4}>
                        <InputText
                            label="Order ID"
                            name="transactionId"
                            onChange={handleChange}
                            value={state.transactionId}
                        />
                    </Grid>

                    <Grid item md={4}>
                        <DatePickerSimple
                            name="dateFromDate"
                            label="Date from (date)"
                            value={state.dateFromDate}
                            onChange={handleChange}
                        />
                    </Grid>

                    <Grid item md={4}>
                        <DatePickerSimple
                            dateTo={true}
                            name="dateToDate"
                            label="Date to (date)"
                            value={state.dateToDate}
                            onChange={handleChange}
                        />
                    </Grid>

                    {/*<Grid item md={4}>*/}
                    {/*    <InputText*/}
                    {/*        label="User id"*/}
                    {/*        name="userId"*/}
                    {/*        value={state.userId}*/}
                    {/*        onChange={handleChange}*/}
                    {/*    />*/}
                    {/*</Grid>*/}

                    <Grid item md={4}>
                        <InputSelect
                            label="Zone"
                            name="zone"
                            onChange={handleChange}
                            value={state?.zone}
                            options={zone}
                            disabled={!state?.company}
                        />
                    </Grid>

                    <Grid item md={4}>
                        <InputSelect
                            label="Region"
                            name="region"
                            onChange={handleChange}
                            value={state?.region}
                            options={region}
                            disabled={!state?.zone}
                        />
                    </Grid>

                    <Grid item md={4}>
                        <InputSelect
                            label="Country"
                            name="country"
                            onChange={handleChange}
                            value={state?.country}
                            options={country}
                        />
                    </Grid>

                    <Grid item md={4}>
                        <InputText
                            label="Store Category"
                            name="locationCategory"
                            onChange={handleChange}
                            value={state.locationCategory}
                        />
                    </Grid>

                    <Grid item md={4}>
                        <InputText
                            label="Order Comment"
                            name="orderComment"
                            onChange={handleChange}
                            value={state.orderComment}
                        />
                    </Grid>

                    <Grid item md={4}>
                        <InputText
                            label="Shipment Comments"
                            name="shipmentComment"
                            onChange={handleChange}
                            value={state.shipmentComment}
                        />
                    </Grid>

                    {/*<Grid item md={3}>*/}
                    {/*    <DatePickerTime*/}
                    {/*        name="dateFromTime"*/}
                    {/*        label="Date from (hour)"*/}
                    {/*        value={state.dateFromTime}*/}
                    {/*        disabled={state.dateFromDate ? false : true}*/}
                    {/*        format={'12h'}*/}
                    {/*        onChange={handleChange}*/}
                    {/*    />*/}
                    {/*</Grid>*/}

                    {/*<Grid item md={3}>*/}
                    {/*    <DatePickerTime*/}
                    {/*        name="dateToTime"*/}
                    {/*        label="Date to (hour)"*/}
                    {/*        value={state.dateToTime}*/}
                    {/*        disabled={state.dateToDate ? false : true}*/}
                    {/*        onChange={handleChange}*/}
                    {/*        format={'12h'}*/}
                    {/*    />*/}
                    {/*</Grid>*/}

                </Grid>
            )}
        </>
    )
}

function initialValues() {
    return {
        offset: 0, sort: {field: "changeDate", sort: "desc"},
        user: "",
        userId: "",
        transactionId: "",
        dateToTime: "",
        dateToDate: "",
        dateFromTime: "",
        dateFromDate: "",
        country: "",
        region: "",
        zone: "",
        company: "",
        location: "",
        locationCategory: "",
        orderComment: "",
        shipmentComment: "",
    }
}
