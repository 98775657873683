import Basic from "../../../containers/Admin/Layouts/Basic"
import ComponentEdit from "../../../containers/Admin/User/Fields";

export default function Edit() {
  return (
    <Basic menu="users">
          <ComponentEdit />
    </Basic>
  )
}
