import React, {useEffect, useState} from "react"
import {useFormik} from "formik"
import * as Yup from "yup"
import {createApi, gerUserApi, updateUserApi} from "../../../../services/admin/user"
import {useHistory, useParams} from "react-router-dom"
import {listApi as requestListApi} from "../../../../services/admin/request"
import useAuth from "../../../../hooks/useAuth"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import ArrowBackIos from "@material-ui/icons/ArrowBackIos"
import Switch from "@material-ui/core/Switch"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Autocomplete from "@material-ui/lab/Autocomplete"
import jwtDecode from "jwt-decode";
import {impersonateApi} from "../../../../services/impersonate";
import {checkAuthDate} from "../../../../services/auth/authKey";
import DatePickerSimple from "../../../../components/Admin/Inputs/DatePickerSimple";
import Microsoft from "./Microsoft";

export default function Fields() {
    const {id} = useParams()
    const {logout} = useAuth()
    const history = useHistory()
    const {login} = useAuth()

    const [msgSuccess, setMsgSuccess] = useState(false)
    const [msgDanger, setMsgDanger] = useState(null)
    const [loading, setLoading] = useState(true)
    const [authDateToLogin, setAuthDateToLogin] = useState(false)

    const [listCompany, setListCompany] = useState([])

    const [listCountry, setListCountry] = useState([])

    const [listRegion, setListRegion] = useState([])
    const [listRegionAll, setListRegionAll] = useState([])

    const [listSubregion, setListSubregion] = useState([])
    const [listSubregionAll, setListSubregionAll] = useState([])

    const [listLocation, setListLocation] = useState([])
    const [listLocationAll, setListLocationAll] = useState([])

    const [defaultCompany, setDefaultCompany] = useState(null)
    const [defaultCountry, setDefaultCountry] = useState(null)
    const [defaultRegion, setDefaultRegion] = useState(null)
    const [defaultSubregion, setDefaultSubregion] = useState(null)
    const [defaultLocation, setDefaultLocation] = useState(null)
    const [defaultLocations, setDefaultLocations] = useState([]);

    const [initialValues, setInitialValues] = useState(defaultValue())

    const [defaultRole, setDefaultRole] = useState({})

    const listRole = [
        {id: 1, key: "ROLE_ADMIN", name: "Admin"},
        {id: 3, key: "ROLE_STOREG", name: "Store"},
        {id: 4, key: "ROLE_COMPANY_MANAGER", name: "Company Manager"},
        {id: 5, key: "ROLE_REGION_MANAGER", name: "Approver/Region"},
        {id: 6, key: "ROLE_MULTI_STORE", name: "Multi-Store"}
    ]

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object(validationSchema()),
        enableReinitialize: true,
        onSubmit: async (formData) => {
            setMsgSuccess(false)
            setMsgDanger(null)

            if (id) {
                const response = await updateUserApi(logout, formData)
                if (response.status) {
                    setMsgSuccess(true)
                } else {
                    setMsgDanger(response.errors)
                }
            } else {
                const response = await createApi(formData)
                if (response.status) {
                    history.push(`/admin/users/edit/${response.data.id}`)
                } else {
                    setMsgDanger(response.errors);
                }
            }
        },
    })

    useEffect(() => {
        apiGetUser()
        apiListCompany()
        apiListCountry()
        apiListRegion()
        apiListLocation()
        apiListSubregion()

        setLoading(false)

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const authDate = (email) => {
        setAuthDateToLogin(false)
        checkAuthDate({
            email: email
        }).then(resp => {
            setAuthDateToLogin(true)
        }).catch(err => {
            setAuthDateToLogin(false)
        })
    }

    const apiGetUser = async () => {
        if (id) {
            // Llamamos a la API para obtener los datos del usuario
            const response = await gerUserApi(logout, id)
            if (response.status) {

                const location = response.data.idLocation
                let locationResultId;
                let locationResultObject = response.data.idLocation;

                if (response.data.idUserRole.key !== "ROLE_MULTI_STORE") {

                    locationResultId = location[0]?.id;
                    locationResultObject = location[0]

                } else {

                    locationResultId = location.map(loc => {
                        return loc.id
                    })

                }

                setInitialValues({
                    id: response.data.id,
                    name: response.data.name,
                    surnames: response.data.surnames,
                    email: response.data.email,
                    isActive: response.data.isActive,
                    isAccessMicrosoft: response.data.isAccessMicrosoft,
                    role: response.data.idUserRole,
                    authDate: response.data.authDate,
                    microsoftId: response.data.microsoftId,
                    microsoftEmail: response.data.microsoftEmail,

                    idCompany: response.data.idCompany?.id,
                    idCountry: response.data.idCountry?.id,
                    idRegion: response.data.idRegion?.id,
                    idSubregion: response.data.idSubregion?.id,
                    idLocation: locationResultId,

                    isAllRegion: response.data.isAllRegion,
                    isAllLocation: response.data.isAllLocation,
                    isAllSubregion: response.data.isAllSubregion,

                    isAllowedReportProductionPlan: response.data?.isAllowedReportProductionPlan,
                    isAllowedReportSupplyChain: response.data?.isAllowedReportSupplyChain,
                    isAllowedReportAvailability: response.data?.isAllowedReportAvailability,
                    isAllowedReportConsumption: response.data?.isAllowedReportConsumption,
                    isAllowedReportMaxOrder: response.data?.isAllowedReportMaxOrder,
                    isAllowedReportStock: response.data?.isAllowedReportStock,
                    isAllowedReportStockQty: response.data?.isAllowedReportStockQty,

                })

                setDefaultRole(response.data.idUserRole)

                setDefaultCompany(response.data.idCompany)
                setDefaultCountry(response.data.idCountry)
                setDefaultRegion(response.data.idRegion)
                setDefaultSubregion(response.data.idSubregion)
                if (response.data.idUserRole.key !== "ROLE_MULTI_STORE") {
                    setDefaultLocation(locationResultObject);
                } else {
                    setDefaultLocations(locationResultObject)
                }

                authDate(response.data.email)
            }
        }
    }

    const apiListCompany = async () => {
        const response = await requestListApi(logout, "company");
        if (response.status) {
            const temp = response.data.map((doc) => {
                return {
                    id: doc.id,
                    ...doc,
                }
            })
            setListCompany(temp)
        }
    }

    const apiListCountry = async () => {
        const response = await requestListApi(logout, "country");
        if (response.status) {
            const temp = response.data.map((doc) => {
                return {
                    id: doc.id,
                    ...doc,
                }
            })
            setListCountry(temp)
        }
    }

    const apiListSubregion = async () => {
        const response = await requestListApi(logout, `region/subregion/list`);
        if (response.status) {
            const temp = response.data.map((doc) => {
                return {
                    id: doc.id,
                    ...doc,
                }
            })
            setListSubregionAll(temp)
        }
    }

    const apiListRegion = async () => {
        const response = await requestListApi(logout, "region")
        if (response.status) {
            const temp = response.data.map((doc) => {
                return {
                    id: doc.id,
                    ...doc,
                }
            })
            setListRegionAll(temp)
        }
    }

    const apiListLocation = async () => {
        const response = await requestListApi(logout, "location");
        if (response.status) {
            const temp = response.data.map((doc) => {
                return {
                    id: doc.id,
                    ...doc,
                }
            })

            setListLocationAll(temp)
        }
    }

    // Escuchamos cuando una de las dos variables es modificada, para recargar los valores de Región
    useEffect(() => {
        showRegionInSelect(initialValues.idCompany)
    }, [initialValues, listRegionAll]) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        showSubregionInSelect(initialValues.idRegion)
    }, [initialValues, listSubregionAll]) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        showLocationInSelect(initialValues.idCompany, initialValues.idRegion, initialValues.idSubregion)
    }, [initialValues, listLocationAll]) // eslint-disable-line react-hooks/exhaustive-deps

    // Filtramos las regiones segun la companya
    const showRegionInSelect = (idCompany) => {
        setListRegion(
            listRegionAll.filter(item => {
                let add = false
                if (idCompany === item.idCompany.id) {
                    add = true
                }
                return add
            })
        )
    }

    // Filtramos las tiendas segun la region y la compañia
    const showLocationInSelect = (idCompany, idRegion, idSubregion) => {
        setListLocation(
            listLocationAll.filter(item => {
                let add = false

                if (item.idRegion?.id === idRegion && idCompany === item.idCompany?.id && item.idSubregion?.id === idSubregion) {
                    add = true
                }
                return add
            })
        )
    }

    // Filtramos las regiones segun la companya
    const showSubregionInSelect = (idRegion) => {
        setListSubregion(
            listSubregionAll.filter(item => {
                let add = false
                if (idRegion === item.idRegion.id) {
                    add = true
                }
                return add
            })
        )
    }

    const onChangeCompany = (event, value) => {
        if (value !== null) {

            formik.values.idCompany = value.id
            setDefaultCompany({
                id: value.id,
                name: value.name,
            })

            showRegionInSelect(value.id)

        } else {

            setDefaultCompany(null)
            formik.values.idCompany = null
            showRegionInSelect(null)
            showLocationInSelect(null, null, null)

        }

        // Limpiamos los valores de la tienda (Location)
        setDefaultRegion(null)
        formik.values.idRegion = null

        // Limpiamos los valores de la tienda (Location)
        setDefaultLocation(null)
        formik.values.idLocation = null
    }

    const onChangeCountry = (event, value) => {
        if (value !== null) {

            formik.values.idCountry = value.id
            setDefaultCountry({
                id: value.id,
                name: value.name,
            })


        } else {

            setDefaultCountry(null)
            formik.values.idCountry = null

        }
    }

    const onChangeRegion = (event, value) => {
        if (value !== null) {

            formik.values.idRegion = value.id
            setDefaultRegion({
                id: value.id,
                name: value.name,
            })

            showSubregionInSelect(value.id)

        } else {

            setDefaultRegion(null)
            formik.values.idRegion = null
            showSubregionInSelect(null)

        }

        // Limpiamos los valores de la tienda (Location)
        setDefaultLocation(null)
        formik.values.idLocation = null
    }

    const onChangeSubregion = (event, value) => {
        if (value !== null) {

            formik.values.idSubregion = value.id
            setDefaultSubregion({
                id: value.id,
                name: value.name,
            })

            showLocationInSelect(defaultCompany?.id, defaultRegion?.id, value.id)

        } else {

            setDefaultSubregion(null)
            formik.values.idSubregion = null
            showLocationInSelect(null, null, null)

        }

        // Limpiamos los valores de la tienda (Location)
        setDefaultLocation(null)
        formik.values.idLocation = null
    }

    const clickImpersonate = async () => {
        const response = await impersonateApi({
            id: id
        })
        if (response?.token) {
            login(response?.token);

            if (jwtDecode(response?.token).roles[0] !== "ROLE_ADMIN") {
                history.push("/dash/order");
            }
        } else {
            setMsgDanger(true);
        }
    }

    if (loading) return ""

    return (
        <>
            <form onSubmit={formik.handleSubmit}>

                <Grid container spacing={3} className="p-20">

                    <Grid item xs={12} md={12} lg={12}>
                        <h2 className="p-0">Account Settings</h2>
                    </Grid>

                    {msgSuccess && (
                        <Grid item xs={12} md={12} lg={12}>
                            <Box className="alert-success">
                                User data has been saved correctly
                            </Box>
                        </Grid>
                    )}

                    {msgDanger && (
                        <Grid item xs={12} md={12} lg={12}>
                            <Box className="alert-danger">
                                {msgDanger.map((item) => {
                                    return (<span>{item} <br/></span>)
                                })}
                            </Box>
                        </Grid>
                    )}

                    <Grid item xs={12} md={6} lg={6}>
                        <Box border={1} className="p-20 box-default">
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12} lg={12} className="border-bottom">
                                    <h4 className="p-0 mt-0">Info</h4>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>
                                    {id && (
                                        <div className="my-2">
                                            <TextField
                                                variant="outlined"
                                                label="* User ID"
                                                className="form-control"
                                                name="id"
                                                type="text"
                                                value={formik.values.id}
                                                onChange={formik.handleChange}
                                                disabled
                                                fullWidth
                                                InputLabelProps={{shrink: true}}
                                                size="small"
                                            />
                                        </div>
                                    )}

                                    <div className="my-2">
                                        <TextField
                                            variant="outlined"
                                            label="* Name"
                                            className="form-control"
                                            name="name"
                                            type="text"
                                            value={formik.values.name}
                                            onChange={formik.handleChange}
                                            fullWidth
                                            InputLabelProps={{shrink: true}}
                                            size="small"
                                        />
                                        {formik.errors.name && (
                                            <p className="text-100 text-danger">
                                                {formik.errors.name}
                                            </p>
                                        )}
                                    </div>

                                    <div className="my-2">
                                        <TextField
                                            variant="outlined"
                                            label="* Surnames"
                                            className="form-control"
                                            name="surnames"
                                            type="text"
                                            value={formik.values.surnames}
                                            onChange={formik.handleChange}
                                            fullWidth
                                            InputLabelProps={{shrink: true}}
                                            size="small"
                                        />
                                        {formik.errors.surnames && (
                                            <p className="text-100 text-danger">
                                                {formik.errors.surnames}
                                            </p>
                                        )}
                                    </div>

                                    <div className="my-2">
                                        <TextField
                                            variant="outlined"
                                            label="* Email"
                                            className="form-control"
                                            name="email"
                                            type="text"
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            fullWidth
                                            InputLabelProps={{shrink: true}}
                                            size="small"
                                        />
                                        {formik.errors.email && (
                                            <p className="text-100 text-danger">
                                                {formik.errors.email}
                                            </p>
                                        )}
                                    </div>

                                </Grid>
                            </Grid>
                        </Box>
                        <Box border={1} className="p-20 mt-2 box-default">
                            <Grid item xs={12} md={12} lg={12} className="border-bottom">
                                <h4 className="p-0 mt-0">Reports</h4>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={formik.values.isAllowedReportProductionPlan}
                                            onChange={formik.handleChange}
                                            name="isAllowedReportProductionPlan"
                                            color="primary"
                                        />
                                    }
                                    label="Production Plan"
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={formik.values.isAllowedReportSupplyChain}
                                            onChange={formik.handleChange}
                                            name="isAllowedReportSupplyChain"
                                            color="primary"
                                        />
                                    }
                                    label="Supply Chain"
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={formik.values.isAllowedReportAvailability}
                                            onChange={formik.handleChange}
                                            name="isAllowedReportAvailability"
                                            color="primary"
                                        />
                                    }
                                    label="MSL Report"
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={formik.values.isAllowedReportConsumption}
                                            onChange={formik.handleChange}
                                            name="isAllowedReportConsumption"
                                            color="primary"
                                        />
                                    }
                                    label="Consumption"
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={formik.values.isAllowedReportMaxOrder}
                                            onChange={formik.handleChange}
                                            name="isAllowedReportMaxOrder"
                                            color="primary"
                                        />
                                    }
                                    label="Max Ordered Qty."
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={formik.values.isAllowedReportStock}
                                            onChange={formik.handleChange}
                                            name="isAllowedReportStock"
                                            color="primary"
                                        />
                                    }
                                    label="Stock Report"
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={formik.values.isAllowedReportStockQty}
                                            onChange={formik.handleChange}
                                            name="isAllowedReportStockQty"
                                            color="primary"
                                        />
                                    }
                                    label='Stock Report "Pieces"'
                                />
                            </Grid>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                        <Box border={1} className="p-20 box-default">
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12} lg={12} className="border-bottom">
                                    <h4 className="p-0 mt-0">Access</h4>
                                </Grid>

                                <Grid item xs={12} md={12} lg={12}>
                                    <div className="my-2 mb-2">
                                        <Autocomplete
                                            options={listCompany}
                                            value={defaultCompany}
                                            getOptionLabel={(option) =>
                                                option.name ? option.name : ""
                                            }
                                            getOptionSelected={(option, value) =>
                                                option?.value === value?.value
                                            }
                                            onChange={onChangeCompany}
                                            fullWidth
                                            size="small"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="* Company"
                                                    name="idCompany"
                                                    variant="outlined"
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                        {formik.errors.idCompany && (
                                            <p className="text-100 text-danger">
                                                {formik.errors.idCompany}
                                            </p>
                                        )}
                                    </div>

                                    <div className="my-2 mb-2">
                                        <Autocomplete
                                            options={listCountry}
                                            value={defaultCountry}
                                            getOptionLabel={(option) =>
                                                option.name ? option.name : ""
                                            }
                                            getOptionSelected={(option, value) =>
                                                option?.value === value?.value
                                            }
                                            onChange={onChangeCountry}
                                            fullWidth
                                            size="small"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="* Country"
                                                    name="idCountry"
                                                    variant="outlined"
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                        {formik.errors.idCountry && (
                                            <p className="text-100 text-danger">
                                                {formik.errors.idCountry}
                                            </p>
                                        )}
                                    </div>

                                    <div className="my-2 mb-2">
                                        <Autocomplete
                                            options={listRegion}
                                            value={defaultRegion}
                                            getOptionLabel={(option) => option.name}
                                            getOptionSelected={(option, value) =>
                                                option?.value === value?.value
                                            }
                                            onChange={onChangeRegion}
                                            fullWidth
                                            size="small"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Region"
                                                    name="idRegion"
                                                    variant="outlined"
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    </div>

                                    <div className="my-2">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={formik.values.isAllRegion}
                                                    onChange={formik.handleChange}
                                                    name="isAllRegion"
                                                    color="primary"
                                                />
                                            }
                                            label="All Region"
                                        />
                                    </div>

                                    <div className="my-2 mb-2">
                                        <Autocomplete
                                            options={listSubregion}
                                            value={defaultSubregion}
                                            getOptionLabel={(option) => option.name}
                                            getOptionSelected={(option, value) =>
                                                option?.value === value?.value
                                            }
                                            onChange={onChangeSubregion}
                                            fullWidth
                                            size="small"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Subregion"
                                                    name="idSubregion"
                                                    variant="outlined"
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    </div>

                                    <div className="my-2">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={formik.values.isAllSubregion}
                                                    onChange={formik.handleChange}
                                                    name="isAllSubregion"
                                                    color="primary"
                                                />
                                            }
                                            label="All Subregion"
                                        />
                                    </div>

                                    <div className="my-2 mb-2">
                                        {defaultRole?.key !== "ROLE_MULTI_STORE" && (
                                            <Autocomplete
                                                options={listLocation}
                                                value={defaultLocation}
                                                getOptionLabel={(option) => option.name}
                                                getOptionSelected={(option, value) =>
                                                    option?.value === value?.value
                                                }
                                                onChange={(event, newValue) => {
                                                    if (newValue !== null) {
                                                        formik.values.idLocation = newValue.id
                                                        setDefaultLocation({
                                                            id: newValue.id,
                                                            name: newValue.name
                                                        });
                                                    } else {
                                                        setDefaultLocation(null)
                                                        formik.values.idLocation = null
                                                    }
                                                }}
                                                fullWidth
                                                size="small"
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Store"
                                                        name="idLocation"
                                                        variant="outlined"
                                                        InputLabelProps={{ shrink: true }}
                                                    />
                                                )}
                                            />
                                        )}
                                        {defaultRole?.key === "ROLE_MULTI_STORE" && (
                                            <Autocomplete
                                                multiple
                                                value={defaultLocations}
                                                defaultValue={defaultLocations}
                                                options={listLocation}
                                                filterSelectedOptions
                                                size="small"
                                                getOptionLabel={(option) => option.name}
                                                onChange={(_, value) => {
                                                    formik.values.idLocation = value.map(val => {
                                                        return val.id
                                                    })

                                                    let locations = value.map(val => {
                                                        return {
                                                            id: val.id,
                                                            name: val.name
                                                        }
                                                    })

                                                    setDefaultLocations(locations)
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        label="Store"
                                                        name="idLocation"
                                                        InputLabelProps={{ shrink: true }}
                                                    />
                                                )}
                                            />
                                        )}
                                    </div>

                                    <div className="my-2">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={
                                                        formik.values.isAllLocation
                                                    }
                                                    onChange={formik.handleChange}
                                                    name="isAllLocation"
                                                    color="primary"
                                                />
                                            }
                                            label="All Location"
                                        />
                                    </div>

                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    {(formik?.alues?.role?.key !== 'ROLE_ADMIN' && formik?.values?.idCompany) && (
                        <Grid item xs={12} md={12} lg={12}>
                            <Box border={1} className="p-20 box-default">
                                <Grid container spacing={1}>
                                    <Grid item xs={12} md={12} lg={12} className="border-bottom">
                                        <h4 className="p-0 mt-0">Microsoft (OKTA)</h4>
                                    </Grid>

                                    <Grid item md={12}><div style={{height: 10}}></div></Grid>

                                    <Grid item md={12}>
                                        <TextField
                                            name="microsoftId"
                                            value={formik.values.microsoftId || ""}
                                            onChange={formik.handleChange}
                                            className="form-control"
                                            type="text"
                                            fullWidth
                                            size="small"
                                            variant="outlined"
                                            label="ID"
                                            InputLabelProps={{shrink: true}}
                                        />
                                    </Grid>

                                    <Grid item md={12}>
                                        <TextField
                                            name="microsoftEmail"
                                            value={formik.values.microsoftEmail || ""}
                                            onChange={formik.handleChange}
                                            className="form-control"
                                            type="text"
                                            fullWidth
                                            size="small"
                                            variant="outlined"
                                            label="Email"
                                            InputLabelProps={{shrink: true}}
                                        />
                                    </Grid>
                                    <Grid item md={12}>
                                        <a href="https://azure.microsoft.com/" target="_blank" rel="noreferrer">Panel Microsoft</a>
                                    </Grid>

                                    <Grid item md={12}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={formik.values.isAccessMicrosoft}
                                                    onChange={formik.handleChange}
                                                    name="isAccessMicrosoft"
                                                    color="primary"
                                                />
                                            }
                                            label="Access from Microsoft (OKTA)"
                                        />
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    )}

                    <Grid item xs={12} md={12} lg={12}>
                        <Box border={1} className="p-20 box-default">
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6} lg={6}>
                                    <h4 className="p-0 mt-0">Security</h4>
                                    <p>
                                        - You only have to fill in the password if you
                                        want to change it.
                                    </p>
                                    <p>
                                        - Deactivate the option "Access to the platform"
                                        in case you want to block the user.
                                    </p>
                                </Grid>
                                <Grid item xs={12} md={6} lg={6}>

                                    <div className="mb-2">
                                        <Autocomplete
                                            options={listRole}
                                            value={defaultRole}
                                            getOptionLabel={(option) =>
                                                option.name || ""
                                            }
                                            getOptionSelected={(option, value) =>
                                                option.value === value.value
                                            }
                                            onChange={(event, newValue) => {
                                                if (newValue !== null) {
                                                    formik.values.role = newValue
                                                    setDefaultRole(newValue)
                                                } else {
                                                    setDefaultRole({})
                                                }
                                            }}
                                            fullWidth
                                            size="small"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="* Type of user (Role)"
                                                    name="role"
                                                    variant="outlined"
                                                />
                                            )}
                                        />
                                    </div>

                                    <div className="mb-2">
                                        <TextField
                                            name="password"
                                            value={formik.values.password || ""}
                                            onChange={formik.handleChange}
                                            className="form-control"
                                            type="password"
                                            fullWidth
                                            size="small"
                                            variant="outlined"
                                            label="Password"
                                            inputProps={{
                                                autoComplete: "autocomplete=\"ÑÖcompletes\"",
                                                form: {
                                                    autoComplete: "off",
                                                },
                                            }}
                                            InputLabelProps={{shrink: true}}
                                        />
                                        {formik.errors.password && (
                                            <p className="text-100 text-danger">
                                                {formik.errors.password}
                                            </p>
                                        )}
                                    </div>

                                    <div className="mb-2">
                                        <DatePickerSimple
                                            name="authDate"
                                            label="Login code expiration date"
                                            value={formik.values.authDate || ""}
                                            onChange={formik.handleChange}
                                        />
                                        {formik.errors.authDate && (
                                            <p className="text-100 text-danger">
                                                {formik.errors.authDate}
                                            </p>
                                        )}
                                    </div>

                                    <div className="mb-2" style={{display: 'flex'}}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={formik.values.isActive}
                                                    onChange={formik.handleChange}
                                                    name="isActive"
                                                    color="primary"
                                                />
                                            }
                                            label="Access to the platform"
                                        />
                                    </div>

                                    <div className="mb-2">
                                        {id && formik.values.role?.key !== "ROLE_ADMIN" && (
                                            <>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    type="button"
                                                    fullWidth
                                                    onClick={clickImpersonate}
                                                    disabled={!authDateToLogin}
                                                >
                                                    Impersonate
                                                </Button>

                                                {!authDateToLogin && (
                                                    <p className="text-100 text-danger">
                                                        Cannot impersonate, 2FA date expired.
                                                    </p>
                                                )}
                                            </>
                                        )}
                                    </div>

                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    <Grid container spacing={3} className="p-20">
                        <Grid item xs={12} md={6} lg={6}>&nbsp;</Grid>
                        <Grid item xs={6} md={3} lg={3}>
                            <Button
                                type="button"
                                color="secondary"
                                href="/admin/users/management"
                                fullWidth
                            >
                                {id ? (<><ArrowBackIos/> Back to list </>) : "Cancel"}
                            </Button>
                        </Grid>
                        <Grid item xs={6} md={3} lg={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                fullWidth
                            >
                                {id ? "Modify" : "Create"}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </form>

            {/*<Microsoft />*/}
        </>
    )
}

// Declaramos los objetos del formulario
function defaultValue() {
    return {
        id: "",
        name: "",
        surnames: "",
        email: "",
        password: "",
        isActive: true,
        isAccessMicrosoft: false,
        role: null,
        authDate: "",
        microsoftId: "",
        microsoftEmail: "",

        idCompany: null,
        idCountry: null,
        idRegion: null,
        idSubregion: null,
        idLocation: null,

        isAllRegion: false,
        isAllLocation: false,
        isAllSubregion: false,

        isAllowedReportProductionPlan: false,
        isAllowedReportSupplyChain: false,
        isAllowedReportAvailability: false,
        isAllowedReportConsumption: false,
        isAllowedReportMaxOrder: false,
        isAllowedReportStock: false,
        isAllowedReportStockQty: false,
    }
}

function validationSchema() {
    return {
        name: Yup.string().required("The name is required."),
        surnames: Yup.string().required("Surnames is required"),
        email: Yup.string().email("The email is not correct.").required("Email is required")
    }
}
