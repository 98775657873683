import useAuth from "../../hooks/useAuth"
import React, {useEffect, useState} from "react"
import {listApi} from "../../services/user/request"
import {listApi as listLocationApi, listCountriesApi as listCountryLocationApi} from "../../services/user/location"
import {listApi as listSubregionApi} from "../../services/user/subregion"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid";
import {getCatalogByCompanyApi} from "../../services/user/catalog";
import InputAutocomplete from "../Inputs/InputAutocomplete";
import InputSelect from "../Inputs/InputSelect";
import {Box, List, ListItemText, Modal} from "@mui/material";
import Typography from "@mui/material/Typography";
import {VscChromeClose} from "react-icons/vsc";
import ListItem from "@material-ui/core/ListItem";
import LoadingSpinner from "../LoadingSpinner";

export default function SelectStore(props) {

    const {
        onChange
    } = props

    const {logout, auth} = useAuth()
    const [locationName, setLocationName] = useState("")
    const [loadingLocation, setLoadingLocation] = useState(false)

    const [region, setRegion] = useState([])
    const [subregion, setSubregion] = useState([])
    const [country, setCountry] = useState([])
    const [location, setLocation] = useState([])

    const [initScreen, setInitScreen] = useState(null)

    const [open, setOpen] = React.useState(false)
    const [idRegion, setIdRegion] = useState(null)
    const [idSubregion, setIdSubregion] = useState(null)
    const [idCountry, setIdCountry] = useState(null)
    const [idLocation, setIdLocation] = useState(null)

    useEffect(() => {
        (async () => {
            if (!auth?.location?.id) {
                await searchRegion()
            }

            setIdLocation(auth?.location?.id)
        })()
    }, [auth])

    useEffect(() => {
        searchSubregion()
    }, [idRegion])

    useEffect(() => {
        searchCountry()
    }, [idSubregion])

    useEffect(() => {
        searchLocation()
    }, [idCountry])

    const searchRegion = async () => {
        if (auth.isAllRegion) {
            const response = await listApi(logout, "region");
            if (response.status) {
                setRegion(response.data)
            }
        } else {
            setRegion([{
                id: auth.region?.id,
                name: auth.region?.name
            }])
            setIdRegion(auth.region?.id)
        }
    }


    const searchSubregion = async () => {
        if (idRegion && !auth.subregion?.id) {
            const response = await listSubregionApi(logout, {idRegion: idRegion})
            if (response.status) {
                setSubregion(response.data)
            }
        } else {
            setSubregion([{
                id: auth.subregion?.id,
                name: auth.subregion?.name
            }])
            setIdSubregion(auth.subregion?.id)
        }
    }

    const searchCountry = async () => {
        if ((idRegion && idSubregion) && !auth.country?.id) {
            const response = await listCountryLocationApi(logout, {
                idRegion: idRegion,
                idSubregion: idSubregion
            });
            if (response.status) {
                setCountry(response.data)
            }
        } else {
            setCountry([{
                id: auth.country?.id,
                name: auth.country?.name
            }])
            setIdCountry(auth.country?.id)
        }
    }

    const searchLocation = async () => {
        if (idRegion && idSubregion && idCountry) {
            setLoadingLocation(true)
            const response = await listLocationApi(logout, {
                idRegion: idRegion || auth.region?.id,
                idSubregion: idSubregion || auth.subregion?.id,
                idCountry: idCountry || auth.country?.id
            })
            if (response.status) {
                setLocation(response.data)
                setLoadingLocation(false)
            }
        }

    }

    const handleChangeLocation = async (event) => {
        if (event.target.name === "region") {
            setIdRegion(event.target.value)

            setSubregion([])
            setIdSubregion(null)

            setCountry([])
            setIdCountry(null)

            setLocation([])
        } else if (event.target.name === "subregion") {
            setIdSubregion(event.target.value)

            setCountry([])
            setCountry([])
            setIdCountry(null)

            setLocation([])
        } else if (event.target.name === "country") {
            setIdCountry(event.target.value)

            setLocation([])
        }
    }

    const handleClose = () => setOpen(false)

    return (
        <>
            <button
                type="button"
                onClick={() => setOpen(true)}
                style={{
                    width: '100%',
                    textAlign:'center',
                    backgroundColor: '#fff',
                    color: '#ef6f28',
                    border: '1px solid #ef6f28',
                    borderRadius: '5px',
                    height: '37px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'

                }}
            >
                {idLocation ? locationName : "Select the store"}
            </button>

            <Modal
                open={open}
                onClose={handleClose}
                BackdropProps={{
                    style: {
                        background: 'rgba(0, 0, 0, .5)',
                        backdropFilter:' blur(5px)',
                    },
                }}
            >
                <Box sx={{...style}}>
                    <Grid container spacing={1} style={{margin: 0}}>
                        <Grid item md={7}>
                            <h3 style={{marginTop: 5}}>Select the store you wish to interact with</h3>
                        </Grid>
                        <Grid item md={5}>
                            <div
                                style={{
                                    float: 'right',
                                    fontSize: 20,
                                    marginTop: '-15px'
                                }}
                            >
                                <VscChromeClose
                                    onClick={handleClose}
                                    style={{cursor: 'pointer'}}
                                />
                            </div>
                        </Grid>
                        <Grid item md={12} />

                        <Grid item md={4}>
                            <InputSelect
                                label="Zone"
                                name="region"
                                onChange={handleChangeLocation}
                                value={idRegion}
                                disabled={auth.region?.id}
                                options={region}
                            />
                        </Grid>

                        <Grid item md={4}>
                            <InputSelect
                                label="Region"
                                name="subregion"
                                onChange={handleChangeLocation}
                                value={idSubregion}
                                disabled={!idRegion || auth.subregion?.id}
                                options={subregion}
                            />
                        </Grid>

                        <Grid item md={4}>
                            <InputSelect
                                label="Country"
                                name="country"
                                onChange={handleChangeLocation}
                                value={idCountry}
                                disabled={!idSubregion || auth.country?.id}
                                options={country}
                            />
                        </Grid>

                        <Grid item md={12}>
                            <LoadingSpinner loading={loadingLocation} />
                            <List style={{maxHeight: 200, overflowY: 'auto', width: '100%'}}>
                                {location.map((item, index) => (
                                    <ListItem
                                        key={index}
                                        dense
                                        button
                                        style={{color: item.id === idLocation ? '#ef6f28' : '#4d4d4d'}}
                                        onClick={() => {
                                            onChange({
                                                target: {
                                                    name: "idLocation",
                                                    value: {
                                                        id: item.id,
                                                        name: item.name,
                                                        idRegion: idRegion,
                                                        idSubregion: idSubregion,
                                                        idCountry: idCountry,
                                                        customerRefPo: item.customerRefPo,
                                                        customerRefPoTov: item.customerRefPoTov
                                                    }
                                                }
                                            })
                                            setLocationName(item.name)
                                            setIdLocation(item.id)
                                            setOpen(false)
                                        }}
                                    >
                                        <ListItemText primary={`${item.name}`} />
                                    </ListItem>
                                ))}
                            </List>
                        </Grid>

                    </Grid>
                </Box>
            </Modal>
        </>
    )
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: 0,
    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    pt: 2,
    px: 4,
    pb: 3,
    borderRadius: '8px',
    backgroundColor: '#fff',
}
