import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { listApi } from "../../../../services/admin/request";
import useAuth from "../../../../hooks/useAuth";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import NewTable from "../../../../components/Admin/NewTable";
import EditIcon from "@mui/icons-material/Edit";
import {IconButton} from "@mui/material";
import {GoDotFill} from "react-icons/go";

export default function Product() {
    const { id } = useParams()
    const { logout } = useAuth()

    const [state, setState] = useState(initialValues)
    const [loading, setLoading] = useState(false)
    const [row, setRow] = useState([])

    useEffect(() => {
        getData()
    }, [state])

    const getData = async () => {
        setLoading(true)
        const response = await listApi(logout, `location/products/${id}?field=${state.sort.field}&sort=${state.sort.sort}`);
        if (response.length > 0) {
            const temp = response.map((doc) => {

                let price = '-';
                if (doc.price) {
                    price = doc.price.price ? `${doc.price.price} ${doc.price.currency_symbol}` : '-';
                }

                let priceTariff = '-';
                if (doc.priceTariff) {
                    priceTariff = doc.priceTariff.price ? `${doc.priceTariff.price} ${doc.priceTariff.currency_symbol}` : '-';
                }

                let warehouse = '-';
                if (doc.warehouse) {
                    warehouse = doc.warehouse.name ? doc.warehouse.name : '-';
                }
                return {
                    ...doc,
                    stockOnHand: doc.stockOnHand ? doc.stockOnHand : '0',
                    price: price,
                    priceTariff: priceTariff,
                    warehouse: warehouse
                };
            });
            setRow(temp)
        }
        setLoading(false)
    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box border={1} className="box-default">
                        <NewTable
                            columns={tableColumns()}
                            rows={row}
                            rowCount={row.length}
                            filter={state}
                            setFilter={setState}
                            loading={loading}
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
    return {
        offset: 0,
        sort: {field: "code", sort: "desc"},
    }
}

function tableColumns() {
    return [
        {
            headerName: "CAN BUY",
            field: "con_by",
            sortable: true,
            flex: 1,
            renderCell: (params) => {
                let can_bay = true

                if (params?.idWarehouse === '-') {
                    can_bay = false
                }

                if (params?.price === '-' && !params?.priceTariff === '-') {
                    can_bay = false
                }

                if (params?.stockOnHand === '0') {
                    can_bay = false
                }

                return (
                    <>
                        {can_bay ? (
                            <GoDotFill color={'#90ee90'} size={20}/>
                        ) : (
                            <GoDotFill color={'#f8c3c0'} size={20}/>
                        )}
                    </>
                )
            },
        },
        {
            headerName: "CODE",
            field: "code",
            sortable: true,
            flex: 1
        },
        {
            headerName: "NAME",
            field: "name",
            sortable: true,
            flex: 1
        },
        {
            headerName: "DESCRIPTION",
            field: "description",
            sortable: false,
            flex: 1
        },
        {
            headerName: "PRICE",
            field: "price",
            sortable: false,
            flex: 1
        },
        {
            headerName: "PRICE TARIFF",
            field: "priceTariff",
            sortable: false,
            flex: 1
        },
        {
            headerName: "WAREHOUSE",
            field: "warehouse",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        {params.idWarehouse ? (
                            <a
                                href={`/admin/warehouse/edit/${params.idWarehouse}/1`}
                                target="_blank"
                                style={{marginLeft: 10}}
                            >
                                {params.warehouseName}
                            </a>
                        ) : (
                            <span>-</span>
                        )}
                    </>
                )
            },
        },
        {
            headerName: "STOCK ON HAND",
            field: "stockOnHand",
            sortable: true,
            flex: 1
        },
        {
            headerName: "ACTION",
            field: "action",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        <a
                            href={`/admin/product/edit/${params.id}/4`}
                            target="_blank"
                        >
                            <IconButton size="small">
                                <EditIcon fontSize="inherit"/>
                            </IconButton>
                        </a>
                    </>
                )
            },
        },
    ]
}
