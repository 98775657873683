import React, {useEffect, useMemo, useState} from "react";
import {listApi} from "../../../../services/admin/request";
import {exportWarehouseProductStockExportApi} from "../../../../services/admin/warehouse"
import useAuth from "../../../../hooks/useAuth";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {useParams} from "react-router-dom";
import TableBase, {SelectColumnFilter} from "../../../../components/Admin/TableFilterBaser";
import TextField from "@material-ui/core/TextField";
import {saveStockDataReq} from "../../../../services/admin/product";
import {Search} from "@material-ui/icons";
import {InputBase, styled} from "@material-ui/core";
import Loading from "../../../../components/User/Loading";
import moment from "moment/moment";
import InputButtonLoading from "../../../../components/Inputs/InputButtonLoading";

const EditableCell = ({
                          value: initialValue,
                          row: {original},
                          column: {Header, id, inputType, widthCol},
                      }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);

    const onChange = (e) => {
        let val = e.target.value;

        if (val < 0) {
            val = val * -1;
        }

        setValue(val);
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
        setValue(
            inputType === "number" ? initialValue ?? 0 : initialValue ?? ""
        );
    }, [initialValue]);

    return (
        <>
            <TextField
                variant="outlined"
                value={value || ""}
                className="form-control"
                placeholder={Header}
                type={inputType ?? "text"}
                name={`${id}_${original.idProduct}_${original.idWarehouse}`}
                style={{width: widthCol, minWidth: "70px"}}
                InputLabelProps={{ shrink: true }}
                onChange={onChange}
                size="small"
                sx={{
                    "& label.Mui-focused": {
                        display: "none"
                    },
                    "& legend": {
                        display: "none"
                    }
                }}
                inputProps={{min: 0, style: {fontSize: 15}}}
            />
        </>
    );
};

export default function Management() {
    const {id} = useParams();
    const {logout} = useAuth();

    const [products, setProducts] = useState(null);
    const [selectedRows, setSelectedRows] = useState({});
    const [msgSuccess, setMsgSuccess] = useState(false);
    const [msgDanger, setMsgDanger] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingExport, setLoadingExport] = useState(false)

    const columns = useMemo(
        () => [
            {
                Header: "COMPANY",
                accessor: "company",
                width: 10,
                Filter: SelectColumnFilter
            },
            {
                Header: "NAME",
                accessor: "name"
            },
            {
                Header: "DESCRIPTION",
                accessor: "description",
                disableFilters: true
            },
            {
                Header: "REF.",
                accessor: "sort"
            },
            {
                Header: "UNIT PRICE",
                accessor: "unitPriceSymbol",
                //width: 70,
                disableFilters: true
            },
            {
                Header: "PCS PER PACK",
                accessor: "unitsPerPack",
                disableFilters: true,
                width: 70,
            },
            {
                Header: "CUSTOMER ORDERS (since 1st Jan 2024)",
                accessor: "stockOnOrder",
                disableFilters: true,
                width: 10
            },
            {
                Header: "STOCK ON HAND",
                accessor: "stockOnHand",
                // Cell: EditableCell,
                inputType: "number",
                width: 80,
                disableFilters: true
            },
            {
                Header: "STOCK ON HAND VALUE",
                accessor: "stockOnHandValueSymbol",
                //width: 80,
                disableFilters: true
            },
            {
                Header: "STOCK IN TRANSIT",
                accessor: "stockInTransit",
                // Cell: EditableCell,
                inputType: "number",
                width: 20,
                disableFilters: true,
            },
            {
                Header: "STOCK IN TRANSIT VALUE",
                accessor: "stockInTransitValueSymbol",
                //width: 20,
                disableFilters: true
            },
            {
                Header: "COMMENT",
                accessor: "comment",
                Cell: EditableCell,
                disableFilters: true,
                width: 235
            },
            /* 
            {
                Header: "BALANCE IDP PO",
                accessor: "stockBalanceIdpPo",
                Cell: EditableCell,
                inputType: "number",
                width: 10,
                disableFilters: true
            },
            */
            {
                Header: "STOCK (ON HAND + IN TRANSIT) VALUE",
                accessor: "stockOnHandPlusInTransitValueSymbol",

                disableFilters: true
            },
            {
                Header: "LAST UPDATE",
                accessor: "lastUpdate",
                width: 100,
                disableFilters: true
            },
        ],
        []
    );

    useEffect(() => {
        getData()
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getData = async () => {
        setLoading(true)
      
        const response = await listApi(
            logout,
            `warehouse/product-stock/${id}`
        );
        if (response.status) {
            const prods = response.data.map(doc => {
                let up=doc.unitPrice ?  parseFloat(doc.unitPrice).toFixed(2) : ""
                let ps= doc.priceSymbol ? doc.priceSymbol : ""
                let ups = up ? up + " "+ ps : ""
                let sohv=  doc.stockOnHandValue ? parseFloat(doc.stockOnHandValue).toFixed(2) : ""
                let sohvs = sohv ? sohv + " " + ps : ""
                let sitv = doc.stockInTransitValue ? parseFloat(doc.stockInTransitValue).toFixed(2) : ""
                let sitvs = sitv ? sitv + " " + ps : ""
                let sohpitv = (doc.stockOnHandValue || doc.stockInTransitValue) ? parseFloat(doc.stockOnHandValue + doc.stockInTransitValue).toFixed(2) : ""
                let sohpitvs = sohpitv?  sohpitv + " " + ps : ""
                return {
                    ...doc,
                    lastUpdate: moment.unix(doc.lastUpdate).utc().format('DD-MMM-YYYY HH:mm A'),
                    unitPriceSymbol: ups,
                    stockOnHandValueSymbol: sohvs,
                    stockInTransitValueSymbol: sitvs,
                    stockOnHandPlusInTransitValueSymbol:  sohpitvs
                }
            })
            setProducts(prods)
            setLoading(false)
        } else {
            setLoading(false)
        }
        setMsgSuccess(null)
        setMsgDanger(null)
    }

    const saveStockData = async (event) => {
        event.preventDefault();
        setMsgSuccess(null);
        setMsgDanger(null)

        const href = `warehouse/set-product-stock`;

        const data = new FormData(event.target);

        data.append("idWarehouse", id);

        let object = {};

        data.forEach((value, key) => {
            object[key] = value;
        });

        const response = await saveStockDataReq(object, href);

        if (response.status) {
            setMsgSuccess('Stock data has been successfully saved')
            getData();
        }
    };

    const exportTable = async () => {

        let data = {
            idWarehouse: id,
            company: document.querySelector('input[name="company"]').value ?? "",
            name: document.querySelector('input[name="name"]').value,
            sort: document.querySelector('input[name="sort"]').value
        }
        
        setMsgSuccess(null)
        setMsgDanger(null)
        setLoadingExport(true)
        const response = await exportWarehouseProductStockExportApi(logout, data);

        if (response.status) {
            window.open(response.data, '_blank');
            setMsgSuccess('Export finished')
        } else {
            setMsgDanger(response.errors)
        }
        setLoadingExport(false)
    }

    return (
        <>
            {msgSuccess && (
                <Grid item xs={12} md={12} lg={12}>
                    <Box className="alert-success">
                        {msgSuccess}
                    </Box>
                </Grid>
            )}

            {msgDanger && (
                <Grid item xs={12} md={12} lg={12}>
                    <Box className="alert-danger">
                        {msgDanger.map((item) => {
                            return (
                                <span>
                                    {item} <br/>{" "}
                                </span>
                            );
                        })}
                    </Box>
                </Grid>
            )}

            <Loading show={loading} />

            { (products && !loading) && (
            <form onSubmit={saveStockData}>
                <Grid container spacing={3}>
                  
                    <Grid item xs={12} md={12} lg={12}>
                        <div style={{ marginRight: "20px", textAlign: "right" }}>
                            <InputButtonLoading
                                onClick={exportTable}
                                loading={loadingExport}
                                label={`Export Stock`}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <Box border={1} className="box-default">
                            <Box border={1} className="box-default">
                                <TableBase
                                    columns={columns}
                                    data={products}
                                    selectedRows={selectedRows}
                                    onSelectedRowsChange={setSelectedRows}
                                    upInfo={true}
                                    isButtonVisible={true}/>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </form>
            )}
        </>
    );
}
