import React, {useEffect, useState} from "react";
import {Chip, Grid} from "@mui/material";
import Box from "@material-ui/core/Box";
import {apiListProductsRelation} from "../../../../services/admin/warehouse";
import NewTable from "../../../../components/Admin/NewTable";
import InputButtonLoading from "../../../../components/Inputs/InputButtonLoading";
import {apiUpdateProduct} from "../../../../services/admin/container";

export default function Products(props) {

    const {
        idContainer,
        idCompany,
        idWarehouse,
        setMsgError,
        product,
        setProduct,
        stateField,
        listStatus,
        handleSubmit,
        loadingContainerSave,
        setMsgSuccess,
        myRef
    } = props

    
    const [rows, setRows] = useState([])
    const [state, setState] = useState(initialValues())
    const [rowCount, setRowCount] = useState(null)
    const [loading, setLoading] = useState(true)
    const [loadingSave, setLoadingSave] = useState(false)
    const [nameStatusEdit, setNameStatusEdit] = useState(null)
    const [totalValue, setTotalValue] = useState(null)
    const [totalValueUnit, setTotalValueUnit] = useState(null)

    useEffect(() => {
        setNameStatusEdit(null)
        if (listStatus.length > 0) {
            setNameStatusEdit(listStatus[0]?.name)
        }
    }, [listStatus])

    useEffect(() => {
        if (idWarehouse) {
            setLoading(true)
            apiListProductsRelation({
                idCompany: idCompany || null,
                isCheckout: true,
                idWarehouse: idWarehouse
            }).then(resp => {
                setRowCount(resp.data?.total)
                let sumTotal=0
                let unitSumTotal=""
                let temp = resp.data.data?.map((doc, key) => {

                    // buscamos en el producto el producto que seleccionamos
                    let temp = product.find(item => +item.id === +doc.idProduct)
                    sumTotal+= temp? (temp.quantity*doc.unitPrice): 0
                    unitSumTotal=doc.priceSymbol ? doc.priceSymbol:""
                    let up=doc.unitPrice ?  parseFloat(doc.unitPrice).toFixed(2) : ""
                    let ps= doc.priceSymbol ? doc.priceSymbol : ""
                    let ups = up ? up + " "+ ps : ""
                    return {
                        ...doc,
                        id: doc.idProduct,
                        packs: temp ? temp.quantity : "",
                        unitPriceSymbol: ups,
                        total: (temp && doc.unitPrice)? (temp.quantity*doc.unitPrice).toFixed(2): "",
                        totalSymbol: (temp && doc.unitPrice && doc.priceSymbol)? (temp.quantity*doc.unitPrice).toFixed(2)+" "+doc.priceSymbol  : "",
                    }
                })

                setRows(temp) 
                setTotalValue(sumTotal)
                setTotalValueUnit(unitSumTotal)
                setLoading(false)
            }).catch(err => {
                setMsgError(err.response?.data?.msg)
                setLoading(false)
                setRows([])
            })
        }
    }, [idWarehouse, idCompany, stateField.products])

    const handleChangeCell = (e) => {
        let idProduct = e.target.id
        let quantity = e.target.value.trim()

        // Convertir el valor de quantity a número (o a null si está vacío)
        //quantity = quantity !== "" ? quantity : 0

        // Crear un nuevo array para las filas
        const updatedRows = rows.map(item => {
            if (+item.idProduct === +idProduct) {
                  // Si la cantidad es 0, limpiar total y totalSymbol
                if (quantity === 0) {
                    return {
                        ...item,
                        pack:"",
                        total: "",
                        totalSymbol: "",
                    }
                }
                // Calcular el total y el símbolo del total
                const total = item.unitPrice ? (quantity * item.unitPrice).toFixed(2) : ""
                const totalSymbol = (item.unitPrice && item.priceSymbol) 
                    ? `${total} ${item.priceSymbol}` 
                    : ""
                return {
                    ...item,
                    packs: quantity,
                    total: total,
                    totalSymbol: totalSymbol,
                }
            }
            return item
        })
    
        // Actualizar el estado de rows
        setRows(updatedRows)
    
         // Actualizar el producto en el array de productos, permitiendo números negativos
        const updatedProducts = updatedRows.filter(item => (item.packs !== 0 && item.packs != ""))
        setProduct(updatedProducts.map(item => ({ id: item.idProduct, quantity: +item.packs })))
    
        // Calcular el total después de actualizar las filas
        const newTotal = updatedRows.reduce((acc, item) => {
            const itemTotal = parseFloat(item.total || 0)
            return acc + itemTotal
        }, 0)
        setTotalValue(newTotal.toFixed(2))
    }
    
    const handleSubmitProduct = () => {
        setMsgSuccess([])
        setMsgError([])
        state['statusIdOriginal'] = state.statusId
        setState({ ...state })
        setLoadingSave(true)
    
        // Enviar el estado actualizado de productos (sin los que tienen cantidad 0)
        const stateTemp = { id: idContainer, products: product }
        apiUpdateProduct(stateTemp).then(resp => {
            setMsgSuccess(resp.data?.msg)
            setLoadingSave(false)
            myRef.current.scrollIntoView()
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoadingSave(false)
            myRef.current.scrollIntoView()
        })
    }
        
    return (
        <Grid container spacing={3}>

            <Grid item xs={12} md={12}>
                <h3 className="fields-title-1">Container line
                    {stateField.statusIdOriginal !== 3 && (
                        <>
                            <span style={{fontWeight: 100, marginLeft: 5, float: 'right'}}>
                                <Chip color="info"
                                      label={`Locked: To update quantities, you need to be in ${nameStatusEdit}`}
                                      style={{marginTop: '-13px', fontWeight: 'bold'}}/>
                            </span>
                        </>
                    )}

                    {(idContainer && stateField.statusIdOriginal === 3) && (
                        <span style={{marginLeft: 5, float: 'right'}}>
                            <InputButtonLoading
                                onClick={handleSubmitProduct}
                                loading={loadingSave}
                                label="Save quantities"
                                variant="outlined"
                            />
                        </span>
                    )}
                
                {/* <h4 style={{marginTop:'10px'}}>TOTAL CONTAINER VALUE: {totalValue? parseFloat(totalValue).toFixed(2): totalValue} {totalValueUnit}</h4> */}

                </h3>
            </Grid>
           
            {idContainer ? (
                <Grid item xs={12} md={12}>
                    <NewTable
                        columns={tableColumns(stateField)}
                        handleChangeCell={handleChangeCell}
                        rows={rows || []}
                        rowCount={rowCount}
                        filter={state}
                        setFilter={setState}
                        loading={loading}
                    />
                </Grid>
            ) : (
                <Grid item xs={12} md={12}>
                    <p style={{textAlign: 'center', marginTop: 30}}>
                        To add products to a container, you must first create it.
                    </p>

                    <div style={{display: 'table', margin: 'auto'}}>
                        <InputButtonLoading
                            onClick={handleSubmit}
                            loading={loadingContainerSave}
                            label="Create container"
                            variant="outlined"
                        />
                    </div>

                    <div style={{height: 70}}></div>
                </Grid>
            )}
            
            <Grid item xs={12} md={12}>

                {(idContainer && stateField.statusIdOriginal === 3 && !loading) && (
                    <span style={{marginLeft: 5, float: 'right'}}>
                        <InputButtonLoading
                            onClick={handleSubmitProduct}
                            loading={loadingSave}
                            label="Save quantities"
                        />
                    </span>
                )}
            </Grid>

        </Grid>
    )
}

function tableColumns(props) {

    const editable = props.statusIdOriginal === 3 ? true : false

    return [
        {
            field: "name",
            headerName: "PRODUCT",
            flex: 2
        },
        {
            field: "description",
            headerName: "DESCRIPTION",
            flex: 2
        },
        {
            field: "item_reference",
            headerName: "ITEM REF.",
            flex: 1
        },
        {
            field: "unitPriceSymbol",
            headerName: "UNIT PRICE",
            flex: 1
        },
        {
            field: "unitsPerPack",
            headerName: "PCS PER PACK",
            flex: 1
        },
        {
            field: "packs",
            headerName: "PACKS",
            editable: editable,
            flex: 1
        },
        {
            field: "totalSymbol",
            headerName: "TOTAL",
            flex: 1
        },
    ]
}

function initialValues(props) {
    return {
        offset: 0,
        sort: {field: "id", sort: "desc"},
    }
}
