import React, {useState} from "react";
import {Chip, Grid} from "@mui/material";
import ButtonFile from "../../../components/Inputs/ButtonFile";
import InputSelect from "../../../components/Inputs/InputSelect";
import MsgError from "../../../components/Admin/MsgError";
import {apiImport} from "../../../services/admin/import";
import HeaderPage from "../../../components/Admin/HeaderPage";
import MsgSuccess from "../../../components/Admin/MsgSuccess";
import InputButtonLoading from "../../../components/Inputs/InputButtonLoading";
import ImportOrder from "../../../assets/import/order.xlsx";
import downloaderFile from "../../../utils/downloaderFile";
import History from "./History";

export default function Management() {
    const [loadingExport, setLoadingExport] = useState(false)
    const [loadingFile, setLoadingFile] = useState(false)

    const [type, setType] = useState(null)

    const [msgSuccess, setMsgSuccess] = useState([])
    const [msgErrorExport, setMsgErrorExport] = useState([])
    const [msgErrorImport, setMsgErrorImport] = useState([])

    const [fileType, setFileType] = useState(null)
    const [file, setFile] = useState(null)

    const optionsType = [
        {id: 1, name: "Order", type: "order"},

    ]

    const exportTemplate = (event) => {
        setMsgErrorExport([])
        setLoadingExport(false)

        downloaderFile(ImportOrder, "order.xlsx")
    }

    const importFile = (event) => {
        setFile(event.target.value[0])
        setLoadingFile(true)
        setMsgErrorImport([])
        setFileType(null)
        setMsgSuccess([])

        let responseType = detectTypeFile(event.target.value[0].name)

        if (responseType){
            const type = event.target.value[0].name.split(".")
            const typeFile = optionsType.find(item => item.type === type[0])

            let f = new FormData()
            f.append("file[]", event.target.value[0]?.binary)


            apiImport(typeFile?.id, f).then(resp => {
                setLoadingFile(false)
                setFile(null)
                setFileType(null)
                setMsgSuccess(resp.data?.msg)
            }).catch(err => {
                setLoadingFile(false)
                setMsgErrorImport(err.response.data?.msg, "Please note that orders containing any rows with errors will not be imported.")
                setFile(null)
                if (err.response.data?.fileErrors) {
                    downloaderFile(err.response.data?.fileErrors, "errors.xlsx")
                }

            })
        } else {
            setLoadingFile(false)
        }
    }

    const detectTypeFile = (file) => {
        const type = file.split(".")

        // Comprobamos el formato del fichero
        if (type[type.length - 1] !== 'xlsx') {
            setMsgErrorImport(['The file format is not valid (.xlsx)'])
            return false
        }

        // Buscamos el tipo de archivo
        const typeFile = optionsType.find(item => item.type === type[0])

        if (typeFile) {
            setFileType(typeFile?.name)
            return true
        } else {
            setMsgErrorImport(['The file name is not valid'])
            setFileType(null)
            return false
        }
    }

    return (
        <>
            <HeaderPage title="Import manager"/>

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <p className="w-600">
                        Remember that to carry out an import, it is necessary that the file you are going to upload has
                        the same structure as the template file.
                    </p>
                </Grid>

                <Grid item xs={12}>
                    <div style={{maxWidth: 300}}>
                        <InputSelect
                            label="Type"
                            name="type"
                            onChange={(e) => setType(e.target.value)}
                            value={type}
                            options={optionsType}
                        />
                        <div style={{paddingTop: 10}}>
                            <InputButtonLoading
                                onClick={exportTemplate}
                                loading={loadingExport}
                                label={`Get Template / Export`}
                                style={{width: '100%'}}
                            />
                        </div>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div style={{maxWidth: 600}}>
                        <MsgError msg={msgErrorExport}/>
                    </div>
                </Grid>

                <Grid item xs={12}>
                    <br/>
                    <br/>
                    <p className="w-600">
                        If you are certain that the file is correct, use the button below to import.
                        <br/>
                        Remember to keep the template name.
                        <br/>
                        Also, remember that the columns, both in quantity and in position and order, must be the same as those in the template.
                    </p>
                </Grid>

                <Grid item xs={12}>
                    <div style={{maxWidth: 300}}>

                        {fileType && (<Chip label={`Type: ${fileType}`} variant="outlined" style={{width: '100%', marginBottom: 20}}/>)}
                        <ButtonFile
                            label="Select File"
                            loading={loadingFile}
                            name="newImage"
                            onChange={importFile}
                            multiple={true}
                            value={file}
                            style={{width: '100%'}}
                        />

                    </div>
                </Grid>

                <Grid item xs={12}>
                    <div style={{maxWidth: 600}}>
                        <MsgError msg={msgErrorImport}/>
                        <MsgSuccess msg={msgSuccess}/>
                    </div>
                </Grid>
            </Grid>

            <br/>
            <br/>

            <History
                reload={loadingFile}
            />

        </>
    )
}

