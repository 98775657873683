import Frontend from "../../../../containers/User/Layouts/Frontend";
import HistoryPage from "../../../../containers/User/Transaction/History"

export default function History() {
    return (
        <Frontend title="Order History" menu="orderHistory">
            <HistoryPage />
        </Frontend>
    );
}

