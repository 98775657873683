import React from "react"
import Link from "@material-ui/core/Link";

export default function SubMenu (props) {
    const {name, totalProducts, active} = props

    return(
        <li className="nav-item">
            <Link href="#" className={`nav-link p-2 ${active ? "active" : ""}`} onClick={props.action} color="inherit" style={{textDecoration: 'none'}}>
                {name} ({totalProducts})
            </Link>
        </li>
    )
}