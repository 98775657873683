import React, {useEffect, useState} from "react";
import {apiLogMail} from "../../../services/admin/log";
import moment from "moment";
import {Chip, Grid} from "@mui/material";
import HeaderPage from "../../../components/Admin/HeaderPage";
import Table from "../../../components/Admin/NewTable";
import {DASH_MAX_ROW} from "../../../utils/constants";
import InputText from "../../../components/Inputs/InputText";

export default function LogMail() {
    const [rows, setRows] = useState([])
    const [msgError, setMsgError] = useState([])
    const [loading, setLoading] = useState(true)
    const [rowCount, setRowCount] = useState(0)
    const [state, setState] = useState(initialValues)
    const [selection, setSelection] = useState(["92"])

    useEffect(() => {
        setLoading(true)
        const timeOutId = setTimeout(() => {
            getItemsData()
        }, 500)
        return () => clearTimeout(timeOutId)
    }, [state])

    const getItemsData = () => {
        setMsgError([])

        // Renombramos en caso de lo que columna no coinicda con el nombre de la base de datos
        let field = state.sort.field
        if (state.sort.field === "roleName") { field = "role" }
        if (state.sort.field === "active") { field = "isActive" }
        if (state.sort.field === "access") { field = "lastActivity" }

        let params = {
            limit: DASH_MAX_ROW,
            offset: state.offset ?? 0,
            field: field,
            sort: state.sort.sort
        }

        if (state.mailTo) { params.mailTo = state.mailTo }

        apiLogMail(params).then(resp => {
            setRowCount(resp.data?.count)

            let temp = resp.data.data?.map(doc => {

                let bcc = doc.mailBcc?.split(",")

                // array to Chip
                let bccChip = bcc?.map((item, index) => {
                    return (
                        <Chip
                            key={index}
                            label={item}
                            style={{margin: 3}}
                            size="small"
                        />
                    )
                })

                return {
                    ...doc,
                    id: doc.id,
                    roleName: doc?.role?.niceName,
                    isSent: doc.isSent ? "Yes" : "No",
                    createdAt: moment.unix(doc.createdAt).format("D/M/Y"),
                    mailBcc: bccChip
                }
            })
            setRows(temp)
            setLoading(false)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            setRows([])
        })
    }

    const handleChangeSelection = (e) => {
        if (selection.includes(e.target.value)) {
            setSelection(selection.filter(item => item !== e.target.value))
        } else {
            setSelection([...selection, e.target.value])
        }
    }

    return (
        <>
            <HeaderPage title="Mail" />

            <Grid container spacing={1}>
                <Grid item xs={12}>
                </Grid>

                <Grid item xs={12}>


                    <Filters
                        state={state}
                        setState={setState}
                    />


                    <Table
                        columns={tableColumns()}
                        rows={rows}
                        rowCount={rowCount}
                        filter={state}
                        setFilter={setState}
                        loading={loading}
                    />
                </Grid>
            </Grid>
        </>
    );
}

function initialValues(props) {
    return {
        offset: 0,
        sort: { field: "id", sort: "desc" },
        mailTo: "",
    }
}

function tableColumns(handleChangeSelection, selection) {
    return [
        {
            field: "id",
            headerName: "ID",
            sortable: false,
            flex: 1
        },
        {
            field: "createdAt",
            headerName: "CREATED AT",
            sortable: false,
            flex: 1
        },
        {
            field: "mailFrom",
            headerName: "MAIL FROM",
            sortable: false,
            flex: 1
        },
        {
            field: "mailTo",
            headerName: "MAIL TO",
            sortable: false,
            flex: 1
        },
        {
            field: "mailBcc",
            headerName: "MAIL BCC",
            sortable: false,
            flex: 1
        },
        {
            field: "subject",
            headerName: "SUBJECT",
            sortable: false,
            flex: 1
        },
        {
            field: "isSent",
            headerName: "IS SENT",
            sortable: false,
            flex: 1
        }
    ]
}

function Filters(props) {

    const {state, setState} = props

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        state["offset"] = 0
        setState({...state})
    }

    return (
        <Grid container spacing={1} style={{marginBottom: '20px'}}>
            <Grid item xs={12}>
                <InputText
                    label="Mail To"
                    name="mailTo"
                    value={state.mailTo}
                    onChange={handleChange}
                />
            </Grid>
        </Grid>
    )
}
