import React, { useEffect, useState, useMemo } from "react"
import useAuth from "../../../../hooks/useAuth"
import {getProductWarehouseLocation, setProductWarehouseLocation} from "../../../../services/admin/warehouse"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import {useParams} from "react-router-dom";
import Button from "@material-ui/core/Button";
import TableBase from "../../../../components/Admin/TableBase";

export default function LocationProduct() {
    const { idWarehouse, idLocation } = useParams()
    const { logout } = useAuth()

    const [products, setProducts] = useState([])

    const [selectedRows, setSelectedRows] = useState({})
    const [msgSuccess, setMsgSuccess] = useState(false)
    const [msgDanger, setMsgDanger] = useState(null)

    const columns = useMemo(
        () => [
            {
                Header: "CODE",
                accessor: "code",
            },
            {
                Header: "NAME",
                accessor: "name",
            },
            {
                Header: "DESCRIPTION",
                accessor: "description",
            },
            {
                Header: "REFERENCE",
                accessor: "item_reference",
            },
            {
                Header: "VISIBLE",
                accessor: "visible",
            },
            {
                Header: "STORE PRODUCT RELATIONSHIP",
                accessor: "disabled",
            },
        ],
        []
    );

    useEffect(() => {
        (async () => {
            const response = await getProductWarehouseLocation(logout,{
                idWarehouse,
                idLocation
            });
            if (response.status) {
                let position = {}
                let product = []

                response.data.forEach( (doc, key) => {

                    let visible = "Yes"
                    if (!doc.productActive) {
                        visible = "No"
                    }

                    let disabled = (<span style={{color: "green"}}>Yes</span>)
                    if (!doc.locationBuyProduct) {
                        // La tienda no tiene el producto disponible
                        disabled = (<span style={{color: "red"}}>No</span>)
                    }

                    product.push({
                        ...doc,
                        visible: visible,
                        disabled: disabled
                    })

                    if (doc.warehouseLocationProduct == "1") {
                        position[`${key}`] = true
                    }

                })

                setSelectedRows(position)
                setProducts(product)
            }
        })()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    const selectProduct = async () => {
        setMsgSuccess(false)
        setMsgDanger(null)

        let idProducts = []
        let error = false
        for (const position in selectedRows) {
            if (!products[position].locationBuyProduct) {
                error = true
            }
            idProducts.push(products[position].idProduct)
        }

        if (error){
            setMsgDanger(["You have selected products that are not related to the store."])
            return
        }

        let response = await setProductWarehouseLocation(logout, {
            idWarehouse: idWarehouse,
            idLocation: idLocation,
            idProducts: idProducts
        });

        if (response.status) {
            setMsgSuccess(true)
        } else {
            setMsgDanger(response.error)
        }
    }

    if (!products) return "";

    return (
        <>
            {msgSuccess && (
                <Grid item xs={12} md={12} lg={12}>
                    <Box className="alert-success">

                        Products have been successfully added to warehouse
                    </Box>
                </Grid>
            )}

            {msgDanger && (
                <Grid item xs={12} md={12} lg={12}>
                    <Box className="alert-danger">
                        {msgDanger.map((item) => {
                            return (
                                <span>{item} <br />{" "}</span>
                            )
                        })}
                    </Box>
                </Grid>
            )}

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box border={1} className="box-default">
                        <Button
                            variant="contained"
                            color="primary"
                            style={{
                                float: "right",
                                margin: "20px 20px 20px 0px",
                            }}
                            onClick={selectProduct}
                            size="small"
                        >
                            Save
                        </Button>
                        <TableBase
                            columns={columns}
                            data={products}
                            selectedRows={selectedRows}
                            onSelectedRowsChange={setSelectedRows}
                            search={true}
                            selectable={true}
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
