import React, { useState} from "react";
import Header from "./Header";
import Table from "./Table";
import {listApi} from "../../../../services/user/request";
import useAuth from "../../../../hooks/useAuth";

export default function Reports() {

    const { logout } = useAuth()

    const [regionSelected, setRegionSelected] = useState({})
    const [companySelected, setCompanySelected] = useState({})
    const [warehouseSelected, setWarehouseSelected] = useState({})
    const [productCategorySelected, setProductCategorySelected] = useState(null)
    const [dateFromSelected, setDateFromSelected] = useState(null)
    const [dateToSelected, setDateToSelected] = useState(null)
    const [country, setCountry] = useState(null)

    const clickExport = async () => {
        const response = await listApi(logout, `report/stock/quantity`, {
            idRegion: regionSelected?.id,
            idCompany: companySelected?.id,
            idWarehouseName: warehouseSelected?.name,
            idProductCategory: productCategorySelected?.id,
            dateFrom: dateFromSelected,
            dateTo: dateToSelected,
            export: true,
            idRegionName: regionSelected?.name,
            reportQuantity: true,
        });
        if (response.status){
            window.open(response.data, '_blank');
        }
    }

    return (
        <>
            <div className="card-body">

                <Header
                    regionSelected={regionSelected}
                    companySelected={companySelected}
                    warehouseSelected={warehouseSelected}
                    productCategorySelected={productCategorySelected}
                    dateFromSelected={dateFromSelected}
                    dateToSelected={dateToSelected}
                    country={country}

                    setCountry={setCountry}
                    setRegionSelected={setRegionSelected}
                    setCompanySelected={setCompanySelected}
                    setWarehouseSelected={setWarehouseSelected}
                    setProductCategorySelected={setProductCategorySelected}
                    setDateFromSelected={setDateFromSelected}
                    setDateToSelected={setDateToSelected}

                    clickExport={clickExport}
                />

                <br/>

                <Table
                    regionSelected={regionSelected}
                    companySelected={companySelected}
                    warehouseSelected={warehouseSelected}
                    productCategorySelected={productCategorySelected}
                    dateFromSelected={dateFromSelected}
                    dateToSelected={dateToSelected}
                />

            </div>
        </>
    )

}
