import { TOKEN } from "../../utils/constants"
import jwtDecode from "jwt-decode"

export function setToken(token) {
    localStorage.setItem(TOKEN, token)
}

export function getToken() {
    return localStorage.getItem(TOKEN)
}

export function removeToken() {
    localStorage.removeItem(TOKEN)
}

export function removeCart() {
    localStorage.removeItem("cart_products")
    localStorage.removeItem("cart_zone_details")
    localStorage.removeItem("order")
    localStorage.removeItem("cart_detail")
}

export function hasExpiredToken(token) {
    const tokenDecode = jwtDecode(token)
    const expireDate = tokenDecode.exp * 1000
    const currentDate = new Date().getTime()

    if (currentDate > expireDate) {
        // En caso de que la fecha este expirado
        return true
    }

    return false
}
