import useAuth from "../../../../hooks/useAuth";
import React, {useEffect, useState} from "react";
import {listApi} from "../../../../services/user/request";
import DatePickerSimple from "../../../../components/Admin/Inputs/DatePickerSimple";
import {Grid} from "@mui/material";
import InputSelect from "../../../../components/Inputs/InputSelect";
import InputButtonLoading from "../../../../components/Inputs/InputButtonLoading";

export default function Header({roleName, clickExport, filter, setFilter, loadingExcel, filterStatus, setFilterStatus}) {

    const [optionRegions, setOptionRegions] = useState([])
    const [optionSubregions, setOptionSubregions] = useState([])
    const [optionStores, setOptionStores] = useState([])
    const [optionStatus, setOptionStatus] = useState([])
    const [optionCountry, setOptionCountry] = useState(null)
    const [disabled, setDisabled] = useState({
        region: false,
        subregion: false,
        country: false,
        store: false
    })

    const [endLoadRegion, setEndLoadRegion] = useState(false)
    const [endLoadSubregion, setEndLoadSubregion] = useState(false)
    const [endLoadCountry, setEndLoadCountry] = useState(false)
    const [endLoadStore, setEndLoadStore] = useState(false)
    const [endLoadStatus, setEndLoadStatus] = useState(false)

    const {logout, auth} = useAuth()

    useEffect( async () => {
        await searchRegion()
        await searchSubregion()
        await searchCountry()
        await searchStore()
        await searchStatus()

        // Solo entramos la primera vez
        if (!filterStatus) {
            await defaultValues()
        }
    }, [filter.idSubregion, filter.idRegion, filter.idCountry])

    useEffect(() => {
        if (endLoadCountry && endLoadRegion && endLoadStore && endLoadSubregion && endLoadStatus) {
            setFilterStatus(true)
        }
    }, [endLoadCountry, endLoadRegion, endLoadStore, endLoadSubregion, endLoadStatus])

    const searchStatus = async () => {
        const statusData = await listApi(logout, "status")
        setEndLoadStatus(true)
        if (statusData?.status) {
            setOptionStatus(statusData.data)
        }
    }

    const searchStore = async () => {
        const storesData = await listApi(logout, "location", {
            idRegion: filter.idRegion || auth.region?.id,
            idSubregion: filter.idSubregion || auth.subregion?.id,
            idCountry: filter.idCountry || auth.country?.id
        })
        setEndLoadStore(true)
        if (storesData?.status) {
            setOptionStores(storesData.data)
        }
    }

    const searchCountry = async () => {
        const countryData = await listApi(logout, "location/countries", {
            idRegion: filter.idRegion || auth.region?.id,
            idSubregion: filter.idSubregion || auth.subregion?.id
        })
        setEndLoadCountry(true)
        if (countryData?.status) {
            setOptionCountry(countryData.data)
        }
    }

    const searchSubregion = async () => {
        const subregionsData = await listApi(logout, "subregion", {
            idRegion: filter.idRegion || auth.region?.id
        })
        setEndLoadSubregion(true)
        if (subregionsData?.status) {
            setOptionSubregions(subregionsData.data)

        }
    }

    const searchRegion = async () => {
        const regionsData = await listApi(logout, "region")
        setEndLoadRegion(true)
        if (regionsData?.status) {
            setOptionRegions(regionsData.data)
        }
    }

    const defaultValues = () => {
        if (auth.region) { filter['idRegion'] = auth.region?.id; disabled['region'] = true }
        if (auth.subregion) { filter['idSubregion'] = auth.subregion?.id; disabled['subregion'] = true }
        if (auth.location) { filter['idStore'] = auth.location?.id; disabled['store'] = true }
        if (auth.country) { filter['idCountry'] = auth.country?.id; disabled['country'] = true }

        setFilter({...filter})
        setDisabled({...disabled})
    }

    const handleChange = (event) => {
        setFilter({
            ...filter,
            [event.target.name]: event.target.value
        })
    }

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={4} md={4}>
                    <DatePickerSimple
                        label="Date From"
                        name="dateFrom"
                        onChange={handleChange}
                        value={filter?.dateFrom}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <DatePickerSimple
                        dateTo={true}
                        label="Date To"
                        name="dateTo"
                        onChange={handleChange}
                        value={filter?.dateTo}
                    />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <InputButtonLoading
                        onClick={clickExport}
                        loading={loadingExcel}
                        variant="outlined"
                        label="Export (.xlsx)"
                        disabled={!filterStatus}
                        style={{width: '100%'}}
                    />
                </Grid>

                <Grid item xs={12} sm={2} md={2}>
                    <InputSelect
                        label="Zone"
                        name="idRegion"
                        onChange={handleChange}
                        value={filter?.idRegion}
                        options={optionRegions || []}
                        disabled={disabled.region}
                    />
                </Grid>
                <Grid item xs={12} sm={2} md={2}>
                    <InputSelect
                        label="Region"
                        name="idSubregion"
                        onChange={handleChange}
                        value={filter?.idSubregion}
                        options={optionSubregions || []}
                        disabled={disabled.subregion}
                    />
                </Grid>

                <Grid item xs={12} sm={2} md={2}>
                    <InputSelect
                        label="Country"
                        name="idCountry"
                        onChange={handleChange}
                        value={filter?.idCountry}
                        options={optionCountry || []}
                        disabled={disabled.country}
                    />
                </Grid>

                <Grid item xs={12} sm={3} md={3}>
                    <InputSelect
                        label="Store"
                        name="idStore"
                        onChange={handleChange}
                        value={filter?.idStore}
                        options={optionStores || []}
                        disabled={disabled.store}
                    />
                </Grid>
                <Grid item xs={12} sm={3} md={3}>
                    <InputSelect
                        label="Status"
                        name="idStatus"
                        onChange={handleChange}
                        value={filter?.idStatus}
                        options={optionStatus || []}
                    />
                </Grid>
            </Grid>
        </>
    )
}
