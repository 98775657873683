import React, { useEffect, useState, useMemo } from "react"
import { listApi } from "../../../../../services/admin/request"
import { deleteShipment } from "../../../../../services/admin/transaction"
import { useParams } from "react-router-dom"
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import {toast} from "react-toastify";
import useAuth from "../../../../../hooks/useAuth"
import TableBase from "../../../../../components/Admin/TableBase"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Link from "@material-ui/core/Link"
import Button from "@material-ui/core/Button"
import moment from "moment/moment";

export default function Management() {
    const [shipments, setShipments] = useState(null)
    const [shipmentToDelete, setShipmentToDelete] = useState(null)
    const [refreshShipmentTable, setRefreshShipmentTable] = useState(true)
    const [status, setStatus] = useState({})
    const { logout } = useAuth()
    const { id } = useParams()
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = (shipment) => {
        setShipmentToDelete(shipment)
        setOpen(true)
    };

    const handleClose = () => {
        setOpen(false)
        setShipmentToDelete(null)
    };

    const columns = useMemo(
        () => [
            {
                Header: "SHIP DATE",
                accessor: "creationDate",
            },
            {
                Header: "TRACKING NUMBER",
                accessor: "trackingNumber"
            },
            {
              Header: "COMMENT",
              accessor: "comment"
            },
            {
                Header: "ACTIONS",
                accessor: "actions",
            },

        ],
        []
    )

    const handleDeleteShipment = (shipment) => {
        handleClose()

        if (!shipmentToDelete) {
            return false
        }

        let preparedShipment = shipmentToDelete
        let url = `${preparedShipment.shipment_id}`

        let data = new FormData()

        let object = {};

        for ( var key in preparedShipment ) {
            data.append(key, preparedShipment[key]);
        }

        let response = deleteShipment(logout, url, preparedShipment);

        if (response.status) {
            toast.info("Shipment removed", {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined
            });
        } else {
        }
        setShipmentToDelete(null)
        setRefreshShipmentTable(true)
        window.location.reload()
    };

    useEffect(() => {
        (async () => {
            if (refreshShipmentTable == null) {
                return false
            }
            const response = await listApi(logout, `transaction/shipment/${id}`);
            if (response.status) {

                setStatus(response.data.transactionStatus)

                const temp = response.data.shipments.map((doc) => {

                    let href = `/admin/transaction/shipment/edit/${id}/${doc?.shipment_id}`

                    return {
                        id: doc.shipment_id,
                        creationDate: moment.unix(doc.shipment_creationDate).utc().format('DD-MMM-YYYY HH:mm A'),
                        trackingNumber: doc.shipment_trackingNumber,
                        unitsShipped: doc.unitsShipped,
                        comment: doc.shipment_comment,
                        actions: (
                            <>
                            <Link color="primary" href={href}>
                                Details
                            </Link>
                            <br />
                            <Link color="primary" href="#" onClick={() => handleClickOpen(doc)}>
                                Delete
                            </Link>
                            </>
                        ),
                    }
                })
                setShipments(temp)

            }
        })()
        setRefreshShipmentTable(null)
    }, [refreshShipmentTable]) // eslint-disable-line react-hooks/exhaustive-deps

    if (!shipments) return ""

    return (
        <>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Delete Shipment"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    Are you sure to delete this shipment?
                    This process is not reversible.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>CANCEL</Button>
                    <Button onClick={handleDeleteShipment} autoFocus>
                        ACCEPT
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container spacing={3}>
                <Grid item xs={6} md={6} lg={6}></Grid>
                <Grid item xs={6} md={6} lg={6}>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={status.id === 7}
                        disableElevation={status.id === 7}
                        style={{ float: "right", margin: "20px 0px" }}
                        href={`/admin/transaction/shipment/create/${id}`}
                    >
                        Create Shipment
                    </Button>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Box border={1} className="box-default">
                        <TableBase 
                            columns={columns} 
                            data={shipments}
                            selectedRows={shipments}
                            onSelectedRowsChange={setShipments}/>
                    </Box>
                </Grid>
            </Grid>
        </>
    );

}
