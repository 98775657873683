import Frontend from "../../../containers/User/Layouts/Frontend";
import OrderPage from "../../../containers/User/Order/Store"
import CartPage from "../../../containers/User/Order/Cart"
import SubMenu from "../../../containers/User/Order/SubMenu";
import React, {useState} from "react";
import Box from "@material-ui/core/Box";
import NavBar from "../../../components/NavBar";

export default function Order() {

    const [isViewCart, setViewCart] = useState(false);
    const [totalProducts, setTotalProducts] = useState(0);
    const [totalCartProducts, setTotalCartProducts] = useState(JSON.parse(localStorage.getItem("cart_products"))?.length)

    return (
        <Frontend title="Order Form" menu="orderFrom">
            <div className="card-body">
                <NavBar>

                    <SubMenu
                        name={"All products"}
                        totalProducts={totalProducts}
                        action={() => setViewCart(false)}
                        active={!isViewCart}
                    />

                    <SubMenu
                        name={"Selected products"}
                        totalProducts={totalCartProducts}
                        action={() => {
                            setViewCart(true)
                            setTotalCartProducts(JSON.parse(localStorage.getItem("cart_products"))?.length)
                        }}
                        active={isViewCart}
                    />

                </NavBar>

                <Box component="div" sx={{display: !isViewCart ? "block" : "none"}}>
                    <OrderPage setTotalProducts={setTotalProducts} setTotalCartProducts={setTotalCartProducts}
                               totalProducts={totalCartProducts}
                               setViewCart={setViewCart} isViewCart={isViewCart}/>
                </Box>
                <Box component="div" sx={{display: isViewCart ? "block" : "none"}}>
                    <CartPage setTotalCartProducts={setTotalCartProducts} totalCartProducts={totalCartProducts}
                              setViewCart={setViewCart} isViewCart={isViewCart}/>
                </Box>
            </div>
        </Frontend>
    );
}

