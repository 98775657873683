import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { listApi } from "../../../../../services/admin/request";
import useAuth from "../../../../../hooks/useAuth";
import TableBase from "../../../../../components/Admin/TableBase";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";

export default function Management() {
    const { id } = useParams()
    const [subregion, setSubregion] = useState(null)
    const { logout } = useAuth()

    const hrefAdd = `/admin/region/subregion/create/${id}`
    
    const columns = useMemo(
        () => [
            {
                Header: "NAME",
                accessor: "name",
            },
            {
                Header: "CODE",
                accessor: "code",
            },
            {
                Header: "VISIBLE",
                accessor: "access",
            },
            {
                Header: "ACTIONS",
                accessor: "actions",
            },
        ],
        []
    );

    useEffect(() => {
        (async () => {
            const response = await listApi(logout, `region/subregions/${id}`)
            if (response.status) {
                const temp = response.data.map((doc) => {
                    let href = `/admin/region/subregion/edit/${id}/${doc.id}`

                    let access = "No";
                    if (doc.isActive) {
                        access = "Yes";
                    }

                    return {
                        ...doc,
                        id: doc.id,
                        name: doc.name,
                        access: access,
                        actions: (
                            <Link color="primary" href={href}>
                                Edit
                            </Link>
                        ),
                    }
                })
                setSubregion(temp)
            }
        })()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (!subregion) return ""

    return (
        <>
            <Box border={1} className="box-default">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={6}>
                        <h3
                            style={{
                                float: "left",
                                margin: "20px 20px 0px 20px",
                            }}
                        >
                            Maintain Zone
                        </h3>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6}>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{
                                float: "right",
                                margin: "20px 20px 0px 0px",
                            }}
                            href={hrefAdd}
                            size="small"
                        >
                            + add
                        </Button>
                    </Grid>
                </Grid>

                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <TableBase 
                            columns={columns} 
                            data={subregion}
                            selectedRows={subregion}
                            onSelectedRowsChange={setSubregion}
                            search={true}/>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
