import React, { useEffect, useState, useMemo } from "react"
import { listApi } from "../../../../services/admin/request"
import useAuth from "../../../../hooks/useAuth"
import TableBase from "../../../../components/Admin/TableFilterBaser"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Link from "@material-ui/core/Link"
import moment from "moment/moment";

export default function Management() {
    const [shipments, setShipments] = useState(null)
    const { logout } = useAuth()

    const columns = useMemo(
        () => [
            {
                Header: "SHIP DATE",
                accessor: "creationDate",
            },
            {
                Header: "COMPANY",
                accessor: "companyName"
            },
            {
                Header: "REGION",
                accessor: "regionName"
            },
            {
                Header: "STORE",
                accessor: "storeName"
            },
            {
                Header: "TRACKING NUMBER",
                accessor: "trackingNumber",
                disableFilters: true
            },
            {
                Header: "ACTIONS",
                accessor: "actions",
                disableFilters: true
            },
        ],
        []
    )

    useEffect(() => {
        (async () => {
            const response = await listApi(logout, "transaction/shipments");
            if (response.status) {

                const temp = response.data.map((doc) => {
                    let href = `/admin/transaction/shipment/edit/${doc.idTransaction}/${doc.id}`

                    return {
                        ...doc,
                        creationDate: moment.unix(doc.creationDate).utc().format('DD-MMM-YYYY HH:mm A'),
                        actions: (
                            <Link color="primary" href={href}>
                                Edit
                            </Link>
                        ),
                    }
                })
                setShipments(temp)
            }
        })()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (!shipments) return ""

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={6} md={6} lg={6}>
                    <h1>Display Shipments</h1>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box border={1} className="box-default">
                        <TableBase 
                            columns={columns} 
                            data={shipments}
                            selectedRows={shipments}
                            onSelectedRowsChange={setShipments}/>
                    </Box>
                </Grid>
            </Grid>
        </>
    );

}
