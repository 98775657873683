import { BASE_PATH, PATH_BASE_EXTENSION_ADMIN } from "../../utils/constants";
import { authFetch } from "../../utils/fetch";
import axios from "axios";

const baseUrl = `${BASE_PATH}${PATH_BASE_EXTENSION_ADMIN}/shipping-charge`;

export function apiShippingChargeList(props) {
    const config = {headers: { 'Authorization': `Bearer ${localStorage.getItem("token")}`}};

    let query = "";
    if (props.limit) { query += `&limit=${props.limit}`; }
    if (props.offset) { query += `&offset=${props.offset}`; }
    if (props.field) { query += `&field=${props.field}`; }
    if (props.sort) { query += `&sort=${props.sort}`; }

    if (props.name) { query += `&name=${props.name}`; }
    if (props.company) { query += `&company=${props.company}`; }

    return axios.get(`${baseUrl}/list?1=1${query}`, config)
}

export function apiShippingChargeCreate(props) {
    const config = {headers: { 'Authorization': `Bearer ${localStorage.getItem("token")}`}};
    return axios.post(`${baseUrl}/create`, props, config)
}

export function apiShippingChargeUpdate(props) {
    const config = {headers: { 'Authorization': `Bearer ${localStorage.getItem("token")}`}};
    return axios.post(`${baseUrl}/update`, props, config)
}

export function apiShippingCharge(id) {
    const config = {headers: { 'Authorization': `Bearer ${localStorage.getItem("token")}`}};
    return axios.get(`${baseUrl}/${id}`, config)
}