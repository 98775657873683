import React from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";

export default function MsgSuccess(props) {

    const {msg} = props

    if (!msg || msg.length === 0) { return "" }

    return (
        <Grid item xs={12} md={12} lg={12}>
            <Box className="alert-success">
                {msg.map((doc, key) => {
                    return ( <span key={key}>{doc} <br /></span>)
                })}
            </Box>
        </Grid>
    )
}
