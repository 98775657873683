import Frontend from "../../../../containers/User/Layouts/Frontend";
import ReportStockPage from "../../../../containers/User/Reports/StockQty";

export default function Report() {
    return (
        <Frontend title="Send Reports" menu="sendReportsStockQty">
            <ReportStockPage />
        </Frontend>
    );
}
