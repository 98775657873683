import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {TextField} from "@material-ui/core";
import useAuth from "../../../../hooks/useAuth";

export default function ProductBox(props) {
    const {
        id,
        name,
        description,
        price,
        priceDefault,
        category,
        sort,
        currencySymbol,
        unitsPerPack,
        quantityDefault,
        unitDefault,
        image,
        quantityIsModify,
        stockOnHand,
        calculatePrice,
        setTotalCartProducts
    } = props

    const [unit, setUnit] = useState(unitDefault)
    const [quantity, setQuantity] = useState(quantityDefault)
    const {auth} = useAuth();

    /**
     * Añadimos elementos al carrito
     */
    const addCart = () => {

        setUnit(unitsPerPack * quantity)

        const cart = JSON.parse(localStorage.getItem('order_products'))

        if (quantity < 1 || !quantity) {

            removeCard(cart, id)
            setTotalCartProducts(cart?.length || 0)
            calculatePrice()
            return
        }

        let existId = false
        if (cart) {
            existId = updateCard(cart, existId, quantity)
        }

        if (!existId) {
            addCreateCart(cart, id, quantity)
        }
        setTotalCartProducts(cart?.length || 0)
        calculatePrice()
    }

    useEffect(() => {
        addCart()
    }, [quantity])

    const removeCard = (order, id) => {
        order.forEach((storage, key) => {
            if (order[key].id === id) {
                order[key].delete = true
            }
        })
        localStorage.setItem('order_products', JSON.stringify(order))
    }

    const updateCard = (order, existId, quantity) => {
        order.forEach(storage => {
            if (storage.id === id) {
                existId = true
                storage.quantity = quantity
                if (storage?.delete) {
                    storage.delete = false
                }
            }
        })
        localStorage.setItem('order_products', JSON.stringify(order))
        if (props?.calculateTotalCart) {
            props.calculateTotalCart()
        }
        return existId;
    }

    const addCreateCart = (order, id, quantity) => {
        if (!order) {
            order = []
        }
        order.push({
            id: id,
            quantity: quantity,
            packsPrice: `${priceDefault} ${currencySymbol}`,
            image: image ?? "https://ows-idp-packaging.s3.eu-west-3.amazonaws.com/product/no_available.png",
            name: name,
            description: description,
            category: category,
            sort: sort,
            price: price,
            priceDefault: priceDefault,
            unit: unit,
            unitsPerPack: unitsPerPack,
            stockOnHand: stockOnHand,
            currencySymbol: currencySymbol,
            delete: false
        })
        localStorage.setItem('order_products', JSON.stringify(order))
        if (props?.calculateTotalCart) {
            props.calculateTotalCart()
        }
    }

    return (
        <Grid container spacing={3} alignItems={"center"}>
            <Grid item xs={2} className={"text-center"}>
                <img
                    alt="Product"
                    src={image ?? "https://ows-idp-packaging.s3.eu-west-3.amazonaws.com/product/no_available.png"}
                    className="avatar avatar-xl"
                    loading="lazy"
                />
            </Grid>
            <Grid item xs={3}>
                <span className={"h5"}>{name}</span>
                <br/>
                <span className={"h6"}>{description}</span>
                <div className="row">
                    <div className="col-md-12">
                        {sort && (
                            <div className="text-body">
                                <span className="small">Ref:</span>
                                <span className="font-weight-bold small px-2">{sort}</span>
                            </div>
                        )}
                        <div className="text-body">
                            <span className="small">Category:</span>
                            <span className="font-weight-bold small px-2">{category}</span>
                        </div>
                    </div>
                </div>
            </Grid>
            <Grid item xs={2}>
                <TextField
                    id={`input-${id}`}
                    variant={"outlined"}
                    size={"small"}
                    type="number"
                    placeholder="Pack quantity"
                    value={quantity}
                    onWheel={() => document.activeElement.blur()}
                    disabled={!quantityIsModify}
                    onChange={(event) => {
                        setQuantity(event.target.value)
                    }}
                    sx={{
                        "& label.Mui-focused": {
                            display: "none"
                        },
                        "& legend": {
                            display: "none"
                        }
                    }}
                    inputProps={{style: {fontSize: 15, padding: "2px 5px 5px 5px"}}}
                    InputLabelProps={{shrink: true}}
                />
                {quantity && unitsPerPack ? (
                    <div className="text-body">
                        <span className="font-weight-bold small pr-1">{unit || unitsPerPack * quantity}</span>
                        <span className="small">pcs in <b>{quantity}</b> packs</span>
                        <br/>
                        {(stockOnHand && !auth.isHideAvailability) && (
                            <>
                                <span className="small">Packs available: </span>
                                <span className="font-weight-bold small">{stockOnHand}</span>
                            </>
                        )}
                    </div>
                ) : (
                    <div className="text-body">
                        <span className="small">Pcs per pack:</span>
                        <span className="font-weight-bold small px-2">{unitsPerPack}</span>
                    {!auth.isHideAvailability ? (
                        <>
                        <br/>
                        <span className="small">Packs available:</span>
                        <span className="font-weight-bold small px-2">{stockOnHand}</span>
                        </>
                    ): null
                    }
                    </div>
                )}
            </Grid>
            <Grid item xs={3} className={"text-center"}>
                <span className="h5"> {priceDefault} {currencySymbol}</span>
            </Grid>
            <Grid item xs={2} className={"text-center"}>
                <span
                    className="h5"> {(quantity && (price * quantity).toFixed(2)) || priceDefault} {currencySymbol}</span>
            </Grid>
        </Grid>
    )
}
