import React, { useEffect, useState, useMemo } from "react"
import { listApi } from "../../../../services/admin/request"
import useAuth from "../../../../hooks/useAuth"
import TableBase from "../../../../components/Admin/TableFilterBaser"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Link from "@material-ui/core/Link"

export default function Management() {
    const [regions, setRegions] = useState(null)
    const { logout } = useAuth()

    const columns = useMemo(
        () => [
            {
                Header: "COMPANY NAME",
                accessor: "nameCompany",
            },
            {
                Header: "ZONE",
                accessor: "nameRegion"
            },
            {
                Header: "VISIBLE",
                accessor: "access",
                disableFilters: true
            },
            {
                Header: "ACTIONS",
                accessor: "actions",
                disableFilters: true
            },
        ],
        []
    )

    useEffect(() => {
        (async () => {
            const response = await listApi(logout, 'region');
            if (response.status) {
                const temp = response.data.map((doc) => {
                    let href = `/admin/region/edit/${doc.id}/1`;

                    let access = "No";
                    if (doc.isActive) {
                        access = "Yes";
                    }

                    return {
                        ...doc,
                        id: doc.id,
                        nameCompany: doc.idCompany.name,
                        nameRegion: doc.name,
                        access: access,
                        actions: (
                            <Link color="primary" href={href}>
                                Edit
                            </Link>
                        )
                    }
                })
                setRegions(temp)
            }
        })()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    if (!regions) return ""

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={6} md={6} lg={6}>
                    <h1>Maintain Zone</h1>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ float: "right", margin: "20px 0px" }}
                        href="/admin/region/create"
                    >
                        + add
                    </Button>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box border={1} className="box-default">
                        <TableBase
                            columns={columns} 
                            data={regions}
                            selectedRows={regions}
                            onSelectedRowsChange={setRegions}/>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}


