import React, { useState, useEffect } from "react"
import { useHistory, useParams } from "react-router-dom"
import useAuth from "../../../../hooks/useAuth"
import { createApi, getCurrencyRegionApi, updateApi } from "../../../../services/admin/currencyRegion"
import {listApi as requestListApi, listApi} from "../../../../services/admin/request"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import ArrowBackIos from "@material-ui/icons/ArrowBackIos"
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import InputSelect from "../../../../components/Inputs/InputSelect";
import Loading from "../../../../components/User/Loading";
import InputButtonLoading from "../../../../components/Inputs/InputButtonLoading";
import InputAutocomplete from "../../../../components/Inputs/InputAutocomplete";
import InputText from "../../../../components/Inputs/InputText";

export default function Fields() {
    const { id, idProduct } = useParams()
    const { logout } = useAuth()
    const history = useHistory()

    const [msgSuccess, setMsgSuccess] = useState(false)
    const [msgDanger, setMsgDanger] = useState(null)

    const [urlBack, setUrlBack] = useState("/admin/currency-region/management")

    const [loadingIni, setLoadingIni] = useState(true)
    const [loadingBtn, setLoadingBtn] = useState(false)
    const [isEndGetData, setIsEndGetData] = useState(false)

    const [optionCompany, setOptionCompany] = useState([])
    const [optionRegion, setOptionRegion] = useState([])
    const [optionProduct, setOptionProduct] = useState([])
    const [optionCurrency, setOptionCurrency] = useState([])

    const [state, setState] = useState(initialValues())

    useEffect(async () => {

        if (idProduct !== 0) {
            setUrlBack(`/admin/currency-region/management`)
        }

        await getCompany()

        if (id) {
            await getData()
            await getData() // La segunda vez es para obtener el resto de selectores
            setIsEndGetData(true)
            setLoadingIni(false)
        } else {
            setIsEndGetData(true)
            setLoadingIni(false)
        }
    }, [])

    useEffect(() => {
        setOptionProduct([])
        setOptionRegion([])
        if (state.idCompany) {
            getProduct()
            getRegion()
            getCurrency()

            setState({
                ...state,
                idProduct: null,
                idRegion: null,
                idCurrency: null
            })
        }
    }, [state.idCompany])

    const getData = async () => {
        const response = await getCurrencyRegionApi(logout, {
            id: id
        })
        if (response.status) {
            setState({
                ...response.data.currencyProduct,
                price: response.data.currencyProduct.price ?? "",
                idCompany: response.data.currencyProduct.idCompany,
                idProduct: response.data.currencyProduct.idProduct,
                idRegion: response.data.currencyProduct.idRegion,
                idCurrency: response.data.currencyProduct.idCurrency
            })
        }
    }

    const getRegion = async () => {
        if (state.idCompany) {
            const response = await listApi(logout, `region`, `?idCompany=${state.idCompany}`)
            if (response.status) {
                const listRegion = response.data.map((doc) => {
                    return {
                        id: doc.id,
                        ...doc,
                    }
                })
                setOptionRegion(listRegion)
            }
        }
    }

    const getProduct = async () => {
        if (state.idCompany) {
            const response = await listApi(logout, "product", `?companyId=${state.idCompany}`)
            if (response.status) {
                const listProduct = response.data.map((doc) => {
                    return {
                        ...doc,
                        id: parseInt(doc.id)
                    };
                });
                setOptionProduct(listProduct)
            }
        }
    }

    const getCurrency = async () => {
        if (state.idCompany) {
            const response = await listApi(logout, "currency")
            if (response.status) {
                const listCurrency = response.data.map((doc) => {
                    return {
                        ...doc,
                    }
                })
                setOptionCurrency(listCurrency)
            }
        }
    }

    const getCompany = async () => {
        const response = await requestListApi(logout, "company");
        if (response.status) {
            const temp = response.data.map((doc) => {
                return {
                    id: doc.id,
                    ...doc,
                }
            })
            setOptionCompany(temp)
        }
    }

    const handleChange = (event) => {
        if (event.target.name === "isActive") {
            setState({
                ...state,
                [event.target.name]: event.target.checked,
            })
        } else {
            setState({...state, [event.target.name]: event.target.value})
        }
    }

    const handleSubmit = async () => {
        setLoadingBtn(true)

        setMsgSuccess(false)
        setMsgDanger(null)
        if (id) {
            const response = await updateApi(logout, state);
            if (response.status) {
                setMsgSuccess(true)
            } else {
                setMsgDanger(response.errors)
            }
            setLoadingBtn(false)

        } else {
            const response = await createApi(state)
            if (response.status) {
                history.push(`/admin/currency-region/edit/${idProduct}/${response.data.currencyProduct.id}`)
            } else {
                setMsgDanger(response.errors);
            }
            setLoadingBtn(false)

        }
    }


    return (
        <>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                        <Button
                            type="button"
                            color="primary"
                            href={urlBack}
                            style={{
                                float: "left"
                            }}
                        >
                            <ArrowBackIos />
                            BACK TO LIST
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={12} lg={12}>
                        <h2 className="p-0">Currency by Zone Settings</h2>
                    </Grid>
                </Grid>

                {msgSuccess && (
                    <Grid item xs={12} md={12} lg={12}>
                        <Box className="alert-success">
                            Currency by Zone data has been saved correctly
                        </Box>
                    </Grid>
                )}

                {msgDanger && (
                    <Grid item xs={12} md={12} lg={12}>
                        <Box className="alert-danger">
                            {msgDanger.map((item) => {
                                return (
                                    <span>
                                        {" "}
                                        {item} <br />{" "}
                                    </span>
                                );
                            })}
                        </Box>
                    </Grid>
                )}

                {loadingIni && (
                    <Loading show={loadingIni} />
                )}

                <Box border={1} className="p-20 box-default" style={{display: loadingIni ? 'none' : ''}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={12} className="border-bottom">
                            <h5 className="p-0 mt-0">Info</h5>
                        </Grid>
                        {id && (
                            <Grid item md={12}>
                                <TextField
                                    label="* ID"
                                    value={state?.id}
                                    onChange={handleChange}
                                    name="id"
                                    type="number"
                                    variant="outlined"
                                    className="form-control"
                                    disabled
                                    fullWidth
                                    size="small"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        )}

                        <Grid item md={6}>
                            <InputSelect
                                label="Company"
                                name="idCompany"
                                onChange={handleChange}
                                value={state?.idCompany}
                                required
                                options={optionCompany}
                            />
                        </Grid>

                        <Grid item md={6}>
                            <InputAutocomplete
                                label="* Product"
                                name="idProduct"
                                onChange={handleChange}
                                value={state?.idProduct}
                                required
                                disabled={!state?.idCompany}
                                options={optionProduct}
                            />
                        </Grid>

                        <Grid item md={6}>
                            <InputAutocomplete
                                label="* Zone"
                                name="idRegion"
                                onChange={handleChange}
                                value={state?.idRegion}
                                required
                                disabled={!state?.idCompany}
                                options={optionRegion}
                            />
                        </Grid>

                        <Grid item md={6}>
                            <InputSelect
                                label="* Currency"
                                name="idCurrency"
                                onChange={handleChange}
                                value={state?.idCurrency}
                                required
                                disabled={!state?.idCompany}
                                options={optionCurrency}
                            />
                        </Grid>

                        <Grid item md={12}>
                            <InputText
                                label="* Price"
                                name="price"
                                onChange={handleChange}
                                value={state?.price}
                            />
                        </Grid>

                        <Grid item md={12}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={state.isActive}
                                        onChange={handleChange}
                                        name="isActive"
                                        color="primary"
                                    />
                                }
                                label="Visible"
                            />
                        </Grid>
                    </Grid>
                </Box>

                {!loadingIni && (
                    <div style={{display: "flex", float: "right", marginTop: 20}}>
                        <Button
                            type="button"
                            color="secondary"
                            href={urlBack}
                        >
                            { id ? ( <><ArrowBackIos /> Back to list </>) : "Cancel" }
                        </Button>
                        <InputButtonLoading
                            onClick={handleSubmit}
                            loading={loadingBtn}
                            label={ id ? "Modify" : "Create" }
                            style={{marginLeft: 20}}
                        />
                    </div>
                )}
            </form>
        </>
    );
}

function initialValues() {
    return {
        id: "",
        idCompany: null,
        idCurrency: null,
        idProduct: null,
        idRegion: null,
        price: "",
        isActive: true
    }
}
