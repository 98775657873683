import Basic from "../../../containers/Admin/Layouts/Basic"
import ProductEdit from "../../../containers/Admin/Product/Fields"
import ProductLocationManagement from "../../../containers/Admin/Product/Location/Management"
import WarehouseStockManagement from "../../../containers/Admin/Product/Warehouse/Management"
import { useParams } from "react-router-dom";

import React, {useEffect, useState} from "react";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import ContainerManagement from "../../../containers/Admin/CurrencyRegion/Management"
import useAuth from "../../../hooks/useAuth";
import {getProductApi} from "../../../services/admin/product";


export default function Edit() {
    const { id, tab } = useParams()
    const [value, setValue] = React.useState(tab)
    const { logout } = useAuth()
    const [name, setName] = useState(null)

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    useEffect(() => {
        (async () => {
            if (id){
                const response = await getProductApi(logout, {id: id})
                if (response.status) {
                    setName(response.data.product.name)
                }
            }
        })()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Basic menu="products">

        <Grid container spacing={3} className="pl-20 pr-20">
            <Grid item xs={12} md={12} lg={12}>
                <Button
                    type="button"
                    color="primary"
                    href="/admin/product/management"
                    style={{
                        float: "left"
                    }}
                >
                    <ArrowBackIos />
                    BACK TO LIST
                </Button>
            </Grid>
            <Grid item xs={12} md={12} lg={12}>
                <h2 className="p-0">Product Settings
                    {id && (
                        <span className="font-w-100"> {name}</span>
                    )}
                </h2>
            </Grid>
        </Grid>

        <TabContext value={value}>

            <AppBar position="static" style={{ background: "#fff", color: "#000", boxShadow: "none" }}>
                <TabList onChange={handleChange}>
                    <Tab label="Settings" value="1" />
                    <Tab label="Currencies by Zone" value="2" />
                    <Tab label="Stores" value="3" />
                    <Tab label="Warehouse / Stock" value="4"/>
                </TabList>
            </AppBar>

            <TabPanel value="1">
                <ProductEdit />
            </TabPanel>
            <TabPanel value="2">
                <ContainerManagement id={id}/>
            </TabPanel>
            <TabPanel value="3">
                <ProductLocationManagement />
            </TabPanel>
            <TabPanel value="4">
                <WarehouseStockManagement />
            </TabPanel>

        </TabContext>
    </Basic>
  )
}
