import Basic from "../../../containers/Admin/Layouts/Basic"
import ComponentEdit from "../../../containers/Admin/User/Fields"

export default function Create() {
  return (
    <Basic menu="users">
          <ComponentEdit />
    </Basic>
  )
}
