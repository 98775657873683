import React, { useMemo, useEffect, useState } from "react";
import { listApi } from "../../../../services/admin/request";
import useAuth from "../../../../hooks/useAuth";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import TableFilterBase from "../../../../components/Admin/TableFilterBaser";

export default function Management() {
    const { logout } = useAuth();
    const [warehouse, setWarehouse] = useState([]);

    const columns = useMemo(
        () => [
            {
                Header: "CODE",
                accessor: "code",
                disableFilters: true
            },
            {
                Header: "NAME",
                accessor: "name",
                disableFilters: true
            },
            {
                Header: "PHONE",
                accessor: "phone",
                disableFilters: true
            },
            {
                Header: "EMAIL",
                accessor: "email",
                disableFilters: true

            },
            {
                Header: "VISIBLE",
                accessor: "access",
                disableFilters: true
            },
            {
                Header: "ACTIONS",
                accessor: "actions",
                disableFilters: true
            },
        ],
        []
    )

    useEffect(() => {
        (async () => {
            const response = await listApi(logout, "warehouse");
            if (response.status) {
                const temp = response.data.map((doc) => {
                    let href = `/admin/warehouse/edit/${doc.id}/1`

                    let access = "No"
                    if (doc.isActive) {
                        access = "Yes"
                    }

                    return {
                        id: doc.id,
                        access: access,
                        actions: (
                            <Link color="primary" href={href}>
                                Edit
                            </Link>
                        ),
                        ...doc,
                    }
                })
                setWarehouse(temp)
            }
        })()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    if (!warehouse) return ""

    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={6} md={6} lg={6}>
                    <h1>Maintain Warehouses</h1>
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ float: "right", margin: "20px 0px" }}
                        href="/admin/warehouse/create"
                    >
                        + add
                    </Button>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box border={1} className="box-default">
                        <TableFilterBase
                            columns={columns} 
                            data={warehouse}                             
                            selectedRows={warehouse}
                            onSelectedRowsChange={setWarehouse}
                            search={true} />
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
