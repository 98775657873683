import axios from "axios";
import { BASE_PATH, PATH_BASE_EXTENSION_ADMIN } from "../../utils/constants";
import {getToken} from "../auth/token";

const baseUrl = `${BASE_PATH}${PATH_BASE_EXTENSION_ADMIN}/tariff`;

export function apiList(props) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()}`}};

    let query = "";
    if (props.limit) {
        query += `&limit=${props.limit}`;
    }
    if (props.offset) {
        query += `&offset=${props.offset}`;
    }
    if (props.companyId) {
        query += `&companyId=${props.companyId}`;
    }
    if (props.name) {
        query += `&name=${props.name}`;
    }
    if (props.description) {
        query += `&description=${props.description}`;
    }
    if (props.idCompany) {
        query += `&companyId=${props.idCompany}`;
    }

    return axios.get(`${baseUrl}/list?field=${props.field}&sort=${props.sort}${query}`, config)
}

export function apiCreate(props) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()}`}};

    return axios.post(`${baseUrl}/create`, props , config)
}

export function apiUpdate(props) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()}`}};

    return axios.put(`${baseUrl}/update/${props.id}`, props , config)
}

export function apiGetTariff(id) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()}`}};

    return axios.get(`${baseUrl}/${id}`, config)
}
