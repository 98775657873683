import React, { useState, useEffect } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import { useHistory, useParams } from "react-router-dom"
import useAuth from "../../../../hooks/useAuth"
import {  createApi, getProductApi, updateProductApi,} from "../../../../services/admin/product"
import { listApi } from "../../../../services/admin/request"
import {getCatalogByCompanyApi} from "../../../../services/admin/catalog"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import ArrowBackIos from "@material-ui/icons/ArrowBackIos"
import Autocomplete from "@material-ui/lab/Autocomplete"
import InputAdornment from '@mui/material/InputAdornment';
import Switch from "@material-ui/core/Switch"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import {apiList as apiListCategory} from "../../../../services/admin/category";

export default function Edit() {
    const { id } = useParams()
    const { logout } = useAuth()
    const history = useHistory()
    const [msgSuccess, setMsgSuccess] = useState(false)
    const [msgDanger, setMsgDanger] = useState(null)
    const [loading, setLoading] = useState(true)
    const [listCompany, setListCompany] = useState([])
    const [listCatalog, setListCatalog] = useState([])
    const [listCategories, setListCategories] = useState([])
    const [defaultCategory, setDefaultCategory] = useState(null)
    const [defaultCatalog, setDefaultCatalog] = useState(null)
    const [defaultProductType, setDefaultProductType] = useState(null)
    const [defaultCompany, setDefaultCompany] = useState(null)
    const [listCurrency, setListCurrency] = useState([])
    const [listProductTypes, setListProductTypes] = useState([])
    const [productImage, setProductImage] = useState(null)
    const [productImageBase64, setProductImageBase64] = useState(null)

    const [initialValues, setInitialValues] = useState(defaultValue())

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object(validationSchema()),
        enableReinitialize: true,
        onSubmit: async () => {
            setMsgSuccess(false)
            setMsgDanger(null)

            let f = new FormData()
            for (const property in formik.values) {
                if (property !== "image"){
                    f.append(`${property}`, formik.values[property])
                }
            }
            if (productImage) {
                f.append("image", productImage[0])
            }

            if (id) {
                const response = await updateProductApi(logout, f);
                if (response.status) {
                    setMsgSuccess(true)
                } else {
                    setMsgDanger(response.errors)
                }
            } else {
                const response = await createApi(f);
                if (response.status) {
                    history.push(`/admin/product/edit/${response.data.product.id}/1`);
                } else {
                    setMsgDanger(response.errors);
                }
            }
        },
    });

    // actualizar catálogo al modificar la compañía
    useEffect(() => {
        getCatalog()
        getCategory()
    }, [formik.values.idCompany])

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            const { length, width, height } = formik.values;
        
            // Convert input values to numbers (will become NaN if conversion fails)
            const numLength = Number.parseFloat(length);
            const numWidth = Number.parseFloat(width);
            const numHeight = Number.parseFloat(height);
        
            // Initialize cbm as a string (for blank value)
            let cbm = '';
        
            // Check if all inputs are valid numbers greater than zero
            if (!isNaN(numLength) && !isNaN(numWidth) && !isNaN(numHeight) && numLength > 0 && numWidth > 0 && numHeight > 0) {
                cbm = (numLength * numWidth * numHeight / 1000000);
            }
        
            // Change the CBM field with the calculated value or an empty string
            formik.setFieldValue('cbm', cbm);
        }, 3000); // Delay de 3 segundos

        // Cleanup function to clear the timeout if the dependencies change
        return () => clearTimeout(debounceTimer);
    }, [formik.values.length, formik.values.width, formik.values.height]);

    const getCatalog = () => {
        setListCatalog([])

        if (formik.values.idCompany) {
            if (!loading) {
                // Evitamos eliminarlo  caso de obtener pro primera vez los datos
                setDefaultCatalog(null)
                formik.values.idCatalog = null
            }

            getCatalogByCompanyApi(logout, formik.values.idCompany).then((response) => {
                if (response.status) {
                    const temp = response.data.map((doc) => {
                        return {
                            id: doc.id,
                            ...doc,
                        }
                    })
                    setListCatalog(temp)
                }
            })
        }
    }

    const getCategory = () => {
        setListCategories([])

        if (formik.values.idCompany) {
            if (!loading) {
                // Evitamos eliminarlo  caso de obtener pro primera vez los datos
                setDefaultCategory(null)
                formik.values.idCategory = null

            }

            let params = {}
            params.idCompany = formik.values.idCompany

            apiListCategory(params).then(resp => {
                let temp = resp.data.data?.map((doc, key) => {
                    return {
                        id: doc.id,
                        ...doc,
                    }
                })

                setListCategories(temp)
            }).catch(err => {
                setListCategories([])
            })
        }
    }

    useEffect(() => {
        (async () => {
            const response = await listApi(logout, "company");
            if (response.status) {
                const temp = response.data.map((doc) => {
                    return {
                        id: doc.id,
                        ...doc,
                    }
                })
                setListCompany(temp)
            }
        })();

        (async () => {
            const response = await listApi(logout, "currency");
            if (response.status) {
                const temp = response.data.map((doc) => {
                    return {
                        ...doc,
                    }
                })
                setListCurrency(temp)
            }
        })();

        (async () => {
            const response = await listApi(logout, "product-type");
            if (response.status) {
                const temp = response.data.map((doc) => {
                    return {
                        id: doc.id,
                        ...doc,
                    }
                })
                setListProductTypes(temp)
            }
        })();

        (async () => {
            if (id) {
                const response = await getProductApi(logout, {id: id});
                if (response.status) {
                    
                    const product = response.data.product;

                    // Crear una copia de `product` con los valores procesados
                    const tempProduct = {
                        ...product,
                        length: product.length ? Number.parseFloat(product.length) : "",
                        width: product.width ? Number.parseFloat(product.width) : "",
                        height: product.height ? Number.parseFloat(product.height) : "",
                        cbm: product.cbm ? Number.parseFloat(product.cbm) : "",
                        weight: product.weight ? Number.parseFloat(product.weight) : ""
                    };

                    
                    setProductImageBase64(response.data.product.image)
                    setInitialValues(tempProduct)
                    setDefaultCompany(response.data.company)
                    setDefaultCategory(response.data.category)
                    setDefaultProductType(response.data.productType)
                    setDefaultCatalog(response.data.catalog)
                    setLoading(false)
                }
            } else {
                setLoading(false)
            }
        })();

    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const changeCompany = (event, value) => {
        if (value !== null) {
            formik.values.idCompany = value.id
            setDefaultCompany({
                id: value.id,
                name: value.name
            })
        } else {
            setDefaultCompany(null)
            formik.values.idCompany = null
        }
    }

    const onChangeCategory = (event, value) => {
        if (value !== null) {
            formik.values.idCategory = value.id
            setDefaultCategory(value)
        } else {
            setDefaultCategory(null)
            formik.values.idCategory = null
        }
    }

    const onChangeCatalog = (event, value) => {
        if (value !== null) {
            formik.values.idCatalog = value.id
            setDefaultCatalog(value)
        } else {
            setDefaultCatalog(null)
            formik.values.idCatalog = null
        }
    }

    const onChangeProductType = (event, value) => {
        if (value !== null) {
            formik.values.idProductType = value.id
            setDefaultProductType(value)
        } else {
            setDefaultProductType(null)
            formik.values.idProductType = null
        }
    }


    if (!listCompany && loading && !initialValues) return ""

    return (
        <>
            <form onSubmit={formik.handleSubmit}>

                {msgSuccess && (
                    <Grid item xs={12} md={12} lg={12}>
                        <Box className="alert-success">
                            Product data has been saved correctly
                        </Box>
                    </Grid>
                )}

                {msgDanger && (
                    <Grid item xs={12} md={12} lg={12}>
                        <Box className="alert-danger">
                            {msgDanger.map((item) => {
                                return (
                                    <span>
                                        {" "}
                                        {item} <br />{" "}
                                    </span>
                                );
                            })}
                        </Box>
                    </Grid>
                )}

                <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={6}>
                        <Box border={1} className="p-20 box-default">
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12} lg={12} className="border-bottom">
                                    <h5 className="p-0 mt-0">Info</h5>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>

                                    { productImageBase64 && (
                                        <div className="my-2">
                                            <img
                                                alt="Product"
                                                src={productImageBase64}
                                                style={{
                                                    height: "100px",
                                                }}
                                            />
                                        </div>
                                    )}
                                    <div className="my-2">
                                        <TextField
                                            variant="outlined"
                                            label="* Image"
                                            className="form-control"
                                            name="file"
                                            type="file"
                                            onChange={(event) => {
                                                let reader = new FileReader()
                                                setProductImage(event.target.files)

                                                let file = event.currentTarget.files[0]
                                                reader.onloadend = () => {
                                                    setProductImageBase64(reader.result)
                                                }
                                                reader.readAsDataURL(file)
                                            }}
                                            fullWidth
                                            size="small"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </div>

                                    {formik.values.id && (
                                        <div className="my-2">
                                            <TextField
                                                variant="outlined"
                                                label="* Product ID"
                                                className="form-control"
                                                name="id"
                                                type="text"
                                                value={formik.values.id}
                                                onChange={formik.handleChange}
                                                disabled={loading}
                                                fullWidth
                                                size="small"
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        </div>
                                    )}

                                    <div className="my-2">
                                        <TextField
                                            variant="outlined"
                                            label="* Net Suit ID"
                                            className="form-control"
                                            name="code"
                                            type="text"
                                            value={formik.values?.code}
                                            onChange={formik.handleChange}
                                            disabled={loading}
                                            fullWidth
                                            size="small"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                        {formik.errors.code && (
                                            <p className="text-100 text-danger">
                                                {formik.errors.code}
                                            </p>
                                        )}
                                    </div>

                                    <div className="my-2">
                                        <TextField
                                            variant="outlined"
                                            label="* Name"
                                            className="form-control"
                                            name="name"
                                            type="text"
                                            value={formik.values?.name}
                                            onChange={formik.handleChange}
                                            disabled={loading}
                                            fullWidth
                                            size="small"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                        {formik.errors.name && (
                                            <p className="text-100 text-danger">
                                                {formik.errors.name}
                                            </p>
                                        )}
                                    </div>

                                    <div className="my-2">
                                        <TextField
                                            variant="outlined"
                                            label="* Description"
                                            className="form-control"
                                            name="description"
                                            type="text"
                                            value={formik.values?.description}
                                            onChange={formik.handleChange}
                                            disabled={loading}
                                            fullWidth
                                            size="small"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                        {formik.errors.description && (
                                            <p className="text-100 text-danger">
                                                {formik.errors.description}
                                            </p>
                                        )}
                                    </div>

                                    <div className="my-2">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={formik.values.isActive}
                                                    onChange={formik.handleChange}
                                                    disabled={loading}
                                                    name="isActive"
                                                    color="primary"
                                                />
                                            }
                                            label="Visible"
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={6} lg={6}>
                        <Box border={1} className="p-20 box-default">
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12} lg={12} className="border-bottom">
                                    <h5 className="p-0 mt-0">Details</h5>
                                </Grid>
                                <Grid item xs={12} md={12} lg={12}>

                                    <div className="my-2">
                                        <TextField
                                            variant="outlined"
                                            label="* Item Reference"
                                            className="form-control"
                                            name="sort"
                                            type="text"
                                            value={formik.values.sort}
                                            onChange={formik.handleChange}
                                            fullWidth
                                            disabled={loading}
                                            size="small"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                        {formik.errors.sort && (
                                            <p className="text-100 text-danger">
                                                {formik.errors.sort}
                                            </p>
                                        )}
                                    </div>

                                    <div className="my-2">
                                        <TextField
                                            variant="outlined"
                                            label="* Pcs per pack"
                                            className="form-control"
                                            name="unitsPerPack"
                                            type="number"
                                            value={formik.values.unitsPerPack}
                                            onChange={formik.handleChange}
                                            fullWidth
                                            disabled={loading}
                                            size="small"
                                            InputLabelProps={{ shrink: true }}
                                        />
                                        {formik.errors.unitsPerPack && (
                                            <p className="text-100 text-danger">
                                                {formik.errors.unitsPerPack}
                                            </p>
                                        )}
                                    </div>

                                    <div className="my-2 mb-2">
                                        <Autocomplete
                                            options={listCompany}
                                            value={defaultCompany}
                                            getOptionLabel={(option) => option.name}
                                            getOptionSelected={(option, value) => option.value === value.value }
                                            onChange={changeCompany}
                                            fullWidth
                                            disabled={loading}
                                            size="small"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="* Company "
                                                    variant="outlined"
                                                    name="idCompany"
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    </div>

                                    <div className="my-2 mb-2">
                                        <Autocomplete
                                            name="idCatalog"
                                            options={listCatalog}
                                            value={defaultCatalog}
                                            getOptionLabel={(option) => option.name}
                                            getOptionSelected={(option, value) =>
                                                option.value === value.value
                                            }
                                            onChange={onChangeCatalog}
                                            fullWidth
                                            disabled={loading}
                                            size="small"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="* Catalog"
                                                    variant="outlined"
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    </div>

                                    <div className="my-2 mb-2">
                                        <Autocomplete
                                            name="idCategory"
                                            options={listCategories}
                                            value= {defaultCategory}
                                            getOptionLabel={(option) => option.name}
                                            getOptionSelected={(option, value) =>
                                                option.value === value.value
                                            }
                                            onChange={onChangeCategory}
                                            fullWidth
                                            disabled={loading}
                                            size="small"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="* Category"
                                                    variant="outlined"
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    </div>

                                    <div className="my-2 mb-2">
                                        <Autocomplete
                                            name="idProductType"
                                            options={listProductTypes}
                                            value={defaultProductType}
                                            getOptionLabel={(option) => option.name}
                                            getOptionSelected={(option, value) =>
                                                option.value === value.value
                                            }
                                            onChange={onChangeProductType}
                                            fullWidth
                                            disabled={loading}
                                            size="small"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="* Product Type"
                                                    variant="outlined"
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>

                <Box border={1} className="p-20 box-default mt-2">
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={12} lg={12} className="border-bottom mb-2">
                            <h5 className="p-0 mt-0">Dimensions information</h5>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={2} lg={2}>
                                    <TextField
                                        label="Length"
                                        name="length"
                                        value={formik.values?.length}
                                        onChange={formik.handleChange}
                                        fullWidth
                                        disabled={loading}
                                        type="number"
                                        size="small"
                                        variant="outlined"
                                        className="form-control"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2} lg={2}>
                                    <TextField
                                        label="Width"
                                        name="width"
                                        value={formik.values?.width}
                                        onChange={formik.handleChange}
                                        fullWidth
                                        disabled={loading}
                                        type="number"
                                        size="small"
                                        variant="outlined"
                                        className="form-control"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2} lg={2}>
                                    <TextField
                                        label="Height"
                                        name="height"
                                        value={formik.values?.height}
                                        onChange={formik.handleChange}
                                        fullWidth
                                        disabled={loading}
                                        type="number"
                                        size="small"
                                        variant="outlined"
                                        className="form-control"
                                        InputLabelProps={{ shrink: true }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2} lg={2}>
                                    <TextField
                                        label="CBM"
                                        name="cbm"
                                        value={formik.values?.cbm}
                                        onChange={formik.handleChange}
                                        fullWidth
                                        disabled={loading}
                                        type="number"
                                        size="small"
                                        variant="outlined"
                                        className="form-control"
                                        InputLabelProps={{ shrink: true, readOnly: true }}
                                        slot={{
                                            input: {
                                              readOnly: true,
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={2} lg={2}>
                                    <TextField
                                        label="Weight"
                                        name="weight"
                                        value={formik.values?.weight}
                                        onChange={formik.handleChange}
                                        fullWidth
                                        disabled={loading}
                                        type="number"
                                        size="small"
                                        variant="outlined"
                                        className="form-control"
                                        InputLabelProps={{ shrink: true }}
                                        endAdornment={<InputAdornment position="end">kg</InputAdornment>}

                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>

                {/* 
                <Box border={1} className="p-20 box-default mt-2">
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={12} lg={12} className="border-bottom mb-2">
                            <h5 className="p-0 mt-0">Currencies</h5>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={12} lg={12}>
                                    <div className="mb-2">
                                        <p>INFO: Assign a default price to the product. If you want different prices depending on the region, you must assign it in the "Currencies by Region" menu. </p>
                                    </div>
                                </Grid>
                                {listCurrency.map((currency, key) => (
                                    <Grid key={key} item xs={12} md={4} lg={4}>
                                        <TextField
                                            label={currency.name}
                                            name={`idCurrency${currency.id}`}
                                            value={formik.values[`idCurrency${currency.id}`]}
                                            onChange={formik.handleChange}
                                            fullWidth
                                            disabled={loading}
                                            type="number"
                                            size="small"
                                            variant="outlined"
                                            className="form-control"
                                            InputLabelProps={{ shrink: true }}

                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                */}

                <Grid container spacing={3} className="p-20">
                    <Grid item xs={12} md={6} lg={6}>&nbsp;</Grid>
                    <Grid item xs={6} md={3} lg={3}>
                        <Button
                            type="button"
                            color="secondary"
                            href="/admin/product/management"
                            fullWidth
                        >
                            { id ? ( <><ArrowBackIos /> Back to list </>) : "Cancel" }
                        </Button>
                    </Grid>
                    <Grid item xs={6} md={3} lg={3}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            fullWidth
                        >
                            { id ? "Modify" : "Create" }
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    )
}

function defaultValue() {
    return {
        code: "",
        description: "",
        idCategory: null,
        idCompany: null,
        idProductType: null,
        image: null,
        isActive: true,
        name: "",
        sort: "",
        unitsPerPack: "",
        minimumProduct: "",
    }
}

function validationSchema() {
    return {
        name: Yup.string().required("Please provide a valid product name"),
        code: Yup.string().required("Please provide a valid Net Suit ID"),
        unitsPerPack: Yup.string().required("Please provide a valid Units per pack"),
        description: Yup.string().required(
            "Please provide a valid product description"
        ),
        idCompany: Yup.number().required("Please select a valid company"),
        idProductType: Yup.number().required(
            "Please select a valid product type"
        ),
    };
}
