import React, {useEffect, useRef, useState} from "react";
import {Link, useHistory, useParams} from "react-router-dom";
import InputText from "../../../../components/Inputs/InputText";
import MsgError from "../../../../components/Admin/MsgError";
import MsgSuccess from "../../../../components/Admin/MsgSuccess";
import {Grid} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import LoadingBar from "../../../../components/Admin/LoadingBar";
import Box from "@material-ui/core/Box";
import {
    apiCreate,
    apiUpdate,
    apiGetPo,
    apiListSemester,
    apiListStatus
} from "../../../../services/admin/po";
import {getWarehouseApi} from "../../../../services/admin/warehouse";
import Products from "./Products";
import useAuth from "../../../../hooks/useAuth";
import InputSelect from "../../../../components/Inputs/InputSelect";
import {listApi} from "../../../../services/admin/request";
import DatePickerSimple from "../../../../components/Inputs/DatePickerSimple";
import HeaderPage from "../../../../components/Admin/HeaderPage";
import InputSwitch from "../../../../components/Inputs/InputSwitch";
import moment from "moment/moment";



export default function Edit() {
    const {id, idWarehouse} = useParams()
    const history = useHistory()
    const {logout} = useAuth()

    const [msgError, setMsgError] = useState([])
    const [msgSuccess, setMsgSuccess] = useState([])
    const [state, setState] = useState(initialValues)
    const [loading, setLoading] = useState(false)
    const [name, setName] = useState("")
    const [company, setCompany] = useState([])
    const [loadingGetInfo, setLoadingGetInfo] = useState(true)
    const [product, setProduct] = useState([])
    const [listStatus, setListStatus] = useState([])
    const [listSemester, setListSemester] = useState([])
    const [warehouse, setWarehouse] = useState([])

    const myRef = useRef(null)

    useEffect(() => {
        getCompany()
        getSemester()
        getStatus()
        getWarehouse()
        if (id) {
            void getContainer()
        } else {
            setLoadingGetInfo(false)
            getWarehouses(idWarehouse)
            state["idWarehouse"] = idWarehouse
            setState({...state})
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const getWarehouses = async (idWarehouse) => {
        const response = await getWarehouseApi(logout, {id: idWarehouse})
        if (response.status) {
            setName(response?.data?.warehouse?.name)
        }
    }

    const getCompany = async () => {
        const response = await listApi(logout, 'company')
        if (response.status) {
            const temp = response.data.map((doc) => {
                return {
                    id: doc.id,
                    name: doc.name,
                }
            })
            setCompany(temp)
        }
    }

    const getSemester = async () => {
        apiListSemester().then(resp => {
            setListSemester(resp.data)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }

    const getStatus = async () => {
        apiListStatus().then(resp => {
            setListStatus(resp.data)
        }).catch(err => {
            setMsgError(err.response?.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }

    const getWarehouse = async () => {
        const response = await listApi(logout, "warehouse")
        const temp = response.data.map((doc) => {
            return {
                id: doc.id,
                name: doc.name
            }
        })
        setWarehouse(temp)
    }

    const getContainer = () => {
        setMsgError([])

        apiGetPo(id).then(resp => {
            setProduct(resp.data?.data.products)
            setState(initialValues(resp.data?.data))
            setLoadingGetInfo(false)

            // Consultar
            getWarehouses(resp.data?.data?.idWarehouse)
        }).catch(err => {
            setLoadingGetInfo(false)
            setMsgError(err.response.data?.msg)
        })
    }

    const handleChange = (event) => {
        state[event.target.name] = event.target.value
        setState({...state})
    }
    const handleChangeSwitch = (event) => {
        state[event.target.name] = event.target.checked
        setState({...state})
    }

    const handleSubmit = (event) => {
        event.preventDefault()

        setLoading(true)
        setMsgError([])
        setMsgSuccess([])

        let error = validationSchema(state)

        if (error.length !== 0) {
            setMsgError(error)
            setLoading(false)
            myRef.current.scrollIntoView()
            return
        }

        if (id) {
            void updatePo()
        } else {
            void createPo()
        }
    }

    const updatePo = () => {

        state['statusIdOriginal'] = state.statusId
        setState({...state})

        let stateTemp = {...state, id: id, products: product}
        apiUpdate(stateTemp).then(resp => {
            setMsgSuccess(resp.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }

    const createPo = () => {
        let stateTemp = {...state, products: product}

        apiCreate(stateTemp).then(resp => {
            setMsgSuccess(resp.data?.msg)
            setLoading(false)
            history.push(`/admin/po/edit/${resp.data?.id}`)
        }).catch(err => {
            setMsgError(err.response.data?.msg)
            setLoading(false)
            myRef.current.scrollIntoView()
        })
    }
    function handleValidation() {

    }
    return (
        <>
            <div ref={myRef}></div>

            <HeaderPage title="PO" subtitle={name} urlBack={`/admin/po/management/`}/>

            <MsgError msg={msgError}/>
            <MsgSuccess msg={msgSuccess}/>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Box border={1} className="p-20 box-default">
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12} lg={12} className="border-bottom">
                                <h5 className="p-0 mt-0">Info</h5>
                            </Grid>
                            <LoadingBar loading={loadingGetInfo}/>

                            <Grid item xs={12} container spacing={1}>
                                <Grid item xs={3} style={{paddingBottom: 10}}>
                                    <InputSelect
                                        label="Company"
                                        name="idCompany"
                                        onChange={handleChange}
                                        value={state?.idCompany}
                                        options={company}

                                    />
                                </Grid>
                                <Grid item xs={3} style={{paddingBottom: 10}}>
                                    <InputSelect
                                        label="Warehouse"
                                        name="idWarehouse"
                                        required
                                        onChange={handleChange}
                                        value={state?.idWarehouse}
                                        options={warehouse}
                                    />
                                </Grid>
                                <Grid item xs={3} >
                                      <InputSelect
                                        label="Semester"
                                        name="semester"
                                        onChange={handleChange}
                                        value={state?.semester}
                                        options={listSemester}
                                    />
                                </Grid>
                                <Grid item xs={3} style={{paddingBottom: 10}}>
                                    <InputSelect
                                        label="Status"
                                        name="statusId"
                                        required
                                        onChange={handleChange}
                                        value={state?.statusId}
                                        options={listStatus}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <InputText
                                        label="Name"
                                        required
                                        name="name"
                                        value={state?.name}
                                        onChange={handleChange}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <InputText
                                        label="Description"
                                        required
                                        name="description"
                                        value={state?.description}
                                        onChange={handleChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputText
                                        label="Creation Date"
                                        required
                                        name="creationDate"
                                        value={state?.creationDate}
                                        onChange={handleChange}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputText
                                        label="Validation Date"
                                        required
                                        name="validationDate"
                                        value={state?.validationDate}
                                        onChange={handleChange}
                                        disabled={true}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <InputSwitch
                                        label="Main PO of Semester"
                                        name="isMain"
                                        checked={state?.isMain}
                                        onChange={handleChangeSwitch}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <hr/>
                                </Grid>
                                <Grid item xs={6}></Grid>
                                <Grid item xs={6}>
                                    <LoadingButton
                                        onClick={handleSubmit}
                                        loading={loading}
                                        loadingPosition="start"
                                        variant="contained"
                                        fullWidth
                                    >
                                        {id ? 'Save' : 'Create'}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>

                <Products
                    idCompany={state?.idCompany}
                    idWarehouse={state?.idWarehouse}
                    setMsgError={setMsgError}
                    product={product}
                    setProduct={setProduct}
                    stateField={state}
                    listStatus={listStatus}
                />
            </Grid>

        </>
    )
        ;
}

function initialValues(props) {

    let creationDate = props?.creationDate ? moment.unix(props?.creationDate).format("Y/M/D") : ""
    let validationDate = props?.validationDate ? moment.unix(props?.validationDate).format("Y/M/D") : ""

    return {
        id: props?.id || "",
        idCompany: props?.company || [],
        name: props?.name || "",
        description: props?.description || "",
        semester: props?.semester || "",
        idWarehouse: props?.warehouse || "",
        products: props?.products || "",
        statusId: props?.statusId || 1,
        statusIdOriginal: props?.statusId || 1,
        isMain: props?.isMain || false,
        creationDate: creationDate || "",
        validationDate: validationDate || "",
    }
}

function validationSchema(schema) {

    let error = []

    if (!schema.semester) {
        error.push("Semester is required")
    }
    
    if (!schema.statusId) {
        error.push("Status is required")
    }
    return error
}
