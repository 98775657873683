import Basic from "../../../containers/Admin/Layouts/Basic";
import ContainersEdit from "../../../containers/Admin/Region/Fields";
import ContainerManagementSubregion from "../../../containers/Admin/Region/Subregion/Management";
import { useParams } from "react-router-dom";

import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";


export default function LabTabs() {
    const { tab } = useParams();
    const [value, setValue] = React.useState(tab);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Basic menu="region">

            <Grid container spacing={3} className="pl-20 pr-20">
                <Grid item xs={12} md={12} lg={12}>
                    <Button
                        type="button"
                        color="primary"
                        href="/admin/region/management"
                        style={{
                            float: "left"
                        }}
                    >
                        <ArrowBackIos />
                        BACK TO LIST
                    </Button>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <h2 className="p-0">Zone Settings</h2>
                </Grid>
            </Grid>

            <TabContext value={value}>

                <AppBar position="static" style={{ background: "#fff", color: "#000", boxShadow: "none" }}>
                    <TabList onChange={handleChange}>
                        <Tab label="Settings" value="1" />
                        <Tab label="Region" value="2" />
                    </TabList>
                </AppBar>

                <TabPanel value="1">
                    <ContainersEdit />
                </TabPanel>
                <TabPanel value="2">
                    <ContainerManagementSubregion />
                </TabPanel>

            </TabContext>
        </Basic>
    );
}
