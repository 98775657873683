import Basic from "../../../containers/Admin/Layouts/Basic"
import PoManagement from "../../../containers/Admin/Po/Management"

export default function Management() {
  return (
    <Basic menu="pos">
          <PoManagement />
    </Basic>
  )
}
