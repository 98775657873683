import Basic from "../../../containers/Admin/Layouts/Basic"
import ShipmentManagement from "../../../containers/Admin/Shipment/Management"

export default function Management() {
    return (
        <Basic menu="shippings">
            <ShipmentManagement />
        </Basic>
    )
}
