import Basic from "../../../containers/Admin/Layouts/Basic"
import ProductEdit from "../../../containers/Admin/Product/Fields"
import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";

export default function Edit() {

    return (
        <Basic menu="products">

            <Grid container spacing={3} className="pl-20 pr-20">
                <Grid item xs={12} md={12} lg={12}>
                    <Button
                        type="button"
                        color="primary"
                        href="/admin/product/management"
                        style={{
                            float: "left"
                        }}
                    >
                        <ArrowBackIos />
                        BACK TO LIST
                    </Button>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <h2 className="p-0">Product Creation</h2>
                </Grid>
            </Grid>

            <ProductEdit />
        </Basic>
    )
}
