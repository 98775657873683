import React from "react";
import {CircularProgress} from "@mui/material";

/**
 * Muestra un botón con un spinner
 *
 * ## PROPS
 * - **label** *string*
 * - **name** *string*
 * - **onClick** *function*
 * - **loading** *boolean*
 * - **positionIn** *string* (Por defecto right)
 * - **variant** *string* (Por defecto contained)
 */
export default function InputButtonLoading(props) {

    const {label, onClick, loading, position, variant, style, disabled} = props

    const color = '#ef6f28'

    const positionIn = position ? position : 'right'
    const variantIn = variant ? variant : 'contained'
    const disabledIn = disabled ? disabled : false
    return (
        <>
            <button
                type="button"
                onClick={onClick}
                className={variantIn === 'contained' ? `btn-primary` : `btn-primary-outline`}
                style={{
                    float: positionIn,
                    ...style
                }}
                disabled={(loading || disabledIn)}
            >
                {loading && (
                    <CircularProgress style={{color: '#fff', marginRight: 10, marginBottom: '-2px'}} size={16}/>)}
                {label}

            </button>

            <style>
                {`
                    /* BUTTON PRIMARY */
                    .btn-primary-outline {
                      padding: 5px 22px;
                      border-radius: 6px;
                      color: #ef6f28;
                      background-color: transparent;
                      border: 1px solid #ef6f28;
                      box-shadow: none;
                      font-size: 15px;
                      cursor: pointer;
                    }
                    
                    .btn-primary {
                      padding: 5px 22px;
                      border-radius: 6px;
                      color: #fff !important;
                      background-color: #ef6f28;
                      border: 1px solid #ef6f28;
                      box-shadow: none;
                      font-size: 15px;
                      font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
                      cursor: pointer;
                      font-weight: 500 !important;
                    }
                    
                    .btn-primary:hover {
                      box-shadow: 0 4px 11px rgba(55, 125, 255, 0) !important;
                    }
                    
                    .btn-primary:disabled, .btn-primary-outline:disabled {
                      background-color: #e7bcc0 !important;
                      color: #ef6f28 !important;
                      border-color: #e7bcc0 !important;
                      cursor: default;
                      font-weight: 500 !important;
                    }
                `}
            </style>
        </>
    )
}
