import React, {useEffect, useState} from "react";
import {Grid, TextField} from "@material-ui/core";
import imgNoData from "../../../../assets/userTheme/assets/svg/components/empty-state-no-data.svg";
import ProductBox from "../ProductBox";
import Footer from "../Footer";
import InputText from "../../../../components/Inputs/InputText";
import useAuth from "../../../../hooks/useAuth";


export default function Cart(props) {

    const {logout, auth} = useAuth();
    const {totalCartProducts, setViewCart, isViewCart, setTotalCartProducts} = props

    const [noData, setNoData] = useState(true);
    const [products, setProducts] = useState([]);
    const [totalPrice, setTotalPrice] = useState([]);
    const [submitForm, setSubmitForm] = useState({
        comment: "",
        ref: "",
        refDisabled: false,
        refTov: "",
        refTovDisabled: false
    });

    useEffect(() => {
        (async () => {
            await setProducts([]);
            updateUi();
            roleStoreRefCheck()
        })()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalCartProducts, isViewCart])

    const roleStoreRefCheck = (event) => {
        if(auth?.role === 'ROLE_STOREG'){
            setSubmitForm({...submitForm,
                ref: auth?.location?.customerRefPo ? auth.location.customerRefPo : null,
                refDisabled: auth?.location?.customerRefPo ? true : false,
                refTov: auth?.location?.customerRefPoTov ? auth?.location?.customerRefPoTov : null,
                refTovDisabled: auth?.location?.customerRefPoTov ? true : false,
            });
            localStorage.setItem('cart_detail', JSON.stringify({...submitForm}))
        }
    };

    const updateUi = () => {
        let cart = JSON.parse(localStorage.getItem("cart_products"));
        setSubmitForm(JSON.parse(localStorage.getItem("cart_detail")));

        setProducts(cart);
        if (cart?.length) {
            setNoData(false)
        } else {
            setNoData(true)
        }
        
        roleStoreRefCheck()
    }

    const handleChange = (event) => {
        submitForm[event.target.name] = event.target.value;
        setSubmitForm({...submitForm});
        localStorage.setItem('cart_detail', JSON.stringify(submitForm))
    };

    const calculatePrice = () => {
        const cart = JSON.parse(localStorage.getItem("cart_products"));

        let tempPrice = [];
        cart?.forEach((storage) => {
            if (!tempPrice[storage.currencySymbol]) {
                tempPrice[storage.currencySymbol] = null;
            }
            tempPrice[storage.currencySymbol] =
                tempPrice[storage.currencySymbol] +
                (storage.priceDefault * storage.quantity);
        });

        setTotalPrice(tempPrice);
    };

    const number_format = (user_input) => {
        for (const property in user_input) {
            user_input[property] = parseFloat(user_input[property]).toFixed(2);
        }
        return user_input;
    }

    return (
        <>

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <InputText
                        label="Num REF/PO#"
                        name="ref"
                        value={submitForm?.ref}
                        onChange={handleChange}
                        disabled={submitForm?.refDisabled}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputText
                        label="Num REF/PO# TOV"
                        name="refTov"
                        value={submitForm?.refTov}
                        onChange={handleChange}
                        disabled={submitForm?.refTovDisabled}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputText
                        label="Comment"
                        name="comment"
                        value={submitForm?.comment}
                        onChange={handleChange}
                        rows={2}
                        multiline={true}
                    />
                </Grid>
                <Grid item xs={12}><div style={{height: 5}}></div></Grid>

                <Grid item xs={12}>
                    {(noData) && (
                        <Grid container spacing={1} className="text-center">
                            <Grid item xs={12}>
                                <img src={imgNoData} className="avatar avatar-xl mb-3" alt="Product"/>
                            </Grid>
                            <Grid item xs={12}>
                                <p className="card-text">The cart is empty</p>
                            </Grid>
                        </Grid>
                    )}
                    {products?.map((doc, key) => {
                        return (
                            <ProductBox
                                key={doc.id}
                                id={doc.id}
                                name={doc.name}
                                description={doc.description}
                                price={doc.price}
                                priceDefault={doc.priceDefault}
                                category={doc.category}
                                image={doc.image}
                                currencySymbol={doc.currencySymbol}
                                unitsPerPack={doc.unitsPerPack}
                                quantityDefault={doc.quantity}
                                unitDefault={doc.unit}
                                sort={doc.sort}
                                calculatePrice={calculatePrice}
                                quantityIsModify={true}
                                stockOnHand={doc.stockOnHand}
                                setTotalCartProducts={setTotalCartProducts}
                                idWarehouse={doc.idWarehouse}
                            />
                        )
                    })}
                </Grid>
                <Grid item xs={12}>
                    <Footer
                        totalPrice={number_format(totalPrice)}
                        setViewCart={setViewCart}
                        isViewCart={isViewCart}
                    />
                </Grid>
            </Grid>

        </>
    )
}
