import Basic from "../../../../containers/Admin/Layouts/Basic"
import ContainersEdit from "../../../../containers/Admin/Region/Subregion/Fields"

export default function Edit() {
  return (
    <Basic menu="region">
          <ContainersEdit  />
    </Basic>
  )
}
