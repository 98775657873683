export default function NavBar(props) {

    const { children } = props;

    return (
        <ul className="nav nav-segment nav-fill mb-5" id="featuresTab" role="tablist">
            {children}
        </ul>
    )


}