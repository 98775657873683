import Basic from "../../../containers/Admin/Layouts/Basic"
import Import from "../../../containers/Admin/Import"

export default function Management() {
    return (
        <Basic menu="import">
            <Import />
        </Basic>
    )
}
