import React from "react"
import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid";

export default function HeaderOrder(props) {


    const {
        selectedRegion,
        selectedSubregion,
        selectedLocation,
        selectedCountry
    } = props

    return (
        <>
            <Grid item xs={12}>
                <Grid container spacing={1}>
                    <Grid item xs={3}>
                        <TextField
                            label="Zone"
                            variant="outlined"
                            disabled={true}
                            style={{width: '100%'}}
                            size="small"
                            value={selectedRegion}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Region"
                            variant="outlined"
                            disabled={true}
                            style={{width: '100%'}}
                            size="small"
                            InputLabelProps={{shrink: true}}
                            value={selectedSubregion}
                        />

                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Country"
                            variant="outlined"
                            disabled={true}
                            style={{width: '100%'}}
                            size="small"
                            InputLabelProps={{shrink: true}}
                            value={selectedCountry}
                        />

                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Store"
                            variant="outlined"
                            disabled={true}
                            style={{width: '100%'}}
                            size="small"
                            InputLabelProps={{shrink: true}}
                            value={selectedLocation}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
