import {BASE_PATH, PATH_BASE_EXTENSION, PATH_BASE_EXTENSION_ADMIN} from "../../utils/constants";
import { authFetch } from "../../utils/fetch";
import {getToken} from "../auth/token";
import axios from "axios";

const baseUrl = `${BASE_PATH}${PATH_BASE_EXTENSION_ADMIN}/category`;

export function apiList(props) {
    const config = {headers: { 'Authorization': `Bearer ${getToken()}`}};

    let query = "";
    if (props.idCompany) {
        query += `&companyId=${props.idCompany}`;
    }

    return axios.get(`${baseUrl}/?1=1${query}`, config)
}

export async function createApi(formData) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/category/create`;
        const token = localStorage.getItem("token");
        const params = {
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(formData),
        };
        const response = await fetch(url, params);
        const result = await response.json();

        return result;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function updateApi(logout, data) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/category/update`;
        const token = localStorage.getItem("token");
        const params = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(data),
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export async function getCategoryApi(logout, data) {
    try {
        const url = `${BASE_PATH}${PATH_BASE_EXTENSION}/admin/category/${data.id}`;
        const token = localStorage.getItem("token");
        const params = {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };
        const result = await authFetch(url, params, logout);
        return result ? result : null;
    } catch (error) {
        console.error(error);
        return null;
    }
}
