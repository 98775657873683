import React, {useEffect, useMemo, useState} from "react";
import {listApi} from "../../../../../services/admin/request";
import useAuth from "../../../../../hooks/useAuth";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import {useParams} from "react-router-dom";
import {createDeleteRelationProduct} from "../../../../../services/admin/product";
import TableBase from "../../../../../components/Admin/TableFilterBaser";

export default function Management() {
    const {id} = useParams()
    const {logout} = useAuth()

    const [locations, setLocations] = useState(null);
    const [selectedRows, setSelectedRows] = useState({})
    const [msgSuccess, setMsgSuccess] = useState(false)
    const [msgDanger, setDsgDanger] = useState(null)

    const columns = useMemo(
        () => [
            {
                Header: "ZONE",
                accessor: "regionName",
            },
            {
                Header: "REGION",
                accessor: "subregionName"
            },
            {
                Header: "COUNTRY",
                accessor: "country",
            },
            {
                Header: "NAME",
                accessor: "name",
            },
            {
                Header: "STORE CATEGORY",
                accessor: "locationCategoryName",
            }
        ],
        []
    )

    useEffect(() => {
        (async () => {
            const response = await listApi(logout, `product/product-locations/${id}`)
            if (response.status) {
                let position = {}
                let temp = []

                response.data.forEach((doc, key) => {
                    temp.push({
                        ...doc
                    })

                    if (doc.isCheckout) {
                        position[`${key}`] = true
                    }
                })

                setSelectedRows(position)
                setLocations(temp)
            }
        })()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    const selectLocations = async () => {
        setMsgSuccess(false)
        setDsgDanger(null)

        let idLocations = []
        for (const position in selectedRows) {
            idLocations.push(locations[position].idLocation)
        }

        let response = await createDeleteRelationProduct({
            idProduct: id,
            idLocations: idLocations
        })

        if (response.status) {
            setMsgSuccess(true)
        } else {
            setDsgDanger(response.error)
        }
    }

    if (!locations) return ""

    return (
        <>
            {msgSuccess && (
                <Grid item xs={12} md={12} lg={12}>
                    <Box className="alert-success">
                        Stores have been successfully added to the product
                    </Box>
                </Grid>
            )}

            {msgDanger && (
                <Grid item xs={12} md={12} lg={12}>
                    <Box className="alert-danger">
                        {msgDanger.map((item) => {
                            return (
                                <span>{item} <br/>{" "}</span>
                            );
                        })}
                    </Box>
                </Grid>
            )}

            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                    <Box border={1} className="box-default">
                        <Box border={1} className="box-default">
                            <TableBase
                                columns={columns}
                                data={locations}
                                selectedRows={selectedRows}
                                onSelectedRowsChange={setSelectedRows}
                                selectable={true}
                                downInfo={false}
                                upInfo={true}
                                isButtonVisible={true}
                                handleClick={selectLocations}
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
}
